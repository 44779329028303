/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import * as XLSX from 'xlsx';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';

import { deepOrange } from '@mui/material/colors';
import { useDropzone } from 'react-dropzone';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ImportationDrawer({ user }) {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fileName, setFileName] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [excelData, setExcelData] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    (async () => {
      try {
        console.log('');
      } catch (error) {
        console.log('error');
      }
    })();
  }, []);

  const handleFileChange = (e) => {
    setLoading(true);
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        // Assume the first sheet is the one you want
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        setExcelData(jsonData);
        setFileName(file.name);
        setFileSize(file.size);
        console.log(jsonData[1]);
      };

      reader.readAsArrayBuffer(file);
    }
    setLoading(false);
  };

  return (
    <Box>
      <Box paddingBottom={3} paddingX={3} sx={{ height: { xs: '75vh', sm: ' 78vh', xl: '85vh' }, overflowX: 'auto', overflowY: 'auto' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ bgcolor: '#F0F0F0', mb: 2, mt: 3 }}
        >
          <AccordionSummary
            expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <Iconify icon="mdi:calendar-outline" sx={{ width: 20, height: 20 }} />
              <Typography variant='body2'><strong>0/2000</strong> contacts importé ce mois</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='body2'>
              Vous pouvez importer au maximum 2000 contacts inscrits. Au-delà de cette limite, des coûts
              supplémentataires seront requis.
              <Typography>Besoin d’en importer plus ?Nous contacter</Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Card sx={{ padding: 3 }}>
          <Stack direction="row" justifyItems="center">
            <Iconify />
            <Typography variant="body2"><strong>Importer des contacts depuis un fihier csv</strong></Typography>
          </Stack>
          <Typography variant='body2'>Les colonnnes de votre fichier CSV doivent avoir les titres suivants :</Typography>
          <Grid container spacing={1} paddingY={2}>
            <Grid item xs={3}>
              <Box
                sx={{
                  borderRadius: '5px',
                  bgcolor: '#F0F0F0',
                  color: '#5E17EB',
                  padding: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='caption'>nom</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                sx={{
                  borderRadius: '5px',
                  bgcolor: '#F0F0F0',
                  color: '#5E17EB',
                  padding: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='caption'>prenoms</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{
                  borderRadius: '5px',
                  bgcolor: '#F0F0F0',
                  color: '#5E17EB',
                  padding: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='caption'>email</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                sx={{
                  borderRadius: '5px',
                  bgcolor: '#F0F0F0',
                  color: '#5E17EB',
                  padding: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='caption'>abonne (yes/no)</Typography>
              </Box>
            </Grid>
          </Grid>
          <Button
            sx={{
              textTransform: 'initial',
              border: '2px solid transparent',
              color: '#5E17EB',
              backgroundColor: 'white',
              borderRadius: '7px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#5E17EB',
                borderColor: 'white',
              },
            }}
            variant="text"
          >
            Télécharger un exemple de fichier CSV
          </Button>
        </Card>
        <Button
          fullWidth
          size="medium"
          sx={{
            marginTop: 2,
            textTransform: 'initial',
            boxShadow: 'none',
            border: '1px dashed gainsboro',
            color: 'black',
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white',
              color: '#5E17EB',
              borderColor: '#5E17EB',
            },
          }}
          variant="contained"
          component="label"
        >
          <input
            hidden
            // accept=""
            id="select-image"
            type="file"
            onChange={handleFileChange}
          />
          <Stack
            padding={2}
            sx={{
              justifyContent: 'space-around',
              alignItems: 'center',
              color: 'black',
            }}
          >
            {loading ? (
              '... laoding'
            ) : excelData ? (
              <>
                <Typography>{fileName}</Typography>
              </>
            ) : (
              <>
                <Iconify icon="material-symbols:upload" sx={{ width: 30, height: 30, color: 'gainsboro' }} />
                <Typography variant='caption'>Cliquer ici pour charger votre fichier</Typography>
                <Typography variant='caption'>Seul les types de fichier .csv, .xsl sont acceptés</Typography>
              </>
            )}
          </Stack>
        </Button>
      </Box>

      <Stack direction="row" justifyContent="end" spacing={2} padding={1} paddingRight={5}>
        <Button
          sx={{
            textTransform: 'initial',
            border: '2px solid transparent',
            color: 'white',
            backgroundColor: 'black',
            boxShadow: 'none',
            borderRadius: '7px',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
              borderColor: 'black',
            },
          }}
          variant="contained"
        >
          Annuler
        </Button>
        <Button
          disabled
          sx={{
            textTransform: 'initial',
            border: '2px solid transparent',
            color: 'white',
            backgroundColor: '#5E17EB',
            borderRadius: '7px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'white',
              color: '#5E17EB',
              borderColor: '#5E17EB',
            },
          }}
          variant="contained"
        >
          Exporter en CSV
        </Button>
      </Stack>
    </Box>
  );
}

import { Typography, Box, Button, Stack } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import EmailEditor from 'react-email-editor';
import { toPng } from 'html-to-image';
import CustomButton from '../../components/CustomButton';
import sample from './sample/sample.json';
import '../crm/component/campaing/style/customStyle.css'; // Fichier de styles personnalisés
import { db } from '../../firebase.config';




const SiteBuilder = () => {
    const emailEditorRef = useRef(null);

    const exportHtml = () => {
        emailEditorRef.current.editor.exportHtml(data => {
            const { design, html } = data;
            // console.log('exported html:', html);
            // Sauvegarder dans Firestore la version HTML et la structure design
        });
        console.log('salut')
        console.log(emailEditorRef.current?.editor)
    };

    const sendTestEmail = async () => {
        emailEditorRef.current.editor.exportHtml((data) => {
            const { html } = data;
            // sendEmail('test@example.com', html, 'Test de campagne');
        });
    };

    const sendPersonalizedEmail = (contact, htmlTemplate) => {
        const personalizedHtml = htmlTemplate
            .replace('{{nom}}', contact.nom)
            .replace('{{eventName}}', contact.eventName);

        // sendEmail(contact.email, personalizedHtml);
    };

    const saveCampaign = (design, html) => {
        const campaign = {
            design,
            html,
            createdAt: new Date(),
            status: 'draft',
        };
        // Sauvegarde dans Firestore
        // firestore.collection('campagnes').add(campaign);
    };

    const loadSavedCampaign = async (campaignId) => {
        // const campaign = await firestore.collection('campagnes').doc(campaignId).get();
        // emailEditorRef.current.editor.loadDesign(campaign.data().design);
    };

    // chargement d'un template dans l'editeur
    const loadTemplateFromDatabase = async (templateId) => {
        // const template = await fetchTemplateFromDatabase(templateId);
        // emailEditorRef.current.editor.loadDesign(template.design);
    };

    const handleImageUpload = async (file) => {
        // const storage = getStorage();
        // const storageRef = ref(storage, `uploads/${file.name}`);
        // await uploadBytes(storageRef, file);
        // const url = await getDownloadURL(storageRef);

        // Ajouter l'image dans l'éditeur d'email
        // emailEditorRef.current.editor.addImage(url);
    };

    const removeUndefinedFields = (obj) => {
        return JSON.parse(JSON.stringify(obj));
    };

    const saveTemplateToFirebase = async () => {
        emailEditorRef.current.editor.exportHtml(async (data) => {
          const { design, html } = data;
      
          const ddesign = removeUndefinedFields(design);
      
          // Assurez-vous d'avoir le bon conteneur ici
          const editorContainer = document.getElementById('email-editor-container');
      
          try {
            const previewImage = await toPng(editorContainer); // Capture du conteneur contenant l'éditeur
            console.log(previewImage);
            const storage = getStorage();
            const storageRef = ref(storage, `templatePreviews/${new Date().getTime()}.png`);
            const snapshot = await uploadString(storageRef, previewImage, 'data_url');

            // Obtenir l'URL de téléchargement de l'image
            const imageUrl = await getDownloadURL(snapshot.ref);
      
            if (ddesign && html) {
              try {
                // Sauvegarde dans Firestore
                const docRef = await addDoc(collection(db, 'publicTemplates'), {
                  ddesign,
                  html,
                  imageUrl,
                  createdAt: new Date(),
                });
                console.log('Template enregistré avec succès, ID:', docRef.id);
              } catch (e) {
                console.error('Erreur lors de la sauvegarde du template:', e);
              }
            } else {
              console.error('Design ou HTML est undefined, vérifiez votre template.');
            }
          } catch (error) {
            console.error('Erreur lors de la capture du preview image:', error);
          }
        });
      };

    

    const onLoad = () => {
        /* const editor = emailEditorRef.current.editor;
        
        // Charger les paramètres personnalisés dans l'éditeur
        editor.loadDesign({
          body: {
            rows: [],
          },
        });
    
        // Ajouter le slider pour arrondir les bords des images
        const imageSettings = editor.getToolboxConfig('image');
    
        if (imageSettings) {
          imageSettings.properties.push({
            label: 'Arrondir les bords',
            name: 'borderRadius',
            type: 'slider',
            default: 0,
            min: 0,
            max: 50,
          });
    
          editor.setToolboxConfig('image', imageSettings);
        }
      */
        emailEditorRef.current.editor.loadDesign(sample);

    };

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));


    const testImageCapture = async () => {
        await delay(1000);
        const editorContainer = document.getElementById('email-editor-container');
    
        try {
            // Capturer l'image du conteneur
            editorContainer.style.visibility = 'visible';
        editorContainer.style.position = 'relative';
        const previewImage = await toPng(editorContainer, { backgroundColor: '#ffffff' });
    
            // Créer un élément image pour afficher la capture
            const img = new Image();
            img.src = previewImage;
    
            // Ajouter l'image capturée au document pour vérification
            document.body.appendChild(img);
    
            console.log('Image capturée avec succès !');
        } catch (error) {
            console.error('Erreur lors de la capture de l\'image :', error);
        }
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Stack direction='row' justifyContent='space-between' sx={{ flex: '0 0 auto', padding: 2, backgroundColor: '#f5f5f5' }}>
                <Typography variant='body2'><strong>Design</strong></Typography>
                <Stack direction='row' spacing={1}>
                    <CustomButton type='secondaire' buttonText='Envoyer un tester' onClick={testImageCapture} />
                    <CustomButton type='primaire' buttonText='Enregistrer' onClick={saveTemplateToFirebase} />
                </Stack>
            </Stack>

            <div id="email-editor-container" style={{ flex: 1 }}>
                <EmailEditor
                    ref={emailEditorRef}
                    options={{
                        appearance: {
                            theme: 'dark',
                            panels: {
                                tools: {
                                    dock: 'left',
                                    social: {
                                        enabled: true,

                                    }
                                },
                            },
                        }
                    }}
                    onLoad={onLoad} style={{ height: '89.5vh' }} />
            </div>
        </Box>
    );
};

export default SiteBuilder;

import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './tableHeader';
import TableRow from './tableRow';


const styles = ({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#5E17EB',
    },
});

const ItemsTable = ({ billets, commandes }) => (
    <View style={styles.tableContainer}>
        <TableHeader />
        {
            <TableRow items1={billets} items2={commandes} /> 
        }
        
        
    </View>
);

export default ItemsTable;
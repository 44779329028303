/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Modal,
  Radio,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  Timestamp,
  setDoc,
  serverTimestamp,
  addDoc,
  getCountFromServer,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import { db } from '../../firebase.config';

import { UserAuth } from '../../context/AuthContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CheckOrg from '../../components/CheckOrg';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  p: 3,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const params = useParams;

// ----------------------------------------------------------------------

export default function Coordonnees() {
  const { user } = UserAuth();
  const [refresh, setRefresh] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [opMobile, setOpMobile] = React.useState();
  const [opMobileError, setOpMobileError] = React.useState(false);
  const [moyenVir, setMoyenVir] = React.useState([]);
  const [isPaie, setIsPaie] = React.useState();
  // loading
  const [loading, setLoading] = React.useState(false);

  const createMoyenVir = (doc) => {
    setMoyenVir((events) => [...events, doc]); // ajouter a la fin
  };

  // Field bancaire
  const [nom, setNom] = React.useState('');
  const [nomError, setNomError] = React.useState(false);
  const [numBank, setBank] = React.useState('');
  const [numBankError, setBankError] = React.useState(false);
  const [codeSwift, setSwift] = React.useState('');
  const [codeSwiftError, setSwiftError] = React.useState(false);
  const [id, setId] = React.useState();

  // field Mobile

  const [num, setNum] = React.useState('');
  const [numError, setNumError] = React.useState(false);

  const update = async (e) => {
    setLoading(true);
    console.log(e);

    try {
      const refDesc = doc(db, `organisations/${id}/infoPaie`, `${moyenVir[activeModif].id}`);

      if (e === 0) {
        await updateDoc(refDesc, {
          name: nom,
          iban: numBank,
          swift: codeSwift,
          updateDate: serverTimestamp(),
        });
      } else if (e === 1) {
        await updateDoc(refDesc, {
          name: nom,
          op: opMobile,
          mobile: num,
          updateDate: serverTimestamp(),
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addVir = async () => {
    setLoading(true);
    let tmpError = false;
    if (nom === '') {
      setNomError(true);
    }
    if (numBank === '' && selectedValue === 'b') {
      setBankError(true);
      tmpError = true;
    }
    if (codeSwift === '' && selectedValue === 'b') {
      setSwiftError(true);
      tmpError = true;
    }
    if (opMobile === null && selectedValue === 'a') {
      setOpMobileError(true);
      tmpError = true;
    }
    if (num === '' && selectedValue === 'a') {
      setNumError(true);
      tmpError = true;
    }
    try {
      if (!tmpError) {
        const orgRef = doc(db, `organisations`, `${id}`);
        const docSnap = await getDoc(orgRef);
        if (docSnap.exists()) {
          await updateDoc(orgRef, {
            infoPaie: docSnap.data().infoPaie + 1,
          });

          if (selectedValue === 'b') {
            await addDoc(collection(db, `organisations/${id}/infoPaie`), {
              name: nom,
              iban: numBank,
              swift: codeSwift,
              type: 0,
              createDate: serverTimestamp(),
            });
          } else if (selectedValue === 'a') {
            await addDoc(collection(db, `organisations/${id}/infoPaie`), {
              name: nom,
              op: opMobile,
              mobile: num,
              type: 1,
              createDate: serverTimestamp(),
            });
          }
        }
      }

      setLoading(false);
    } catch (error) {
      console.log('error');
      console.log(error);
      setLoading(false);
    }
    if (!tmpError) {
      setRefresh(!refresh);
      changeScreen('0');
    }
  };

  // pin
  const [pin, setPin] = React.useState(['', '', '', '']);
  const [isPin, setIsPin] = React.useState(false);
  const [verifiedPin, setVerifiedPin] = React.useState(false);
  const [isVerified, setIsVerified] = React.useState(false);

  const handlePinChange = (index, value) => {
    const newPin = [...pin];
    if (/^\d$/.test(value)) {
      newPin[index] = value;
    }

    if (newPin[index] !== undefined && newPin[index] !== null) {
      newPin[index] = value.charAt(value.length - 1);
    }
    if (index > 0 && value === '') {
      // If a digit is deleted, move focus to the previous input
      document.getElementById(`pin-input-${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      // Move focus to the next input
      document.getElementById(`pin-input-${index + 1}`).focus();
    }

    setPin(newPin);

    // Check if all 4 digits are entered
    if (newPin.every((digit) => digit !== '')) {
      handleSavePin(newPin.join(''));
    }
  };

  const handleSavePin = async (e) => {
    setLoading(true);
    console.log(e);

    try {
      const refDesc = doc(db, `organisations/`, `${id}`);

      if (e !== '') {
        await updateDoc(refDesc, {
          pin: e,
        });
      }
      setPin(['', '', '', '']);
      setLoading(false);
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // pin verification
  // Modal Pinoursement
  const [openPinModal, setOpenPinModal] = React.useState(false);
  const [loadingPin, setLoadingPin] = React.useState(false);
  const handleOpenPinModal = () => setOpenPinModal(true);
  const handleClosePinModal = () => setOpenPinModal(false);

  const show = () => {
    if (isPin) {
      handleOpenPinModal();
    } else {
      setIsVerified(true);
    }
  };
  const verified = (index, value) => {
    const newPin = [...pin];
    if (/^\d$/.test(value)) {
      newPin[index] = value;
    }

    if (newPin[index] !== undefined && newPin[index] !== null) {
      newPin[index] = value.charAt(value.length - 1);
    }
    if (index > 0 && value === '') {
      // If a digit is deleted, move focus to the previous input
      document.getElementById(`pin-input-${index - 1}`).focus();
    } else if (index < 3 && value !== '') {
      // Move focus to the next input
      document.getElementById(`pin-input-${index + 1}`).focus();
    }

    setPin(newPin);

    // Check if all 4 digits are entered
    if (newPin.every((digit) => digit !== '')) {
      makePinVerification(newPin.join(''));
    }
  };

  const makePinVerification = async (e) => {
    // setLoadingVerification(true);
    try {
      //
      const q1 = doc(db, `organisations`, id);
      const orgSnap = await getDoc(q1);
      const tmp = orgSnap.data().pin;
      console.log('PIN : ');
      console.log(tmp);
      if (tmp === e) {
        console.log('PIN is correct : ');
        // carhgement des informations
        const q = collection(db, `organisations/${id}/infoPaie`);
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(element => {
          console.log(element)
          setNom(element.data().name)
          if (element.data().type === 0) {
            setSelectedValue('b')
            setBank(element.data().iban)
            setSwift(element.data().swift)
          } else if (element.data().type === 1) {
            setSelectedValue('a')
          }
        });
        setIsVerified(true);
        handleClosePinModal();
      } else {
        
        console.log('PIN : ');
      }
    } catch (error) {
      console.log(error);
    }
    document.getElementById(`pin-input-${0}`).focus();
    setPin(['', '', '', '']);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setMoyenVir([]);
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          // info sur l'organisation
          const q1 = doc(db, `organisations`, docSnap.data().idOrg);
          const orgSnap = await getDoc(q1);
          const tmp = orgSnap.data().pin;
          console.log('PIN : ');
          console.log(tmp);
          if (tmp !== undefined) {
            setIsPin(true);
          } else {
            setIsPin(false);
          }
          // info de paie
          console.log(docSnap.data().idOrg);
          setId(docSnap.data().idOrg);
          const q = collection(db, `organisations/${docSnap.data().idOrg}/infoPaie`);
          const querySnapshot = await getCountFromServer(q);
          console.log('collection ', querySnapshot.data().count);
          if (querySnapshot.data().count > 0) {
            setIsPaie(true);
          } else {
            setIsPaie(false);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [user.uid, refresh]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectedValue, setSelectedValue] = React.useState();

  const handleChangePaie = (event) => {
    setSelectedValue(event);
  };
  const [activeModif, setActiveModif] = React.useState();
  const [screen, setScreen] = React.useState('0');
  const handleModif = (index) => {
    console.log(index);
    setActiveModif(index);
    setNom(moyenVir[index].data().name);
    if (moyenVir[index].data().type === 0) {
      setSwift(moyenVir[index].data().swift);
      setBank(moyenVir[index].data().iban);
    }
    if (moyenVir[index].data().type === 1) {
      setOpMobile(moyenVir[index].data().op);
      setNum(moyenVir[index].data().mobile);
    }
    setScreen('2');
  };

  const changeScreen = (e) => {
    setNom('');
    setOpMobile();
    setNum('');
    setBank('');
    setSwift('');
    setScreen(e);
  };

  return (
    <Page title="Organisation">
      {/** Modal validation de token */}
      <Modal
        open={openPinModal}
        onClose={handleClosePinModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: '7px' }}
      >
        <Box sx={styleModal}>
          <Typography variant="h6">Token de validation</Typography>
          <Box sx={{ p: 2 }} dir="ltr">
            <Typography variant="body1">Veuillez entrez votre code pin.</Typography>
          </Box>
          <Box sx={{ p: 2 }} dir="ltr">
            <Stack direction="row" spacing={2} justifyContent="center">
              {pin.map((digit, index) => (
                <Box key={index}>
                  <TextField
                    key={index}
                    id={`pin-input-${index}`}
                    type="password"
                    variant="outlined"
                    size="medium"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => verified(index, e.target.value)}
                    sx={{ width: '35px' }}
                  />
                </Box>
              ))}
            </Stack>
          </Box>
        </Box>
      </Modal>
      {/** Fin Modal validation de token */}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Coordonnées Bancaire | Mobile
            <br />
            <Typography variant="body2">Vous permets de faire des demandes de virement</Typography>
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: '100%',
                borderRadius: '7px',
                padding: 3,
                border: '2px solid transparent',
                bgcolor: '#f2f2f2',
                borderColor: '#f2f2f2',
              }}
            >
              <Typography textAlign="Start">
                <strong>Information de reversement</strong>
              </Typography>
              <Typography variant="body2" textAlign="Start">
                Ces informations seront utilisé pour vous reverser votre argent à la fin de vos evenement
              </Typography>
              <Box paddingTop={2}>
                {isVerified ? (
                  <>
                    <Stack direction="row" alignItems="center" spacing={2} paddingY={2}>
                      {selectedValue === 'b' ? (
                        <Box
                          padding={3}
                          sx={{
                            borderRadius: '7px',
                            border: '2px solid transparent',
                            cursor: 'pointer',
                            bgcolor: '#F2ECFD',
                            borderColor: '#5E17EB',
                            '&:hover': {
                              borderColor: '#5E17EB',
                            },
                          }}
                          onClick={() => handleChangePaie('b')}
                        >
                          <Typography> Transfére Bancaire</Typography>
                        </Box>
                      ) : (
                        <Box
                          padding={3}
                          sx={{
                            borderRadius: '7px',
                            border: '2px solid transparent',
                            cursor: 'pointer',
                            bgcolor: '#F2ECFD',
                            borderColor: '#F2ECFD',
                            '&:hover': {
                              borderColor: '#5E17EB',
                            },
                          }}
                          onClick={() => handleChangePaie('b')}
                        >
                          <Typography> Transfére Bancaire</Typography>
                        </Box>
                      )}

                      {selectedValue === 'a' ? (
                        <Box
                          padding={3}
                          onClick={() => handleChangePaie('a')}
                          sx={{
                            borderRadius: '7px',
                            border: '2px solid transparent',
                            cursor: 'pointer',
                            bgcolor: '#F2ECFD',
                            borderColor: '#5E17EB',
                            '&:hover': {
                              borderColor: '#5E17EB',
                            },
                          }}
                        >
                          <Typography> Transfére Mobile</Typography>
                        </Box>
                      ) : (
                        <Box
                          padding={3}
                          onClick={() => handleChangePaie('a')}
                          sx={{
                            borderRadius: '7px',
                            border: '2px solid transparent',
                            cursor: 'pointer',
                            bgcolor: '#F2ECFD',
                            borderColor: '#F2ECFD',
                            '&:hover': {
                              borderColor: '#5E17EB',
                            },
                          }}
                        >
                          <Typography> Transfére Mobile</Typography>
                        </Box>
                      )}
                    </Stack>
                    {selectedValue === 'b' && (
                    <Stack sx={{ bgcolor: 'white' }} padding={2} spacing={2}>
                      <TextField
                        label="Nom et prenom"
                        placeholder="Ex: Diagou christophe"
                        value={nom}
                        onChange={(e) => setNom(e.target.value)}
                        id="nameField"
                        type="text"
                        fullWidth
                        error={nomError}
                        helperText={nomError && nom === '' ? 'Ce champ est requis' : ''}
                      />
                      <TextField
                        label="Numéro de compte"
                        value={numBank}
                        onChange={(e) => setBank(e.target.value)}
                        placeholder="Entrer le numero de compte"
                        id="licenceField"
                        type="text"
                        error={numBankError}
                        helperText={numBankError && numBank === '' ? 'Ce champ est requis' : ''}
                        fullWidth
                      />
                      <TextField
                        label="Code swift"
                        value={codeSwift}
                        onChange={(e) => setSwift(e.target.value)}
                        placeholder="Entrer le numero SWIFT"
                        id="licenceField"
                        type="text"
                        error={codeSwiftError}
                        helperText={codeSwiftError && codeSwift === '' ? 'Ce champ est requis' : ''}
                        fullWidth
                      />
                    </Stack> )}
                    {selectedValue === 'a' && (
                      <Stack sx={{ bgcolor: 'white' }} padding={2} spacing={2}>
                        <TextField
                        label="Nom du titulaire"
                          value={nom}
                          onChange={(e) => setNom(e.target.value)}
                          placeholder="Ex: Diagou christophe"
                          id="nameField"
                          type="text"
                          error={nomError}
                          helperText={nomError && nom === '' ? 'Ce champ est requis' : ''}
                          fullWidth
                        />
                        <FormControl fullWidth>
                          <Select
                            value={parseInt(opMobile)}
                            onChange={(e) => setOpMobile(e.target.value)}
                            labelId="demo-simple-select-label"
                            id="opMobile"
                            placeholder="Entrer le nom"
                            error={opMobileError}
                          >
                            <MenuItem value={-1}>Operateur Mobile</MenuItem>
                            <MenuItem value={0}>Orange</MenuItem>
                            <MenuItem value={1}>MTN</MenuItem>
                            <MenuItem value={2}>Moov</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                        label='Numero mobile'
                          value={num}
                          onChange={(e) => setNum(e.target.value)}
                          placeholder="+225 09999999"
                          id="licenceField"
                          type="number"
                          error={numError}
                          helperText={numError && num === '' ? 'Ce champ est requis' : ''}
                          fullWidth
                        />
                      </Stack>
                    )}
                    <Stack direction="row" spacing={2}>
                      <LoadingButton
                        sx={{
                          textTransform: 'initial',
                          border: '2px solid black',
                          color: 'black',
                          backgroundColor: 'white',
                          borderRadius: '7px',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: 'black',
                            borderColor: 'black',
                          },
                        }}
                        onClick={() => {
                          setIsVerified(false);
                        }}
                        color="secondary"
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                        fullWidth
                      >
                        Fermer
                      </LoadingButton>
                      <LoadingButton
                        fullWidth
                        sx={{
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          color: 'white',
                          backgroundColor: '#5E17EB',
                          borderRadius: '7px',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: '#5E17EB',

                            borderColor: '#5E17EB',
                          },
                        }}
                        onClick={() => (isPaie ? update() : addVir())}
                        color="secondary"
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                      >
                        {isPaie ? 'Modifier' : 'Ajouter'}
                      </LoadingButton>
                    </Stack>
                  </>
                ) : (
                  <LoadingButton
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',

                        borderColor: '#5E17EB',
                      },
                    }}
                    onClick={() => show()}
                    color="secondary"
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                  >
                    {isPaie ? 'Voir les informations' : 'Ajoutez vos informations'}
                  </LoadingButton>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: '100%',
                borderRadius: '7px',
                padding: 3,
                border: '2px solid transparent',
                cursor: 'pointer',
                bgcolor: '#f2f2f2',
                borderColor: '#f2f2f2',
                
              }}
            >
              <Typography textAlign="Start">
                <strong>Information sur l'organisation</strong>
              </Typography>
              <Typography variant="body2" textAlign="Start">
                Ces informations seront utilisé pour vous reverser votre argent à la fin de vos evenement
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: '100%',
                borderRadius: '7px',
                padding: 3,
                border: '2px solid transparent',
                cursor: 'pointer',
                bgcolor: '#f2f2f2',
                borderColor: '#f2f2f2',
                '&:hover': {
                  borderColor: '#5E17EB',
                },
              }}
            >
              <Typography textAlign="Start">
                <strong>Sécurité</strong>
              </Typography>
              <Typography variant="body2" textAlign="Start">
                Sécurisez l'accès à vos informations de reversement
              </Typography>
              <Box paddingTop={2}>
                {isPin ? (
                  <Typography>Vous avez configuré un code PIN. Pour le changer, contactez notre support.</Typography>
                ) : (
                  <>
                    <Typography>Choissisez un code pin :</Typography>
                    <Stack direction="row" spacing={2} padding={2} justifyContent="center">
                      {pin.map((digit, index) => (
                        <Box key={index}>
                          <TextField
                            key={index}
                            id={`pin-input-${index}`}
                            type="password"
                            variant="outlined"
                            size="medium"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => handlePinChange(index, e.target.value)}
                            sx={{ width: '35px' }}
                          />
                        </Box>
                      ))}
                    </Stack>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: '100%',
                borderRadius: '7px',
                padding: 3,
                border: '2px solid transparent',
                bgcolor: '#f2f2f2',
                borderColor: '#f2f2f2',
                '&:hover': {
                  borderColor: '#5E17EB',
                },
              }}
            >
              <Typography textAlign="Start">
                <strong>Conditions d'utilisation</strong>
              </Typography>
              <Typography variant="body2" textAlign="Start">
                Ces informations seront utilisé pour vous reverser votre argent à la fin de vos evenement
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Coordonnées Bancaire | Mobile
            <br />
            <Typography variant="body2">Vous permets de faire des demandes de virement</Typography>
          </Typography>
        </Stack>
        <CheckOrg />
        <Box>
          {screen === '0' && moyenVir.length === 0 && (
            <Box textAlign="end">
              <Button
                variant="contained"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'white',
                  backgroundColor: '#5E17EB',
                  borderRadius: '7px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',

                    borderColor: '#5E17EB',
                  },
                }}
                onClick={() => changeScreen('1')}
              >
                Ajouter
              </Button>
            </Box>
          )}
          {screen === '1' || screen === '2' ? (
            <Box textAlign="start" marginBottom={5}>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'black',
                  backgroundColor: 'white',
                  borderRadius: '7px',
                  borderColor: 'black',
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'black',
                  },
                }}
                onClick={() => changeScreen('0')}
              >
                Retour
              </Button>
            </Box>
          ) : null}

           Ajout de moyen de virement 
          {screen === '1' ? (
            <Container>
              <Alert severity="info" sx={{}}>
                Attention ! Ces informations nous sont indispensables pour effectuer vos virements !
              </Alert>
              <Stack direction="row" alignItems="center" spacing={2} paddingY={2}>
                {selectedValue === 'b' ? (
                  <Box
                    padding={5}
                    sx={{
                      borderRadius: '7px',
                      border: '2px solid transparent',
                      cursor: 'pointer',
                      bgcolor: '#F2ECFD',
                      borderColor: '#5E17EB',
                      '&:hover': {
                        borderColor: '#5E17EB',
                      },
                    }}
                    onClick={() => handleChangePaie('b')}
                  >
                    <Typography> Transfére Bancaire</Typography>
                  </Box>
                ) : (
                  <Box
                    padding={5}
                    sx={{
                      borderRadius: '7px',
                      border: '2px solid transparent',
                      cursor: 'pointer',
                      bgcolor: '#F2ECFD',
                      borderColor: '#F2ECFD',
                      '&:hover': {
                        borderColor: '#5E17EB',
                      },
                    }}
                    onClick={() => handleChangePaie('b')}
                  >
                    <Typography> Transfére Bancaire</Typography>
                  </Box>
                )}

                {selectedValue === 'a' ? (
                  <Box
                    padding={5}
                    onClick={() => handleChangePaie('a')}
                    sx={{
                      borderRadius: '7px',
                      border: '2px solid transparent',
                      cursor: 'pointer',
                      bgcolor: '#F2ECFD',
                      borderColor: '#5E17EB',
                      '&:hover': {
                        borderColor: '#5E17EB',
                      },
                    }}
                  >
                    <Typography> Transfére Mobile</Typography>
                  </Box>
                ) : (
                  <Box
                    padding={5}
                    onClick={() => handleChangePaie('a')}
                    sx={{
                      borderRadius: '7px',
                      border: '2px solid transparent',
                      cursor: 'pointer',
                      bgcolor: '#F2ECFD',
                      borderColor: '#F2ECFD',
                      '&:hover': {
                        borderColor: '#5E17EB',
                      },
                    }}
                  >
                    <Typography> Transfére Mobile</Typography>
                  </Box>
                )}
              </Stack>
              <Divider />
              {selectedValue === 'b' && (
                <Box>
                  <>
                    Coordonnées bancaires 
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Typography variant="h6" gutterBottom>
                        Transfère Bancaire
                      </Typography>
                      <Box sx={{ p: 1 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Nom du titulaire</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              placeholder="Ex: Diagou christophe"
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              id="nameField"
                              type="text"
                              fullWidth
                              error={nomError}
                              helperText={nomError && nom === '' ? 'Ce champ est requis' : ''}
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Code IBAN (N° de compte beneficiaire)</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={numBank}
                              onChange={(e) => setBank(e.target.value)}
                              placeholder="Entrer le numero de compte"
                              id="licenceField"
                              type="text"
                              error={numBankError}
                              helperText={numBankError && numBank === '' ? 'Ce champ est requis' : ''}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Code BIC / SWIFT</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={codeSwift}
                              onChange={(e) => setSwift(e.target.value)}
                              placeholder="Entrer le numero SWIFT"
                              id="licenceField"
                              type="text"
                              error={codeSwiftError}
                              helperText={codeSwiftError && codeSwift === '' ? 'Ce champ est requis' : ''}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </>
                </Box>
              )}

              <Box>
                <>
                 Coordonnées mobile 
                  {selectedValue === 'a' && (
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Typography variant="h6" gutterBottom>
                        Transfère mobile
                      </Typography>
                      <Box sx={{ p: 1 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Nom du titulaire</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              placeholder="Ex: Diagou christophe"
                              id="nameField"
                              type="text"
                              error={nomError}
                              helperText={nomError && nom === '' ? 'Ce champ est requis' : ''}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Opérateur mobile</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <FormControl fullWidth>
                              <Select
                                value={parseInt(opMobile)}
                                onChange={(e) => setOpMobile(e.target.value)}
                                labelId="demo-simple-select-label"
                                id="opMobile"
                                placeholder="Entrer le nom"
                                error={opMobileError}
                              >
                                <MenuItem value={0}>Orange</MenuItem>
                                <MenuItem value={1}>MTN</MenuItem>
                                <MenuItem value={2}>Moov</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Numéro mobile</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={num}
                              onChange={(e) => setNum(e.target.value)}
                              placeholder="+225 09999999"
                              id="licenceField"
                              type="number"
                              error={numError}
                              helperText={numError && num === '' ? 'Ce champ est requis' : ''}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  )}
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={2} md={2}>
                        <Button
                          fullWidth
                          variant="contained"
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            color: 'black',
                            backgroundColor: 'white',
                            borderRadius: '7px',
                            borderColor: 'black',
                            '&:hover': {
                              backgroundColor: 'black',
                              color: 'white',
                              borderColor: 'black',
                            },
                          }}
                          onClick={() => changeScreen('0')}
                        >
                          Annuler
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <LoadingButton
                          fullWidth
                          variant="contained"
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            color: 'white',
                            backgroundColor: '#5E17EB',
                            borderRadius: '7px',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: '#5E17EB',

                              borderColor: '#5E17EB',
                            },
                          }}
                          onClick={() => addVir()}
                          loading={loading}
                          loadingPosition="end"
                          disableElevation
                        >
                          Enregister
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              </Box>
            </Container>
          ) : 
          screen === '0' ? (
            <Container>
              {
                moyenVir.length > 0 && <Grid container justifyContent="center" paddingTop={2}>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" spacing={1} justifyContent="end">
                    <Iconify icon="fluent:edit-20-regular" sx={{cursor: 'pointer'}} onClick={() => handleModif(0)} />
                  </Stack>
                </Grid>
              </Grid>
              }
              
              {moyenVir.length > 0 && moyenVir[0].data().type === 1 ? (
                <Grid container justifyContent="center" padding={1}>
                  <Grid item xs={12} sm={6}>
                    <Card
                      sx={{
                        borderRadius: '7px',
                        padding: 3,
                        border: '2px solid transparent',
                        cursor: 'pointer',
                        bgcolor: '#F2ECFD',
                        borderColor: '#F2ECFD',
                        '&:hover': {
                          borderColor: '#5E17EB',
                        },
                      }}
                    >
                      <Typography textAlign="center">
                        <strong>Coordonné Mobile</strong>
                      </Typography>
                      <Divider/>
                      <Stack direction="row" spacing={2} justifyContent="space-between" paddingTop={2}>
                        <Typography>
                          <strong>Opérateur mobile :</strong>
                        </Typography>
                        <Typography>
                          {' '}
                          {moyenVir[0].data().op === 1
                            ? 'MTN'
                            : moyenVir[0].data().op === 0
                            ? 'Orange'
                            : moyenVir[0].data().op === 2
                            ? 'Moov'
                            : null}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} justifyContent="space-between" paddingY={1}>
                        <Typography>
                          <strong>Titulaire :</strong>
                        </Typography>
                        <Typography>{moyenVir[0].data().name}</Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} justifyContent="space-between">
                        <Typography>
                          <strong>Numéro : </strong>
                        </Typography>

                        <Stack direction="row" spacing={1}>
                          <Typography>{moyenVir[0].data().mobile}</Typography>
                        </Stack>
                      </Stack>
                      
                    </Card>
                  </Grid>
                </Grid>
              ) : moyenVir.length > 0 && moyenVir[0].data().type === 0 ? (
                <Grid container justifyContent="center" padding={1}>
                  <Grid item xs={12} sm={6}>
                    <Card
                      sx={{
                        borderRadius: '7px',
                        padding: 3,
                        border: '2px solid transparent',
                        cursor: 'pointer',
                        bgcolor: '#F2ECFD',
                        borderColor: '#F2ECFD',
                        '&:hover': {
                          borderColor: '#5E17EB',
                        },
                      }}
                    >
                      <Typography textAlign="center">
                        <strong>Coordonné Bancaire</strong>
                      </Typography>
                      <Stack direction="row" spacing={2} justifyContent="space-between">
                        <Typography>
                          <strong>Titulaire :</strong>
                        </Typography>
                        <Typography>{moyenVir[0].data().name}</Typography>
                      </Stack>
                      <Stack direction="row" spacing={2} justifyContent="space-between">
                        <Typography>
                          <strong>IBAN : </strong>
                        </Typography>

                        <Stack direction="row" spacing={1}>
                          <Typography>{moyenVir[0].data().iban}</Typography>
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={2} justifyContent="space-between">
                        <Typography>
                          <strong>SWIFT /BIC : </strong>
                        </Typography>
                        <Typography>{moyenVir[0].data().nom}</Typography>
                      </Stack>
                    </Card>
                  </Grid>
                  
                </Grid>
              ) : null}
            </Container>
          ) : 
          screen === '2' ? (
            <Container>
              <Box>
                <>
                 
                  {moyenVir[activeModif].data().type === 0 ? (
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Box sx={{ p: 1 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Nom du titulaire</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              placeholder="Entrer le nom"
                              id="nameField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Code IBAN (N° de compte beneficiaire)</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={numBank}
                              onChange={(e) => setBank(e.target.value)}
                              placeholder="Nom et prenoms du titulaire du compte"
                              id="licenceField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Code BIC / SWIFT</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={codeSwift}
                              onChange={(e) => setSwift(e.target.value)}
                              placeholder="Code BIC / SWIFT"
                              id="licenceField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ p: 3 }} dir="ltr">
                        <Grid container spacing={1}>
                          <Grid item xs={6} sm={2} md={2}>
                            <LoadingButton
                              onClick={() => update(0)}
                              fullWidth
                              color="secondary"
                              loading={loading}
                              loadingPosition="end"
                              variant="contained"
                            >
                              Enregistrer
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Box>{' '}
                    </Box>
                  ) : (
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Box sx={{ p: 1 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Nom du titulaire</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              placeholder="Nom et prenoms du titulaire du compte"
                              id="nameField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Opérateur mobile</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <FormControl fullWidth>
                              <Select
                                value={parseInt(opMobile)}
                                onChange={(e) => setOpMobile(e.target.value)}
                                labelId="demo-simple-select-label"
                                id="opMobile"
                                placeholder="Entrer le nom"
                              >
                                <MenuItem value={0}>Orange</MenuItem>
                                <MenuItem value={1}>MTN</MenuItem>
                                <MenuItem value={2}>Moov</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Mobile</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={num}
                              onChange={(e) => setNum(e.target.value)}
                              placeholder="Numero mobile"
                              id="licenceField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ p: 3 }} dir="ltr">
                        <Grid container spacing={1}>
                          <Grid item xs={6} sm={2} md={2}>
                            <LoadingButton
                              onClick={() => update(1)}
                              fullWidth
                              color="secondary"
                              loading={loading}
                              loadingPosition="end"
                              variant="contained"
                            >
                              Mettre a jour
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Box>{' '}
                    </Box>
                  )}
                </>
              </Box>
            </Container>
          ) : null}
        </Box>
      </Container> */}
    </Page>
  );
}

/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unneeded-ternary */
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Link,
  Container,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  Box,
  TextField,
  Alert,
} from '@mui/material';
// hooks
// components
// sections
import { useForm, useFrom } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { addDoc, doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { parseInt } from 'lodash';
import { UserAuth } from '../context/AuthContext';
import Iconify from '../components/Iconify';
import { RHFTextField, FormProvider, RHFCheckbox } from '../components/hook-form';
import AuthSocial from '../sections/auth/AuthSocial';
import { RegisterForm } from '../sections/auth/register';
import Logo from '../components/Logo';
import Page from '../components/Page';
import useResponsive from '../hooks/useResponsive';
import { db } from '../firebase.config';
import RHFSelect from '../components/hook-form/RHFSelect';
import logo1 from '../assets/logo/logo2.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(5, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPass() {
  const smUp = useResponsive('up', 'sm');
  const { upn } = useParams();
  const mdUp = useResponsive('up', 'md');
  // *******************************************************************

  const { user, signUp, signUpSecondStep } = UserAuth();
  const { resetpass } = UserAuth();
  const navigate = useNavigate();
  const [mdpN, setMdpN] = useState('');
  const [mdpC, setMdpc] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [passRest, setPassReset] = useState(false);

  const onSubmit = async (e) => {
    setLoading(true);
    if (mdpC !== mdpN) {
      setErrorMsg('Veuillez entrer des valeurs identitques');
      setError(true);
      setLoading(false);
      return;
    }
    try {
      await resetpass(oobCode, mdpC);
      setPassReset(true);
    } catch (error) {
      console.log(error);
      setPassReset(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);
        const tmp = upn.split('&');
        setOobCode(tmp[0]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const [showPasswordN, setShowPasswordN] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);

  // *****************************************************************************

  return (
    <Page title="Mon compte">
      <RootStyle>
        <Container>
          <Box sx={{ paddingTop: 5 }}>
            <Link href="/login" underline="hover">
              <Stack direction="row" display="flex" alignItems="center">
                <Iconify icon="material-symbols:arrow-back-ios" />
                retour
              </Stack>
            </Link>
          </Box>

          <ContentStyle>
            <HeaderStyle>
              <Box sx={{ width: 80, height: 80 }}>
                <img src={logo1} alt="Logo" />
              </Box>
            </HeaderStyle>
            <Typography textAlign="center" paddingBottom={5} paddingTop={1} variant="h4" gutterBottom>
              Reinitialisation du mot de passe
            </Typography>

            <Stack spacing={3}>
              {passRest ? (
                <>
                  <Alert sx={{ marginY: 2 }} severity="success">
                    Mot de passe reinitialisé
                  </Alert>

                  <LoadingButton
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    onClick={() => {navigate('/login')}}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    Retourner à la page de connexion
                  </LoadingButton>
                </>
              ) : (
                <>
                  <TextField
                    label="Nouveau mot de passe"
                    value={mdpN}
                    onChange={(e) => setMdpN(e.target.value)}
                    error={error && mdpN === '' ? true : false}
                    helperText={error && mdpN === '' ? 'Ce champs est obligatoire' : null}
                    type={showPasswordN ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPasswordN(!showPasswordN)} edge="end">
                            <Iconify icon={showPasswordN ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Confirmation du mot de passe"
                    value={mdpC}
                    onChange={(e) => setMdpc(e.target.value)}
                    error={error && mdpC === '' ? true : false}
                    helperText={error && mdpC === '' ? 'Ce champs est obligatoire' : null}
                    type={showPasswordC ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPasswordC(!showPasswordC)} edge="end">
                            <Iconify icon={showPasswordC ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {error && (
                    <Alert sx={{ marginY: 2 }} severity="error">
                      {errorMsg}
                    </Alert>
                  )}
                  <LoadingButton
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    onClick={() => onSubmit()}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    Reinitialisation
                  </LoadingButton>
                </>
              )}
            </Stack>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

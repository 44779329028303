import React from 'react';
import { Carousel } from 'antd';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import Iconify from '../Iconify';
import logo from '../../assets/logo/logo2.png';
import androidBadge from '../../assets/badges/google-play-badge.png';
import iosBadge from '../../assets/badges/ios.svg';
// import {ReactComponent as iosBadge} from '../../assets/badges/ios.svg';

function AppFooter() {
  return (
    <div className="block ">
      <div className="container-fluid">
        <Grid spacing={5} container textAlign="center" justifyContent="space-around">
          <Grid item xs={12} sm={3}>
            <Box sx={{ width: 100, height: 100 }}>
              <a href="/">
                <img src={logo} alt="Logo" />
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography colors="white" fontSize={16} textAlign="center" variant="body1">
              Besoin d'aide, de conseils ou d'informations ? Notre équipe est à votre disposition du lundi au samedi, de
              9h à 18h
            </Typography>
            <Typography paddingY={2} colors="white" fontSize={16} textAlign="center" variant="body1">
              Suivez nous les nos diferentes plateformes :
            </Typography>
            <Stack spacing={2} direction="row" display="flex" alignItems="center" justifyContent="center">
              <Iconify icon="akar-icons:facebook-fill" width={30} height={30} />
              <Iconify icon="mdi:instagram" width={30} height={30} />
              <Iconify icon="ic:baseline-tiktok" width={30} height={30} />
              <Iconify icon="devicon:twitter" width={30} height={30} />
            </Stack>
            <Stack
              paddingTop={2}
              spacing={2}
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography colors="white" fontSize={16} textAlign="center" variant="body1">
                Politique de confidentialité
              </Typography>
              <Typography paddingY={2} colors="white" fontSize={16} textAlign="center" variant="body1">
                .
              </Typography>
              <Typography colors="white" fontSize={16} textAlign="center" variant="body1">
                Politique d'utilisation
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography colors="white" fontSize={16} textAlign="center" variant="caption">
              2023 Choptonticket, All Rights Reserved. Made by Choptonticket.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default AppFooter;

import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  Stack,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase.config';

import Iconify from '../Iconify';
import { UserAuth } from '../../context/AuthContext';
import TabFacturation from './TabFacturation';
import TabOrganisation from './tabOrg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 1, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const params = useParams;

export default function BasicTabs1() {
  const { user } = UserAuth();
  const [value, setValue] = React.useState(0);
  const [events, setEvents] = React.useState([]);
  const [pastEvents, setPastEvents] = React.useState([]);
  const [brouillonEvent, setbrouillonEvent] = React.useState([]);
  const [notReadyEvents, setNotReadyEvents] = React.useState([]);

  // loading
  const [loading, setLoading] = React.useState(false);
  // loading
  const [brouillon, setBrouillon] = React.useState(false);

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createPastEventList = (doc) => {
    setPastEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createBrouillonEventList = (doc) => {
    setbrouillonEvent((events) => [...events, doc]); // ajouter a la fin
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          console.log(docSnap.data().idOrg);
          const q = query(collection(db, 'evenement'), where('orgId', '==', docSnap.data().idOrg));
          const querySnapshot = await getDocs(q);
          // querySnapshot.empty()
          console.log(querySnapshot);
          // setEvents(querySnapshot.docs);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, ' => ', doc.data());

            // sort list
            const element = doc;
            if (element.data().status === 1) {
              createEventList(element);
            } else if (element.data().status === 0) {
              createBrouillonEventList(element);
            } else if (element.data().status === 2) {
              createPastEventList(element);
            }
            // setLoading(false);
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user.uid]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs  value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={`Mon organisation `} {...a11yProps(0)} />
          <Tab label={`Facturation`} {...a11yProps(1)} />
        </Tabs>
      </Box>
      {loading && <LinearProgress/>}
      <TabPanel value={value} index={0}>
        <Box>
          <TabOrganisation />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box>
        <TabFacturation />
        </Box>
      </TabPanel>
    </Box>
  );
}

import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase.config';

import Iconify from '../../Iconify';
import { UserAuth } from '../../../context/AuthContext';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function Reversement() {
  const { user } = UserAuth();
  const [value, setValue] = React.useState(0);
  const [events, setEvents] = React.useState([]);
  const [revers, setRevers] = React.useState([]);
  const [pastEvents, setPastEvents] = React.useState([]);
  const [brouillonEvent, setbrouillonEvent] = React.useState([]);
  const [notReadyEvents, setNotReadyEvents] = React.useState([]);

  // loading
  const [loading, setLoading] = React.useState(false);
  // loading
  const [brouillon, setBrouillon] = React.useState(false);

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createPastEventList = (doc) => {
    setPastEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createBrouillonEventList = (doc) => {
    setbrouillonEvent((events) => [...events, doc]); // ajouter a la fin
  };

  React.useEffect(() => {
    (async () => {
      try {
        setRevers([])
        setEvents([])
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          console.log(docSnap.data().idOrg);
          const q = collection(db, `organisations/${docSnap.data().idOrg}/reversements`);
          const querySnapshot = await getDocs(q);
          // querySnapshot.empty()
          console.log(querySnapshot);
          // setEvents(querySnapshot.docs);
          querySnapshot.forEach(async (doc1) => {
            console.log(doc1.id, ' => ', doc1.data());
            setRevers((revers) => [...revers, doc1.data()]);

            // recuperation du nom de l'evenement
            const docRef1 = doc(db, 'evenement', doc1.data().idEvent);
            const docSnap1 = await getDoc(docRef1);
            setEvents((events) => [...events, docSnap1.data().nomEvent])

            // setLoading(false);
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user.uid]);

  return (
    <Container>
      <Box>
        <Typography fontSize={20}>Historique reversements</Typography>
        <Divider />
      </Box>
      {revers.length === 0 ? (
        <Box paddingY={5}>
          <Card sx={{ padding: 2, bgcolor: 'gainsboro' }}>
            <Typography>
              CHOPTONTICKET transférera la recette de votre événement régulièrement. Dès que vous aurez un événement
              ayant généré du chiffre d'affaires, vous le retrouverez ici. <br />
              Vous pouvez également faire des demandes de reversement lorsque vous en souhaitez en vous rendant sur le
              tableau de bord de votre evenement.
            </Typography>
          </Card>
        </Box>
      ) : (
        <Box paddingY={5}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Evenement</TableCell>
                  <TableCell align="left">Date de la demande</TableCell>
                  <TableCell align="left">Montant</TableCell>
                  <TableCell align="left">Statut</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {revers.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {events[index]}
                    </TableCell>
                    <TableCell align="left">{row.dateCreation.seconds}</TableCell>
                    <TableCell align="left">{row.montant}</TableCell>
                    <TableCell align="left">{row.statut}</TableCell>
                    <TableCell align="left">Telecharger</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Container>
  );
}

/* eslint-disable no-unneeded-ternary */
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Link,
  Container,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  Box,
  TextField,
  Avatar,
  Button,
  Snackbar,
} from '@mui/material';
// hooks
// components
// sections
import { useForm, useFrom } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { addDoc, doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { parseInt } from 'lodash';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { UserAuth } from '../context/AuthContext';
import Iconify from '../components/Iconify';
import { RHFTextField, FormProvider, RHFCheckbox } from '../components/hook-form';
import AuthSocial from '../sections/auth/AuthSocial';
import { RegisterForm } from '../sections/auth/register';
import Logo from '../components/Logo';
import Page from '../components/Page';
import useResponsive from '../hooks/useResponsive';
import { db, storage } from '../firebase.config';
import RHFSelect from '../components/hook-form/RHFSelect';
import logo1 from '../assets/logo/logo2.png';
import CustomButton from '../components/CustomButton';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(5, 0),
}));

// ----------------------------------------------------------------------

export default function Account() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  // *******************************************************************

  const { user, signUp, signUpSecondStep } = UserAuth();

  const navigate = useNavigate();
  const [id, setId] = useState();
  const [emailF, setEmail] = useState('');
  const [nomF, setNom] = useState('');
  const [prenomF, setPrenom] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const RegisterOrgSchema = Yup.object().shape({
    email: Yup.string().required('Ce champs est obligatoire'),
    prenom: Yup.string().required('Ce champs est obligatoire'),
    nom: Yup.string().required('Ce champs est obligatoire '),
    tel: Yup.number().optional(),
  });

  const [defaultValues, setDefault] = useState({
    email: 'ze',
    prenom: '',
    nom: '',
  });
  const [methods, setMethods] = useState(
    useForm({
      resolver: yupResolver(RegisterOrgSchema),
      defaultValues,
    })
  );

  const onSubmit = async (e) => {
    setLoading(true);
    console.log(emailF);
    console.log(nomF);
    const statRef = doc(db, `organisateurs`, `${user.uid}`);
    try {
      if (nomF !== '' && prenomF !== '') {
        if (nomF !== userInfo.nom || prenomF !== userInfo.prenom || selectedImage) {
          if (selectedImage) {
            const storageRef = ref(storage, `/Organisateurs/${user.uid}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
            await uploadBytes(storageRef, selectedImage).then((snapshot) => {
              getDownloadURL(snapshot.ref).then(async (downloadURL) => {
                console.log(downloadURL);
                setImageUrl(downloadURL);
                const refCoord = doc(db, `organisateurs`, `${user.uid}`);
                const CoordSnap = await getDoc(refCoord);
                if (CoordSnap.exists()) {
                  await updateDoc(refCoord, {
                    idVisuel: downloadURL,
                    nom: nomF,
                    prenom: prenomF,
                    updateDate: serverTimestamp(),
                  });
                }
              });
            });
          } else {
            await updateDoc(statRef, {
              nom: nomF,
              prenom: prenomF,
              updateDate: serverTimestamp(),
            });
          }
        }
      } else {
        console.log('error');
        setError(true);
      }
      // navigate('/bo/new_event', { replace: true });
      // setLoading(false);
      setMsg({ content: 'modifications enregistrées ', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      console.log(error);
      // setLoading(false);
      setMsg({ content: 'Une erreur est survenue ', color: 'green' });
      setOpenSnack(true);
    }
    setLoading(false);
  };

  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);

        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log('idog', docSnap.data().idOrg);
        if (docSnap.exists()) {
          console.log('start');
          setImageUrl(docSnap.data().idVisuel);
          setUserInfo(docSnap.data());
          setEmail(docSnap.data().email);
          setNom(docSnap.data().nom);
          setPrenom(docSnap.data().prenom);
          console.log('start');
          // setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user.uid]);

  const [selectedImage, setSelectedImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  //  snack bar
  const [openSnack, setOpenSnack] = useState(false);
  const [msg, setMsg] = useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };


  // *****************************************************************************

  return (
    <Page title="Mon compte">
      <RootStyle>
        <Container>
          <Stack sx={{ paddingTop: 3 }} direction={'row'} alignItems="center" justifyContent="space-between">
            <Link onClick={() => window.history.back()} underline="hover">
              <Stack direction="row" display="flex" alignItems="center">
                <Iconify icon="material-symbols:arrow-back-ios" />
                retour
              </Stack>
            </Link>
            <Box sx={{ width: 50, height: 50 }}>
              <img src={logo1} alt="Logo" />
            </Box>
          </Stack>

          <ContentStyle>
            <Typography paddingBottom={5} paddingTop={1} variant="h4" gutterBottom>
              Mon compte
            </Typography>

            {userInfo && (
              <> {/* <Stack spacing={3}>
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Avatar src={imageUrl} alt={imageUrl} sx={{ width: 100, height: 100 }} />
                  <Button
                    fullWidth
                    size="medium"
                    variant="text"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: '#5E17EB',
                      backgroundColor: 'transparent',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#5E17EB',
                        borderColor: 'transparent',
                      },
                    }}
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      id="select-image"
                      type="file"
                      onChange={(e) => setSelectedImage(e.target.files[0])}
                    />
                    <Typography variant="body2">Choisir une image</Typography>
                  </Button>
                </Box>

                
                <Box sx={{}}>
                  <Link href="/settings/password" underline="hover">
                    <Typography textAlign="end">Gérer mon mot de passe</Typography>
                  </Link>
                </Box>
                <LoadingButton
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: '#5E17EB',
                    },
                  }}
                  onClick={() => onSubmit()}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Enregister
                </LoadingButton>
              </Stack>
            */}
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  height: '100%',
                  borderRadius: '7px',
                  padding: 3,
                  border: '2px solid transparent',
                  bgcolor: '#f2f2f2',
                  borderColor: '#f2f2f2',
                }}
              >
                <Typography textAlign="Start">
                  <strong>Email de connexion</strong>
                </Typography>
                <Typography variant="body2" textAlign="Start">
                  Adresse utilisée pour vous connecter à votre compte Shotgun
                </Typography>
                <Box paddingTop={3}>
                  <TextField
                    fullWidth
                    label="E-mail"
                    type="email"
                    value={emailF}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  height: '100%',
                  borderRadius: '7px',
                  padding: 3,
                  border: '2px solid transparent',
                  bgcolor: '#f2f2f2',
                  borderColor: '#f2f2f2',
                }}
              >
                <Typography textAlign="Start">
                  <strong>informations</strong>
                </Typography>
                
                <Stack paddingTop={3} spacing={2}>
                  <TextField
                    label="Nom"
                    type="text"
                    value={nomF}
                    onChange={(e) => setNom(e.target.value)}
                    error={error && nomF === '' ? true : false}
                    helperText={error && nomF === '' ? 'Ce champs est obligatoire' : null}
                  />
                  <TextField
                    label="Prénom(s)"
                    type="text"
                    value={prenomF}
                    onChange={(e) => setPrenom(e.target.value)}
                    error={error && prenomF === '' ? true : false}
                    helperText={error && prenomF === '' ? 'Ce champs est obligatoire' : null}
                  />
                </Stack>
                <Box paddingTop={2} >
                  <CustomButton type='primaire' buttonText='Mettre a jour les informations' />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  height: '100%',
                  borderRadius: '7px',
                  padding: 3,
                  border: '2px solid transparent',
                  bgcolor: '#f2f2f2',
                  borderColor: '#f2f2f2',
                }}
              >
                <Typography textAlign="Start">
                  <strong>Changer de mot de passe</strong>
                </Typography>
                <Typography variant="body2" textAlign="Start">
                Nous vous enverrons un lien à cette adresse e-mail pour mettre à jour votre mot de passe 
                </Typography>
                <Box paddingTop={3}>
                <TextField
                    fullWidth
                    label="E-mail"
                    type="email"
                    value={emailF}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
                <Box paddingTop={2} >
                  <CustomButton type='primaire' buttonText='Envoyer un lien de réinitialisation' />
                </Box>
              </Box>
            </Grid>
          </Grid> </>
            )}
          </ContentStyle>
          
        </Container>
      </RootStyle>
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message={
          <Stack spacing={1} direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='caption'>{msg.content}{' '}</Typography>
            <Iconify
              sx={{ color: msg.color }}
              icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
            />
          </Stack>
        }
      />
    </Page>
  );
}

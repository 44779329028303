/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton } from '@mui/material';
import { Variables } from '../../../context/VariableContext';
import ContactDrawer from './drawer/contactDrawer';
import Iconify from '../../../components/Iconify';
import FilterDrawer from './drawer/filters';
import ImportationDrawer from './drawer/importationsContact';
import FilterCommDrawer from './drawer/filtersCommandes';
import ImportationOrdersDrawer from './drawer/importationsOrders';
import CreatePopulation from './drawer/population/createPopulation';

export default function Swipeable({  user, nbEvents, nbTickets, nbDepense, email, organisation }) {
  const { openDrawer, setOpenDrawer } = Variables();
  const { type, setType } = Variables();

  const toggleDrawer = (open) => {
    setOpenDrawer(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
      role="presentation"
      onClick={(e) => {
        toggleDrawer(false);
      }}
      onKeyDown={(e) => {
        toggleDrawer(false);
      }}
    >
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ mt: 5, width: 500 }}>
      <SwipeableDrawer
        anchor="right"
        open={openDrawer}
        onClose={() => {
          toggleDrawer(false);
        }}
        onOpen={() => {
          toggleDrawer(true);
        }}
      >
        <Box sx={{ width: {xs: '100%', sm: 500} }}>
          <Box paddingX={2} paddingY={{xs: 1, sm:2}}>
            <IconButton
              onClick={() => {
                toggleDrawer(false);
              }}
            >
              <Iconify icon="material-symbols:close" />
            </IconButton>
          </Box>
          <Divider />
          {
            type === 1 && user ? <ContactDrawer user={user} email={email} nbEvents={nbEvents} nbTickets={nbTickets} /> : type === 2 ? <FilterDrawer orgID={organisation} /> : type === 3 ? <ImportationDrawer /> : type === 4 ? <FilterCommDrawer /> : type === 5 ? <ImportationOrdersDrawer/> : type === 6 ? <CreatePopulation orgID={organisation} /> : ''
          }
          
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}

import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, CardMedia, Stack } from '@mui/material';
import { useState } from 'react';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
import event from '../assets/images/event.png';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';

// ---------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  vh: '100%',
  // background: 'radial-gradient(#3E1093 10% , #5516CA 60% );',
  // background: 'linear-gradient(#3E1093 70% , #000 30% );',
  // background: 'linear-gradient(135deg, #5516CA 60%, #2a0273)',
  background: 'linear-gradient(217deg, #5516CA, rgba(255,0,0,0) 100.71%), linear-gradient(127deg, #e66465, rgba(0,255,0,0) 60.71%), linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%)',
  maxWidth: '43%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(0, 0, 0, 0),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  // ---login
  const navigate = useNavigate();

  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
          <Logo />

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Pas encore de compe? {''}
              <Link variant="subtitle2" component={RouterLink} to="/register">
                S'inscrire
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            {/* <Card>
                <CardMedia
                  component="img"
                  sx={{ width: "100%", height: "100vh",  objectFit: "cover" }}
                  image={event}
                  title="green iguana"
                />
              </Card> */}
            <Stack justifyContent='center' alignItems='center' paddingX={10} sx={{ }}>
              <Typography paddingBottom={5} variant='h3' sx={{color: 'white'}}>Sell your event better, faster, stronger.</Typography>
              <Typography textAlign='center' sx={{color: 'whitesmoke'}} fontSize={20}>
                Nous facilitons la vente de billets et le développement de vos communautés, afin que vous puissiez vous
                concentrer sur la création d’évènements exceptionnels.
              </Typography>
            </Stack>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Bienvenue Chez ChopTonTicket
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              Entrer les informations suivantes pour vous connecter.
            </Typography>

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Pas encore de compe?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  S'inscrire
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

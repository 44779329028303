/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable no-bitwise */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate, Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  Timestamp,
  addDoc,
} from 'firebase/firestore';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import replImage from '../../assets/images/noimage.png';
import { db } from '../../firebase.config';

import Iconify from '../Iconify';
import { UserAuth } from '../../context/AuthContext';

import 'react-lazy-load-image-component/src/effects/blur.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { parseInt } from 'lodash';
import fr from 'date-fns/locale/fr';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: `90%`, sm: 500 },
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '7px',
  p: 4,
};

const params = useParams;

export default function BasicTabs() {
  // refresh
  const [refresh, setRefresh] = React.useState(false);
  const { user } = UserAuth();
  const [value, setValue] = React.useState(0);
  const [events, setEvents] = React.useState([]);
  const [pastEvents, setPastEvents] = React.useState([]);
  const [brouillonEvent, setbrouillonEvent] = React.useState([]);
  const [notReadyEvents, setNotReadyEvents] = React.useState([]);
  const navigate = useNavigate();

  // loading
  const [loading, setLoading] = React.useState(false);
  // loading
  const [brouillon, setBrouillon] = React.useState(false);

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createPastEventList = (doc) => {
    setPastEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createBrouillonEventList = (doc) => {
    setbrouillonEvent((events) => [...events, doc]); // ajouter a la fin
  };

  /* const sortEvent = (events) => {
    for (let index = 0; index < events.length; index++) {
      const element = array[index];
      if(element.nomEvent ==='ConcertKS'){
        cr
      }
      
    }
  } */
  const today = new Date();
  const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours(), today.getMinutes());

  const getData = async () => {
    try {
      setLoading(true);
      setEvents([]);
      setPastEvents([]);
      setbrouillonEvent([]);
      if (user) {
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          const q = query(
            collection(db, 'evenement'),
            where('orgId', '==', docSnap.data().idOrg),
            orderBy('dateDebut', 'desc')
          );
          const querySnapshot = await getDocs(q);
          // setEvents(querySnapshot.docs);
          querySnapshot.forEach((doc) => {
            // sort list
            const element = doc;
            if (element.data().status === 1) {
              createEventList(element);
            } else if (element.data().status === 0) {
              createBrouillonEventList(element);
            } else if (element.data().status === 2) {
              createPastEventList(element);
            }
            // setLoading(false);
          });
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    (async () => {
      getData();
    })();
  }, [user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // supression d'un billet
  const handleSupp = async () => {
    // handleToggle();
    setLoading(true);
    if (activeSup) {
      const sup = activeSup;
      try {
        await deleteDoc(doc(db, `evenement`, `${sup}`));
      } catch (error) {
        console.log(error.message);
        getData();
        setLoading(false);
        setOpenSup(false);
        // handleClose();
      }
      getData();
      setLoading(false);
      setOpenSup(false);
    }
    // handleClose();
  };

  // dupliquer part
  const [nomD, setNomD] = React.useState('');
  const [dateDebutD, setDateDebutD] = React.useState('');
  const [paysD, setPaysD] = React.useState('');
  const [villeD, setVilleD] = React.useState('');
  const [lieuD, setLieuD] = React.useState('');
  const [locationD, setLocationD] = React.useState('');
  const [dateFinD, setDateFinD] = React.useState('');
  const [imgD, setImgD] = React.useState(true);
  const [descD, setDescD] = React.useState(true);
  const [billetD, setBilletD] = React.useState(true);
  const [codeD, setCodeD] = React.useState(true);
  const [seanceD, setSeanceD] = React.useState(true);

  const handleDuplicate = async () => {
    // handleToggle();
    setLoading(true);
    let docRef = '';
    if (active) {
      try {
        // await deleteDoc(doc(db, `evenement`, `${id}`));
        const d = Date.now();

        docRef = await addDoc(collection(db, 'evenement'), {
          controleurSet: false,
          // idEvent: 202,
          dateDebut: Timestamp.fromDate(new Date(dateDebutD)),
          dateFin: Timestamp.fromDate(new Date(dateFinD)),
          idVisuel: `${imgD ? active.data().idVisuel : null}`,
          isImageSet: `${imgD}`,
          isTicketCreated: `${billetD}`,
          nomEvent: nomD,
          orgId: active.data().orgId,
          participant: 0,
          status: 0,
          seanceActive: active.data().seanceActive,
          localisation: active.data().localisation,
          city: villeD,
          country: paysD,
          lieu_event: lieuD,
          info: '',
          createDate: Timestamp.fromDate(new Date(d)),
          type: active.data().type,
        });
        console.log('docref : ', docRef.id);
        // set desc
        const q = query(collection(db, `evenement/${active.id}/description`));
        const snapshot = await getDocs(q);
        snapshot.forEach(async (d) => {
          await addDoc(collection(db, `evenement/${docRef.id}/description`), {
            value: `${descD ? d.data().value : ''}`,
          });
        });

        // set ticket
        if (billetD) {
          const q1 = query(collection(db, `evenement/${active.id}/tickets`));
          const snapshot1 = await getDocs(q1);
          snapshot1.forEach(async (d) => {
            await addDoc(collection(db, `evenement/${docRef.id}/tickets`), {
              description: d.data().description,
              price: d.data().price,
              quota: d.data().quota,
              sell: 0,
              status: d.data().status,
              ticket_name: d.data().ticket_name,
            });
          });
        }

        // set code promo
        if (codeD) {
          const d1 = Date.now();
          const q2 = query(collection(db, `evenement/${active.id}/codePromo`));
          const snapshot2 = await getDocs(q2);
          snapshot2.forEach(async (d) => {
            if (d.data().debut && d.data().fin) {
              // verification de la periode
              await addDoc(collection(db, `evenement/${docRef.id}/codePromo`), {
                // creationDate: name,
                code: d.data().code,
                reduc: d.data().reduc,
                statut: d.data().statut,
                usage: d.data().usage,
                billet: d.data().billet,
                nom_code: d.data().nom_code,
                debut: Timestamp.fromDate(new Date(d.data().debut)),
                fin: Timestamp.fromDate(new Date(d.data().fin)),
                createDate: Timestamp.fromDate(new Date(d1)),
              });
            } else if (d.data().debut) {
              await addDoc(collection(db, `evenement/${docRef.id}/codePromo`), {
                // creationDate: name,
                code: d.data().code,
                reduc: d.data().reduc,
                statut: d.data().statut,
                usage: d.data().usage,
                billet: d.data().billet,
                nom_code: d.data().nom_code,
                debut: Timestamp.fromDate(new Date(d.data().debut)),
                createDate: Timestamp.fromDate(new Date(d1)),
              });
            } else if (d.data().fin) {
              await addDoc(collection(db, `evenement/${docRef.id}/codePromo`), {
                code: d.data().code,
                reduc: d.data().reduc,
                statut: d.data().statut,
                usage: d.data().usage,
                billet: d.data().billet,
                nom_code: d.data().nom_code,
                debut: Timestamp.fromDate(Date.now()),
                fin: Timestamp.fromDate(new Date(d.data().fin)),
                createDate: Timestamp.fromDate(new Date(d1)),
              });
            } else {
              await addDoc(collection(db, `evenement/${docRef.id}/codePromo`), {
                code: d.data().code,
                reduc: d.data().reduc,
                statut: d.data().statut,
                usage: d.data().usage,
                billet: d.data().billet,
                nom_code: d.data().nom_code,
                createDate: Timestamp.fromDate(new Date(d1)),
              });
            }
          });
        }

        // set seance
        if (seanceD) {
          const q3 = query(collection(db, `evenement/${active.id}/seances`));
          const snapshot3 = await getDocs(q3);
          snapshot3.forEach(async (d) => {
            await addDoc(collection(db, `evenement/${docRef.id}/seances`), {
              date: d.data().date,
              quota: parseInt(d.data().quota),
              billets: d.data().billets,
              status: parseInt(1),
              lieu_event: d.data().lieu_event,
              city: d.data().city,
              country: d.data().country,
              localisation: d.data().localisation,
              participants: parseInt(0),
            });
          });
        }

        navigate(`/${docRef.id}/dashboard/information`);
      } catch (error) {
        console.log(error.message);
        // handleClose();
        setLoading(true);
      }
    }
    setLoading(true);
    // handleClose();
  };

  // gestion date
  const addDebut = (e) => {
    const today = new Date();
    const tmp = new Date(e);
    const tmpF = new Date(dateFinD);
    console.log(today);
    console.log(tmp);
    if (tmp <= today) {
      // setDebutError(true);
      // setDebutErrorMsg("La valeur de champ n'est pas valie");
    } else if (tmp >= tmpF) {
      /* setDebutErrorMsg("La date de debut doit être inferrieur à la date de fin de l'événément");
      setFinErrorMsg("La date de debut doit être inferrieur à la date de fin de l'événément");
      setDebutError(true);
      setFinError(true); */
    } else {
      // setFinError(false);
      // setDebutError(false);
    }
    setDateDebutD(e);
  };

  const addFin = (e) => {
    const debutT = new Date(dateDebutD);
    const tmp = new Date(e);
    console.log(tmp);
    if (tmp <= debutT) {
      // setFinError(true);
      // setFinErrorMsg("La date de fin doit être supérieur à la date de debut de l'événément");
    } else {
      // setFinError(false);
    }

    setDateFinD(e);
  };

  // place autocomplete
  const searchOptions = {
    componentRestrictions: { country: 'CIV' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['establishment'],
  };

  const [address, setAddress] = React.useState('');
  const [lieuError, setLieuError] = React.useState(false);

  const handleSelect = async (address, placeId, suggestion) => {
    console.log(suggestion.description);
    const tmp = suggestion.description.split(',');
    setLieuD(tmp[0]);
    setVilleD(tmp[tmp.length - 2]);
    setPaysD(tmp[tmp.length - 1]);
    setAddress(suggestion.description);
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    setLocationD(ll);
  };

  const handlePays = (e) => {
    setPaysD(e.target.value);
  };

  // active event
  const [active, setActive] = React.useState();
  const [activeSup, setActiveSup] = React.useState();
  // modal de duplication
  const [openDupplicate, setOpenDupplicate] = React.useState(false);
  const handleOpenDupplicate = (event) => {
    setActive(event);
    setOpenDupplicate(true);
    setNomD(`${event.data().nomEvent} - copie`);
    setDateDebutD(handleTimestamp(event.data().dateDebut));
    setDateFinD(handleTimestamp(event.data().dateFin));
    setLieuD(event.data().lieu_event);
    setVilleD(event.data().city);
    setPaysD(event.data().country);
    setLocationD(event.data().localisation);
    if (event.data().seanceActive === 0) {
      setSeanceD(false);
    }
  };
  const handleCloseDupplicate = () => setOpenDupplicate(false);

  // modal de suppression
  const [openSup, setOpenSup] = React.useState(false);
  const handleOpenSup = (id) => {
    setActiveSup(id);
    setOpenSup(true);
  };
  const handleCloseSup = () => setOpenSup(false);

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Modal
        open={openDupplicate}
        onClose={handleCloseDupplicate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Duplication d'evenement
          </Typography>
          <Divider />
          {loading ? (
            <Box padding={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Nom de l'évènement
              </Typography>
              <TextField value={nomD} onChange={(e) => setNomD(e.target.value)} fullWidth />
              <Grid container spacing={1} marginBottom={4}>
                <Grid item xs={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Date de début
                  </Typography>
                  <TextField
                    placeholder="Date de debut"
                    id="datetime-local"
                    type="datetime-local"
                    value={dateDebutD}
                    onChange={(e) => addDebut(e.target.value)}
                    fullWidth
                    // error={debutError ? true : false}
                    // helperText={debutError ? debutErrorMsg : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Date de fin
                  </Typography>
                  <TextField
                    placeholder="Date de fin"
                    id="datetime-local1"
                    type="datetime-local"
                    value={dateFinD}
                    onChange={(e) => addFin(e.target.value)}
                    fullWidth
                    // error={debutError ? true : false}
                    // helperText={debutError ? debutErrorMsg : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PlacesAutocomplete
                    value={lieuD}
                    onChange={setLieuD}
                    onSelect={handleSelect}
                    searchOptions={searchOptions}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        {!lieuError ? (
                          <TextField
                            {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        ) : (
                          <TextField
                            {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                            error
                            helperText="Le lieu de l'évnement est importante. veuillez remplir ce champs"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                        <Card>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: 2 }
                              : { backgroundColor: '#ffffff', cursor: 'pointer', padding: 2 };
                            return (
                              <Box
                                key={suggestion.placeId}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <Typography>{suggestion.description}</Typography>
                              </Box>
                            );
                          })}
                        </Card>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    placeholder="Ville"
                    value={villeD}
                    onChange={(e) => {
                      setVilleD(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Select
                      placeholder="Pays"
                      label="choix du pays"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={paysD}
                      onChange={handlePays}
                    >
                      <MenuItem value={paysD}>{paysD}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Typography>Choisissez les informations à dupliquer</Typography>
              <Grid container spacing={1} marginBottom={4} marginTop={1}>
                <Grid item xs={10}>
                  <Typography>L'image de couverture</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    checked={imgD}
                    onChange={(e) => {
                      setImgD(e.target.checked);
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography>Description</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    checked={descD}
                    onChange={(e) => {
                      setDescD(e.target.checked);
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography>Les tickets</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    checked={billetD}
                    onChange={(e) => {
                      setBilletD(e.target.checked);
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography>Codes de promotion</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Switch
                    checked={codeD}
                    onChange={(e) => {
                      setCodeD(e.target.checked);
                    }}
                    size="small"
                  />
                </Grid>
                {active && active.data().seanceActive === 1 && (
                  <>
                    <Grid item xs={10}>
                      <Typography>Les seances</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        checked={seanceD}
                        onChange={(e) => {
                          setSeanceD(e.target.checked);
                        }}
                        size="small"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item xs={6} sm={4} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '7px',
                      borderColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'black',
                      },
                    }}
                    onClick={handleCloseDupplicate}
                  >
                    Annuler
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={4}>
                  <Button
                    fullWidth
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    variant="contained"
                    onClick={() => handleDuplicate()}
                  >
                    Enregistrer
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
      <Modal
        open={openSup}
        onClose={handleCloseSup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography fontSize={20} id="modal-modal-title" variant="h6" component="h2">
            Etes-vous certain de vouloir supprimer définitivement ?
          </Typography>
          <Divider />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Attention, la suppression est définitive et irréversible !
          </Typography>
          <Grid paddingTop={2} container justifyContent="flex-end" spacing={1}>
            <Grid item xs={6} sm={4} md={4}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'black',
                  backgroundColor: 'white',
                  borderRadius: '7px',
                  borderColor: 'black',
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white',
                    borderColor: 'black',
                  },
                }}
                onClick={handleCloseSup}
              >
                Annuler
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Button
                fullWidth
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'white',
                  backgroundColor: 'red',
                  borderRadius: '7px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'red',
                    borderColor: 'red',
                  },
                }}
                variant="contained"
                onClick={() => handleSupp()}
              >
                Supprimer
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={`Publiés (${events.length})`} {...a11yProps(0)} />
          <Tab label={`brouillons (${brouillonEvent.length})`} {...a11yProps(1)} />
          <Tab label={`Clos (${pastEvents.length})`} {...a11yProps(2)} />
        </Tabs>
      </Box>
      {loading && <LinearProgress />}
      <TabPanel value={value} index={0}>
        <Grid container spacing={5}>
          {events.length === 0 && !loading ? (
            <Grid item xs={12}>
              <Stack spacing={2} display="flex" alignItems="center" justifyContent="center">
                <Iconify icon="mdi-light:calendar" sx={{ minHeight: 30, minWidth: 30 }} />
                <Typography textAlign="center">
                  {' '}
                  <strong>Pas d'évènements publiés pour l'instant</strong>
                </Typography>
              </Stack>
              <Typography variant="body2" textAlign="center">
                {' '}
                Vos évènements futurs apparaitront ici une fois créés et publiés
              </Typography>{' '}
              <Box sx={{ padding: 3, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  component={RouterLink}
                  to="/bo/new_event"
                  variant="text"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: '#5E17EB',
                    backgroundColor: 'white',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: 'white',
                    },
                  }}
                >
                  Ajouter un évènement
                </Button>
              </Box>
            </Grid>
          ) : (
            events.map((event, i) => (
              <Grid key={event.nomEvent} item xs={12} md={6} lg={4}>
                <Card sx={{ backgroundColor: '#f2f2f2', color: 'black', height: '100%' }}>
                  {/* <CardMedia component="img" height="200" image={`${event.data().idVisuel}`} alt="green iguana" /> */}
                  {/* <CardMedia component="img" height="200" image='./noimage.png' alt="green iguana" /> */}
                  <LazyLoadImage
                    key={i}
                    src={`${event.data().idVisuel}`}
                    alt={`${event.data().idVisuel}`}
                    className="img-lazy"
                    width="100%"
                    height={200}
                    style={{ objectFit: 'cover' }}
                    effect="blur" // opacity | black-and-white
                  />
                  <Box position="absolute" top={2} left={2} bgcolor="#141414" padding={1} borderRadius={3}>
                    <Typography sx={{ color: 'white' }}>
                      {format(new Date(handleTimestamp(event.data().dateDebut)), 'dd')}
                    </Typography>
                    <Typography sx={{ color: 'white' }}>
                      {format(new Date(handleTimestamp(event.data().dateDebut)), 'MMM')}
                    </Typography>
                  </Box>
                  <CardContent sx={{ padding: 2 }}>
                    <Typography gutterBottom variant="body2" component="div">
                      <strong>{event.data().nomEvent}</strong>
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify icon="mdi-light:calendar" sx={{ width: 12, height: 12 }} />
                        <Typography noWrap variant="caption" color="black">
                          {event.data().seanceActive === 1 ? `${format(new Date(handleTimestamp(event.data().dateDebut)), 'dd/MMM',{ locale: fr })} - ${format(new Date(handleTimestamp(event.data().dateFin)), 'dd/MMM',{ locale: fr })}` : format(new Date(handleTimestamp(event.data().dateDebut)), 'dd/MMM',{ locale: fr })}
                        </Typography>
                      </Stack>
                      <Stack spacing={0.5} direction="row">
                        {new Date(handleTimestamp(event.data().dateDebut)) > today1 ?
                          <Box
                            component="button"
                            padding={0.5}
                            color='#7E1F86'
                            sx={{
                              borderRadius: '5px',
                              fontSize: 10,
                              backgroundColor: '#F5EFFA',
                              border: '0.5px solid #B991DE',
                            }}
                          >
                            A venir
                          </Box>
                          : new Date(handleTimestamp(event.data().dateDebut)) < today1 && new Date(handleTimestamp(event.data().dateFin)) > today1 ?
                            <Box
                              component="button"
                              padding={0.5}
                              color='#7E1F86'
                              sx={{
                                borderRadius: '5px',
                                fontSize: 10,
                                backgroundColor: '#F5EFFA',
                                border: '0.5px solid #B991DE',
                              }}
                            >
                              En cours
                            </Box>
                            : new Date(handleTimestamp(event.data().dateFin)) < today1 ? (
                              <Box
                                component="button"
                                padding={0.5}
                                color='#7E1F86'
                                sx={{
                                  borderRadius: '5px',
                                  fontSize: 10,
                                  backgroundColor: '#F5EFFA',
                                  border: '0.5px solid #B991DE',
                                }}
                              >
                                Passé
                              </Box>) : null}
                        {
                          event.data().seanceActive === 1 ? <Box
                            component="button"
                            padding={0.5}
                            color='#7E1F86'
                            sx={{
                              borderRadius: '5px',
                              fontSize: 10,
                              backgroundColor: '#F5EFFA',
                              border: '0.5px solid #B991DE',
                            }}
                          >
                            Plusieurs dates
                          </Box>
                            : null
                        }

                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Iconify icon="icon-park-outline:local-two" sx={{ width: 12, height: 12 }} />
                      <Typography noWrap variant="caption" color="black">
                        {event.data().lieu_event}
                      </Typography>
                    </Stack>
                    <Grid container paddingY={1}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="black">
                          Participant(s)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="#5E17EB">
                          {event.data().participant}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Gérer" placement="bottom">
                        <IconButton sx={{ bgcolor: 'white' }} component={RouterLink} to={`/${event.id}/dashboard/app/`}>
                          <Iconify sx={{ color: '#5E17EB' }} icon="bytesize:settings" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Voir les stats" placement="bottom">
                        <IconButton
                          sx={{ bgcolor: 'white' }}
                          component={RouterLink}
                          to={`/${event.id}/dashboard/statistique`}
                        >
                          <Iconify sx={{ color: '#5E17EB' }} icon="ion:stats-chart-outline" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Dupliquer" placement="bottom">
                        <IconButton sx={{ bgcolor: 'white' }} onClick={() => handleOpenDupplicate(event)}>
                          <Iconify sx={{ color: '#5E17EB' }} icon="bx:duplicate" />
                        </IconButton>
                      </Tooltip>

                      {event.data().participant > 0 ? (
                        <Tooltip title="Des billets on deja été vendu. Impossible de supprimer cet evenement">
                          <IconButton sx={{ bgcolor: 'white' }}>
                            <Iconify icon="material-symbols:delete-outline" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Supprimer le ticket">
                          <IconButton sx={{ bgcolor: 'white' }} onClick={(e) => handleOpenSup(event.id)}>
                            <Iconify sx={{ color: '#5E17EB' }} icon="material-symbols:delete-outline" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={5}>
          {brouillonEvent.length === 0 && !loading ? (
            <Grid item xs={12}>
              <Stack spacing={2} display="flex" alignItems="center" justifyContent="center">
                <Iconify icon="mdi-light:calendar" sx={{ minHeight: 30, minWidth: 30 }} />
                <Typography textAlign="center">
                  {' '}
                  <strong>Pas d'évènements brouillon </strong>
                </Typography>
              </Stack>
              <Typography variant="body2" textAlign="center">
                {' '}
                Vos évènements brouillons apparaitront ici une fois créés
              </Typography>{' '}
              <Box sx={{ padding: 3, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  variant="text"
                  component={RouterLink}
                  to="/bo/new_event"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: '#5E17EB',
                    backgroundColor: 'white',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: 'white',
                    },
                  }}
                >
                  Créer un évènement
                </Button>
              </Box>
            </Grid>
          ) : (
            brouillonEvent.map((event, i) => (
              <Grid key={event.nomEvent} item xs={12} md={6} lg={4}>
                <Card sx={{ backgroundColor: '#f2f2f2', color: 'black', height: '100%' }}>
                  {/* <CardMedia component="img" height="200" image={`${event.data().idVisuel}`} alt="green iguana" /> */}
                  {/* <CardMedia component="img" height="200" image='./noimage.png' alt="green iguana" /> */}
                  <LazyLoadImage
                    key={i}
                    src={`${event.data().idVisuel}`}
                    alt={`${event.data().idVisuel}`}
                    className="img-lazy"
                    width="100%"
                    height={200}
                    style={{ objectFit: 'cover' }}
                    effect="blur" // opacity | black-and-white
                  />
                  <Box position="absolute" top={2} left={2} bgcolor="#141414" padding={1} borderRadius={3}>
                    <Typography sx={{ color: 'white' }}>
                      {format(new Date(handleTimestamp(event.data().dateDebut)), 'dd')}
                    </Typography>
                    <Typography sx={{ color: 'white' }}>
                      {format(new Date(handleTimestamp(event.data().dateDebut)), 'MMM')}
                    </Typography>
                  </Box>
                  <CardContent sx={{ padding: 2 }}>
                    <Typography gutterBottom variant="body2" component="div">
                      <strong>{event.data().nomEvent}</strong>
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify icon="mdi-light:calendar" sx={{ width: 12, height: 12 }} />
                        <Typography noWrap variant="caption" color="black">
                          {event.data().seanceActive === 1 ? `${format(new Date(handleTimestamp(event.data().dateDebut)), 'dd/MMM',{ locale: fr })} - ${format(new Date(handleTimestamp(event.data().dateFin)), 'dd/MMM',{ locale: fr })}` : format(new Date(handleTimestamp(event.data().dateDebut)), 'dd/MMM',{ locale: fr })}
                        </Typography>
                      </Stack>
                      <Stack spacing={0.5} direction="row">
                        {new Date(handleTimestamp(event.data().dateDebut)) > today1 ?
                          <Box
                            component="button"
                            padding={0.5}
                            color='#7E1F86'
                            sx={{
                              borderRadius: '5px',
                              fontSize: 10,
                              backgroundColor: '#F5EFFA',
                              border: '0.5px solid #B991DE',
                            }}
                          >
                            A venir
                          </Box>
                          : new Date(handleTimestamp(event.data().dateDebut)) < today1 && new Date(handleTimestamp(event.data().dateFin)) > today1 ?
                            <Box
                              component="button"
                              padding={0.5}
                              color='#7E1F86'
                              sx={{
                                borderRadius: '5px',
                                fontSize: 10,
                                backgroundColor: '#F5EFFA',
                                border: '0.5px solid #B991DE',
                              }}
                            >
                              En cours
                            </Box>
                            : new Date(handleTimestamp(event.data().dateFin)) < today1 ? (
                              <Box
                                component="button"
                                padding={0.5}
                                color='#7E1F86'
                                sx={{
                                  borderRadius: '5px',
                                  fontSize: 10,
                                  backgroundColor: '#F5EFFA',
                                  border: '0.5px solid #B991DE',
                                }}
                              >
                                Passé
                              </Box>) : null}
                        {
                          event.data().seanceActive === 1 ? <Box
                            component="button"
                            padding={0.5}
                            color='#7E1F86'
                            sx={{
                              borderRadius: '5px',
                              fontSize: 10,
                              backgroundColor: '#F5EFFA',
                              border: '0.5px solid #B991DE',
                            }}
                          >
                            Plusieurs dates
                          </Box>
                            : null
                        }

                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Iconify icon="icon-park-outline:local-two" sx={{ width: 12, height: 12 }} />
                      <Typography noWrap variant="caption" color="black">
                        {event.data().lieu_event}
                      </Typography>
                    </Stack>
                    <Grid container paddingY={1}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="black">
                          Participant(s)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="#5E17EB">
                          {event.data().participant}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Gérer" placement="bottom">
                        <IconButton sx={{ bgcolor: 'white' }} component={RouterLink} to={`/${event.id}/dashboard/app/`}>
                          <Iconify sx={{ color: '#5E17EB' }} icon="bytesize:settings" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Voir les stats" placement="bottom">
                        <IconButton
                          sx={{ bgcolor: 'white' }}
                          component={RouterLink}
                          to={`/${event.id}/dashboard/statistique`}
                        >
                          <Iconify sx={{ color: '#5E17EB' }} icon="ion:stats-chart-outline" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Dupliquer" placement="bottom">
                        <IconButton sx={{ bgcolor: 'white' }} onClick={() => handleOpenDupplicate(event)}>
                          <Iconify sx={{ color: '#5E17EB' }} icon="bx:duplicate" />
                        </IconButton>
                      </Tooltip>

                      {event.data().participant > 0 ? (
                        <Tooltip title="Des billets on deja été vendu. Impossible de supprimer cet evenement">
                          <IconButton sx={{ bgcolor: 'white' }}>
                            <Iconify icon="material-symbols:delete-outline" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Supprimer le ticket">
                          <IconButton sx={{ bgcolor: 'white' }} onClick={(e) => handleOpenSup(event.id)}>
                            <Iconify sx={{ color: '#5E17EB' }} icon="material-symbols:delete-outline" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={5}>
          {pastEvents.length === 0 && !loading ? (
            <Grid item xs={12}>
              <Stack spacing={2} display="flex" alignItems="center" justifyContent="center">
                <Iconify icon="mdi-light:calendar" sx={{ minHeight: 30, minWidth: 30 }} />
                <Typography textAlign="center">
                  {' '}
                  <strong>Pas d'évènements clos</strong>
                </Typography>
              </Stack>
              <Typography variant="body2" textAlign="center">
                {' '}
                Vos évènement clos apparaitront ici une fois créés et clos
              </Typography>{' '}
              <Box sx={{ padding: 3, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button
                  // onClick={goLive}
                  variant="text"
                  component={RouterLink}
                  to="/bo/new_event"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: '#5E17EB',
                    backgroundColor: 'white',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: 'white',
                    },
                  }}
                >
                  Créer un évènement
                </Button>
              </Box>
            </Grid>
          ) : (
            pastEvents.map((event, i) => (
              <Grid key={event.nomEvent} item xs={12} md={6} lg={4}>
                <Card sx={{ backgroundColor: '#f2f2f2', color: 'black', height: '100%' }}>
                  {/* <CardMedia component="img" height="200" image={`${event.data().idVisuel}`} alt="green iguana" /> */}
                  {/* <CardMedia component="img" height="200" image='./noimage.png' alt="green iguana" /> */}
                  <LazyLoadImage
                    key={i}
                    src={`${event.data().idVisuel}`}
                    alt={`${event.data().idVisuel}`}
                    className="img-lazy"
                    width="100%"
                    height={200}
                    style={{ objectFit: 'cover' }}
                    effect="blur" // opacity | black-and-white
                  />
                  <Box position="absolute" top={2} left={2} bgcolor="#141414" padding={1} borderRadius={3}>
                    <Typography sx={{ color: 'white' }}>
                      {format(new Date(handleTimestamp(event.data().dateDebut)), 'dd')}
                    </Typography>
                    <Typography sx={{ color: 'white' }}>
                      {format(new Date(handleTimestamp(event.data().dateDebut)), 'MMM')}
                    </Typography>
                  </Box>
                  <CardContent sx={{ padding: 2 }}>
                    <Typography gutterBottom variant="body2" component="div">
                      <strong>{event.data().nomEvent}</strong>
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify icon="mdi-light:calendar" sx={{ width: 12, height: 12 }} />
                        <Typography noWrap variant="caption" color="black">
                          {event.data().seanceActive === 1 ? `${format(new Date(handleTimestamp(event.data().dateDebut)), 'dd/MMM',{ locale: fr })} - ${format(new Date(handleTimestamp(event.data().dateFin)), 'dd/MMM',{ locale: fr })}` : format(new Date(handleTimestamp(event.data().dateDebut)), 'dd/MMM',{ locale: fr })}
                        </Typography>
                      </Stack>
                      <Stack spacing={0.5} direction="row">
                        
                        {
                          event.data().seanceActive === 1 ? <Box
                            component="button"
                            padding={0.5}
                            color='#7E1F86'
                            sx={{
                              borderRadius: '5px',
                              fontSize: 10,
                              backgroundColor: '#F5EFFA',
                              border: '0.5px solid #B991DE',
                            }}
                          >
                            Plusieurs dates
                          </Box>
                            : null
                        }

                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Iconify icon="icon-park-outline:local-two" sx={{ width: 12, height: 12 }} />
                      <Typography noWrap variant="caption" color="black">
                        {event.data().lieu_event}
                      </Typography>
                    </Stack>
                    <Grid container paddingY={1}>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="black">
                          Participant(s)
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="#5E17EB">
                          {event.data().participant}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Stack direction="row" spacing={1}>
                      <Tooltip title="Gérer" placement="bottom">
                        <IconButton sx={{ bgcolor: 'white' }} component={RouterLink} to={`/${event.id}/dashboard/app/`}>
                          <Iconify sx={{ color: '#5E17EB' }} icon="bytesize:settings" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Voir les stats" placement="bottom">
                        <IconButton
                          sx={{ bgcolor: 'white' }}
                          component={RouterLink}
                          to={`/${event.id}/dashboard/statistique`}
                        >
                          <Iconify sx={{ color: '#5E17EB' }} icon="ion:stats-chart-outline" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Dupliquer" placement="bottom">
                        <IconButton sx={{ bgcolor: 'white' }} onClick={() => handleOpenDupplicate(event)}>
                          <Iconify sx={{ color: '#5E17EB' }} icon="bx:duplicate" />
                        </IconButton>
                      </Tooltip>

                      {event.data().participant > 0 ? (
                        <Tooltip title="Des billets on deja été vendu. Impossible de supprimer cet evenement">
                          <IconButton sx={{ bgcolor: 'white' }}>
                            <Iconify icon="material-symbols:delete-outline" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Supprimer le ticket">
                          <IconButton sx={{ bgcolor: 'white' }} onClick={(e) => handleOpenSup(event.id)}>
                            <Iconify sx={{ color: '#5E17EB' }} icon="material-symbols:delete-outline" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </TabPanel>
    </Box>
  );
}

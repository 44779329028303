/* eslint-disable radix */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unneeded-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import EmojiPicker from "emoji-picker-react";
// components
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getCountFromServer,
    getDoc,
    getDocs,
    orderBy,
    query,
    where,
} from 'firebase/firestore';

import { deepOrange } from '@mui/material/colors';
import { db } from '../../../../../firebase.config';
import Iconify from '../../../../../components/Iconify';
import { UserAuth } from '../../../../../context/AuthContext';
import { Variables } from '../../../../../context/VariableContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function CreatePopulation({ orgID }) {
    const { user } = UserAuth();
    const [expanded, setExpanded] = React.useState(false);
    const [filter, setFilter] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [selectedEvents, setSelectedEvents] = React.useState([]);
    const [compare, setCompare] = React.useState();
    const [minBillet, setMinBillet] = React.useState('');
    const [maxBillet, setMaxBillet] = React.useState('');
    const [filterBillet, setFilterBillet] = React.useState([]);
    const [filterEvent, setFilterEvent] = React.useState([]);
    const [filterNotif, setFilterNotif] = React.useState('');
    const [filterNewsletter, setFilterNewsletter] = React.useState('');
    const [filterAbonne, setFilterAbonne] = React.useState('');
    const [filterHist, setFilterHist] = React.useState('');
    const [refreshCount, setRefreshCount] = React.useState(false);
    const [nombre, setNombre] = React.useState(0);
    const { openDrawer, setOpenDrawer } = Variables();
    const [step, setStep] = React.useState(0);
    const [nom, setNom] = React.useState('');
    const [desc, setDesc] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const handleSelectCompareBillet = (tmp) => {
        setCompare(tmp);
    };

    const handleChange = (panel) => async (event, isExpanded) => {
        if (panel === 'panel1') {
            if (events.length === 0) {
                setEvents([]);
                console.log(user);
                const u1 = await getDoc(doc(db, 'organisateurs', `${user.uid}`));
                console.log(u1.data());
                const q = query(
                    collection(db, 'evenement'),
                    where('orgId', '==', u1.data().idOrg),
                    orderBy('dateDebut', 'asc')
                );
                const querySnapshot = await getDocs(q);
                // setEvents(querySnapshot.docs);
                querySnapshot.forEach((doc) => {
                    // sort list
                    const element = doc;
                    if (element.data().status === 1) {
                        setEvents((events) => [...events, element]);
                    }
                });
            }
        } else {
            console.log('ko');
        }
        setExpanded(isExpanded ? panel : false);
    };

    React.useEffect(() => {
        (async () => {
            try {
                console.log('');
            } catch (error) {
                console.log('error');
            }
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            // setTicket([]);

            const queryContains = [];
            console.log(minBillet);
            // console.log(filterOrigine);
            console.log(filterNotif);

            try {
                const today = new Date();
                const date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                const ttmp = new Date(today.getTime() + 24 * 60 * 60 * 1000);
                const tomorrow = new Date(ttmp.getFullYear(), ttmp.getMonth(), ttmp.getDate());
                if (
                    minBillet === '' &&
                    maxBillet === '' &&
                    filterEvent.length === 0 &&
                    filterNotif === '' &&
                    filterNewsletter === '' &&
                    filterHist === ''
                ) {
                    setNombre(0);
                } else {
                    if (filterEvent.length !== 0) {
                        console.log('-------------------------------------------0');
                        console.log(filterEvent);
                        queryContains.push(where('events', 'array-contains-any', filterEvent));
                    }
                    if (minBillet !== '') {
                        console.log('-------------------------------------------1');
                        console.log(parseInt(minBillet));
                        // queryContains.push(where('tickets', '>=', parseInt(minBillet)));
                        // queryContains.push(orderBy('tickets', 'desc'));
                        queryContains.push(
                            query(
                                collection(db, `organisations/${orgID}/crm/${orgID}/contacts`),
                                where('tickets', '>=', parseInt(minBillet)),
                                orderBy('tickets', 'desc')
                            )
                        );
                    }
                    if (maxBillet !== '') {
                        console.log(maxBillet);
                        console.log('-------------------------------------------2');
                        queryContains.push(where('tickets', '<=', parseInt(maxBillet)));
                        if (minBillet === '') {
                            queryContains.push(orderBy('tickets', 'desc'));
                        }
                    }
                    if (filterNotif !== '') {
                        console.log('-------------------------------------------3');
                        console.log(filterNotif);
                        queryContains.push(where('push', '==', false));
                    }
                    if (filterNewsletter !== '') {
                        console.log('-------------------------------------------4');
                        console.log(filterNewsletter);
                        queryContains.push(where('newslettres', '==', filterNewsletter));
                    }
                    // if (filterBillet.length !== 0) {
                    //  queryContains.push(where('refTicket', 'in', filterBillet));
                    // }
                    if (filterHist !== '') {
                        console.log('-------------------------------------------5');
                        console.log(filterHist);
                        if (filterHist === 'today') {
                            queryContains.push(where('createDate', '>=', date));
                            queryContains.push(where('createDate', '<', new Date(today.getTime() + 24 * 60 * 60 * 1000)));
                        } else if (filterHist === 'yesterday') {
                            queryContains.push(where('createDate', '<=', date));
                            queryContains.push(where('createDate', '>=', new Date(date.getTime() - 24 * 60 * 60 * 1000)));
                        } else if (filterHist === 'last-7-days') {
                            queryContains.push(where('createDate', '<=', date));
                            queryContains.push(where('createDate', '>=', new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)));
                        } else if (filterHist === 'last-14-days') {
                            queryContains.push(where('createDate', '<=', date));
                            queryContains.push(where('createDate', '>=', new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000)));
                        } else if (filterHist === 'last-30-days') {
                            queryContains.push(where('createDate', '<=', date));
                            queryContains.push(where('createDate', '>=', new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)));
                        }
                    }
                    // queryContains.push(where("createDate", ">=", tomorrow));
                    console.log(queryContains.toString);
                    const total = await getCountFromServer(
                        query(
                            collection(db, `organisations/${orgID}/crm/${orgID}/contacts`),
                            ...queryContains,
                            orderBy('createDate', 'desc')
                        )
                    );
                    console.log('total : ', total.data().count);
                    setNombre(total.data().count);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [refreshCount]);

    const handleSubmit = () => { };

    // selection des events
    const handleChangeEvent = (event) => {
        const tmp = filterEvent.findIndex((element) => element === event.target.value);
        if (event.target.checked && tmp === -1) {
            setFilterEvent((events) => [...events, event.target.value]);
        } else {
            const array = filterEvent;
            array.splice(tmp, 1);
            setFilterEvent(array);
        }

        setSelectedEvents({
            ...selectedEvents,
            [event.target.name]: event.target.checked,
        });
        setRefreshCount(!refreshCount);
    };

    // selection historique
    const handleChangeHistorique = (event) => {
        setFilterHist(event.target.value);
        setRefreshCount(!refreshCount);
    };

    // selection min billet quantité
    const handleChangeMinBillet = (event) => {
        setMinBillet(event.target.value);
        setRefreshCount(!refreshCount);
    };

    // selection max billet quantité
    const handleChangeMaxBillet = (event) => {
        setMaxBillet(event.target.value);
        setRefreshCount(!refreshCount);
    };

    // selection max billet quantité
    const handleChangeAbonne = (event) => {
        setFilterAbonne(event.target.value);
        setRefreshCount(!refreshCount);
    };

    // selection max billet quantité
    const handleChangeNotif = (event) => {
        setFilterNotif(event.target.value);
        setRefreshCount(!refreshCount);
    };

    // selection max billet quantité
    const handleChangeNewsLetter = (event) => {
        setFilterNewsletter(event.target.value);
        setRefreshCount(!refreshCount);
    };

    // selection max billet quantité
    const RemoveAllFilter = () => {
        setFilterEvent([]);
        setFilterNewsletter('');
        setFilterAbonne('');
        setFilterNotif('');
        setFilterHist('');
        setMinBillet('');
        setMaxBillet('');
        setRefreshCount(!refreshCount);
    };
    const RemoveBilletFilter = () => {
        setMinBillet('');
        setMaxBillet('');
        setRefreshCount(!refreshCount);
    };
    const RemoveNotifFilter = () => {
        setFilterNotif('');
        setRefreshCount(!refreshCount);
    };
    const RemoveNewletterFilter = () => {
        setFilterNewsletter('');
        setRefreshCount(!refreshCount);
    };
    const RemoveAbonneFilter = () => {
        setFilterAbonne('');
        setRefreshCount(!refreshCount);
    };
    const RemoveHistFilter = () => {
        setFilterHist('');
        setRefreshCount(!refreshCount);
    };

    // emoji
    const [chosenEmoji, setChosenEmoji] = useState("😊");
    const [showPicker, setShowPicker] = useState(false);

    const onEmojiClick = (emojiObject) => {
        console.log(emojiObject.emoji)
        setChosenEmoji(emojiObject.emoji);
        setShowPicker(false);
    };

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    // save population
    const createPopulation = async () => {
        setLoading(true)
        try {
            console.log('salut')
            console.log(orgID)
            await addDoc(collection(db, `organisations/${orgID}/crm/${orgID}/populations`), {
                emoji: chosenEmoji,
                name: nom,
                description: desc
            });
            setOpenDrawer(false);
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    };

    return (
        <Box>
            {
                // eslint-disable-next-line no-nested-ternary
                step === 0 ?
                    <Box
                        paddingBottom={3}
                        paddingX={3}
                        sx={{ height: { xs: '75vh', sm: ' 78vh', xl: '85vh' }, overflowX: 'auto', overflowY: 'auto' }}
                    >
                        <Typography variant="h5" paddingBottom={1} paddingTop={1}>
                            Nouvelle population 1/2
                        </Typography>
                        <Typography variant="h5" paddingBottom={1} paddingTop={2}>
                            Achats
                        </Typography>
                        <Accordion
                            expanded={expanded === 'panel1'}
                            onChange={handleChange('panel1')}
                            sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                        >
                            <AccordionSummary
                                expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <Iconify icon="mdi:calendar-outline" sx={{ minWidth: 20, minHeight: 20 }} />
                                    <Typography>Participations aux évènements</Typography>
                                    {filterEvent.length !== 0 && (
                                        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                {filterEvent.length} selectionné
                                            </Typography>
                                            <IconButton onClick={RemoveAllFilter}>
                                                <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Contacts qui ont participé à au moins un des évènements suivants</FormLabel>
                                    <FormGroup aria-label="position">
                                        {events.map((event) => (
                                            <FormControlLabel
                                                key={event.id}
                                                value={event.id}
                                                control={
                                                    <Checkbox
                                                        checked={selectedEvents[event.data().ticket_name]}
                                                        onChange={handleChangeEvent}
                                                        name={event.id}
                                                    />
                                                }
                                                label={event.data().nomEvent}
                                                labelPlacement="end"
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === 'panel2'}
                            onChange={handleChange('panel2')}
                            sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                        >
                            <AccordionSummary
                                expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <Iconify icon="carbon:ticket" sx={{ minWidth: 20, minHeight: 20 }} />
                                    <Typography>Billets</Typography>
                                    {(minBillet !== '' || maxBillet !== '') && (
                                        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                {minBillet}-{maxBillet}
                                            </Typography>
                                            <IconButton onClick={RemoveBilletFilter}>
                                                <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body2">Nombre de tickets détenus (pour vos évènements).</Typography>
                                <Stack paddingY={2} spacing={2} direction="row" alignItems="center">
                                    {/* <FormControl fullWidth>
                <Select size="small" id="demo-simple-select" value={compare} onChange={handleSelectCompareBillet}>
                  <MenuItem value={1}>Egale à =</MenuItem>
                  <MenuItem value={2}>Supérieur à {'>'}</MenuItem>
                  <MenuItem value={3}>Supérieur ou égale à {'>='} </MenuItem>
                  <MenuItem value={2}>Inférieur à {'<'}</MenuItem>
                  <MenuItem value={3}>Inférieur ou égale à {'<='} </MenuItem>
                </Select>
                    </FormControl> */}
                                    <TextField placeholder="min" value={minBillet} onChange={handleChangeMinBillet} size="small" />
                                    <Typography variant="body2">à</Typography>
                                    <TextField placeholder="max" value={maxBillet} onChange={handleChangeMaxBillet} size="small" />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                        <Typography variant="h5" paddingBottom={1} paddingTop={3}>
                            Marketing
                        </Typography>
                        <Accordion
                            expanded={expanded === 'panel7'}
                            onChange={handleChange('panel7')}
                            sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                        >
                            <AccordionSummary
                                expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                                    <Typography>Abonné à la notifications?</Typography>
                                    {filterNotif !== '' && (
                                        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                {filterNotif}
                                            </Typography>
                                            <IconButton onClick={RemoveNotifFilter}>
                                                <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        <Typography variant="caption">
                                            Contacts ayant accepté de recevoir des notifications de votre organisation dans l'application
                                            Choptonticket
                                        </Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value={filterNotif}
                                        onChange={handleChangeNotif}
                                    >
                                        <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                        <FormControlLabel value={false} control={<Radio />} label="Non" />
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === 'panel6'}
                            onChange={handleChange('panel6')}
                            sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                        >
                            <AccordionSummary
                                expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                                    <Typography>Abonné à la newsletters ?</Typography>
                                    {filterNewsletter !== '' && (
                                        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                {filterNewsletter}
                                            </Typography>
                                            <IconButton onClick={RemoveNewletterFilter}>
                                                <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        <Typography variant="caption">Contacts abonnés à votre page organisateur sur Choptonticket</Typography>
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value={filterNewsletter}
                                        onChange={handleChangeNewsLetter}
                                    >
                                        <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                                        <FormControlLabel value="non" control={<Radio />} label="Non" />
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            expanded={expanded === 'panel5'}
                            onChange={handleChange('panel5')}
                            sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                        >
                            <AccordionSummary
                                expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <Iconify icon="ion:heart-outline" sx={{ width: 20, height: 20 }} />
                                    <Typography>Abonné ?</Typography>
                                    {filterAbonne !== '' && (
                                        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                                            <Typography sx={{ color: '#5E17EB' }} variant="caption">
                                                {filterAbonne}
                                            </Typography>
                                            <IconButton onClick={RemoveAllFilter}>
                                                <Iconify icon="typcn:delete" sx={{ width: 15, height: 15 }} />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">
                                        Contacts abonnés à votre page organisateur sur Choptonticket
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="female"
                                        name="radio-buttons-group"
                                        value={filterAbonne}
                                        onChange={handleChangeAbonne}
                                    >
                                        <FormControlLabel value="oui" control={<Radio />} label="Oui" />
                                        <FormControlLabel value="non" control={<Radio />} label="Non" />
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>

                        <Typography variant="h5" paddingBottom={1} paddingTop={3}>
                            Historique
                        </Typography>
                        <Accordion
                            expanded={expanded === 'panel8'}
                            onChange={handleChange('panel8')}
                            sx={{ bgcolor: '#F0F0F0', mb: 2 }}
                        >
                            <AccordionSummary
                                expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <Iconify icon="material-symbols:update-rounded" sx={{ width: 20, height: 20 }} />
                                    <Typography>Date d'ajout</Typography>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label1">
                                        Quand ce contact a été ajouté à votre communauté
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label1"
                                        defaultValue="female"
                                        name="radio-buttons-group1"
                                        value={filterHist}
                                        onChange={handleChangeHistorique}
                                    >
                                        <FormControlLabel value="today" control={<Radio />} label="Aujourd'hui" />
                                        <FormControlLabel value="yesterday" control={<Radio />} label="Hier" />
                                        <FormControlLabel value="last-7-days" control={<Radio />} label="Dans une semaine" />
                                        <FormControlLabel value="last-14-days" control={<Radio />} label="Dans les 14 derniers jours" />
                                        <FormControlLabel value="last-30-days" control={<Radio />} label="Dans les 30 derniers jours" />
                                        <FormControlLabel value="from-15/22/23-to-15/22/23" control={<Radio />} label="Selectionner la plage" />
                                    </RadioGroup>
                                </FormControl>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    : step === 1 ?
                        <Box
                            paddingBottom={3}
                            paddingX={3}
                            sx={{ height: { xs: '75vh', sm: ' 78vh', xl: '85vh' }, overflowX: 'auto', overflowY: 'auto' }}
                        >
                            <Typography variant="h5" paddingBottom={1} paddingTop={1}>
                                Nouvelle population 2/2
                            </Typography>
                            <Stack spacing={1} paddingTop={2}>
                                <Typography>
                                    Emoji
                                </Typography>

                                <Box onClick={togglePicker} style={{ backgroundColor: '#F5EFFA', borderRadius: '7px', width: 100, height: 100, border: '1px solid gainsboro', fontSize: "3rem", cursor: "pointer", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {chosenEmoji}
                                </Box>
                                {showPicker && <EmojiPicker onEmojiClick={onEmojiClick} />}
                            </Stack>
                            <Stack spacing={1} paddingTop={2}>
                                <Typography>
                                    Nom
                                </Typography>
                                <TextField size='small' value={nom} onChange={(e) => setNom(e.target.value)} />
                            </Stack>
                            <Stack spacing={1} paddingY={2}>
                                <Typography>
                                    Description
                                </Typography>
                                <TextField value={desc} onChange={(e) => setDesc(e.target.value)} type='text' multiline minRows={3} />
                            </Stack>
                            <Stack spacing={3}>
                                <Typography variant='body2'>
                                    <strong>Contacts correspondants:</strong>
                                </Typography>
                                <Typography variant='caption'>
                                    Ceci est un segment dynamique. Vos contacts seront automatiquement ajoutés et retirés de ce segment lorsqu’ils respectent ses conditions.
                                </Typography>
                                <Typography variant='body2'>
                                    Filtres:
                                </Typography>
                            </Stack>
                        </Box>
                        : null
            }


            <Stack direction="column" justifyContent="end" alignItems='end' spacing={1} padding={1} paddingRight={5}>
                <Typography variant='body2'><strong>{nombre}</strong> contact</Typography>
                <Stack direction='row' spacing={2}>
                    <Button
                        sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            color: 'white',
                            backgroundColor: 'black',
                            borderRadius: '7px',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black',
                                borderColor: 'black',
                            },
                        }}
                        variant="contained"
                        onClick={() => {
                            if (step === 0) {
                                setOpenDrawer(false);
                            }
                            else {
                                setStep(0)
                            }

                        }}
                    >
                        {step === 0 ? 'Annuler' : 'retour'}
                    </Button>
                    <Button
                        sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            color: 'white',
                            backgroundColor: '#5E17EB',
                            borderRadius: '7px',
                            boxShadow: 'none',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: '#5E17EB',
                                borderColor: '#5E17EB',
                            },
                        }}
                        variant="contained"
                        onClick={() => {
                            if (step === 0) {
                                setStep(1)
                            } else if (step === 1) {
                                createPopulation()
                            }
                        }}
                    >
                        {step === 1 ? 'Créer la population' : 'Suivant'}
                    </Button>
                </Stack>

            </Stack>
        </Box>
    );
}

/* eslint-disable no-unreachable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable import/order */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  Grid,
  Divider,
  LinearProgress,
  Tooltip,
  TextField,
  TableHead,
  IconButton,
  Switch,
  Box,
  Link,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, Timestamp, updateDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
// mock
import { db } from '../../firebase.config';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { format } from 'date-fns';
import { fr, id } from 'date-fns/locale';
import { LoadingButton } from '@mui/lab';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { width } from '@mui/system';

// ----------------------------------------------------------------------

export default function DateList() {
  const { eventID } = useParams();
  const [modif, setModif] = useState(false);
  // loading
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };
  // for change value of globla quota
  const [open, setOpen] = useState(false);
  const [refreh, setRefresh] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  // take tickets from firebase
  const [event, setEvent] = useState([]);
  const [dates, setDates] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [quotas, setQuotas] = useState([]);
  const [groupDate, setGroup] = useState([]);
  const [itemGroup, setItemGroup] = useState([]);
  const [active, setActive] = useState([]);
  const [activeJ, setActiveJ] = useState([]);
  const [activeI, setActiveI] = useState([]);
  const [count, setCount] = useState(0);
  const [statusB, setStatusB] = useState([]);
  const [modifQuota, setModifQuota] = useState(false);
  const [modifInfo, setModifInfo] = useState(false);

  // affichage de modification d'une seance
  const handleModif = (index, j) => {
    setLoading(true);

    if (modif) {
      setModif(false);
    } else {
      setModifQuota(false);
      const tmpStatus = [];
      const tmpQuota = [];
      let tmpCount = 0;
      tickets.forEach((ticket) => {
        console.log('ticket')
        if (itemGroup[index][j]?.data()?.billets?.find(({ id }) => id === ticket.id)) {
          tmpQuota.push(
            itemGroup[index][j].data().billets[
              itemGroup[index][j].data().billets.findIndex(({ id }) => id === ticket.id)
            ].quota
          );
          if (
            itemGroup[index][j].data().billets[
              itemGroup[index][j].data().billets.findIndex(({ id }) => id === ticket.id)
            ].status === 1
          ) {
            tmpStatus.push(true);
            tmpCount += 1;
          } else {
            tmpStatus.push(0);
          }
        } else {
          console.log('pas trouvé')
          tmpQuota.push(0);
          tmpStatus.push(false);
        }
      });
      setDebut(handleTime(itemGroup[index][j].data().date));
      setNom(itemGroup[index][j].data().name)
      setLieu(itemGroup[index][j].data().lieu_event);
      setVille(itemGroup[index][j].data().city);
      setPays(itemGroup[index][j].data().country);
      setCount(tmpCount);
      setQuotas(tmpQuota);
      setStatusB(tmpStatus);
      setModif(true);
      setActive(itemGroup[index][j]);
      setActiveI(index);
      setActiveJ(j);
      console.log(itemGroup[index][j].data());
    }
    setLoading(false);
  };

  const handleTime = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };
    const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[0];
  };
  const handleTimestamp1 = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[1];
  };

  // update status séance

  const updateState = async () => {
    const tmpBillets = itemGroup[activeI][activeJ].data().billets;
    try {
      handleToggle();
      const statRef = doc(db, `evenement/${eventID}/seances`, `${itemGroup[activeI][activeJ].id}`);
      if (itemGroup[activeI][activeJ].data().status === 1) {
        await updateDoc(statRef, {
          status: parseInt(0),
        });
      } else {
        await updateDoc(statRef, {
          status: parseInt(1),
        });
      }

      handleClose();
    } catch (error) {
      setRefresh(!refreh);
      handleClose();
      console.log(error.message);
    }

    console.log(tmpBillets);
  };

  // update des infos de la seance
  const updateInfos = async () => {
    try {
      handleToggle();
      console.log(debut)
      // enregistrement des modifications
      const statRef = doc(db, `evenement/${eventID}/seances`, `${active.id}`);
      await updateDoc(statRef, {
        name: nom,
        date: Timestamp.fromDate(new Date(debut)),
      });

      // mis à jour de date de fin et debut
      const eventRef = doc(db, `evenement`, `${eventID}`);
      if (new Date(handleTimestamp(event.dateDebut)) > new Date(debut)) {
        console.log('ok')
        await updateDoc(eventRef, {
          dateDebut: Timestamp.fromDate(new Date(debut)),
        });
      } else if (new Date(handleTimestamp(event.dateFin)) < new Date(debut)) {
        await updateDoc(eventRef, {
          dateFin: Timestamp.fromDate(new Date(debut)),
        });
      }
      
      setModifInfo(false)
      handleClose();
    } catch (error) {
      handleClose();
      console.log(error);
    }
  };

  // update de billets de la seance
  const update = async () => {
    let tmpBillets = []
    console.log(statusB)
    if (active.data().billets) {
      //
      tmpBillets = active.data().billets;
      for (let index = 0; index < statusB.length; index++) {
        if (statusB[index]) {
          const trouve = tmpBillets?.findIndex(({ id }) => id === tickets[index].id);
          if (trouve !== -1) {
            tmpBillets?.splice(trouve, 1, {
              id: tmpBillets[trouve].id,
              quota: parseInt(quotas[index]),
              sell: tmpBillets[trouve].sell,
              status: parseInt(1),
            });
          } else {
            tmpBillets.push({ id: tickets[index].id, quota: parseInt(0), sell: parseInt(0), status: parseInt(1) });
          }
        }
        if (!statusB[index]) {
          const trouve = tmpBillets?.findIndex(({ id }) => id === tickets[index].id);
          if (trouve !== -1) {
            tmpBillets?.splice(trouve, 1, {
              id: tmpBillets[trouve].id,
              quota: parseInt(quotas[index]),
              sell: tmpBillets[trouve].sell,
              status: parseInt(0),
            });
          }
        }
      }
    } else {
      for (let index = 0; index < statusB.length; index++) {
        if (statusB[index]) {
        
            tmpBillets.push({ id: tickets[index].id, quota: parseInt(quotas[index]), sell: parseInt(0), status: parseInt(1) });
          
        }
        else if (!statusB[index]) {
          tmpBillets.push({ id: tickets[index].id, quota: parseInt(quotas[index]), sell: parseInt(0), status: parseInt(0) });
        }
      }
    }
    

    try {
      handleToggle();
      const statRef = doc(db, `evenement/${eventID}/seances`, `${active.id}`);
      await updateDoc(statRef, {
        billets: tmpBillets,
      });
      setModifQuota(false)
      handleClose();
    } catch (error) {
      handleClose();
      console.log(error.message);
    }

    console.log(tmpBillets);
  };
  const navigate = useNavigate();
  // recuperation des dates
  useEffect(() => {
    (async () => {
      setTickets([]);
      setDates([]);
      try {
        setLoading(true);
        // event
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
        }
        // recuperation des tickets
        const q1 = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot1 = await getDocs(q1);
        snapshot1.forEach((d) => {
          setTickets((tickets) => [...tickets, d]);
        });

        // recuperation des dates
        const q = query(collection(db, `evenement/${eventID}/seances`), orderBy('date', 'asc'));
        const snapshot = await getDocs(q);
        const tmpd = [];
        const groups = [];
        const group = [];
        snapshot.forEach((d) => {
          setDates((dates) => [...dates, d]);
          const tmpDate = format(new Date(handleTimestamp(d.data().date)), 'MMM yyyy', { locale: fr });
          tmpd.push(d.data());
          const found = groups.find((element) => element === tmpDate);
          if (!found) {
            console.log('not found ');
            groups.push(tmpDate);
          }
        });
        console.log(groups.length);

        for (let index = 0; index < groups.length; index++) {
          const tgroup = [];
          snapshot.forEach((d) => {
            const tmpDate = format(new Date(handleTimestamp(d.data().date)), 'MMM yyyy', { locale: fr });
            if (groups[index] === tmpDate) {
              tgroup.push(d);
            }
          });
          group.push(tgroup);
        }
        setGroup(groups);
        setItemGroup(group);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refreh]);

  useEffect(() => {
    (async () => {
      try {
        await update()
      } catch (error) {
        console.log(error);
      }
    })();
  }, [statusB]);

  // supression d'un billet
  const handleSupp = async () => {
    handleToggle();
    console.log('supp');
    if (itemGroup[activeI][activeJ].data().participants === 0) {
      try {
        await deleteDoc(doc(db, `evenement/${eventID}/seances`, `${itemGroup[activeI][activeJ].id}`));
      } catch (error) {
        console.log(error.message);
        handleClose();
      }
    }
    handleClose();
    setModif(false);
  };
  const [nom, setNom] = useState('');
  const [debut, setDebut] = useState('');
  const addDebut = (e) => {
    const today = new Date();
    const tmp = new Date(e);
    // const tmpF = new Date(fin);
    console.log(today);
    console.log(tmp);
    if (tmp <= today) {
      // setDebutError(true);
      // setDebutErrorMsg("La valeur de champ n'est pas valide");
    }
    setDebut(e);
  };

  // handle location
  const searchOptions = {
    componentRestrictions: { country: 'CIV' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['establishment'],
  };
  const [address, setAddress] = useState('');
  const [lieuError, setLieuError] = useState(false);
  const [lieu, setLieu] = useState('');
  const [ville, setVille] = useState('');
  const [pays, setPays] = useState('');
  const [location, setLocation] = useState('');
  const handleSelect = async (address, placeId, suggestion) => {
    console.log(suggestion.description);
    const tmp = suggestion.description.split(',');
    setLieu(tmp[0]);
    setVille(tmp[tmp.length - 2]);
    setPays(tmp[tmp.length - 1]);
    setAddress(suggestion.description);
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    setLocation(ll);
  };

  return (
    <Page title="Séance">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      {!loading && event.seanceActive === 1 ? (
        <>
          {!loading && dates.length === 0 ? (
            <Container>
              <Grid container spacing={2} mb={5}>
                <Grid item xs={12} md={8}>
                  <Typography variant="h4" gutterBottom>
                    Gestion des séances <br />
                    <Typography variant="body2">Créez et modifiez vos séances</Typography>
                  </Typography>
                </Grid>
                <Grid textAlign="end" item xs={6} md={4}>
                  <Button
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      Color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    variant="contained"
                    component={RouterLink}
                    to="ajout"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Ajouter des séances
                  </Button>
                </Grid>
              </Grid>
              <Stack spacing={1} sx={{border:'1px solid #f2f2f2', padding: 5, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                <Typography variant="caption">Vous n'avez pas encore de seance</Typography>
                <Typography variant="caption">Cliquer sur <strong>Ajouter des seances</strong> pour créer de nouvelles seances</Typography>
              </Stack>
            </Container>
          ) : (
            <>
              {!modif ? (
                <>
                  <Container>
                    <Grid container spacing={2} mb={5}>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h4" gutterBottom>
                          Gestion des séances <br />
                          <Typography variant="body2">Créez et modifiez vos séances</Typography>
                        </Typography>
                      </Grid>
                      <Grid textAlign="end" item xs={6} md={4}>
                        <Button
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            Color: 'white',
                            backgroundColor: '#5E17EB',
                            borderRadius: '7px',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: '#5E17EB',
                              borderColor: '#5E17EB',
                            },
                          }}
                          variant="contained"
                          component={RouterLink}
                          to="ajout"
                          startIcon={<Iconify icon="eva:plus-fill" />}
                        >
                          Ajouter des séances
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography paddingY={3} variant="h6">
                      {dates.length} séances
                    </Typography>
                    {groupDate.map((group, index) => (
                      <>
                        <Typography fontSize={17}>{group}</Typography>
                        <Divider />

                        <Grid container spacing={4} padding={2} paddingBottom={8}>
                          {itemGroup[index].map((item, j) => (
                            <Grid key={index} item xs={6} md={3}>
                              {item.data().status === 1 ? (
                                // séance avtive
                                <Tooltip title="Séance active">
                                  <Card
                                    sx={{ padding: 3, cursor: 'pointer', bgcolor: '#5E17EB', color: 'white' }}
                                    onClick={() => handleModif(index, j)}
                                  >
                                    <Typography variant="body1">
                                      <strong>
                                        {format(new Date(handleTimestamp(item.data().date)), 'EEE dd', { locale: fr })
                                          .toString()
                                          .toLocaleUpperCase()}
                                      </strong>
                                    </Typography>
                                    <Typography variant="body1">{handleTimestamp1(item.data().date)}</Typography>
                                    <Stack direction="row" spacing={1} paddingY={1}>
                                      <Typography variant="body2">Participants</Typography>
                                      <Typography variant="body2">
                                        <strong>{item.data().participants}</strong>
                                      </Typography>
                                    </Stack>
                                  </Card>
                                </Tooltip>
                              ) : (
                                // séance desactivée
                                <Tooltip title="Séance désactivée">
                                  <Card
                                    sx={{ padding: 3, cursor: 'pointer', bgcolor: 'gainsboro', color: 'white' }}
                                    onClick={() => handleModif(index, j)}
                                  >
                                    <Typography variant="body1">
                                      <strong>
                                        {format(new Date(handleTimestamp(item.data().date)), 'EEE dd', { locale: fr })
                                          .toString()
                                          .toLocaleUpperCase()}
                                      </strong>
                                    </Typography>
                                    <Typography variant="body1">{handleTimestamp1(item.data().date)}</Typography>
                                    <Stack direction="row" spacing={1} paddingY={1}>
                                      <Typography variant="body2">Participants</Typography>
                                      <Typography variant="body2">
                                        <strong>{item.data().participants}</strong>
                                      </Typography>
                                    </Stack>
                                  </Card>
                                </Tooltip>
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ))}
                  </Container>
                </>
              ) : (
                <>
                  <Container>
                    <Stack spacing={3}>


                      <Stack direction="row" justifyContent="space-between" mb={2}>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            color: 'black',
                            backgroundColor: 'white',
                            borderRadius: '7px',
                            borderColor: 'black',
                            boxShadow: 'none',
                            '&:hover': {
                              backgroundColor: 'black',
                              color: 'white',
                              borderColor: 'black',
                            },
                          }}
                          startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                          onClick={() => setModif(false)}
                        >
                          retour
                        </Button>
                        <Stack direction="row" spacing={1}>
                          <LoadingButton
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              color: 'white',
                              backgroundColor: 'red',
                              borderRadius: '7px',
                              boxShadow: 'none',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: 'red',
                                borderColor: 'red',
                              },
                            }}
                            disabled={itemGroup[activeI][activeJ].data().participants === 0 ? false : true}
                            onClick={handleSupp}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                          >
                            Supprimer
                          </LoadingButton>
                          <LoadingButton
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              color: 'white',
                              backgroundColor: '#5E17EB',
                              borderRadius: '7px',
                              boxShadow: 'none',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: '#5E17EB',
                                borderColor: '#5E17EB',
                              },
                            }}
                            onClick={updateState}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                          >
                            {itemGroup[activeI][activeJ].data().status === 1
                              ? 'Desactiver la seance'
                              : 'Activer la séance'}
                          </LoadingButton>
                        </Stack>
                      </Stack>
                      {
                        !modifInfo ?
                          <Stack direction='column'>
                          <Stack direction='row'>
                            <Typography variant="h4" gutterBottom>
                              {format(new Date(handleTimestamp(active.data().date)), 'EEEE dd MMMM yyyy', { locale: fr })
                                .toString()
                                .toLocaleUpperCase()}{' '}
                              - {handleTimestamp1(active.data().date)}
                            </Typography>
                            <IconButton onClick={(e) => setModifInfo(true)}>
                              <Iconify icon="lucide:edit" />
                            </IconButton>
                          </Stack>
                          <Typography><strong>{nom != null ? nom : '-'}</strong></Typography>
                          </Stack>
                          :
                          <>
                            <Card sx={{ padding: 3 }}>
                              <Grid alignItems="center" container paddingY={1}>
                                <Grid item xs={12} sm={2}>
                                  <Typography>Nom de la seance</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    id="nom-seance"
                                    type="text"
                                    value={nom}
                                    onChange={(e) => setNom(e.target.value)}
                                    fullWidth
                                    helperText='ce champ est optionnel'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid alignItems="center" container paddingY={1}>
                                <Grid item xs={12} sm={2}>
                                  <Typography>Date</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    required
                                    id="datetime-local"
                                    type="datetime-local"
                                    value={debut}
                                    onChange={(e) => addDebut(e.target.value)}
                                    fullWidth
                                    // error={debutError ? true : false}
                                    // helperText={debutError ? debutErrorMsg : null}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Card>
                            <Box dir="ltr">
                              <Grid container spacing={1}>
                                <Grid item xs={6} sm={2} md={2}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{
                                      textTransform: 'initial',
                                      border: '2px solid transparent',
                                      color: 'black',
                                      backgroundColor: 'white',
                                      borderRadius: '7px',
                                      borderColor: 'black',
                                      boxShadow: 'none',
                                      '&:hover': {
                                        backgroundColor: 'black',
                                        color: 'white',
                                        borderColor: 'black',
                                      },
                                    }}
                                    onClick={() => setModifInfo(false)}
                                  >
                                    Annuler
                                  </Button>

                                </Grid>
                                <Grid item xs={6} sm={2} md={2}>
                                  {event.seanceActive === 1 ? (
                                    <LoadingButton
                                      fullWidth
                                      sx={{
                                        textTransform: 'initial',
                                        border: '2px solid transparent',
                                        color: 'white',
                                        backgroundColor: '#5E17EB',
                                        borderRadius: '7px',
                                        boxShadow: 'none',
                                        '&:hover': {
                                          backgroundColor: 'white',
                                          color: '#5E17EB',
                                          borderColor: '#5E17EB',
                                        },
                                      }}
                                      onClick={updateInfos}
                                      loading={loading}
                                      loadingPosition="end"
                                      variant="contained"
                                    >
                                      Enregistrer
                                    </LoadingButton>
                                  ) : (
                                    <LoadingButton
                                      fullWidth
                                      sx={{
                                        textTransform: 'initial',
                                        border: '2px solid transparent',
                                        color: 'white',
                                        backgroundColor: '#5E17EB',
                                        borderRadius: '7px',
                                        '&:hover': {
                                          backgroundColor: 'white',
                                          color: '#5E17EB',
                                          borderColor: '#5E17EB',
                                        },
                                      }}
                                      onClick={update}
                                      loading={loading}
                                      loadingPosition="end"
                                      variant="contained"
                                    >
                                      Enregistrer
                                    </LoadingButton>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </>

                      }

                      <Grid alignItems="center" container columnSpacing={2}>
                        <Grid item>
                          <Card sx={{ padding: 3 }}>
                            Billets actifs pour cette seance : {count} / {tickets.length}
                          </Card>
                        </Grid>
                        <Grid item>
                          <Card sx={{ padding: 3 }}>
                            Revenu : {count} / {tickets.length}
                          </Card>
                        </Grid>
                      </Grid>

                      <Box>
                        <Stack alignItems='end' paddingY={1}>
                          {
                            !modifQuota ? <Button
                              sx={{ textTransform: 'initial' }}
                              variant="text"
                              onClick={() => {
                                setModifQuota(true);
                              }}
                            >
                              Editer les quotas
                            </Button> :
                              <Stack direction='row' spacing={1}>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  sx={{
                                    textTransform: 'initial',
                                    border: '2px solid transparent',
                                    color: 'black',
                                    backgroundColor: 'white',
                                    borderRadius: '7px',
                                    borderColor: 'black',
                                    boxShadow: 'none',
                                    '&:hover': {
                                      backgroundColor: 'black',
                                      color: 'white',
                                      borderColor: 'black',
                                    },
                                  }}
                                  onClick={() => {
                                    setModifQuota(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                                <>
                                  {event.seanceActive === 1 ? (
                                    <LoadingButton
                                      fullWidth
                                      sx={{
                                        textTransform: 'initial',
                                        border: '2px solid transparent',
                                        color: 'white',
                                        backgroundColor: '#5E17EB',
                                        borderRadius: '7px',
                                        boxShadow: 'none',
                                        '&:hover': {
                                          backgroundColor: 'white',
                                          color: '#5E17EB',
                                          borderColor: '#5E17EB',
                                        },
                                      }}
                                      onClick={update}
                                      loading={loading}
                                      loadingPosition="end"
                                      variant="contained"
                                    >
                                      Enregistrer
                                    </LoadingButton>
                                  ) : (
                                    <LoadingButton
                                      fullWidth
                                      sx={{
                                        textTransform: 'initial',
                                        border: '2px solid transparent',
                                        color: 'white',
                                        backgroundColor: '#5E17EB',
                                        borderRadius: '7px',
                                        '&:hover': {
                                          backgroundColor: 'white',
                                          color: '#5E17EB',
                                          borderColor: '#5E17EB',
                                        },
                                      }}
                                      onClick={update}
                                      loading={loading}
                                      loadingPosition="end"
                                      variant="contained"
                                    >
                                      Enregistrer
                                    </LoadingButton>
                                  )}
                                </>
                              </Stack>
                          }
                        </Stack>
                        <Card>
                          <TableContainer>
                            <Table sx={{ minWidth: 500 }} aria-label="caption table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Billets</TableCell>
                                  <TableCell align="right">Montant</TableCell>
                                  <TableCell align="right">Ventes</TableCell>
                                  <TableCell align="right">Quotas</TableCell>
                                  <TableCell align="right">Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tickets.map((ticket, index) => (
                                  <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                                    <TableCell component="th" scope="row">
                                      {ticket.data().ticket_name}
                                    </TableCell>
                                    <TableCell align="right">{ticket.data().price} CFA</TableCell>
                                    <TableCell align="right">
                                      {active?.data()?.billets?.find(({ id }) => id === ticket.id) ? (
                                        <>
                                          {active.data().billets[
                                            active.data().billets.findIndex(({ id }) => id === ticket.id)
                                          ].sell ? (
                                            active.data().billets[
                                              active.data().billets.findIndex(({ id }) => id === ticket.id)
                                            ].sell
                                          ) : (
                                            <div>-</div>
                                          )}
                                        </>
                                      ) : (
                                        <div>-</div>
                                      )}
                                    </TableCell>
                                    <TableCell align="right">
                                      {quotas[index] !== 0 && !modifQuota ? (
                                        quotas[index]
                                      ) : quotas[index] === 0 && !modifQuota ? (
                                        <>-</>
                                      ) : (
                                        <TextField
                                          size="small"
                                          value={quotas[index]}
                                          onChange={(e) => {
                                            setQuotas((existItem) => {
                                              return [
                                                ...existItem.slice(0, index),
                                                e.target.value,
                                                ...existItem.slice(index + 1),
                                              ];
                                            });
                                          }}
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell align="right">
                                      <Tooltip title="Activer le ticket">
                                        <Switch
                                          checked={statusB[index]}
                                          size="small"
                                          key={index}
                                          onChange={(e) => {
                                            console.log(e.target.checked);
                                            setStatusB((existItem) => {
                                              if (statusB[index]) {
                                                let tmp = count;
                                                tmp -= 1;
                                                setCount(tmp);
                                              } else {
                                                let tmp = count;
                                                tmp += 1;
                                                setCount(tmp);
                                              }
                                              return [
                                                ...existItem.slice(0, index),
                                                !statusB[index],
                                                ...existItem.slice(index + 1),
                                              ];
                                            });
                                          }}
                                        />
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Box>
                    </Stack>
                  </Container>
                </>
              )}
            </>
          )}
        </>
      ) : event.seanceActive === 0 && event.participant === 0 ? (
        <Typography fontSize={30} paddingY={10} paddingX={5} textAlign="center">
          {' '}
          Le mode séance est desactivé. vous pouvez l'activer dans l'onglet{' '}
          <Link
            onClick={() => {
              navigate(`/${eventID}/dashboard/information`, { replace: true });
            }}
          >
            "Données événement"
          </Link>
        </Typography>
      ) : event.seanceActive === 0 && event.participant !== 0 ? (
        <Typography fontSize={30} paddingY={10} paddingX={5} textAlign="center">
          {' '}
          Vous ne pouvez pas activer le mode séance car vous avez deja effectué des ventes
        </Typography>
      ) : null}
    </Page>
  );
}

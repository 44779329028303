/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  Timestamp,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import { db } from '../../firebase.config';

import { UserAuth } from '../../context/AuthContext';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const params = useParams;

// ----------------------------------------------------------------------

export default function Comptabilite() {
  const { user } = UserAuth();
  const [value, setValue] = React.useState(0);
  const [organisation, setOrg] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [event, setEvent] = React.useState([]);
  const [eventData, setEventData] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setEventData([]);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          console.log(docSnap.data().idOrg);
          // const q = query(collection(db, 'organisations'), where('orgId', '==', docSnap.data().idOrg));
          const q = doc(db, 'organisations', docSnap.data().idOrg);
          const querySnapshot = await getDoc(q);
          setOrg(querySnapshot);
          if (querySnapshot.exists()) {
            const q1 = collection(db, `organisations/${docSnap.data().idOrg}/accounting`);
            const querySnapshot1 = await getDocs(q1);
            querySnapshot1.forEach(async (element) => {
              console.log(element.id);
              const eventDataq = doc(db, 'evenement', element.id);
              const eventDataSnap = await getDoc(eventDataq);
              setEventData((eventData) => [...eventData, eventDataSnap]);
            });
          }
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user.uid]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModif = (index) => {};

  return (
    <Page title="Organisation | Comptabilité">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Votre comptabilité
            <br />
          </Typography>
        </Stack>
        {/* Tableau des billets */}
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>Evènement</TableCell>
                    <TableCell align="right">Date de début</TableCell>
                    <TableCell align="right">Date de fin</TableCell>
                    <TableCell align="right">Réddition</TableCell>
                    <TableCell align="right">Statut</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <caption>
                    <Skeleton variant="rounded" width="100%" />
                  </caption>
                ) : eventData.length === 0 && !loading ? (
                  <caption>
                    <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                      <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                      <Typography variant="caption">Accun billet</Typography>
                    </Stack>
                  </caption>
                ) : (
                  <TableBody>
                    {eventData.map((ticket, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row" onClick={() => handleModif(index)}>
                          {ticket.data().nomEvent}
                        </TableCell>

                        <TableCell align="right" onClick={() => handleModif(index)}>
                          k
                        </TableCell>

                        <TableCell align="right" onClick={() => handleModif(index)}>
                          l
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip title="Desactiver le ticket">
                            <IconButton key={index}>
                              <Iconify icon="gridicons:visible" sx={{width:15, height:15}} />
                              <Typography variant='caption'>Temporaire</Typography>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right" onClick={() => handleModif(index)}>
                          publié
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}

/* eslint-disable no-nested-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  InputAdornment,
  Skeleton,
  Menu,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import Iconify from '../../../components/Iconify';

// mock
import USERLIST from '../../../_mock/user';

import { db } from '../../../firebase.config';
import CheckCard from '../../../components/CheckCard';
import { UserAuth } from '../../../context/AuthContext';
import { Variables } from '../../../context/VariableContext';
import Swipeable from './swiper';
import useResponsive from '../../../hooks/useResponsive';

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const options = ['Importer des contacts', 'Exporter en CSV'];

export default function ContactsCrm() {
  const smUp = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');
  const { eventID } = useParams();
  const { user } = UserAuth();

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);

  const [refreh, setRefresh] = React.useState(false);

  // take operateurs from firebase
  const [contacts, setContact] = React.useState([]);
  const [curent, setCurent] = React.useState();
  const [tmp, setTmp] = React.useState();
  // const [type, setType] = React.useState();
  const [search, setSearch] = React.useState('');
  const [orgID, setOrgID] = React.useState();
  const { openDrawer, setOpenDrawer } = Variables();
  const { type, setType } = Variables();

  React.useEffect(() => {
    (async () => {
      setContact([]);
      try {
        setLoading(true);
        setLoadingPag(true);
        const docRef0 = doc(db, 'organisateurs', `${user.uid}`);
        const docSnap = await getDoc(docRef0);
        /* if (docSnap.exists()) {
          
        } */
        // setIdOrg(docSnap.data().idOrg)

        const q = query(collection(db, `organisations/${docSnap.data().idOrg}/crm/${docSnap.data().idOrg}/contacts`));
        const snapshot = await getDocs(q);
        setOrgID(docSnap.data().idOrg)
        snapshot.forEach(async (d) => {
          setContact((contacts) => [...contacts, d]);
        });
      } catch (error) {
        console.log('error');
      }
      setLoading(false);
      setLoadingPag(false);
    })();
  }, [user, refreh]);

  // loading
  const [loading, setLoading] = React.useState(false);
  const [loadingPag, setLoadingPag] = React.useState(false);

  const handleModif = async (index) => {
    try {
      const docRef0 = doc(db, 'users', `${contacts[index].data().uid}`);
      const docSnap = await getDoc(docRef0);
      if(docSnap.exists()){
        setCurent(docSnap?.data());
      }else{
        console.log('client n existe pas')
        console.log(contacts[index].data())
        setCurent(contacts[index].data());
        // setIDContact(contacts[index].id)
      }
      
    } catch (error) {
      console.log('client n existe pas1')
      console.log(error)
      setCurent(contacts[index].data());
      // setIDContact(contacts[index].id)
    }
    setType(1);
    setTmp(index)
    setOpenDrawer(!openDrawer);
  };

  const handleFilter = () => {
    setType(2);
    setOpenDrawer(!openDrawer);
  };
  const handleImportation = () => {
    setType(3);
    setOpenDrawer(!openDrawer);
  };

  const handleSearch = async (tmp) => {
    setSearch(tmp);
    if (search.length > 3) {
      // loading
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };

  


  return (
    <Page title="Contacts">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <CheckCard />
        <Stack direction="row" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contacts
          </Typography>
          {
            !smUp ? <><IconButton
            aria-label="more"
            id="long-button"
            aria-controls={openMenu ? 'long-menu' : undefined}
            aria-expanded={openMenu ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClickMenu}
            sx={{bgcolor:'#5E17EB'}}
          >
            <Iconify icon="mi:options-horizontal" />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleCloseMenu}>
                {option}
              </MenuItem>
            ))}
          </Menu></> : <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              sx={{
                textTransform: 'initial',
                border: '2px solid transparent',
                color: 'white',
                backgroundColor: '#5E17EB',
                borderRadius: '7px',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#5E17EB',
                  borderColor: '#5E17EB',
                },
              }}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={handleImportation}
            >
              Importer des contacts
            </Button>
            <Button
              sx={{
                textTransform: 'initial',
                border: '2px solid transparent',
                color: 'white',
                backgroundColor: '#5E17EB',
                borderRadius: '7px',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#5E17EB',
                  borderColor: '#5E17EB',
                },
              }}
              variant="contained"
              startIcon={<Iconify icon="material-symbols:download" />}
            >
              Exporter en CSV
            </Button>
          </Stack>
          }
          
        </Stack>
        <Stack
          direction={{xs: "column-reverse", sm: "row"}}
          justifyContent={{xs: "start", sm:"space-between"}}
          alignItems= {{xs: "start", sm:"space-between"}}
          spacing={2}
          sx={{ width: '100%' }}
          mb={2}
        >
          {loading ? (
            <Skeleton width="20%">
              <Typography>.</Typography>
            </Skeleton>
          ) : (
            <Typography variant="h6">{contacts.length} contacts</Typography>
          )}

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <TextField
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              size="small"
              placeholder="Rechercher"
              id="outlined-start-adornment"
              sx={{ m: 1, width: '25ch' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="iconoir:search" />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{
                textTransform: 'initial',
                border: '2px solid transparent',
                color: 'white',
                backgroundColor: '#5E17EB',
                borderRadius: '7px',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#5E17EB',
                  borderColor: '#5E17EB',
                },
              }}
              variant="contained"
              startIcon={<Iconify icon="circum:filter" />}
              onClick={handleFilter}
            >
              Filtres
            </Button>
          </Box>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} aria-label="caption table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Pays</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Genre</TableCell>
                    <TableCell>Abonné</TableCell>
                    <TableCell>Notif push</TableCell>
                    <TableCell>Newsletters</TableCell>
                    <TableCell>Date d'ajout</TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <caption>
                    <Skeleton variant="rounded" width="100%" />  
                  </caption>
                ) : contacts.length === 0 && !loading ? (
                  <caption>
                    <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                      <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                      <Typography variant="caption">Aucune donnée</Typography>
                    </Stack>
                  </caption>
                ) : (
                  <TableBody>
                    {contacts.map((contact, index) => (
                      <TableRow key={index} hover>
                        <TableCell
                          id={contact.data().nom}
                          onClick={(e) => handleModif(index)}
                          key={contact.data().nom}
                          component="th"
                          scope="row"
                        >
                          <Stack>
                            <Stack direction="row">
                              {contact.data().nom} {contact.data().prenom}
                            </Stack>
                            {contact.id}
                          </Stack>
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().pays ? contact.data().pays : '-'}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().age ? contact.data().age : '-'}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().genre ? contact.data().genre : '-'}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().push || contact.data().newslettres ? (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#C9E4D4',
                                border: '1px dashed #C9E4D4',
                              }}
                            >
                              oui
                            </Box>
                          ) : (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#FB8888',
                                border: '1px dashed #FB8888',
                              }}
                            >
                              non
                            </Box>
                          )}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().push ? (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#C9E4D4',
                                border: '1px dashed #C9E4D4',
                              }}
                            >
                              oui
                            </Box>
                          ) : (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#FB8888',
                                border: '1px dashed #FB8888',
                              }}
                            >
                              non
                            </Box>
                          )}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().newslettres ? (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#C9E4D4',
                                border: '1px dashed #C9E4D4',
                              }}
                            >
                              oui
                            </Box>
                          ) : (
                            <Box
                              component="button"
                              paddingX={2}
                              paddingY={0.5}
                              sx={{
                                borderRadius: '5px',
                                fontSize: 11,
                                backgroundColor: '#FB8888',
                                border: '1px dashed #FB8888',
                              }}
                            >
                              non
                            </Box>
                          )}
                        </TableCell>
                        <TableCell id={contact.data().nom} onClick={(e) => handleModif(index)}>
                          {contact.data().createDate ? format(new Date(handleTimestamp(contact.data().createDate)), 'dd/MM/yy', {
                                    locale: fr,
                                  }).toString() : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        <Swipeable type={type} user={curent} email={tmp ? contacts[tmp].id : ''} nbTickets={tmp ? contacts[tmp].data().tickets : 0} nbEvents={tmp ? contacts[tmp].data().events?.length : 0} nbDepense={tmp ? contacts[tmp].data().depenses : 0}  organisation={orgID}/>
      </Container>
    </Page>
  );
}

/* eslint-disable radix */
import React from 'react';

import { List } from 'antd';
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Iconify from '../Iconify';
import NumberTextField from '../NumberTextField';

function AppPricing() {
  // montant
  const [montant, setMontant] = React.useState([0]);
  const [nbBillet, setNbBillet] = React.useState([0]);
  const [commission, setCommission] = React.useState({ type: 1, c1: 6, c2: 5 });
  const [revenu, setRevenu] = React.useState(0);
  const [bCheck, setBcheck] = React.useState(false);
  const [nCheck, setNcheck] = React.useState(false);
  const [type, setType] = React.useState(1);

  const handleBcheck = (event) => {
    setBcheck(!bCheck);
    // augmenter le nom la commission de +0,5
    if (!bCheck) {
      if (type === 1) {
        setCommission({ type: 1, c1: commission.c1 + 0.5, c2: commission.c2 + 0.5 });
      } else if (type === 2) {
        setCommission({ type: 2, c1: commission.c1 + 0.5 });
      }
    } else if (bCheck) {
      if (type === 1) {
        setCommission({ type: 1, c1: commission.c1 - 0.5, c2: commission.c2 - 0.5 });
      } else if (type === 2) {
        setCommission({ type: 2, c1: commission.c1 - 0.5 });
      }
    }
  };

  const handleNcheck = (event) => {
    setNcheck(!nCheck);
    if (!nCheck) {
      if (type === 1) {
        setCommission({ type: 1, c1: commission.c1 + 1, c2: commission.c2 + 1 });
      } else if (type === 2) {
        setCommission({ type: 2, c1: commission.c1 + 1 });
      }
    } else if (nCheck) {
      if (type === 1) {
        setCommission({ type: 1, c1: commission.c1 - 1, c2: commission.c2 - 1 });
      } else if (type === 2) {
        setCommission({ type: 2, c1: commission.c1 - 1 });
      }
    }
  };
  const validateNumber = (input) => {
    // Expression régulière pour n'accepter que les chiffres
    const regex = /^[0-9]+$/;

    // Teste si l'entrée correspond à la regex
    return regex.test(input);
  };
  const handledMontant = (newValue, index) => {
    console.log(newValue);
    if (validateNumber(newValue)) {
      console.log(newValue);
      const values = [...montant]; // Copie du tableau de nouvelles valeurs
      values[index] = newValue;
      console.log(values);
      setMontant(values);
    }
    console.log(newValue);
    // handleRevenue();
  };
  const handledNb = (newValue, index) => {
    console.log(newValue);
    if (validateNumber(newValue)) {
      console.log(newValue);
      const values = [...nbBillet]; // Copie du tableau de nouvelles valeurs
      values[index] = newValue;
      console.log(values);
      setNbBillet(values);
    }
    console.log(newValue);
    // handleRevenue();
  };

  const addBillet = (value) => {
    console.log('ok');
    setNbBillet((nbBillet) => [...nbBillet, value]);
    setMontant((montant) => [...montant, value]);
  };

  const handleMontant = (event) => {
    setMontant(event.target.value);
    handleRevenue();
  };

  const handleRevenue = (event) => {
    if (event.target.id === 'prixVente') {
      setMontant(event.target.value);
    } else if (event.target.id === 'nbBillet') {
      setNbBillet(event.target.value);
    }
    if (montant !== 0) {
      console.log('montant: ', event.target.value);
      console.log('nb billet: ', event.target.value);
      const tmp = montant * 0.05;
      const tmp1 = montant - tmp;
      setRevenu(tmp1 * nbBillet);
    }
  };

  const estimationGain = () => {
    console.log('gain estimation');
    if (montant < 10000) {
      const tmp = (montant * 6) / 100 + 20;
      const tmp1 = montant - tmp;
      setRevenu(tmp1 * nbBillet);
    } else if (montant >= 10000) {
      const tmp = (montant * 5) / 100 + 20;
      const tmp1 = montant - tmp;
      setRevenu(tmp1 * nbBillet);
    }
  };
  const [disablePointerEvents, setDisablePointerEvents] = React.useState(false);

  const handlePassLiberte = () => {
    setType(1);
    setCommission({ type: 1, c1: 6, c2: 5 });
    setNcheck(false);
    setBcheck(false);
  };

  const handlePassExclu = () => {
    setType(2);
    setCommission({ type: 2, c1: 4.5 });
    setNcheck(false);
    setBcheck(false);
  };

  React.useEffect(() => {
    (async () => {
      try {
        console.log('ok');
        let total = 0;
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < montant.length; index++) {
          // calcul de la comission
          let valeurComission = 0;
          if (type === 1) {
            if (parseInt(montant[index]) < 10000) {
              valeurComission = parseInt(montant[index]) * (commission.c1 / 100);
            } else if (parseInt(montant[index]) >= 1000) {
              valeurComission = parseInt(montant[index]) * (commission.c2 / 100);
            }
          } else if (type === 2) {
            valeurComission = parseInt(montant[index]) * (commission.c1 / 100);
          }
          // calcul du revenu
          total += (parseInt(montant[index]) - valeurComission) * parseInt(nbBillet[index]);
        }
        console.warn(total);
        setRevenu(total);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [montant, nbBillet, type, nCheck, bCheck]);

  const suppBillet = (index) => {
    setMontant((existItem) => {
      return [...existItem.slice(0, index), ...existItem.slice(index + 1)];
    });
    setNbBillet((existItem) => {
      return [...existItem.slice(0, index), ...existItem.slice(index + 1)];
    });
  };

  return (
    <div id="pricing" className="block pricingBlock bgGray">
      <div className="container-fluid">
        <div>
          <Typography paddingTop={5} textAlign="center" variant="h2">
            Tarif & Commission
          </Typography>
          <Typography fontSize={16} textAlign="center" variant="body1">
            Avec Choptonticket payé ce que vous consommez
          </Typography>
        </div>

        {/* New design-------------------------------------------------------------- */}

        { /* <Box sx={{ my: 10, border: '2px solid #141414', borderRadius: '10px' }} dir="ltr">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{
                p: { xs: 2, sm: 5 },
                borderBottom: { xs: '2px solid #141414', sm: '2px solid transparent' },
                borderRight: { xs: '2px solid transparent', sm: '2px solid #141414' },
              }}
            >
              <Stack
                direction="row"
                sx={{ backgroundColor: '#5E17EB', border: '2px solid transparent', borderRadius: '10px' }}
                padding={0.5}
                spacing={1}
              >
                <Button
                  size="large"
                  fullWidth
                  sx={
                    type === 1
                      ? {
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        backgroundColor: 'white',
                        color: '#141414',
                        borderRadius: '7px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: '#141414',
                          borderColor: '#141414',
                        },
                      }
                      : {
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'white',
                        backgroundColor: 'transparent',
                        borderRadius: '7px',
                      }
                  }
                  onClick={() => {
                    handlePassLiberte();
                  }}
                  variant="contained"
                >
                  Pass Liberté
                </Button>
                <Button
                  size="large"
                  fullWidth
                  sx={
                    type === 2
                      ? {
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        backgroundColor: 'white',
                        color: '#141414',
                        borderRadius: '7px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: '#141414',
                          borderColor: '#141414',
                        },
                      }
                      : {
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'white',
                        backgroundColor: 'transparent',
                        borderRadius: '7px',
                      }
                  }
                  onClick={() => {
                    handlePassExclu();
                  }}
                  variant="contained"
                >
                  Pass Partenaire
                </Button>
              </Stack>
              <Box sx={{ p: { xs: 1, sm: 5 }, direction: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                
                <Typography textAlign="center">Choptonticket vous prelevera</Typography>
                {commission.type === 1 ? (
                  <>
                    <Typography textAlign="center" variant="h2">
                      {commission.c1}%
                    </Typography>
                    <Typography textAlign="center">
                      Pour le ticket compris entre <strong>200</strong> et <strong>10,000</strong> CFA
                    </Typography>
                    <Typography textAlign="center" variant="h2">
                      {commission.c2}%
                    </Typography>
                    <Typography textAlign="center">
                      Pour le ticket superieur à <strong>10,000 CFA</strong>
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography textAlign="center" variant="h2">
                      {commission.c1}%
                    </Typography>
                    <Typography textAlign="center">Pour tous les tickets vendus</Typography>
                    <Stack padding={2} direction="column" alignItems="center" justifyContent="center">
                      <Button
                        size="large"
                        sx={{
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          color: 'white',
                          backgroundColor: '#141414',
                          borderRadius: '7px',
                          boxShadow: 'none',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: '#141414',
                            borderColor: '#141414',
                          },
                        }}
                        variant="contained"
                        href="/bo/new_event"
                      >
                        Nous contacter
                      </Button>
                    </Stack>
                  </>
                )}
              </Box>

              <Divider />
              <Box paddingY={2} />
              <Typography textAlign="center">
                C'est <strong>gratuit</strong> pour les événements <strong>gratuit</strong>
              </Typography>
              <Typography paddingTop={{ xs: 3, sm: 2 }} paddingBottom={{ xs: 1, sm: 0 }} variant="h4">
                Services supplémentaires
              </Typography>
              <Stack
                direction="column"
                sx={{
                  backgroundColor: '#141414',
                  border: '2px solid transparent',
                  borderRadius: '10px',
                  color: 'white',
                }}
                padding={3}
                spacing={2}
              >
                <Stack
                  direction="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack>
                    <Typography>Bracelet evenementiel</Typography>
                    <Typography variant="caption">
                      (+0.5% sur chaque ticket et un devis vous sera communiqué)
                    </Typography>
                  </Stack>
                  <FormControlLabel control={<Switch checked={bCheck} onChange={handleBcheck} />} />
                </Stack>
                <Stack
                  direction="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack>
                    <Typography>Nom de domaine personnalité</Typography>
                    <Typography variant="caption">(+1% sur chaque ticket)</Typography>
                  </Stack>

                  <FormControlLabel control={<Switch checked={nCheck} onChange={handleNcheck} />} />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ p: { xs: 2, sm: 5 } }}>
              <Box paddingY={3}>
                <Typography variant="h4">Estimation de votre gain</Typography>
                <Typography paddingBottom={5} variant="caption">
                  les estmations sont fournies à titre indicatif.
                </Typography>

                <Grid paddingY={1} textAlign="center" container spacing={3}>
                  <Grid item xs={12}>
                    <Grid paddingY={5} container spacing={1}>
                      {nbBillet.map((element, index) => (
                        <>
                          <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between">
                              <Typography>Billet {index + 1}</Typography>
                              <IconButton sx={{ padding: 0 }} onClick={(e) => suppBillet(index)}>
                                <Iconify icon="typcn:delete" />
                              </IconButton>
                            </Stack>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <TextField
                              size="medium"
                              variant="outlined"
                              label="Prix"
                              id="prixVente"
                              type="text"
                              onFocus={(e) => {
                                e.target.select();
                              }}
                              fullWidth
                              onChange={(e) => handledMontant(e.target.value, index)}
                              value={montant[index]}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6}>
                            <TextField
                              size="medium"
                              variant="outlined"
                              label="Nombre"
                              id="prixVente"
                              type="text"
                              onFocus={(e) => {
                                e.target.select();
                              }}
                              fullWidth
                              onChange={(e) => handledNb(e.target.value, index)}
                              value={element}
                            />
                          </Grid>{' '}
                          <Grid item xs={12}>
                            <Box />
                          </Grid>{' '}
                        </>
                      ))}
                      <Grid item xs={12}>
                        <Button
                          sx={{
                            textTransform: 'initial',
                            border: '2px solid transparent',
                            Color: 'white',
                            backgroundColor: '#5E17EB',
                            borderRadius: '7px',
                            boxShadow: 'none',
                            '&:hover': {
                              backgroundColor: 'white',
                              color: '#5E17EB',
                              borderColor: '#5E17EB',
                            },
                          }}
                          onClick={() => addBillet(0)}
                          variant="contained"
                        >
                          Ajouter un autre billet
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Typography paddingBottom={3} variant="h2" textAlign="center">
                  {revenu} <Typography variant="body1">CFA</Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box> */ }
        <Box paddingTop={5}>
          <Grid container columnSpacing={1}>
            <Grid item xs={4} display='flex' justifyContent='right' >
              <Box sx={{ border: '1px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                <Box sx={{ padding: 2, bgcolor: '#141414', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                  <Typography sx={{ color: 'white', }} variant='h3'>Pass Essentiel</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='subtitle1'>Tout ce qu'il faut savoir pour commencer à vendre des billets en quelques minutes.</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography align='center' variant='h5'>6% de commission sur chaque ticket vendu</Typography>
                </Box>
                <Stack direction='column' padding={2} spacing={1.5}>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>2 type de billet (ex : « Grand public »)</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Fonctionnalités essentielles de billetterie et d'inscription</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Listing sur Choptonticket et les sites partenaires</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Les prix des billets incluent le traitement des paiements</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>

            </Grid>
            <Grid item xs={4} display='flex' justifyContent='center'>
              <Box sx={{ border: '1px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                <Box sx={{ padding: 2, bgcolor: '#5E17EB', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                  <Typography sx={{ color: 'white', }} variant='h3'>Pass Pro</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='subtitle1'>Tout ce qu'il faut savoir pour commencer à vendre des billets en quelques minutes.</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='h6'>9% de commission sur chaque ticket vendu</Typography>
                </Box>
                <Stack direction='column' padding={2} spacing={1.5}>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'><strong>Tout ce que propose Essentials, plus :</strong></Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Types de billets illimités</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Vendez des billets sur votre propre site</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Formulaires de règlement personnalisables</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Analyses détaillées des ventes</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Accès et autorisations d'équipe</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={4} display='flex' justifyContent='left'>
              <Box sx={{ border: '1px solid gainsboro', width: '100%', borderRadius: '7px' }}>
                <Box sx={{ padding: 2, bgcolor: '#141414', display: 'flex', justifyContent: 'center', borderTopLeftRadius: '7px', borderTopRightRadius: '7px' }}>
                  <Typography sx={{ color: 'white', }} variant='h3'>Patners</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='subtitle1'>Des forfaits personnalisés pour les pros de l'événementiel avec des événements de grande taille ou complexes .</Typography>
                </Box>
                <Box padding={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant='h6'>Commission personnalisé</Typography>
                </Box>
                <Stack direction='column' padding={2} spacing={1.5}>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'><strong>Tout ce que propose le Pass Pro, plus :</strong></Typography>
                    </Box>
                  </Stack>
                  <Stack direction='row' spacing={2}>
                    <Box>
                      <Iconify icon='ic:baseline-check' sx={{ height: 24, width: 24, color: '#5E17EB' }} />
                    </Box>
                    <Box>
                      <Typography variant='body1'>Une consultation avec notre équipe en charge des événements pour optimiser les événements et la stratégie tarifaire de votre entreprise.</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Grid>

          </Grid>
        </Box>
        <Box marginTop={5} padding={2} sx={{ border: '1px solid gainsboro', borderRadius: '7px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h5'>Calculez vos gains potentiels</Typography>
              <Stack spacing={2} paddingTop={4}>
                <Stack direction='row' alignItems='center' spacing={1} divider={<Iconify icon='akar-icons:cross' />}
                >
                  <Box flex={1}>
                    <Typography variant='boyd2'>Prix de vente</Typography>
                    <TextField fullWidth size='medium' />
                  </Box>
                  <Box flex={1}>
                    <Typography variant='boyd2'>Quantité</Typography>
                    <TextField fullWidth size='medium' />
                  </Box>
                </Stack>

                <Stack
                  direction="row"
                  sx={{ backgroundColor: '#5E17EB', border: '2px solid transparent', borderRadius: '10px' }}
                  padding={0.5}
                  spacing={1}
                >
                  <Button
                    size="large"
                    fullWidth
                    sx={
                      type === 1
                        ? {
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          backgroundColor: 'white',
                          color: '#141414',
                          borderRadius: '7px',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: '#141414',
                            borderColor: '#141414',
                          },
                        }
                        : {
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          color: 'white',
                          backgroundColor: 'transparent',
                          borderRadius: '7px',
                        }
                    }
                    onClick={() => {
                      handlePassLiberte();
                    }}
                    variant="contained"
                  >
                    Pass Essentiel
                  </Button>
                  <Button
                    size="large"
                    fullWidth
                    sx={
                      type === 2
                        ? {
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          backgroundColor: 'white',
                          color: '#141414',
                          borderRadius: '7px',
                          '&:hover': {
                            backgroundColor: 'white',
                            color: '#141414',
                            borderColor: '#141414',
                          },
                        }
                        : {
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          color: 'white',
                          backgroundColor: 'transparent',
                          borderRadius: '7px',
                        }
                    }
                    onClick={() => {
                      handlePassExclu();
                    }}
                    variant="contained"
                  >
                    Pass Pro
                  </Button>
                </Stack>

              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2} justifyContent='center' display='flex' flexDirection='column'>
                <Typography variant='h6'>Estimation de votre paiement</Typography>
                <Typography variant='h3' sx={{ color: '#5E17EB' }}>Choptonticket est gratuit pour les evenements gratuits !</Typography>
                <Typography variant='h5'>L</Typography>
              </Stack>

            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default AppPricing;

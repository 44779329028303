import React, { createContext, useContext, useState, useEffect } from 'react';
import { setDoc, doc, collection, Timestamp, serverTimestamp } from 'firebase/firestore';
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail, confirmPasswordReset} from 'firebase/auth'
import { auth, db } from '../firebase.config';



const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    
    const [user, setUser] = useState({})
    const [event,setCurrentEvent] = useState({})
    

    function signUp(email, password){
        
        return createUserWithEmailAndPassword(auth, email, password)
    }

    

    function signUpSecondStep(id,mail, idorg, lastname, firstname){
        const newCityRef = doc(db, "organisateurs", id);
        setDoc(newCityRef, {
            dateCreation: serverTimestamp(),
            email: mail,
            idOrg: idorg,
            nom: lastname,
            prenom: firstname
        });
    }

    function logIn(email, password){
        return signInWithEmailAndPassword(auth, email, password)
    }

    

    function logOut(){
        return signOut(auth)
    }

    function addEvent(name, dateDebut, dateFin, location, idVisuel){

    }

    function resetpass(oobCode, newpass){
        return confirmPasswordReset(auth, oobCode, newpass)
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) =>{
            setUser(currentUser)
        })
        return () => {
            unsubscribe();
        }
    })

    

    return (
        <AuthContext.Provider value={{ signUp,logIn, logOut, addEvent, signUpSecondStep, resetpass, user,  }}>
            {children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => useContext(AuthContext);
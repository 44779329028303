import React from 'react';
import { Text, View, } from '@react-pdf/renderer';

const borderColor = '#5E17EB'
const styles = ({
    container: {
        flexDirection: 'row',
        backgroundColor: '#5E17EB',
        color: '#fff',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    num: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    name: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    prenom: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
});

const TableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.num}>N°</Text>
        <Text style={styles.name}>Nom</Text>
        <Text style={styles.prenom}>Prenom</Text>
        <Text style={styles.prenom}>N° Billet</Text>
        <Text style={styles.prenom}>Ticket</Text>
        <Text style={styles.amount}>Prix</Text>
    </View>
);

export default TableHeader;
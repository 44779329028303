/* eslint-disable radix */
import * as React from 'react';
import { Container } from '@mui/system';
import { styled } from '@mui/material/styles';
import {
  Alert,
  AlertTitle,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// import { DateTimePicker } from "@material-ui/pickers";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { Navigate, useNavigate, Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { addDoc, collection, doc, getDoc, increment, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useLoadScript } from '@react-google-maps/api';
import ReactQuill from 'react-quill';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { db, storage } from '../firebase.config';
import Iconify from './Iconify';
import { UserAuth } from '../context/AuthContext';
import CustomButton from './CustomButton';
import 'react-quill/dist/quill.snow.css';
// eslint-disable-next-line import/order
import { LoadingButton } from '@mui/lab';
import Maps from './map/Maps';

const randomstring = require("randomstring");
// accordion-------------------------------------------------
const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<Iconify icon={'fluent:add-28-filled'} sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#f2f2f2',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  borderRadius: '7px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
//--------------------------------------------------------

// Toogle Group Button
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0.5,
    width: 60,
    height: 30,
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
//-----------------------------------------------------------------------

//-----------------------------------------------------------------------

const steps = ['Informations génerales', 'Description', 'visuels'];

export default function HorizontalLinearStepper() {
  const { user } = UserAuth();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  // HANDLE TRAITEMENT
  const [name, setName] = React.useState();
  const [nameError, setNameError] = React.useState(false);
  const [debut, setDateDebut] = React.useState();
  const [debutError, setDebutError] = React.useState(false);
  const [fin, setDateFin] = React.useState();
  const [locationField, setLocation] = React.useState('');
  const [locationField2, setLocation2] = React.useState('');
  const [lieu, setLieu] = React.useState();
  const [sugClick, setSugClick] = React.useState(false);
  const [lieuError, setLieuError] = React.useState(false);
  const [infoLieu, setInfoLieu] = React.useState('');
  const [ville, setVille] = React.useState();
  const [villeError, setVilleError] = React.useState(false);
  const [pays, setpays] = React.useState();
  const [paysError, setPaysError] = React.useState(false);
  const [typeField, setTypeField] = React.useState();
  const [typeFieldError, setTypeFieldError] = React.useState(false);
  const [webLink, setWeb] = React.useState();
  const [fbLink, setFb] = React.useState();
  const [instaLink, setInsta] = React.useState();
  const [desc, setDesc] = React.useState();
  const [descError, setDescError] = React.useState(false);
  const [url, setUrl] = React.useState();
  const [event, setEvent] = React.useState();
  const [load, setLoad] = React.useState(false);
  const [seance, setActiveSeance] = React.useState(0);
  const [day, setDay] = React.useState();
  const [hour, setHour] = React.useState();
  const [hourError, setHourError] = React.useState(false);
  const today = new Date();
  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // google place auto
  const libraries = ['places'];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries,
  });
  const searchOptions = {
    componentRestrictions: { country: 'CIV' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['establishment'],
  };

  // upload functions

  const [selectedImage, setSelectedImage] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();
  const [progresspercent, setProgresspercent] = useState(0);

  const [reductionType, setReductionType] = React.useState(''); // reduction type

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  // description richtext
  const modules = {
    toolbar: [
      // [{ 'font': [] }],
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ['clean'],
    ],
  };

  const formats = [
    // 'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
    'background',
  ];

  const [value, setValue] = useState('');
  const handleChangeQuill = (content, delta, source, editor) => {
    console.log(editor.getHTML()); // html 사용시
    console.log(JSON.stringify(editor.getContents())); // delta 사용시
    setDesc(editor.getHTML());
  };

  // boutton  de passage a l'etape suivante
  const handleNext = () => {
    // test
    const dtmp = dayjs(day).format('DD/MM/YYYY');
    const d = dtmp.split(' ');
    const d0 = d[0].split('/');
    const d1 = `${d0[2]}-${d0[1]}-${d0[0]}T${hour}`;
    console.log(Timestamp.fromDate(new Date(d1)));
    console.log(hour)
    // initialisation de champs
    setNameError(false)
    setTypeFieldError(false)
    setLieuError(false)
    setVilleError(false)
    setPaysError(false)
    setDebutError(false)
    setHourError(false)
    // verification des informations sur l'evenement
    if (activeStep === 0) {
      let isValid = true
      if (!name || !typeField || !lieu) {
        if (!name) {
          setNameError(true);
        }
        if (!typeField) {
          setTypeFieldError(true);
        }
        if (!lieu) {
          setLieuError(true);
        }
        if (!ville) {
          setVilleError(true);
        }
        if (!pays) {
          setPaysError(true);
        }
        isValid = false
      }
      if (seance === 0) {
        if (!debut) {
          setDebutError(true);
          isValid = false
        }
      } else if (seance === 1) {
        if (!day || !hour) {
          isValid = false
          if (!hour) {
            setHourError(true)
          }
        }
      }
      // validation des information
      if (!isValid) {
        setMsg({ content: 'plusieurs champs requis ne sont pas renseignés ', color: 'red' });
        setOpenSnack(true);
        return
      }
    }
    if (activeStep === 1) {
      if (!desc) {
        setDescError(true);
        return;
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    // si on click sur terminer
    if (activeStep === 2) {
      console.log('handle');
      handleSubmit();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  // boutton de retour en arriere
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const navigate = useNavigate();
  const finish = () => {
    handleSubmit();
    navigate('/dashboard');
    // setActiveStep(0);
  };

  // place autocomplete
  const [address, setAddress] = useState('');

  const handleSelect = async (address, placeId, suggestion) => {
    setSugClick(true);
    console.log(suggestion.description);
    const tmp = suggestion.description.split(',');
    setLieu(tmp[0].trim());
    setVille(tmp[tmp.length - 2].trim());
    setpays(tmp[tmp.length - 1].trim());
    setAddress(suggestion.description);
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    setLocation2(suggestion.description);
    setLocation(ll);
  };

  const handleSugClick = () => {
    if (!sugClick) {
      setLieu('');
      setVille('');
      setpays('');
    }
  };

  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // gestion du radio boutton permettant de selectionner le type de reduction
  const handleRadioChange = (event) => {
    setReductionType(event.target.value);
    console.log(reductionType);
  };

  const handleSubmit = async (e) => {
    setLoad(true);
    try {
      // formattage de la date
      let resultDate
      let resultDateEnd
      if (seance === 1) {
        const dtmp = dayjs(day).format('YYYY-MM-DD');
        resultDate = new Date(`${dtmp}T${hour}`);
        resultDateEnd = new Date(resultDate);
        resultDateEnd.setDate(resultDateEnd.getDate() + 1);

        console.log("Date début:", resultDate);
        console.log("Date fin:", resultDateEnd);

        // eslint-disable-next-line no-restricted-globals
        if (isNaN(resultDate.getTime()) || isNaN(resultDateEnd.getTime())) {
          throw new Error("Invalid date format");
        }
      }


      // event id creation
      let eventuid = randomstring.generate(
        {
          length: 10,
          charset: "alphanumeric",
          capitalization: 'uppercase'
        }
      )
      let eventSnap = await getDoc(doc(db, 'evenement', `${eventuid}`));
      while (eventSnap.exists()) {
        eventuid = randomstring.generate(
          {
            length: 10,
            charset: "alphanumeric",
            capitalization: 'uppercase'
          }
        )
        // eslint-disable-next-line no-await-in-loop
        eventSnap = await getDoc(doc(db, 'evenement', `${eventuid}`));
      }

      if (selectedImage) {
        const storageRef = ref(storage, `/eventImages/${name}`);
        await uploadBytes(storageRef, selectedImage).then((snapshot) => {
          getDownloadURL(snapshot.ref).then(async (downloadURL) => {
            console.log(downloadURL);
            setUrl(downloadURL);

            const docRef0 = doc(db, 'organisateurs', user.uid);
            const docSnap = await getDoc(docRef0);

            if (docSnap.exists()) {
              await setDoc(doc(db, 'evenement', `${eventuid}`), {
                controleurSet: false,
                dateDebut: seance === 1 ? Timestamp.fromDate(resultDate) : Timestamp.fromDate(new Date(debut)),
                dateFin: seance === 1 ? Timestamp.fromDate(resultDateEnd) : Timestamp.fromDate(new Date(fin)),
                idVisuel: downloadURL,
                isImageSet: false,
                isTicketCreated: false,
                nomEvent: name,
                orgId: docSnap.data().idOrg,
                participant: 0,
                status: 0,
                seanceActive: seance === 1 ? 1 : 0,
                localisation: locationField,
                localisationDes: locationField2,
                city: ville,
                country: pays,
                lieu_event: lieu,
                info: infoLieu,
                createDate: Timestamp.fromDate(new Date()),
                type: typeField,
              });

              await addDoc(collection(db, `evenement/${eventuid}/description`), {
                value: desc,
              });

              await setDoc(doc(db, `evenement/${eventuid}/recette`, eventuid), {
                recette: 0,
                reduction: 0,
                codePromo: 0,
                frais: 0,
              });

              setEvent(eventuid);

              if (seance === 1) {
                await addDoc(collection(db, `evenement/${eventuid}/seances`), {
                  date: Timestamp.fromDate(resultDate),
                  status: 1,
                  lieu_event: '',
                  city: '',
                  country: '',
                  localisation: '',
                  participants: 0,
                });
              }
            }
          });
        });
      } else {
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);

        if (docSnap.exists()) {
          await setDoc(doc(db, 'evenement', `${eventuid}`), {
            controleurSet: false,
            dateDebut: seance === 1 ? Timestamp.fromDate(resultDate) : Timestamp.fromDate(new Date(debut)),
            dateFin: seance === 1 ? Timestamp.fromDate(resultDateEnd) : Timestamp.fromDate(new Date(fin)),
            idVisuel: '',
            isImageSet: false,
            isTicketCreated: false,
            nomEvent: name,
            orgId: docSnap.data().idOrg,
            participant: 0,
            status: 0,
            seanceActive: seance === 1 ? 1 : 0,
            localisation: locationField,
            localisationDes: locationField2,
            city: ville,
            country: pays,
            lieu_event: lieu,
            info: infoLieu,
            createDate: Timestamp.fromDate(new Date()),
            type: typeField,
          });

          await addDoc(collection(db, `evenement/${eventuid}/description`), {
            value: desc,
          });

          await setDoc(doc(db, `evenement/${eventuid}/recette`, eventuid), {
            recette: 0,
            reduction: 0,
            codePromo: 0,
            frais: 0,
          });

          setEvent(eventuid);

          if (seance === 1) {
            await addDoc(collection(db, `evenement/${eventuid}/seances`), {
              date: Timestamp.fromDate(resultDate),
              status: 1,
              lieu_event: '',
              city: '',
              country: '',
              localisation: '',
              participants: 0,
            });
          }
        }
      }
      setLoad(false);
    } catch (error) {
      console.log('Erreur:', error.message);
      setActiveStep(0);
      setLoad(false);
    }
  };

  // gestion activation seance
  const handleSeance = (e) => {
    try {
      setActiveSeance(parseInt(e.target.value));
    } catch (error) {
      console.log(error.message);
    }
  };
  // gestion activation seance
  const handleDate = (e) => {
    try {
      console.log('--------------------')
      console.log(e)
      const d = dayjs(e).format('DD/MM/YYYY');
      console.log(d)
      const tmp = `${d} ${hour}`;
      setDay(tmp)
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          {
            load === true ? <Box height={'200px'} width={'100%'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
             <CircularProgress/>
            </Box> :
              <>
                <Container sx={{ p: 10 }}>
                  <Stack display="flex" justifyContent="center" alignItems="center" spacing={1}>
                    <Iconify icon="flat-color-icons:ok" sx={{ width: 60, height: 60 }} />
                    <Typography textAlign="center" variant="h6">
                      Félicitation, vous venez créer votre événement.
                      <br />
                      Continuer vers votre page d'administration pour créer vos différents tickets et modifier votre événement
                      si necessaire
                    </Typography>
                  </Stack>
                </Container>

                <Box>
                  <Typography textAlign="start" variant="h6">
                    Services supplémentaires
                  </Typography>
                  <Stack
                direction="column"
                sx={{
                  backgroundColor: '#141414',
                  border: '2px solid transparent',
                  borderRadius: '10px',
                  color: 'white',
                }}
                padding={3}
                spacing={2}
              >
                <Stack
                  direction="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack>
                    <Typography>Bracelet evenementiel</Typography>
                    <Typography variant="caption">
                      (+0.5% sur chaque ticket et un devis vous sera communiqué)
                    </Typography>
                  </Stack>
                  <FormControlLabel control={<Switch  />} />
                </Stack>
                <Stack
                  direction="row"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack>
                    <Typography>Nom de domaine personnalité</Typography>
                    <Typography variant="caption">(+1% sur chaque ticket)</Typography>
                  </Stack>

                  <FormControlLabel control={<Switch  />} />
                </Stack>
              </Stack>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <LoadingButton
                    loading={load}
                    loadingPosition="end"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    component={RouterLink}
                    to={`/${event}/dashboard/app/`}
                  >
                    {load ? 'encours' : "Continuer vers le panel d'administration"}
                  </LoadingButton>
                </Box>
              </>
          }

        </>
      ) : (
        <>
          {activeStep === 0 ? (
            <Container sx={{ padding: 0 }}>
              <Stack paddingTop={3} spacing={3}>
                <Card>
                  <Box sx={{ p: 3 }} dir="ltr">
                    {!nameError ? (
                      <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Nom de l'événement"
                        required
                        fullWidth
                      />
                    ) : (
                      <TextField
                        error
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Nom de l'événement"
                        required
                        fullWidth
                        helperText="Le nom de l'événement est obligatoire"
                      />
                    )}
                  </Box>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Grid paddingY={1} alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography>Type d'évenement</Typography>
                      </Grid>

                      <Grid item xs={12} sm={8} md={8}>
                        {!typeFieldError ? (
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeField}
                            placeholder="Indiqué le type d'évenement"
                            onChange={(e) => setTypeField(e.target.value)}
                          >
                            <MenuItem value={'Concert'}>Concert</MenuItem>
                            <MenuItem value={'Festival'}>Festival</MenuItem>
                            <MenuItem value={'Atelier'}>Atelier</MenuItem>
                            <MenuItem value={'Compétition'}>Compétition</MenuItem>
                            <MenuItem value={'Conférence'}>Conférence</MenuItem>
                            <MenuItem value={'Cours'}>Cours</MenuItem>
                            <MenuItem value={'Défilé'}>Défilé</MenuItem>
                            <MenuItem value={'Dégustation'}>Dégustation</MenuItem>
                            <MenuItem value={'Exposition'}>Exposition</MenuItem>
                            <MenuItem value={'Formation'}>Formation</MenuItem>
                            <MenuItem value={'Forum'}>Forum</MenuItem>
                            <MenuItem value={'Randonnée'}>Randonnée</MenuItem>
                            <MenuItem value={'Soirée clubbing'}>Soirée clubbing</MenuItem>
                            <MenuItem value={'Spétacle'}>Spétacle</MenuItem>
                          </Select>
                        ) : (
                          <Select
                            error
                            helperText="Le nom de l'événement est obligatoire"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={typeField}
                            placeholder="Indiqué le type d'évenement"
                            onChange={(e) => setTypeField(e.target.value)}
                          >
                            <MenuItem value={'Concert'}>Concert</MenuItem>
                            <MenuItem value={'Festival'}>Festival</MenuItem>
                            <MenuItem value={'Atelier'}>Atelier</MenuItem>
                            <MenuItem value={'Compétition'}>Compétition</MenuItem>
                            <MenuItem value={'Conférence'}>Conférence</MenuItem>
                            <MenuItem value={'Cours'}>Cours</MenuItem>
                            <MenuItem value={'Défilé'}>Défilé</MenuItem>
                            <MenuItem value={'Dégustation'}>Dégustation</MenuItem>
                            <MenuItem value={'Exposition'}>Exposition</MenuItem>
                            <MenuItem value={'Formation'}>Formation</MenuItem>
                            <MenuItem value={'Forum'}>Forum</MenuItem>
                            <MenuItem value={'Randonnée'}>Randonnée</MenuItem>
                            <MenuItem value={'Soirée clubbing'}>Soirée clubbing</MenuItem>
                            <MenuItem value={'Spétacle'}>Spétacle</MenuItem>
                          </Select>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
                <Card>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Dates</Typography>
                    <Typography paddingBottom={2} variant="body2">Renseigner les dates de votre évènement</Typography>

                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={6} sm={4} md={4}>
                        <Typography> Evenement a mode seance</Typography>
                      </Grid>
                      <Grid item xs={6} sm={8} md={8}>
                        <Paper
                          elevation={0}
                          sx={{
                            display: 'flex',
                            width: 120,
                            marginY: 1,
                            border: (theme) => `1px solid #f2f2f2`,
                            borderRadius: '7px',
                            flexWrap: 'wrap',
                            bgcolor: '#f2f2f2',
                          }}
                        >
                          <StyledToggleButtonGroup
                            value={seance}
                            exclusive
                            onChange={(e) => handleSeance(e)}
                            aria-label="Platform"
                            sx={{ color: 'red' }}
                          >
                            <ToggleButton value={0}>Non</ToggleButton>
                            <ToggleButton value={1}>Oui</ToggleButton>
                          </StyledToggleButtonGroup>
                        </Paper>
                      </Grid>
                    </Grid>
                    {/** message d'information sur le mode seance */}
                    {seance === 1 && (
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={10} md={10}>
                          <Alert severity="info" sx={{ padding: 1 }}>
                            <AlertTitle>Mode seance</AlertTitle>
                            <Typography variant='body2'>le mode seance vous permets</Typography>
                          </Alert>
                        </Grid>
                      </Grid>
                    )}
                    {seance === 0 ? (
                      <Grid container padding={3} alignItems="center" sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                        <Grid item xs={12} sm={4.5} md={4.5}>
                          <Stack spacing={1}>
                            <Typography>Date de Début</Typography>
                            <TextField
                              id="datetime-local"
                              type="datetime-local"
                              value={debut}
                              onChange={(e) => setDateDebut(e.target.value)}
                              fullWidth
                              error={!!debutError}
                              helperText={
                                debutError
                                  ? 'La date de debut de l evenement est importante. veuillez remplir ce champs'
                                  : null
                              }
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={1}
                          md={1}
                          sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                        >
                          <Iconify icon="oi:arrow-right" />
                        </Grid>
                        <Grid item xs={12} sm={4.5} md={4.5}>
                          <Stack spacing={1}>
                            <Typography>Date de Fin</Typography>
                            <TextField
                              id="datetime-local"
                              type="datetime-local"
                              value={fin}
                              fullWidth
                              onChange={(e) => setDateFin(e.target.value)}
                              // error={!!finError}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            // inputRef={endDateRef}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    ) :
                      <Stack sx={{ border: '1px solid #5E17EB', borderRadius: '7px', marginBottom: 2 }}>
                        <Box paddingX={2} paddingY={1}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="body2">
                              <strong>Premiere séance</strong>
                            </Typography>
                          </Stack>
                        </Box>
                        <Divider />
                        <Box margin={2} padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Stack>
                                <Typography variant="body2">Date de début</Typography>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                  <DesktopDatePicker
                                    inputFormat="DD/MM/YYYY"
                                    minDate={today}
                                    value={day}
                                    onChange={(e) => setDay(e)}
                                    renderInput={(params) => <TextField {...params} />}
                                  />
                                </LocalizationProvider>
                              </Stack>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                              <Stack>
                                <Typography variant="body2">Heure de début</Typography>
                                <TextField
                                  type="time"
                                  value={hour}
                                  error={!!hourError}
                                  onChange={(e) => setHour(e.target.value)}
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    }
                  </Box>
                </Card>

                <Card>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h5">Localisation</Typography>
                    <Typography variant="body2">Préciser la localisation de votre évènement</Typography>

                    <Grid paddingY={3} alignItems="center" container spacing={3}>
                      <Grid item xs={12}>
                        {isLoaded && (
                          <PlacesAutocomplete
                            value={locationField2}
                            onChange={setLocation2}
                            onSelect={handleSelect}
                            searchOptions={searchOptions}
                            googleCallbackName=""
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                {!lieuError ? (
                                  <TextField
                                    {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                                    fullWidth
                                    onFocus={(e) => { e.target.select() }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Iconify icon="iconoir:search" />
                                        </InputAdornment>
                                      ),

                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                ) : (
                                  <TextField
                                    {...getInputProps({ placeholder: "Entrer le lieu de l'évenement" })}
                                    error
                                    helperText="Le lieu de l'évnement est importante. veuillez remplir ce champs"
                                    fullWidth
                                    onFocus={(e) => { e.target.select() }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Iconify icon="iconoir:search" />
                                        </InputAdornment>
                                      ),

                                    }}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                )}
                                <Card>
                                  {loading && (
                                    <Typography variant="body1" paddingX={1}>
                                      Recherche...
                                    </Typography>
                                  )}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: 2 }
                                      : { backgroundColor: '#ffffff', cursor: 'pointer', padding: 2 };
                                    return (
                                      <Box
                                        key={suggestion.placeId}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <Typography variant="body1" paddingX={1}>
                                          {suggestion.description}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </Card>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        )}
                      </Grid>
                    </Grid>
                    <Typography variant="body2">{locationField2}</Typography>
                    <Box sx={{ height: 180, borderRadius: '7px' }}>
                      <Maps loading={isLoaded} centerC={locationField} height={180} />
                    </Box>
                  </Box>
                </Card>
                <Card>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Réseau sociaux / page web de l'evenement</Typography>
                    <Grid paddingY={1} alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          value={webLink}
                          label="Site web"
                          type="text"
                          fullWidth
                          onChange={(e) => setWeb(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={'mdi:web'} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid paddingY={1} alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          value={fbLink}
                          label="Facebook"
                          type="text"
                          fullWidth
                          onChange={(e) => setFb(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={'fa-brands:facebook'} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid paddingY={1} alignItems="center" container spacing={3}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          value={instaLink}
                          label="Instagram"
                          type="text"
                          fullWidth
                          onChange={(e) => setInsta(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Iconify icon={'mdi:instagram'} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Card>
              </Stack>
            </Container>
          ) : null}

          {activeStep === 1 ? (
            <Container sx={{ padding: 0 }}>
              <Stack paddingTop={3} spacing={3}>
                <Card>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Description</Typography>
                    <Typography variant="body2">
                      Localisation de l’évènement. Ce champ est optionnel et peut-être édité plus tard
                    </Typography>

                    {/* descError ? (
                      <TextField
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        id="outlined-textarea"
                        placeholder="Entrer un description"
                        rows={10}
                        fullWidth
                        multiline
                        helperText="Cette description apparaitra sur la page de l’évènement"
                      />
                    ) : (
                      <TextField
                        error
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        id="outlined-textarea"
                        placeholder="Entrer un description"
                        rows={10}
                        fullWidth
                        multiline
                        helperText="Veuillez saisir une description pour votre evenement"
                      />
                    ) */}
                    <Grid paddingY={3} container>
                      <Grid item xs={12}>
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          formats={formats}
                          value={desc}
                          onChange={handleChangeQuill}
                          placeholder="Saisissez une description pour votre evenement ici..."
                        />
                      </Grid>
                      {/* <div dangerouslySetInnerHTML={{ __html: value }}></div> */}
                    </Grid>
                  </Box>
                </Card>
              </Stack>
            </Container>
          ) : null}
          {activeStep === 2 ? (
            <Container>
              <Stack paddingTop={3} spacing={3}>
                <Card>
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography variant="h6">Choisir une image</Typography>
                    <Box sx={{ p: 3 }} dir="ltr">
                      <Grid paddingY={2} alignItems="center" container spacing={3}>
                        {!imageUrl && (
                          <Grid item xs={12}>
                            <Button
                              fullWidth
                              disableElevation
                              size="medium"
                              sx={{
                                textTransform: 'initial',
                                border: '1px solid gainsboro',
                                color: 'black',
                                backgroundColor: 'white',

                                '&:hover': {
                                  backgroundColor: 'white',
                                  color: 'black',
                                  borderColor: 'gainsboro',
                                },
                              }}
                              variant="contained"
                              component="label"
                            >
                              <input
                                hidden
                                accept="image/*"
                                id="select-image"
                                type="file"
                                onChange={(e) => setSelectedImage(e.target.files[0])}
                              />
                              <Stack
                                sx={{
                                  justifyContent: 'space-around',
                                  alignItems: 'center',
                                  color: 'gainsboro',
                                  padding: 2,
                                }}
                              >
                                <Iconify
                                  icon="mdi:image-add-outline"
                                  sx={{ width: 100, height: 100, color: 'gainsboro' }}
                                />
                                <Typography>Choisir une image</Typography>
                              </Stack>
                            </Button>
                          </Grid>
                        )}

                        {imageUrl && (
                          <Grid item xs={12}>
                            <Grid
                              container
                              justifyContent="space-around"
                              fullWidth
                              alignItems="center"
                              sx={{ border: '1px solid gainsboro' }}
                              padding={2}
                            >
                              <Grid item xs={12} sm={8} md={6}>
                                <img src={imageUrl} alt={imageUrl} style={{ maxHeight: 500 }} />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Grid textAlign="end" item xs={12}>
                          <Button
                            size="medium"
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              Color: 'white',
                              backgroundColor: 'black',
                              borderRadius: '7px',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: 'black',
                                borderColor: 'black',
                              },
                            }}
                            startIcon={<Iconify icon="material-symbols:download" />}
                            variant="contained"
                            component="label"
                          >
                            <input
                              hidden
                              accept="image/*"
                              id="select-image"
                              type="file"
                              onChange={(e) => setSelectedImage(e.target.files[0])}
                            />
                            Parcourir
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Card>
              </Stack>
            </Container>
          ) : null}

          <Box sx={{ display: 'flex', flexDirection: 'row', p: 3 }}>
            <CustomButton
              disabled={activeStep === 0}
              backgroundColor="#141414"
              color="#fff"
              buttonText="Retour"
              onClick={handleBack}
            />
            <Box sx={{ flex: '1 1 auto' }} />
            <CustomButton
              backgroundColor="#5E17EB"
              color="#fff"
              buttonText={activeStep === steps.length - 1 ? 'Créé mon évenement' : 'Suivant'}
              onClick={handleNext}
            />
          </Box>
        </>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message={
          <Stack spacing={1} direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='caption'>{msg.content}{' '}</Typography>
            <Iconify
              sx={{ color: msg.color }}
              icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
            />
          </Stack>
        }
      />
    </Box>
  );
}

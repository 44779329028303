import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation, useParams, useNavigate } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  Card,
  Grid,
  Typography,
  ListItem,
  ListItemAvatar,
  Link,
  Alert,
  AlertTitle,
  Stack,
  Snackbar,
  Button,
} from '@mui/material';
//
import { doc, getDoc, getCountServer, query, collection, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase.config';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function CheckCard() {
  const { pathname } = useLocation();
  const { eventID } = useParams();
  const [event, setEvent] = useState();
  const [org, setOrg] = useState();
  const [ticketCount, setTicketCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [outdated, setOutdated] = useState(false);

  //  snack bar
  const [openSnack, setOpenSnack] = useState(true);
  const [msg, setMsg] = useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[0];
  };

  // recuperation des données
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const today = new Date();
        const today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate());

        const docRef0 = doc(db, 'evenement', `${eventID}`);

        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
          console.log('outdated---------------------------');
          console.log(docSnap.data().dateFin);
          console.log(today1);
          if (new Date(handleTimestamp(docSnap.data().dateFin)) < today1) {
            console.log('outdated---------------------------');
            setOutdated(true);
          }
          console.log('outdated---------------------------');
          const docSnap1 = await getDoc(doc(db, 'organisations', `${docSnap.data().orgId}`));
          setOrg(docSnap1.data());
          console.log(docSnap.data());
          // count
          const snapshot = await getCountFromServer(query(collection(db, `evenement/${eventID}/tickets`)));
          setTicketCount(snapshot.data().count);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [eventID]);

  return (
    <>
      {event && (
        <>
          {!loading && (org.certified === false || event.idVisuel === '' || ticketCount === 0) ? (
            <Box severity="info" sx={{ marginY: 2, padding: 2, borderRadius: '7px', color: 'white', bgcolor: '#141414' }}>
              <Grid item xs={12}>
                <Typography variant='body2'><strong>Compléter ces etapes avant la publication de votre évènement</strong></Typography>
                <List>
                  {event.idVisuel === '' ? (
                    <Box sx={{ borderRadius: '7px', border: '0.5px solid #f2f2f2', paddingY: 2 }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Iconify icon="gis:point" sx={{ width: 30, height: 30, ml: 0.5 }} />
                        </ListItemAvatar>
                        <ListItemText
                          onClick={() => {
                            navigate(`/${eventID}/dashboard/information`);
                          }}
                          sx={{ cursor: 'pointer' }}
                          secondary={`Ajouter une photo a votre evenement`}
                        />
                      </ListItem>
                    </Box>

                  ) : null}
                  {ticketCount === 0 ? (
                    <Box sx={{ borderRadius: '7px', border: '0.5px solid #f2f2f2', paddingY: 1, paddingX:2 }}>
                      
                        <Stack justifyContent='space-between' direction='row'>
                          <Stack direction="row" alignItems='center' spacing={1}>
                            <Iconify icon="gis:point" sx={{ width: 25, height: 25 }} />
                            <Typography variant="body2">Créer des billets à proposer aux participants</Typography>
                          </Stack>
                          <Button
                            onClick={() => {
                              navigate(`/${eventID}/dashboard/billets`);
                            }}
                            variant="text"
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              color: '#5E17EB',
                              backgroundColor: 'transparent',
                              borderRadius: '7px',
                              '&:hover': {
                                backgroundColor: 'transparent',
                                color: '#5E17EB',
                                borderColor: 'transparent',
                              },
                            }}
                          >
                            cliquer ici
                          </Button>
                        </Stack>

                      
                    </Box>

                  ) : null}
                  {org.certified === false ? (
                    <ListItem>
                      <Link
                        href={`/organisation/app`}
                        underline="hover"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: 'white',
                          '&:hover': {
                            color: '#5E17EB',
                          },
                        }}
                      >
                        <Stack direction="row" alignItems='center' spacing={1}>
                          <Iconify icon="gis:point" sx={{ width: 25, height: 25 }} />
                          <Typography variant="body2">Terminer la configuration de votre organisation</Typography>
                        </Stack>
                      </Link>
                    </ListItem>
                  ) : null}
                </List>
              </Grid>
            </Box>
          ) : null}
          {!loading && outdated ? (
            <Box sx={{ marginY: 2, padding: 2, borderRadius: '7px', color: 'white', bgcolor: '#141414' }}>

            <Stack justifyContent='space-between' direction='row'>
              <Stack direction="row" alignItems='center' spacing={1}>
                <Typography variant="body2">Cet évenement est terminé, vous pouvez maintenant le clos</Typography>
              </Stack>
              <Button
                onClick={() => {
                  navigate(`/${eventID}/dashboard/billets`);
                }}
                variant="text"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: '#5E17EB',
                  backgroundColor: 'transparent',
                  borderRadius: '7px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#5E17EB',
                    borderColor: 'transparent',
                  },
                }}
              >
                cliquer ici
              </Button>
            </Stack>


          </Box>
          ) : null}
        </>
      )}
    </>
  );
}

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

const styles = {
  titleContainer: {
    marginTop: 2,
    flexDirection: 'row',
  },
  reportTitle: {
    width: '65%',
    color: 'black',
    letterSpacing: 1,
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  reportTitleR: {
    width: '35%',
    color: 'black',
    letterSpacing: 1,
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'right',
    textTransform: 'uppercase',
  },
};

const Title = ({ title, participants }) => (
  <>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>{title}</Text>

      <Text style={styles.reportTitleR}>export date {format(Date.now(), 'dd-MM-yyyy')}</Text>
    </View>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>Mega concert araft</Text>

      <Text style={styles.reportTitleR}>Nombre de participants : {participants}</Text>
    </View>
  </>
);

export default Title;

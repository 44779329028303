import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation, useParams, useNavigate } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Divider,
  Card,
  Grid,
  Typography,
  ListItem,
  ListItemAvatar,
  Link,
  Alert,
  AlertTitle,
} from '@mui/material';
//
import { doc, getDoc, getCountServer, query, collection, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase.config';
import Iconify from './Iconify';
import { UserAuth } from '../context/AuthContext';

// ----------------------------------------------------------------------

export default function CheckOrg() {
  const { pathname } = useLocation();
  const { user } = UserAuth();
  const { eventID } = useParams();
  const [event, setEvent] = useState();
  const [org, setOrg] = useState();
  const [ticketCount, setTicketCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // recuperation des données
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', `${user.uid}`);

        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
          const docSnap1 = await getDoc(doc(db, 'organisations', `${docSnap.data().idOrg}`));
          setOrg(docSnap1.data());
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, [user]);

  return (
    <>
      {org && (
        <>
          {!loading && (org.certified === false || org.infoPaie === 0) ? (
            <>
              {org.certified === false ? (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                  <AlertTitle>Informations organisateur</AlertTitle>
                  Veuillez compléter dès à present vos informations organisateurs pour recevoir vos recette —{' '}
                  <Typography variant='caption'
                    onClick={() => {
                      navigate(`/organisation/app`, { replace: true });
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <strong>Cliquez ici</strong>
                  </Typography>
                </Alert>
              ) : null}
              {org.infoPaie === 0 ? (
                <Alert severity="error" sx={{ marginBottom: 2 }}>
                  <AlertTitle>Informations de paiement</AlertTitle>
                  Veuillez compléter dès à present vos informations de paiement pour recevoir vos recette —{' '}
                  <Typography variant='caption'
                    onClick={() => {
                      navigate(`/organisation/coordonnee_reversement`, { replace: true });
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <strong>Cliquez ici</strong>
                  </Typography>
                </Alert>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </>
  );
}

import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#5E17EB'
const styles = ({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#5E17EB',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    num: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    name: {
        width: '20%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    prenom: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%'
    },
});

 const TableRow = ({ items1, items2 }) => {
    
    console.log(items1[1])
    
    const rows = items1.map((item, index) =>
        <View style={styles.row} key={index}>
            <Text style={styles.num}>{index+1}</Text>
            <Text style={styles.name}>{items2[index] === '' ? 'Inconnu' : items2[index]}</Text> 
            <Text style={styles.prenom}>{items1.length} </Text> 
            <Text style={styles.prenom}>{item.data().numero}</Text> 
            <Text style={styles.prenom}>{item.data().nom}</Text> 
            <Text style={styles.amount}>{item.data().price} CFA</Text> 
        </View>
    ); 
    return (<>{ items1.length === items2.length && rows}</>)
};

export default TableRow;
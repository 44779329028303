import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import ReactPlayer from 'react-player';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box, Tabs, Tab, Grid, Stack, Button } from '@mui/material';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections

import BasicTabs from '../components/home/BasicTabs';
import HomeNavbar from '../layouts/home/HomeNavbar';
import CustomButton from '../components/CustomButton';
import CheckOrg from '../components/CheckOrg';

// ---------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: `calc(100%*0.7)`,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Home() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const [open, setOpen] = useState(false);

  return (
    <Page title="Back office">
      <RootStyle>
        <HomeNavbar onOpenSidebar={() => setOpen(true)} />
        <Container maxWidth="xl">
          {!smUp && (
            <>
              <Grid container sx={{ paddingTop: 10, paddingBottom: 2 }} spacing={3}>
                <Grid item xs={12}>
                  <CheckOrg />
                  <Typography variant="h5">Mes évenements</Typography>
                </Grid>
                <Grid textAlign="center" item xs={8}>
                  <CustomButton
                    backgroundColor="#5E17EB"
                    color="#fff"
                    buttonText="Créer un événement"
                    component={RouterLink}
                    to="/bo/new_event"
                  />
                </Grid>
              </Grid>

              <Box sx={{ pt: 1, pb: 1 }}>
                <Box sx={{ pt: 1, pb: 1 }} dir="ltr">
                  <BasicTabs />
                </Box>
              </Box>
            </>
          )}
          {mdUp && (
            <ContentStyle>
              <CheckOrg />
              <Grid alignContent="center" container sx={{ paddingTop: 2, paddingBottom: 2 }} spacing={3}>
                <Grid item xs={8}>
                  <Typography variant="h5">Mes évenements</Typography>
                </Grid>
                <Grid textAlign="center" item xs={4}>
                  <CustomButton
                    backgroundColor="#5E17EB"
                    color="#fff"
                    buttonText="Créer un événement"
                    component={RouterLink}
                    to="/bo/new_event"
                  />
                </Grid>
              </Grid>
              
                <Box sx={{ p: 1, pb: 1 }} dir="ltr">
                  <BasicTabs />
                </Box>
              
            </ContentStyle>
          )}
        </Container>
      </RootStyle>
    </Page>
  );
}

/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  LinearProgress,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  FormGroup,
  Snackbar,
  Skeleton,
  FormLabel,
  Link,
} from '@mui/material';
// components
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';

import { db } from '../../../firebase.config';
import Page from '../../../components/Page';
import { AppWidgetSummary } from '../../../sections/@dashboard/app';
import Iconify from '../../../components/Iconify';
import { UserAuth } from '../../../context/AuthContext';
import AppWidgetSMV from '../../../sections/@dashboard/app/AppWidgetSMV';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'background.paper',
  border: '1px solid white',
  borderRadius: '7px',
  p: 3,
};

const randomstring = require('randomstring');

export default function CanalVente() {
  const { eventID } = useParams();
  const [org, setorg] = React.useState();
  const { user } = UserAuth();
  // loading
  const [loading, setLoading] = React.useState(false);

  // modification de code
  const [fb, setFb] = React.useState(); // valeur de la reduction
  const [insta, setInsta] = React.useState(); // valeur de la reduction
  const [tt, setTt] = React.useState(); // valeur de la reduction
  

  // for change value of globla quota
  const [modif, setModif] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setModif(false);
    setRefresh((prev) => !prev);
  };

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // setup des codes promo
  const [discounts, setDiscount] = useState([]);
  const [curent, setCurent] = useState();
  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);

        console.log(docSnap.data().idOrg);
        setorg(docSnap.data().idOrg);
        const snapshotfb = await getDoc(doc(db, `organisations/${docSnap.data().idOrg}/sosm/${docSnap.data().idOrg}/${eventID}`, `facebook`));
        if (snapshotfb.exists()) {
            setFb(snapshotfb.data())
        }
        const snapshotinsta = await getDoc(doc(db, `organisations/${docSnap.data().idOrg}/sosm/${docSnap.data().idOrg}/${eventID}`, `instagram`));
        if (snapshotinsta.exists()) {
            setInsta(snapshotinsta.data())
        }
        const snapshottt = await getDoc(doc(db, `organisations/${docSnap.data().idOrg}/sosm/${docSnap.data().idOrg}/${eventID}`, `tiktok`));
        if (snapshottt.exists()) {
            setTt(snapshottt.data())
        }
        
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refreh, user]);

  const handleToggle = () => {
    setOpen(!open);
  };
  // supression d'un billet
  const handleSupp = async (index) => {
    handleToggle();
    console.log('supp');
    const discount = discounts[index];

    try {
      await deleteDoc(doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`));
      setMsg({ content: 'Code promotionel supprimé avec success', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      console.log(error.message);
      setMsg({ content: 'Une erreur est survenue', color: 'red' });
      setOpenSnack(true);
      handleClose();
    }

    handleClose();
  };

  // creer nos lien
 

  const SellOnFb = async () => {
    try {
      const snapshot = await getDoc(doc(db, `organisations/${org}/sosm/${org}/${eventID}`, `facebook`));
      if (!snapshot.exists()) {
        const generatedCode =
          randomstring.generate({ length: 10, charset: 'numeric' }) +
          randomstring.generate({ length: 10, charset: 'alphabetic' }) +
          randomstring.generate({ length: 10, charset: 'numeric' }) +
          randomstring.generate({ length: 10, charset: 'alphabetic' });
        await setDoc(doc(db, `organisations/${org}/sosm/${org}/${eventID}`, `facebook`), {
          code: generatedCode,
          click: parseInt(0),
          sell: parseInt(0),
        });
        handleCloseCode();
      }
      else{
        console.log('exist deja')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SellOnInsta = async () => {
    try {
      const snapshot = await getDoc(doc(db, `organisations/${org}/sosm/${org}/${eventID}`, `instagram`));
      if (!snapshot.exists()) {
        const generatedCode =
          randomstring.generate({ length: 10, charset: 'numeric' }) +
          randomstring.generate({ length: 10, charset: 'alphabetic' }) +
          randomstring.generate({ length: 10, charset: 'numeric' }) +
          randomstring.generate({ length: 10, charset: 'alphabetic' });
        await setDoc(doc(db, `organisations/${org}/sosm/${org}/${eventID}`, `instagram`), {
            code: generatedCode,
            click: parseInt(0),
            sell: parseInt(0),
        });
        handleCloseCode();
      }
      else{
        console.log('exist deja')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SellOnTT = async () => {
    try {
      const snapshot = await getDoc(doc(db, `organisations/${org}/sosm/${org}/${eventID}`, `tiktok`));
      if (!snapshot.exists()) {
        const generatedCode =
          randomstring.generate({ length: 10, charset: 'numeric' }) +
          randomstring.generate({ length: 10, charset: 'alphabetic' }) +
          randomstring.generate({ length: 10, charset: 'numeric' }) +
          randomstring.generate({ length: 10, charset: 'alphabetic' });
        await setDoc(doc(db, `organisations/${org}/sosm/${org}/${eventID}`, `tiktok`), {
            code: generatedCode,
            click: parseInt(0),
            sell: parseInt(0),
        });
        handleCloseCode();
      }
      else{
        console.log('exist deja')
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Modal des choix du RS
  const [openCode, setOpenCode] = React.useState(false);
  const handleOpenCode = () => setOpenCode(true);
  const handleCloseCode = () => setOpenCode(false);

  return (
    <Page title="Vente sur les resaux sociaux">
      <Container  sx={{ marginTop: { xs: 3 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ paddingBottom: 2 }}>
              <Typography variant="h4">Vente sur les Reseaux sociaux</Typography>
              <Typography variant="body2">
                Ce module vous permet de mieux repartir votre budget marketing entre les differents reseaux sociaux en
                vous montrant le nombre de vente initier depuis chaque reseau social.
              </Typography>
              <Typography variant="body2">
                Comment ca marche!! ex de facebook: generer un lien de vente facebook, copier et publier le lien sur vos
                publication facebook et c'est tout
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{
                textTransform: 'initial',
                border: '2px solid transparent',
                Color: 'white',
                backgroundColor: '#5E17EB',
                borderRadius: '7px',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#5E17EB',
                  borderColor: '#5E17EB',
                },
              }}
              variant="contained"
              onClick={handleOpenCode}
            >
              Generer un lien de vente
            </Button>
            {/* modal saisir de code */}
            <Modal
              open={openCode}
              onClose={handleCloseCode}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ borderRadius: '7px' }}
            >
              <Box sx={style}>
                <Typography variant="h6">Choisir le reseau social</Typography>
                <Box sx={{ marginTop: 5, p: 2 }} dir="ltr">
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={6}>
                      <Card
                        onClick={SellOnFb}
                        sx={{
                          height: 60,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: '#f2f2f2',
                        }}
                      >
                        <Iconify icon="fa-brands:facebook" />
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card
                      onClick={SellOnInsta}
                        sx={{
                          height: 60,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: '#f2f2f2',
                        }}
                      >
                        <Iconify icon="mdi:instagram" />
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card
                      onClick={SellOnTT}
                        sx={{
                          height: 60,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          bgcolor: '#f2f2f2',
                        }}
                      >
                        <Iconify icon="ic:baseline-tiktok" />
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Modal>
          </Grid>
         { fb && <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <Skeleton sx={{ height: '100%'}} variant="rounded" />
            ) : !loading && fb ? (
              <AppWidgetSMV
                onClick={() => {
                  // navigate(`/${eventID}/dashboard/statistique`, { replace: true });
                }}
                sx={{ cursor: 'pointer' }}
                sell={fb.sell}
                click={fb.click}
                icon={'fa-brands:facebook'}
              />
            ) : null}
          </Grid> }

          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <Skeleton sx={{ height: '100%' }} variant="rounded" />
            ) : insta ? (
                <AppWidgetSMV
                onClick={() => {
                  // navigate(`/${eventID}/dashboard/statistique`, { replace: true });
                }}
                sx={{ cursor: 'pointer' }}
                sell={insta.sell}
                click={insta.click}
                color="info"
                icon={'mdi:instagram'}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {loading ? (
              <Skeleton sx={{ height: '100%' }} variant="rounded" />
            ) : (
                <AppWidgetSMV
                onClick={() => {
                  // navigate(`/${eventID}/dashboard/statistique`, { replace: true });
                }}
                sx={{ cursor: 'pointer' }}
                sell={0}
                click={0}
                color="warning"
                icon={'ic:baseline-tiktok'}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

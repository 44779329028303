/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-else-return */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Skeleton,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';

// mock
import USERLIST from '../../_mock/user';

import { db } from '../../firebase.config';
import CheckCard from '../../components/CheckCard';
import { UserAuth } from '../../context/AuthContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Operateurs() {
  const { eventID } = useParams();
  const { user } = UserAuth();
  const theme = useTheme();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (e) => {
    console.log('cell click ', e.target.id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Tarif Nomarl', '-', 'Infini', 5000, 4.0),
    createData('Tarif VIP', '-', 9.0, 10000, 4.3),
    createData('Tarif VVIP', '-', 16.0, 20000, 6.0),
  ];

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [refreh, setRefresh] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  // take operateurs from firebase
  const [ops, setOp] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setOp([]);
      setPassview([]);
      try {
        setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/operateurs`));
        const snapshot = await getDocs(q);
        snapshot.forEach(async (d) => {
          setOp((ops) => [...ops, d]);
          setPassview((passview) => [...passview, false]);
        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // GESTION DE LA MODIFICATION
  const [temp, setTemp] = React.useState([]);
  const [name, setName] = React.useState();
  const [id, setId] = React.useState();
  const [pass, setPass] = React.useState();

  // *****ticket
  const [ticketVerif, setTicket] = React.useState([]);
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  // *****recuperation de billets

  const [tickets, ticketList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        // setLoading(true);

        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          ticketList((tickets) => [...tickets, d.data()]);
        });

        // handle email

        // setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, []);

  const [personName, setPersonName] = React.useState([]);
  const handleChangeTicket = (event) => {
    const {
      target: { value },
    } = event;
    setTicket(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    console.log(ticketVerif);
  };

  const [modif, setModif] = React.useState(false);
  const handleModif = (index) => {
    if (modif) {
      setModif(false);
    } else {
      const temp = ops[index].data();
      console.log(index);
      console.log(temp.tickets);
      setName(temp.nameOp);
      setId(temp.idOp);
      setPass(temp.passOp);
      setTicket(temp.tickets);
      setModif(true);
    }
  };

  const handleSupp = async (index) => {
    handleToggle();
    console.log('supp');
    const op = ops[index];

    try {
      await deleteDoc(doc(db, `evenement/${eventID}/operateurs`, `${op.id}`));
    } catch (error) {
      console.log(error.message);
      handleClose();
    }
    handleClose();
  };

  const handleSubmit = async (e) => {};
  const [passView, setPassview] = React.useState([]);
  const showpass = (e) => {
    const nextCounters = passView.map((c, i) => {
      if (i === e) {
        return !passView[e];
      } else {
        return c;
      }
    });
    setPassview(nextCounters);
  };

  return (
    <Page title="Opérateurs">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <CheckCard />
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Opérateurs Scan<br />
            <Typography variant="body2">
              Les profils d'opérateurs vous permettent de vous identifier sur nos appareils de contrôle d'accès,
              <br /> puis de scanner les participants à votre événement.
            </Typography>
          </Typography>
          <Button
            sx={{
              textTransform: 'initial',
              border: '2px solid transparent',
              color: 'white',
              backgroundColor: '#5E17EB',
              borderRadius: '7px',
              '&:hover': {
                backgroundColor: 'white',
                color: '#5E17EB',
                borderColor: '#5E17EB',
              },
            }}
            variant="contained"
            component={RouterLink}
            to="ajout"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Nouvel opérateur
          </Button>
        </Stack>
        {!modif ? (
          /* <Card>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Opérateur</TableCell>
                      <TableCell align="center">Id</TableCell>
                      <TableCell align="center">Mot de passe</TableCell>
                      <TableCell align="center">liste de contrôle</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <caption>
                      <Skeleton variant="rounded" width="100%" />
                    </caption>
                  ) : ops.length === 0 && !loading ? (
                    <caption>
                      <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                        <Typography variant="caption">Aucune donnée</Typography>
                      </Stack>
                    </caption>
                  ) : (
                    <TableBody>
                      {ops.map((op, index) => (
                        <TableRow key={index} hover>
                          <TableCell
                            id={op.data().name}
                            onClick={(e) => handleModif(index)}
                            key={op.data().name}
                            align="center"
                            component="th"
                            scope="row"
                          >
                            {op.data().nameOp}
                          </TableCell>
                          <TableCell id={op.data().name} onClick={(e) => handleModif(index)} align="center">
                            {op.data().idOp}
                          </TableCell>
                          <TableCell id={op.data().name} onClick={(e) => showpass(index)} align="center">
                            <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                              {passView[index] ? op.data().passOp : '******'}
                              {passView[index] ? (
                                <IconButton aria-label="delete" size="small" onClick={(e) => showpass(index)}>
                                  <Iconify icon="carbon:view-off" />
                                </IconButton>
                              ) : (
                                <IconButton aria-label="delete" size="small" onClick={(e) => showpass(index)}>
                                  <Iconify icon="fluent-mdl2:view" />
                                </IconButton>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell id={op.data().name} onClick={(e) => handleModif(index)} align="center">
                            {op.data().ticket}
                          </TableCell>
                          <TableCell id={op.data().name} align="center">
                            <Tooltip title="Modifer le code">
                              <IconButton onClick={(e) => handleModif(index)}>
                                <Iconify icon="material-symbols:settings" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Supprimer le code">
                              <IconButton onClick={() => handleSupp(index)}>
                                <Iconify sx={{ color: '#FB8888' }} icon="material-symbols:delete-outline" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card> */
          <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                height: '100%',
                borderRadius: '7px',
                padding: 3,
                border: '2px solid transparent',
                cursor: 'pointer',
                bgcolor: '#f2f2f2',
              }}
            >
              <Typography textAlign="Start">
                <strong>Identifiants scan</strong>
              </Typography>
              <Typography variant='body2' textAlign="Start">
                Pour utiliser l'app de scan, vous pouvez vous connecter avec vos information de connexion. Mais vous pouvez aussi créer des identitifiant additionnel pour le 
              </Typography>
              <Stack spacing={2} paddingTop={2}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    <strong>identitifiant :</strong>
                  </Typography>
                  <Typography variant="body2">
                    beschristyann@gmail.com
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    <strong>Mot de passe :</strong>
                  </Typography>
                  <Typography variant="body2">
                    *******
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        ) : (
          /* Modification */
          <form onSubmit={handleSubmit}>
            <Card>
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container paddingBottom={2} spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Button
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'black',
                        backgroundColor: 'white',
                        borderRadius: '7px',
                        borderColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                          color: 'white',
                          borderColor: 'black',
                        },
                      }}
                      startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                      onClick={handleModif}
                    >
                      Retour
                    </Button>
                  </Grid>
                </Grid>
                <Grid alignItems="center" container spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Typography variant="h6">Information opérateur</Typography>
                  </Grid>
                </Grid>
                <Box sx={{ p: 2 }} dir="ltr">
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Nom</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={4}>
                      <TextField
                        name="nom"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Identifiant</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={4}>
                      <TextField name="id" type="text" value={id} onChange={(e) => setId(e.target.value)} fullWidth />
                    </Grid>
                  </Grid>
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Mot de passe</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={4}>
                      <TextField
                        name="password"
                        type="password"
                        value={pass}
                        onChange={(e) => setPass(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* liste de controle */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Typography variant="h6">Categorie de ticket à vérifier </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2 }} dir="ltr">
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>choix du ticket</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={4}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                        <Select
                          labelId="demo-multiple-chip-label"
                          id="demo-multiple-chip"
                          multiple
                          value={ticketVerif}
                          onChange={handleChangeTicket}
                          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          <MenuItem key={0} value="Tous les tickets">
                            Tous les tickets
                          </MenuItem>
                          {tickets.map((ticket, index) => (
                            <MenuItem key={index} value={ticket.ticket_name} style={getStyles(name, personName, theme)}>
                              {ticket.ticket_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box sx={{ p: 3 }} dir="ltr">
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={2} md={2}>
                    <Button
                      fullWidth
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        Color: 'white',
                        backgroundColor: '#5E17EB',
                        borderRadius: '7px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: '#5E17EB',
                          borderColor: '#5E17EB',
                        },
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Enregister
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </form>
        )}
      </Container>
    </Page>
  );
}

/* eslint-disable radix */
/* eslint-disable no-unneeded-ternary */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// material
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
// components
import {
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

import { deepOrange } from '@mui/material/colors';
import { db } from '../../../../firebase.config';
import Iconify from '../../../../components/Iconify';
import { UserAuth } from '../../../../context/AuthContext';
import { Variables } from '../../../../context/VariableContext';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function FilterCommDrawer() {
  const { user } = UserAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { openDrawer, setOpenDrawer } = Variables(); 
  const [tickets, setTicket] = React.useState([]);
  const { eventID } = useParams();
  const [expanded, setExpanded] = React.useState(false);
  const [refreshCount, setRefreshCount] = React.useState(false);
  const [filter, setFilter] = React.useState([]);
  const [events, setEvents] = React.useState([]);
  const [billets, setBillets] = React.useState([]);
  const [seances, setSeances] = React.useState([]);
  const [compare, setCompare] = React.useState();
  const [nombre, setNombre] = React.useState(0);
  const [filterStatut, setFilterStatut] = React.useState([]);
  const [filterOrigine, setFilterOrigine] = React.useState([]);
  const [filterBillet, setFilterBillet] = React.useState([]);
  const [filterSeance, setFilterSeance] = React.useState([]);
  const [filterHist, setFilterHist] = React.useState('');

  const handleSelectCompareBillet = (tmp) => {
    setCompare(tmp);
  };

  // filtre items
  const [queryList, setQuery] = React.useState([]);
  const [stateStatus, setStateStatus] = React.useState({
    paye: false,
    reserve: false,
  });

  const [origine, setOrigine] = React.useState({
    appli: false,
    ajout: false,
    siteWeb: false,
    guichet: false,
  });

  const [billet, setBillet] = React.useState();

  const handleChangeStatus = (event) => {
    const tmp = filterStatut.findIndex((element) => parseInt(element === event.target.value));
    if (event.target.checked && tmp === -1) {
      setFilterStatut((tickets) => [...tickets, parseInt(event.target.value)]);
    } else {
      const array = filterStatut;
      array.splice(tmp, 1);
      setFilterStatut(array);
    }

    setStateStatus({
      ...stateStatus,
      [event.target.name]: event.target.checked,
    });
    setRefreshCount(!refreshCount);
  };

  const handleChangeOrigine = (event) => {
    const tmp = filterOrigine.findIndex((element) => element === event.target.value);
    if (event.target.checked && tmp === -1) {
      setFilterOrigine((tickets) => [...tickets, event.target.value]);
    } else {
      const array = filterOrigine;
      array.splice(tmp, 1);
      setFilterOrigine(array);
    }

    setOrigine({
      ...origine,
      [event.target.name]: event.target.checked,
    });
    setRefreshCount(!refreshCount);
  };

  const handleChangeSeance = (event) => {
    const tmp = filterSeance.findIndex((element) => element === event.target.value);
    if (event.target.checked && tmp === -1) {
      setFilterSeance((tickets) => [...tickets, event.target.value]);
    } else {
      const array = filterSeance;
      array.splice(tmp, 1);
      setFilterSeance(array);
    }

    setSeances({
      ...seances,
      [event.target.name]: event.target.checked,
    });
    setRefreshCount(!refreshCount);
  };

  const handleChangeBillet = (event) => {
    const tmp = filterBillet.findIndex((element) => element === event.target.value);
    if (event.target.checked && tmp === -1) {
      setFilterBillet((billets) => [...billets, event.target.value]);
    } else {
      const array = filterBillet;
      array.splice(tmp, 1);
      setFilterBillet(array);
    }

    setBillet({
      ...billet,
      [event.target.name]: event.target.checked,
    });
    console.log(billet)
    setRefreshCount(!refreshCount);
  };

  const handleChangeHistorique = (event) => {
    setFilterHist(event.target.value);
    setRefreshCount(!refreshCount);
  };

  const handleChange = (panel) => async (event, isExpanded) => {
    if (panel === 'panel1') {
      if (events.length === 0) {
        setEvents([]);
        console.log(user);
        const u1 = await getDoc(doc(db, 'organisateurs', `${user.uid}`));
        console.log(u1.data());
        const q = query(
          collection(db, 'evenement'),
          where('orgId', '==', u1.data().idOrg),
          orderBy('dateDebut', 'asc')
        );
        const querySnapshot = await getDocs(q);
        // setEvents(querySnapshot.docs);
        querySnapshot.forEach((doc) => {
          // sort list
          const element = doc;
          if (element.data().status === 1) {
            setEvents((events) => [...events, element]);
          }
        });
      }
    } else {
      console.log('ko');
    }
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();

  const applyFilter = () => {
    // navigate(`salut`);
    let tmpquery = '';
    // filtre  statut
    if (filterStatut.length > 0) {
      tmpquery = 'fs=';
      filterStatut.forEach((element) => {
        tmpquery += `${element},`;
      });
      tmpquery = tmpquery.slice(0, -1);
    }
    // filtre  origine
    if (filterOrigine.length > 0) {
      if (tmpquery === '') {
        tmpquery += 'fo=';
      } else {
        tmpquery += '&fo=';
      }

      filterOrigine.forEach((element) => {
        tmpquery += `${element},`;
      });
      tmpquery = tmpquery.slice(0, -1);
    }
    // filtre billet
    if (filterBillet.length > 0) {
      if (tmpquery === '') {
        tmpquery += 'fb=';
      } else {
        tmpquery += '&fb=';
      }

      filterBillet.forEach((element) => {
        tmpquery += `${element},`;
      });
      tmpquery = tmpquery.slice(0, -1);
    }
    // filtre date d'ajout
    if (filterHist !== '') {
      if (tmpquery === '') {
        tmpquery += 'fa=';
      } else {
        tmpquery += '&fa=';
      }
      tmpquery += `${filterHist},`;
      tmpquery = tmpquery.slice(0, -1);
    }
    console.log('params: ', tmpquery);
    const params = { q: tmpquery };
    navigate({
      pathname: '',
      search: `?${createSearchParams(params)}`,
    });
    setOpenDrawer(false);
  };

  React.useEffect(() => {
    (async () => {
      setTicket([]);
      const tmp = new Map();
      try {
        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          setTicket((tickets) => [...tickets, d]);
          tmp.set(d.data().ticket_name, false);
        });
        setBillet(tmp);
        console.log(tmp);
        const term = searchParams.get('q');
        console.log('params: ', term);
        if (term) {
          console.log('--------------debut filtres---------------------')
          const tmpfilter = term.split('&');
          console.log(tmpfilter);
          tmpfilter.forEach((element) => {
            if (element.includes('fs')) {
              console.log('filter statut');
              const tmp = element.split('=');
              console.log(tmp);
              const tmp1 = tmp[1].split(',');
              console.log(tmp1);
              const filterStatut = [];
              tmp1.forEach((element) => {
                filterStatut.push(parseInt(element));
              });
              if (filterStatut.length !== 0) {
                /* setQueryContains((query) => [...query, where('status', 'in', filterStatut)]);
                query1.push(where('status', 'in', filterStatut)); */
              }
            }
            if (element.includes('fo')) {
              console.log('filter origine');
            }
            if (element.includes('fb')) {
              console.log('filter billet');
              const tmp1 = element.split('=');
              console.log(tmp1);
              const filterBillet = tmp1[1].split(',');
              console.log(filterBillet);
              if (filterBillet.length !== 0) {
                filterBillet.forEach(element => {
                  setBillet({
                    ...billet,
                    [element]: true,
                  });
                });
                console.log(billet);
                console.log(tmp);
                
                /* setQueryContains((query) => [...query, where('refTicket', 'in', filterBillet)]);
                query1.push(where('refTicket', 'in', filterBillet)); */
              }
            }
            if (element.includes('fa')) {
              const today = new Date();
              const date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
              const ttmp = new Date(today.getTime() + 24 * 60 * 60 * 1000);
              const tomorrow = new Date(ttmp.getFullYear(), ttmp.getMonth(), ttmp.getDate());
              const tmp = element.split('=');
              const filterHist = tmp[1];
              setFilterHist(filterHist);
            }
          });
          console.log('--------------fin filtres---------------------')
        }
      } catch (error) {
        console.log('error');
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      // setTicket([]);

      const queryContains = [];
      console.log(filterStatut);
      console.log(filterOrigine);
      console.log(filterBillet);

      try {
        const today = new Date();
        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const ttmp = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        const tomorrow = new Date(ttmp.getFullYear(), ttmp.getMonth(), ttmp.getDate());
        if (filterBillet.length === 0 && filterStatut.length === 0 && filterOrigine === 0) {
          setNombre(0);
        } else {
          if (filterStatut.length !== 0) {
            queryContains.push(where('status', 'in', filterStatut));
          }
          if (filterOrigine.length !== 0) {
            queryContains.push(where('origine', 'in', filterOrigine));
          }
          if (filterBillet.length !== 0) {
            queryContains.push(where('refTicket', 'in', filterBillet));
          }
          if (filterHist !== '') {
            if (filterHist === 'today') {
              queryContains.push(where('createDate', '>=', date));
              queryContains.push(where('createDate', '<', new Date(today.getTime() + 24 * 60 * 60 * 1000)));
            } else if (filterHist === 'yesterday') {
              queryContains.push(where('createDate', '<=', date));
              queryContains.push(where('createDate', '>=', new Date(date.getTime() - 24 * 60 * 60 * 1000)));
            } else if (filterHist === 'last-7-days') {
              queryContains.push(where('createDate', '<=', date));
              queryContains.push(where('createDate', '>=', new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000)));
            } else if (filterHist === 'last-14-days') {
              queryContains.push(where('createDate', '<=', date));
              queryContains.push(where('createDate', '>=', new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000)));
            } else if (filterHist === 'last-30-days') {
              queryContains.push(where('createDate', '<=', date));
              queryContains.push(where('createDate', '>=', new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000)));
            }
          }
          // queryContains.push(where("createDate", ">=", tomorrow));
          const total = await getCountFromServer(
            query(collection(db, `evenement/${eventID}/billets`), ...queryContains, orderBy('createDate', 'desc'))
          );
          console.log('total : ', total.data().count);
          setNombre(total.data().count);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [refreshCount]);

  return (
    <Box>
      <Box paddingBottom={3} paddingX={3} sx={{ height: {xs: '75vh', sm:' 78vh', xl: '85vh'}, overflowX: 'auto', overflowY: 'auto' }}>
        <Typography variant="h5" paddingBottom={1} paddingTop={3}>
          Commandes
        </Typography>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
          sx={{ bgcolor: '#F0F0F0', mb: 2 }}
        >
          <AccordionSummary
            expandIcon={
              expanded !== 'panel5' && filterStatut.length !== 0 ? (
                <Badge color="secondary" badgeContent={filterStatut.length}>
                  <Iconify icon={'ep:arrow-down-bold'} />
                </Badge>
              ) : (
                <Iconify icon={'ep:arrow-down-bold'} />
              )
            }
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <Iconify icon="mdi:calendar-outline" sx={{ width: 20, height: 20 }} />
              <Typography>Seance</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormLabel component="legend"><Typography variant='caption'>A quelle Seance appartient cette commande</Typography></FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  key={'paye'}
                  value={1}
                  control={<Checkbox checked={stateStatus.paye} onChange={handleChangeStatus} name="paye" />}
                  label={'Payé'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  key={'reserve'}
                  value={0}
                  control={<Checkbox checked={stateStatus.reserve} onChange={handleChangeStatus} name="reserve" />}
                  label={'Reservé'}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
          sx={{ bgcolor: '#F0F0F0', mb: 2 }}
        >
          <AccordionSummary
            expandIcon={
              expanded !== 'panel1' && filterStatut.length !== 0 ? (
                <Badge color="secondary" badgeContent={filterStatut.length}>
                  <Iconify icon={'ep:arrow-down-bold'} />
                </Badge>
              ) : (
                <Iconify icon={'ep:arrow-down-bold'} />
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <Iconify icon="mdi:calendar-outline" sx={{ width: 20, height: 20 }} />
              <Typography>Status</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormLabel component="legend">Contacts qui ont participé à au moins un des évènements suivants</FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  key={'paye'}
                  value={1}
                  control={<Checkbox checked={stateStatus.paye} onChange={handleChangeStatus} name="paye" />}
                  label={'Payé'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  key={'reserve'}
                  value={0}
                  control={<Checkbox checked={stateStatus.reserve} onChange={handleChangeStatus} name="reserve" />}
                  label={'Reservé'}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
          sx={{ bgcolor: '#F0F0F0', mb: 2 }}
        >
          <AccordionSummary
            expandIcon={
              expanded !== 'panel2' && filterOrigine.length !== 0 ? (
                <Badge color="secondary" badgeContent={filterOrigine.length}>
                  <Iconify icon={'ep:arrow-down-bold'} />
                </Badge>
              ) : (
                <Iconify icon={'ep:arrow-down-bold'} />
              )
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <Iconify icon="carbon:ticket" sx={{ width: 20, height: 20 }} />
              <Typography>Origine</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormLabel component="legend">Contacts qui ont participé à au moins un des évènements suivants</FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  key={'appli'}
                  value={1}
                  control={<Checkbox checked={origine.appli} onChange={handleChangeOrigine} name="appli" />}
                  label={'Application'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  key={'ajout'}
                  value={0}
                  control={<Checkbox checked={origine.ajout} onChange={handleChangeOrigine} name="ajout" />}
                  label={'Ajout Manuel'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  key={'reserve'}
                  value={0}
                  control={<Checkbox checked={origine.siteWeb} onChange={handleChangeOrigine} name="siteWeb" />}
                  label={'Site Web'}
                  labelPlacement="end"
                />
                <FormControlLabel
                  key={'guichet'}
                  value={0}
                  control={<Checkbox checked={origine.guichet} onChange={handleChangeOrigine} name="guichet" />}
                  label={'Guichet'}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Typography variant="h5" paddingBottom={1} paddingTop={3}>
          Billets
        </Typography>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
          sx={{ bgcolor: '#F0F0F0', mb: 2 }}
        >
          <AccordionSummary
            expandIcon={
              expanded !== 'panel4' && filterBillet.length !== 0 ? (
                <Badge color="secondary" badgeContent={filterBillet.length}>
                  <Iconify icon={'ep:arrow-down-bold'} />
                </Badge>
              ) : (
                <Iconify icon={'ep:arrow-down-bold'} />
              )
            }
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <Iconify icon="ph:gender-neuter-thin" sx={{ width: 20, height: 20 }} />
              <Typography>Type de tarifs</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <FormLabel component="legend">Contacts qui ont participé à au moins un des évènements suivants</FormLabel>
              <FormGroup aria-label="position">
                {tickets.map((ticket, index) => (
                  <FormControlLabel
                    key={ticket.id}
                    value={ticket.id}
                    control={
                      <Checkbox
                        checked={billet[ticket.data().ticket_name]}
                        onChange={handleChangeBillet}
                        name={ticket.id}
                      />
                    }
                    label={ticket.data().ticket_name}
                    labelPlacement="end"
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>

        <Typography variant="h5" paddingBottom={1} paddingTop={3}>
          Historique
        </Typography>
        <Accordion
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
          sx={{ bgcolor: '#F0F0F0', mb: 2 }}
        >
          <AccordionSummary
            expandIcon={<Iconify icon={'ep:arrow-down-bold'} />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <Iconify icon="material-symbols:update-rounded" sx={{ width: 20, height: 20 }} />
              <Typography>Date d'ajout de la commande</Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label1">Quand cette commande a été ajoutée</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label1"
                defaultValue="female"
                name="radio-buttons-group1"
                value={filterHist}
                onChange={handleChangeHistorique}
              >
                <FormControlLabel value="today" control={<Radio />} label="Aujourd'hui" />
                <FormControlLabel value="yesterday" control={<Radio />} label="Hier" />
                <FormControlLabel value="last-7-days" control={<Radio />} label="Dans une semaine" />
                <FormControlLabel value="last-14-days" control={<Radio />} label="Dans les 14 derniers jours" />
                <FormControlLabel value="last-30-days" control={<Radio />} label="Dans les 30 derniers jours" />
                <FormControlLabel value="from-15/22/23-to-15/22/23" control={<Radio />} label="Selectionner la plage" />
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Stack direction="row" justifyContent="end" spacing={2} padding={1} paddingRight={5}>
        <Button
          sx={{
            textTransform: 'initial',
            border: '2px solid transparent',
            color: 'white',
            backgroundColor: 'black',
            boxShadow: 'none',
            borderRadius: '7px',
            '&:hover': {
              backgroundColor: 'white',
              color: 'black',
              borderColor: 'black',
            },
          }}
          variant="contained"
          onClick={() => {
            setOpenDrawer(false);
          }}
        >
          Annuler
        </Button>
        <Button
          disabled={
            filterStatut.length === 0 && filterBillet.length === 0 && filterOrigine.length === 0 && filterHist === ''
              ? true
              : false
          }
          sx={{
            textTransform: 'initial',
            border: '2px solid transparent',
            color: 'white',
            backgroundColor: '#5E17EB',
            borderRadius: '7px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'white',
              color: '#5E17EB',
              borderColor: '#5E17EB',
            },
          }}
          variant="contained"
          onClick={applyFilter}
        >
          Afficher les contacts (
          {filterStatut.length !== 0 || filterBillet.length !== 0 || filterOrigine.length !== 0 || filterHist !== ''
            ? nombre
            : null}
          )
        </Button>
      </Stack>
    </Box>
  );
}

import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box, Tabs, Tab, Grid, Stack, Button } from '@mui/material';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections

import BasicTabs from '../components/home/BasicTabs';
import HomeNavbar from '../layouts/home/HomeNavbar';
import BasicTabs1 from '../components/home/basicTabs1';

// ---------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: `calc(100%*0.8)`,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(8, 0),
}));

// ----------------------------------------------------------------------

export default function Settings() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const [open, setOpen] = useState(false);

  return (
    <Page title="Back office">
      <RootStyle>
        <HomeNavbar onOpenSidebar={() => setOpen(true)} />
        <Container maxWidth="xl">
          {!smUp && (
            <>
              <Card sx={{ pt: 10, pb: 1 }}>
                <Box sx={{ pt: 1, pb: 1 }} dir="ltr">
                  <BasicTabs1 />
                </Box>
              </Card>
            </>
          )}
          {mdUp && (
            <ContentStyle>
              <Card>
                <Box dir="ltr">
                  <BasicTabs1 />
                </Box>
              </Card>
            </ContentStyle>
          )}
        </Container>
      </RootStyle>
    </Page>
  );
}

/* eslint-disable radix */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  Timestamp,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import { db } from '../../firebase.config';

import { UserAuth } from '../../context/AuthContext';
import Page from '../../components/Page';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const params = useParams;

// ----------------------------------------------------------------------

export default function Historique() {
    const { user } = UserAuth();
    const [value, setValue] = React.useState(0);
    const [events, setEvents] = React.useState([]);
    const [revers, setRevers] = React.useState([]);
    const [pastEvents, setPastEvents] = React.useState([]);
    const [brouillonEvent, setbrouillonEvent] = React.useState([]);
    const [notReadyEvents, setNotReadyEvents] = React.useState([]);
  
    // loading
    const [loading, setLoading] = React.useState(false);
    // loading
    const [brouillon, setBrouillon] = React.useState(false);
  
    const createEventList = (doc) => {
      setEvents((events) => [...events, doc]); // ajouter a la fin
    };
  
    const createPastEventList = (doc) => {
      setPastEvents((events) => [...events, doc]); // ajouter a la fin
    };
  
    const createBrouillonEventList = (doc) => {
      setbrouillonEvent((events) => [...events, doc]); // ajouter a la fin
    };
  
    React.useEffect(() => {
      (async () => {
        try {
          setRevers([])
          setEvents([])
          setLoading(true);
          const docRef0 = doc(db, 'organisateurs', user.uid);
          const docSnap = await getDoc(docRef0);
          console.log(docSnap.data().idOrg);
          if (docSnap.exists()) {
            console.log(docSnap.data().idOrg);
            const q = collection(db, `organisations/${docSnap.data().idOrg}/reversements`);
            const querySnapshot = await getDocs(q);
            // querySnapshot.empty()
            console.log(querySnapshot);
            // setEvents(querySnapshot.docs);
            querySnapshot.forEach(async (doc1) => {
              console.log(doc1.id, ' => ', doc1.data());
              setRevers((revers) => [...revers, doc1.data()]);
  
              // recuperation du nom de l'evenement
              const docRef1 = doc(db, 'evenement', doc1.data().idEvent);
              const docSnap1 = await getDoc(docRef1);
              setEvents((events) => [...events, docSnap1.data().nomEvent])
  
              // setLoading(false);
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }, [user.uid]);

  return (
    <Page title="Organisation">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Historique de reversement
            <br />
            <Typography variant="body2">Liste de toute les demandes de reversement effectuer</Typography>
          </Typography>
        </Stack>
        {revers.length === 0 ? (
        <Box paddingY={5}>
          <Card sx={{ padding: 2, bgcolor: 'gainsboro' }}>
            <Typography>
              CHOPTONTICKET transférera la recette de votre événement régulièrement. Dès que vous aurez un événement
              ayant généré du chiffre d'affaires, vous le retrouverez ici. <br/>
              Vous pouvez également faire des demandes de reversement lorsque vous en souhaitez en vous rendant sur le
              tableau de bord de votre evenement.
            </Typography>
          </Card>
        </Box>
      ) : (
        <Box paddingY={5}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Evenement</TableCell>
                  <TableCell align="left">Date de la demande</TableCell>
                  <TableCell align="left">Montant</TableCell>
                  <TableCell align="left">Statut</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {revers.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {events[index]}
                    </TableCell>
                    <TableCell align="left">{row.dateCreation.seconds}</TableCell>
                    <TableCell align="left">{row.montant}</TableCell>
                    <TableCell align="left">{row.statut}</TableCell>
                    <TableCell align="left">Telecharger</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      </Container>
    </Page>
  );
}

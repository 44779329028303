/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Modal,
  Radio,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  Timestamp,
  setDoc,
  serverTimestamp,
  addDoc,
  getCountFromServer,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';

import { QrCode } from '@mui/icons-material';

import { useState, useRef } from 'react';
import { db } from '../../firebase.config';

import { UserAuth } from '../../context/AuthContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CheckOrg from '../../components/CheckOrg';
import QRCodeGenerator from '../../components/QRCodeGenerator';

// eslint-disable-next-line no-var
var QRCode = require('qrcode');

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  p: 3,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const params = useParams;

// ----------------------------------------------------------------------

export default function CttDoor() {
  const { user } = UserAuth();
  const [refresh, setRefresh] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [opMobile, setOpMobile] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [moyenVir, setMoyenVir] = React.useState([]);
  
  const [idOrg, setIdOrg] = React.useState();
  // loading
  const [qrCodeValue, setQrCodeValue] = React.useState('');

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        setIdOrg(docSnap.data().idOrg);
        setQrCodeValue(`choptonticket.com/fr/organisateur/${docSnap.data().idOrg}`)
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [user.uid]);

  // qr code
  return (
    <Page title="CTT Door">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            CTT Door
            <br />
            <Typography variant="body2">
            Xpress Door simplifie la vente de billets à l’entrée de vos évènements
            </Typography>
          </Typography>
        </Stack>
        <Box
          sx={{
            height: '100%',
            borderRadius: '7px',
            padding: 3,
            border: '2px solid transparent',
            bgcolor: '#f2f2f2',
            borderColor: '#f2f2f2',
          }}
        >
          <Typography textAlign={'start'} variant="body2" sx={{color: '#5E17EB', paddingBottom:2}}>
              URL CTT Door : {qrCodeValue}
            </Typography>
          <Grid container spacing={2} alignItems='center'>
            <Grid item>
              <QRCodeGenerator value={qrCodeValue} />
            </Grid>
            <Grid item>
              <Typography variant='h6' textAlign="Start">
                <strong>Votre QR code CTT Door</strong>
              </Typography>
              <Typography variant="body2" textAlign="Start">
                Imprimez ce code QR et affichez-le en évidence pour fluidifier l'achat de billets à l'entrée.
              </Typography>
              <LoadingButton
                sx={{
                  marginTop:1,
                  textTransform: 'initial',
                  border: '2px solid #5E17EB',
                  color: 'white',
                  backgroundColor: '#5E17EB',
                  borderRadius: '7px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',
                  },
                }}
                // onClick={() => show()}
                color="secondary"
                loading={loading}
                loadingPosition="end"
                variant="contained"
              >
                Télécharger votre QR code
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}

import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Autocomplete, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { addDoc, collection, doc, getDocs, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { db } from '../../../firebase.config';
import { UserAuth } from '../../../context/AuthContext';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const { user, signUp, signUpSecondStep } = UserAuth();
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required('Nom est obligatoire'),
    lastName: Yup.string().required('Prénom est obliagtoire'),
    email: Yup.string().email('Email doit est être un email valide').required('Email est obligatoire'),
    password: Yup.string().required('Mot de passe est obligatoire'),
  });

  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (e) => {
    console.log(e.firstName);
    console.log(e.lastName);
    console.log(e.email);
    console.log(e.password);
    try {
      const reg = await signUp(e.email, e.password);
      // create organisation
      console.log(reg.user.uid)
      const docRef = await addDoc(collection(db, "organisations"), {
        dateCreation: serverTimestamp(),
        contact: '',
        followers: [],
        infosPayement: [],
        infoPaie: 0,
        certified: false,
        type: 2,
      });
        
      console.log('organisation : ', docRef);
      console.log('organisation id : ', docRef.id);
      await signUpSecondStep(reg.user.uid, e.email, docRef.id, e.lastName, e.firstName);
      // navigate('/bo/new_event', { replace: true }); 
      navigate('/register/organisation', { replace: true });
      console.log(user.uid)
    } catch (error) {
      console.log('erro');
      setError(error.message);
      console.log(error.message);
    }
    // navigate('/bo', { replace: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <RHFTextField name="firstName" label="Nom" />
          <RHFTextField name="lastName" label="Prénom" />
        </Stack>

        <RHFTextField name="email" label="Email" />

        <RHFTextField
          name="password"
          label="Mot de passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Typography>{error}</Typography>

        <LoadingButton sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'white',
                  backgroundColor: '#5E17EB',
                  borderRadius: '7px',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',
                    borderColor: '#5E17EB',
                  },
                }} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Inscription
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

/* eslint-disable no-lonely-if */
/* eslint-disable radix */
/* eslint-disable no-loop-func */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, CircularProgress, Typography, Grid } from '@mui/material';
// components
import { format } from 'date-fns';
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

AppWebsiteVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  debut: PropTypes.string,
  fin: PropTypes.string,
  chartData: PropTypes.array.isRequired,
  chartStat: PropTypes.array.isRequired,
  ticketName: PropTypes.array.isRequired,
  chartLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function AppWebsiteVisits({
  title,
  subheader,
  chartLabels,
  chartData,
  ticketName,
  chartStat,
  debut,
  fin,
  ...other
}) {
  const [dates, setDates] = useState([]);
  const [donnees, setDonnees] = useState([]);
  const [stats, setStat] = useState(chartStat);
  const [chartData1, setChart] = useState([]);
  const [jour, setJour] = useState();
  const [count, setCount] = useState(7);
  const [dateDebut, setDateDebut] = useState(debut);
  const [tickets, setTickets] = useState(ticketName);

  React.useEffect(() => {
    setReload(true);
    setDates([]);
    setDonnees([]);
    const fetchData = async () => {
      try {
        let tData = [];
        const global = [];
        
        console.log(dateDebut);
        date = new Date();
        for (let index = 0; index < 7; index++) {
          // eslint-disable-next-line no-const-assign
          tData = [];
          
          if (index !== 0) {
            date.setDate(date.getDate() - 1);
          }
          const tmp = format(date, 'dd MMM yyyy');
          setDates((dates) => [tmp, ...dates]);
          // gestion des data
          const f = format(date, 'yyMMdd');

          let tDataMap = {};
          tickets.forEach((ticket, i) => {
            // console.log('ticket',ticket)
            // eslint-disable-next-line prefer-const

            const tmpDate = new Date();
            let tValue = 0;
            for (let index1 = 0; index1 < chartStat.length; index1++) {
              if (f === chartStat[index1].id) {
                if (index1 < chartStat.length) {
                  // recuperer le key dans une map

                  // eslint-disable-next-line no-loop-func
                  Object.entries(chartStat[index1].data()).map(([key, value]) => {
                    if (key === ticket) {
                      tValue = value;
                    }
                  });
                }
              }
            }
            // console.log('tableau: ', tData)
            tData.push(tValue);

            tDataMap = {
              name: ticket,
              type: 'line',
              fill: 'solid',
              data: tData,
            };
          });

          global.push(tData);
        }

        tickets.forEach((ticket, index) => {
          const data2 = [];

          for (let i = 0; i < global.length; i++) {
            for (let j = 0; j < global[i].length; j++) {
              if (index === j) {
                data2.push(global[i][j]);
              }
            }
          }
          const tDataMap2 = {
            name: ticket,
            type: 'line',
            fill: 'solid',
            data: data2.reverse(),
          };
          setDonnees((donnees) => [tDataMap2, ...donnees]);
        });

        setReload(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData().catch(console.error);
  }, []);
  const [reloard, setReload] = useState(false);
  let date = new Date();

  const chartOptions = merge(BaseOptionChart(), {
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: donnees.map((i) => i.fill) },
    labels: dates,
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        datetimeFormatter: {
          year: 'yyyy',
          month: 'dd MMM',
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
      tooltip: {
        formatter: (val) => {
          return format(new Date(val), 'dd MMM');
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} ticket(s)`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {reloard ? (
          <Box height={345} sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <ReactApexChart type="line" series={donnees} options={chartOptions} height={345} />
        )}
      </Box>
    </Card>
  );
}

import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import { Box } from '@mui/material';

const QRCodeGenerator = ({ value }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && value) {
      QRCode.toCanvas(canvasRef.current, value, {width: 200, height:200}, (error) => {
        if (error) console.error('Error generating QR code:', error);
        // Optional callback function
      });
    }
  }, [value]);

  return (
    <Box >
      <canvas ref={canvasRef}/>
      {/* Vous pouvez également ajouter un message sous le QR code */}
    </Box>
  );
};

export default QRCodeGenerator;
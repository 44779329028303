/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable spaced-comment */
/* eslint-disable no-unreachable-loop */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import { faker } from '@faker-js/faker';
import 'dayjs/locale/fr';
// @mui
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Link,
  IconButton,
} from '@mui/material';

// components
import { DesktopDateRangePicker, LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { addDoc, collection, doc, getDoc, getDocs, query, Timestamp, where } from 'firebase/firestore';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateTime } from 'luxon';
import {
  StaticDatePicker,
  LocalizationProvider,
  DesktopDatePicker,
  DesktopDateTimePicker,
  DesktopTimePicker,
} from '@mui/x-date-pickers';

import { format } from 'date-fns';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { db } from '../../firebase.config';

import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { FormProvider } from '../../components/hook-form';

// sections

// ----------------------------------------------------------------------

export default function AddDate() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { eventID } = useParams();

  // billet
  dayjs.extend(utc)
  dayjs.extend(timezone);
  const onSubmit = async (e) => {
    try {
      for (let index = 0; index < dateSelected.length; index++) {
        console.log('salut');
        // récupération de la date
        const tmpDate = dateSelected[index];

        // gestion de ticket sélectionné
        const tmpTickets = [];

        // boucle des tickets
        for (let j = 0; j < tickets.length; j++) {
          if (totalselectedTickets[index][j] === true) {
            tmpTickets.push({
              id: tickets[j].id,
              quota: parseInt(QuotaselectedTickets[index][j]),
              sell: parseInt(0),
              status: parseInt(1),
            });
          }
        }

        // si location change
        const d = tmpDate.split(' ');
        const d0 = d[0].split('/');
        const d1 = d0[2] + '-' + d0[1] + '-' + d0[0] + 'T' + d[1];
        const userTimezone = dayjs.tz.guess(); // Deviner le fuseau horaire de l'utilisateur

        // const formattedDate = dayjs(d[0]).format('YYYY-MM-DD');
        const resultDate = dayjs.tz(`${d1}`, userTimezone).toDate();

        // Conversion de la date en utilisant Luxon avec un fuseau horaire spécifique
        // const dateLuxon = DateTime.fromISO(d1, { zone: 'Europe/Paris' }); // Remplacez 'Europe/Paris' par le fuseau horaire souhaité
        // const dateFormatted = dateLuxon.toJSDate();

        if (isNewLocation[index]) {
          console.log('location change: ', lieu[index]);
          const docRef = await addDoc(collection(db, `evenement/${eventID}/seances`), {
            date: Timestamp.fromDate(resultDate),
            billets: tmpTickets,
            status: parseInt(1),
            lieu_event: lieu[index],
            city: ville[index],
            country: pays[index],
            localisation: location[index],
            participants: parseInt(0),
          });
        }
        // sinon
        else {
          console.log('location inchangé');
          const docRef = await addDoc(collection(db, `evenement/${eventID}/seances`), {
            name: nameArray[index],
            date: Timestamp.fromDate(resultDate),
            billets: tmpTickets,
            status: parseInt(1),
            participants: parseInt(0),
          });
        }
      }
      handleToggle();

      // await addDoc(collection(db, 'event'))
    } catch (error) {
      console.log('erro');
      console.log(error.message);
    }
    handleClose();
    navigate(-1, { replace: true });
  };

  // chargement
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [date, setDate] = React.useState();
  const [heure, setHeure] = React.useState();
  const [heure1, setHeure1] = React.useState();
  const [dateSelected, setDateSelected] = React.useState(['']);
  const [daysArray, setDays] = React.useState(['']);
  const [hoursArray, setHours] = React.useState(['']);
  const [nameArray, setNames] = React.useState(['']);
  const [tickets, setTicket] = React.useState([]);
  const [selectedTickets, setSelectedTickets] = React.useState([]);
  const [totalselectedTickets, setTotalSelectedTicket] = React.useState([]); //
  const [dateQuota, setDateQuota] = React.useState([]); // quota lie a chaque seance
  const [quotas, setQuotas] = React.useState([]); // quota initial
  const [QuotaselectedTickets, setQuotaSelectedTicket] = React.useState([]); // contient les quotas de chaque ticket selectionné par quota
  const [isNewLocation, setIsNew] = React.useState([]);
  const [newLocation, setNewLocation] = React.useState([]); //
  const [affineQuota, setAffineQuota] = React.useState([]);
  const [isContinue, setIsContinue] = React.useState(false);

  // handle location
  const searchOptions = {
    componentRestrictions: { country: 'CIV' },
    fields: ['address_components', 'geometry', 'icon', 'name'],
    types: ['establishment'],
  };
  const [address, setAddress] = React.useState('');
  const [lieuError, setLieuError] = React.useState(false);
  const [lieu, setLieu] = React.useState([]);
  const [ville, setVille] = React.useState([]);
  const [pays, setPays] = React.useState([]);
  const [location, setLocation] = React.useState([]);
  const [activeLocationField, setActiveLocationField] = React.useState([]);
  const [today, setToday] = React.useState();

  const handleToday = (e) => {
    setToday(e);
  };

  const handleLieu = (e, index) => {
    setLieu((existItem) => {
      return [...existItem.slice(0, index), e, ...existItem.slice(index + 1)];
    });
    setActiveLocationField(index);
  };

  const handleSelect = async (address, placeId, suggestion) => {
    const index = activeLocationField;
    console.log(suggestion.description);
    const tmp = suggestion.description.split(',');
    setLieu((existItem) => {
      return [...existItem.slice(0, index), tmp[0], ...existItem.slice(index + 1)];
    });
    setVille((existItem) => {
      return [...existItem.slice(0, index), tmp[tmp.length - 2], ...existItem.slice(index + 1)];
    });
    setPays((existItem) => {
      return [...existItem.slice(0, index), tmp[tmp.length - 1], ...existItem.slice(index + 1)];
    });
    setAddress((existItem) => {
      return [...existItem.slice(0, index), suggestion.description, ...existItem.slice(index + 1)];
    });
    // setLieu(tmp[0]);
    // setVille(tmp[tmp.length - 2]);
    // setPays(tmp[tmp.length - 1]);
    // setAddress(suggestion.description);
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    // setLocation(ll);
    setLocation((existItem) => {
      return [...existItem.slice(0, index), ll, ...existItem.slice(index + 1)];
    });
  };

  const handleAdd = () => {
    console.log('ajouter une date');
    // ajouter des dates
    // defaultValue="2017-05-24T10:30"
    if (heure) {
      let exist = -1;
      const d = dayjs(date).format('DD/MM/YYYY');
      const h = dayjs(heure1).format('HH:mm');
      console.log(heure);
      const tmp = d + ' ' + heure;
      console.log(tmp);
      console.log(dateSelected);
      if (dateSelected.length !== 0) {
        exist = dateSelected.findIndex((element) => element === tmp);
      }
      console.log(exist);
      if (exist === -1) {
        setDateSelected((dateSelected) => [...dateSelected, tmp]);
      } else {
        console.log('exist');
      }
    } else {
      console.log('entrer une heure');
    }
  };

  // lorqu'on clique sur le bouton ajouter une seance
  const addSeance = () => {
    setDateSelected((dateSelected) => [...dateSelected, '']);
    setDays((day) => [...day, '']);
    setHours((hour) => [...hour, '']);
  };

  //lorsqu'on change de date
  const handleDays = (e, index) => {
    const values = [...daysArray]; // Copie du tableau de nouvelles valeurs
    values[index] = e; // Mise à jour de la valeur correspondante
    setDays(values);

    if (hoursArray[index] !== '') {
      const d = dayjs(e).format('DD/MM/YYYY');
      //const h = dayjs(hoursArray[index]).format('HH:mm');
      const tmpDateselected = [...dateSelected];
      const tmp = d + ' ' + hoursArray[index];
      tmpDateselected[index] = tmp;
      setDateSelected(tmpDateselected);
    }
  };
  //lorsqu'on change de date
  const handleHours = (e, index) => {
    const values = [...hoursArray]; // Copie du tableau de nouvelles valeurs
    values[index] = e.target.value; // Mise à jour de la valeur correspondante
    setHours(values);
    if (daysArray[index] !== '') {
      const d = dayjs(daysArray[index]).format('DD/MM/YYYY');
      //const h = dayjs(e.target.value).format('HH:mm');
      //console.log(h)
      const tmpDateselected = [...dateSelected];
      const tmp = d + ' ' + e.target.value;
      tmpDateselected[index] = tmp;
      setDateSelected(tmpDateselected);
    }
    const tmpDateselected = [...dateSelected];
    const h = dayjs(heure1).format('HH:mm');
    //tmpDateselected[index] = d;
    console.log(tmpDateselected);
    console.log(e);
    console.log(h);
    //setDateSelected(tmpDateselected);
  };

  const HandleNames = (e, index) => {
    const values = [...hoursArray]; // Copie du tableau de nouvelles valeurs
    values[index] = e.target.value; // Mise à jour de la valeur correspondante
    setNames(values);
  };

  const suppDate = (index) => {
    setDateSelected((existItem) => {
      return [...existItem.slice(0, index), ...existItem.slice(index + 1)];
    });
    setDays((existItem) => {
      return [...existItem.slice(0, index), ...existItem.slice(index + 1)];
    });
    setHours((existItem) => {
      return [...existItem.slice(0, index), ...existItem.slice(index + 1)];
    });
  };

  // recuperation de tickets
  React.useEffect(() => {
    (async () => {
      setToday(new Date());
      setTicket([]);
      try {
        setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          setTicket((tickets) => [...tickets, d]);
          console.log(d.id, ' => ', d.data());
          setSelectedTickets((tickets) => [...tickets, true]);
          setQuotas((quotas) => [...quotas, 0]);
        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, []);
  const [loading, setLoading] = React.useState(false);

  // gestion de la location en fonction des dates

  const handleIsNew = (index) => {
    const tmp = [];
    isNewLocation.map((a, b) => {
      if (b === index) {
        if (a === true) {
          tmp.push(false);
        } else {
          tmp.push(true);
        }
      } else {
        tmp.push(a);
      }
    });
    setIsNew(tmp);
  };

  // gestion de l'affinage de ticket par date

  const handleAffine = (index) => {
    const tmp = [];
    affineQuota.map((a, b) => {
      if (b === index) {
        if (a === true) {
          tmp.push(false);
        } else {
          tmp.push(true);
        }
      } else {
        tmp.push(a);
      }
    });
    setAffineQuota(tmp);
  };

  // gestion des ticket a selectionner selon les dates
  const handleSelectedTickets = (index, i) => {
    console.log(index, i);
    const tmp = [];
    console.log('all: ', totalselectedTickets);
    totalselectedTickets.map((a, b) => {
      console.log(a);
      if (b === index) {
        const tmp1 = [];
        console.log('a', a);
        for (const element in a) {
          console.log('element', element);
          console.log('element a', a[element]);
          console.log('i', i);

          if (parseInt(element) === i) {
            if (a[element] === false) {
              tmp1.push(true);
            } else {
              tmp1.push(false);
            }
          } else {
            tmp1.push(a[element]);
          }
        }

        tmp.push(tmp1);
      } else {
        tmp.push(a);
      }
    });

    setTotalSelectedTicket(tmp);
  };

  // gestion des quota des tickets selectionné selon les dates
  const handleAffinageQuota = (value, index, i) => {
    console.log(index, i);
    const tmp = [];
    QuotaselectedTickets.map((a, b) => {
      console.log(a);
      if (b === index) {
        const tmp1 = [];
        console.log('a', a);
        for (const element in a) {
          console.log('element', element);
          console.log('element a', a[element]);
          console.log('i', i);

          if (parseInt(element) === i) {
            tmp1.push(value);
          } else {
            tmp1.push(a[element]);
          }
        }

        tmp.push(tmp1);
      } else {
        tmp.push(a);
      }
    });

    setQuotaSelectedTicket(tmp);
  };

  // stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = ['Select campaign settings', 'Create an ad group'];
  const [finish, setFinish] = React.useState(false);

  const handleNext = () => {
    if (activeStep === 0) {
      if (dateSelected.length === 0) {
        console.log('aucune date selectionné');
      } else {
        let tmp = true;
        for (let index = 0; index < daysArray.length; index++) {
          if (daysArray[index] === '' && hoursArray[index] === '') {
            tmp = false;
          } else if (daysArray[index] === '' || hoursArray[index] === '') {
            tmp = false;
          }
        }
        if (tmp) {
          dateSelected.forEach((element) => {
            setTotalSelectedTicket((totalselectedTickets) => [...totalselectedTickets, selectedTickets]);
            setIsNew((isNewLocation) => [...isNewLocation, false]);
            setAffineQuota((affineQuota) => [...affineQuota, false]);
            setNewLocation((newLocation) => [...newLocation, '']);
            setLieu((lieu) => [...lieu, '']);
            setVille((ville) => [...ville, '']);
            setPays((pays) => [...pays, '']);
            setLocation((location) => [...location, '']);
            setQuotaSelectedTicket((QuotaselectedTickets) => [...QuotaselectedTickets, quotas]);
            setDateQuota((dateQuota) => [...dateQuota, 0]);
          });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 1) {
      console.log('active', activeStep);
      onSubmit();
      setFinish(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // ---------------------

  return (
    <Page title="Séance">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="right" mb={2}>
          <Button
            variant="outlined"
            sx={{
              textTransform: 'initial',
              border: '2px solid transparent',
              color: 'black',
              backgroundColor: 'white',
              borderRadius: '7px',
              borderColor: 'black',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'black',
                color: 'white',
                borderColor: 'black',
              },
            }}
            startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
          // onClick={() => setModif(false)}
          >
            retour
          </Button>
        </Stack>
      </Container>
      <Container>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            {activeStep === 0 ? (
              <Box>
                {/* <Grid container spacing={2} padding={1}>
                  <Grid item xs={12} md={8}>
                    <Typography variant="body2">
                      Sélectionnez une ou plusieurs dates dans le calendrier, renseignez une heure, puis cliquez sur
                      AJOUTER.
                    </Typography>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 2,
                        padding: 2,
                        border: '1px solid blue',
                        borderRadius: '7px',
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" sx={{ bgcolor: 'yellow' }}>
                        <StaticDatePicker
                          displayStaticWrapperAs="desktop"
                          orientation="landscape"
                          openTo="day"
                          value={date}
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Box>

                    <Box
                      sx={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBottom: 2, paddingX: 2 }}
                    >
                      <TextField
                        id="time"
                        type="time"
                        label="Entrer une heure"
                        fullWidth
                        value={heure}
                        onChange={(e) => {
                          setHeure(e.target.value);
                        }}
                        inputProps={{
                          step: 600, // pour permettre la sélection toutes les 10 minutes
                        }}
                        // error={debutError ? true : false}
                        // helperText={debutError ? debutErrorMsg : null}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Button
                      disabled={!heure ? true : false}
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'white',
                        backgroundColor: '#5E17EB',
                        borderRadius: '7px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: '#5E17EB',
                          borderColor: '#5E17EB',
                        },
                      }}
                      fullWidth
                      variant="contained"
                      onClick={handleAdd}
                    >
                      Ajouter
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography>Liste des dates selectionnées.</Typography>
                    {dateSelected.map((selected, index) => (
                      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                        <Typography>{selected}</Typography>
                        <IconButton onClick={(e) => suppDate(index)}>
                          <Iconify icon="carbon:delete" sx={{ color: 'red' }} />
                        </IconButton>
                      </Stack>
                    ))}
                  </Grid>
                </Grid> */}
                <Card sx={{ padding: 2 }}>
                  <Typography variant="body1" paddingBottom={3}>
                    <strong>Choisissez les dates de vos séances</strong>
                  </Typography>
                  {dateSelected.map((selected, index) => (
                    <Stack key={index} sx={{ border: '1px solid #5E17EB', borderRadius: '7px', marginBottom: 2 }}>
                      <Box paddingX={2}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                          <Typography variant="body2">
                            <strong>Seance {index + 1}</strong>
                          </Typography>

                          <IconButton disabled={dateSelected.length > 1 ? false : true} onClick={(e) => suppDate(index)}>
                            <Iconify icon="typcn:delete" />
                          </IconButton>

                        </Stack>
                      </Box>
                      <Divider />
                      <Box margin={2} padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>

                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Stack spacing={0.5}>
                              <Typography variant="body2">Nom de la seance</Typography>
                              <TextField
                                type="text"
                                value={nameArray[index]}
                                onChange={(e) => HandleNames(e, index)}
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={6}>
                            <></>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Stack spacing={0.5}>
                              <Typography variant="body2">Date de début</Typography>

                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DesktopDatePicker
                                  inputFormat="DD/MM/YYYY"
                                  minDate={today}
                                  value={daysArray[index]}
                                  onChange={(e) => handleDays(e, index)}
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <Stack spacing={0.5}>
                              <Typography variant="body2">Heure de début</Typography>
                              {/* <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <DesktopTimePicker
                                  views={['hours', 'minutes']}
                                  value={hoursArray[index]}
                                  minutesStep={10}
                                  onChange={(e) => handleDays(e, index)} 
                                  renderInput={(params) => <TextField {...params} />}
                                />
                              </LocalizationProvider> */}
                              <TextField
                                type="time"
                                value={hoursArray[index]}
                                onChange={(e) => handleHours(e, index)}
                              />
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </Stack>
                  ))}
                  <Button variant='text' sx={{color:'#5E17EB'}} onClick={() => addSeance()}>Ajouter une séance</Button>
                </Card>
              </Box>
            ) : activeStep === 1 ? (
              <Card sx={{ padding: 2 }}>
                <Typography variant="body1" paddingBottom={3}>
                  {' '}
                  <strong>Associez des billets a vos séance</strong>
                </Typography>
                {dateSelected.map((selected, index) => (
                  <Stack key={index} sx={{ border: '1px solid gainsboro', borderRadius: '7px', marginBottom: 2 }}>
                    <Box paddingX={2} paddingY={1}>
                      <Typography variant="body2">
                        <strong>Séance du : {selected}</strong>
                      </Typography>
                    </Box>
                    <Divider />
                    <Box margin={2} padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                      {tickets.map((ticket, i) => (
                        <Box key={ticket.id}>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={totalselectedTickets[index][i]}
                                    onChange={() => {
                                      handleSelectedTickets(index, i);
                                    }}
                                    name="jason"
                                  />
                                }
                                label={<Typography variant="body2">{ticket.data().ticket_name}</Typography>}
                              />
                            </Grid>
                            {totalselectedTickets[index][i] && (
                              <Grid item xs={12} paddingX={2}>
                                <Typography variant="body2">Definir un quota</Typography>
                                <TextField
                                  size='small'
                                  type="number"
                                  helperText='laisser 0 pour ne pas definir de quota'
                                  value={QuotaselectedTickets[index][i]}
                                  onChange={(e) => {
                                    handleAffinageQuota(e.target.value, index, i);
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      ))}
                    </Box>
                  </Stack>
                ))}
              </Card>
            ) : (
              <Card sx={{ padding: 2 }}>
                <Typography>Renseignez le nombre maximum de tickets pour chaque séance.</Typography>
                {dateSelected.map((selected, index) => (
                  <Accordion key={{ index }} expanded="true">
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                      <Typography variant="h6">Séance du : {selected}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {/* <Grid container>
                        <Grid item xs={12} sm={4}>
                          <Typography variant="body1">Quota de la séance</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            type="number"
                            value={dateQuota[index]}
                            onChange={(e) => {
                              setDateQuota((existItem) => {
                                return [...existItem.slice(0, index), e.target.value, ...existItem.slice(index + 1)];
                              });
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid> */}
                      <Link
                        onClick={() => {
                          handleAffine(index);
                        }}
                        variant="body2"
                      >
                        Affiner les quotas par ticket
                      </Link>
                      {tickets.map(
                        (ticket, i) =>
                          totalselectedTickets[index][i] && (
                            <Box marginTop={1} key={i} padding={1} marginBottom={2} sx={{ bgcolor: 'gainsboro' }}>
                              <Grid container>
                                <Grid item xs={6} sm={4}>
                                  <Typography variant="body2">{ticket.data().ticket_name}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    type="number"
                                    value={QuotaselectedTickets[index][i]}
                                    onChange={(e) => {
                                      handleAffinageQuota(e.target.value, index, i);
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          )
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Card>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Retour
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              <Button
                // disabled={!heure ? true : false}
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: 'white',
                  backgroundColor: '#5E17EB',
                  borderRadius: '7px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',
                    borderColor: '#5E17EB',
                  },
                }}
                variant="contained"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? 'Terminer' : 'Continuer'}
              </Button>
            </Box>
          </>
        )}
      </Container>
    </Page>
  );
}

/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-await-in-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
// @mui
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  Backdrop,
  CircularProgress,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Snackbar,
  Alert,
} from '@mui/material';
// components

import { addDoc, collection, getDocs, query, Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';
import { db } from '../../firebase.config';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import CustomButton from '../../components/CustomButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'background.paper',
  border: '1px solid white',
  borderRadius: '7px',
  p: 3,
};
// ----------------------------------------------------------------------
const randomstring = require('randomstring');

export default function AddDiscount() {
  const { eventID } = useParams();
  // const theme = useTheme();

  const navigate = useNavigate();

  // recuperation de billets
  const [tickets, ticketList] = React.useState([]); // contient la liste des tickets
  const [checks, setCheck] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      ticketList([]);

      try {
        // setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          ticketList((tickets) => [...tickets, d]);
          setCheck((checks) => [...checks, false]);
        });
        // setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [eventID]);

  const [nomDuCode, setNom] = React.useState(''); // valeur de la reduction
  const [codeASaisir, setASaisir] = React.useState(''); // valeur de la reduction
  const [nbUtilisation, setNb] = React.useState(0); // valeur de la reduction
  const [reduction, setReduction] = React.useState(0); // valeur de la reduction
  const [reductionError, setReductionError] = React.useState(false);
  const [debutField, setDebut] = React.useState(); // valeur de la reduction
  const [finField, setFin] = React.useState(); // valeur de la reduction
  const [codePromos, setCodePromo] = React.useState([]); // tableau qui coontiendra les code de promotion
  const [codePromosError, setCodePromoError] = React.useState(false);
  const [limitedT, setLimit] = React.useState(false); //
  const [limitedTError, setLimitError] = React.useState(false); //
  const [period, setPeriod] = React.useState(false); // activer un periode de validité
  const [periodError, setPeriodError] = React.useState(false);
  const [reductionType, setReductionType] = React.useState('fixe'); // reduction type
  const [quantiteGenerer, setQuantiteGenerer] = React.useState(0); // reduction type

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // generation des codes
  const generateCode = () => {
    if (quantiteGenerer !== 0 && quantiteGenerer < 50) {
      const i = quantiteGenerer;
      for (let index = 0; index < i; index++) {
        const code = randomstring.generate({ length: 6, charset: 'numeric' });
        const f = format(new Date(), 'dd-MM-yy');
        const tmp = {
          nomCode: `${f}-Q${code}`,
          codeSaisir: `Q${code}`,
          utilisation: '1',
        };
        setCodePromo((codePromos) => [...codePromos, tmp]);
      }
      setOpenGenerate(false);
    }
  };

  // saisir des codes
  const saisiCode = () => {
    const tmp = {
      nomCode: nomDuCode,
      codeSaisir: codeASaisir,
      utilisation: nbUtilisation,
    };
    setCodePromo((codePromos) => [...codePromos, tmp]);

    console.log(codePromos);
    setOpenCode(false);
  };

  const handleLimited = () => {
    setLimit((prev) => !prev);
  };
  const handlePeriod = () => {
    setPeriod((prev) => !prev);
  };

  // creer nos codes de promotion
  const handleSubmit = async (e) => {
    e.preventDefault();
    setCodePromoError(false);
    setReductionError(false);
    setLimitError(false);
    setPeriodError(false);
    let tmp = false;
    console.log('reduction ', reduction);
    const d = Date.now();
    // handleToggle();
    const tb = []; // tableau des billets selectionnes

    for (let index = 0; index < checks.length; index++) {
      const element = checks[index];
      if (element) {
        tb.push(tickets[index].id);
      }
    }
    if (codePromos.length === 0) {
      setCodePromoError(true);
      setMsg({
        content: 'Veuillez genérer automatiquemment des codes promoou saisir manuellemment vos codes',
        color: 'red',
      });
      setOpenSnack(true);
      tmp = true;
    }
    if (reduction === 0) {
      setReductionError(true);
      tmp = true;
    }
    if (limitedT && tb.length === 0) {
      setLimitError(true);
      tmp = true;
    }
    console.log('fin :', finField);
    if (period && (!finField || !debutField)) {
      setPeriodError(true);
      console.log('fin :');
      tmp = true;
    }
    if (tmp) {
      return;
    }
    try {
      // handleToggle();
      const tb = []; // tableau des billets selectionnes
      for (let index = 0; index < checks.length; index++) {
        const element = checks[index];
        if (element) {
          tb.push(tickets[index].id);
        }
      }
      console.log('checks : ', tb);

      if (debutField && finField) {
        // verification de la periode
        for (let index = 0; index < codePromos.length; index++) {
          const element = codePromos[index];
          await addDoc(collection(db, `evenement/${eventID}/codePromo`), {
            // creationDate: name,
            code: element.codeSaisir,
            reduc: { type: reductionType, value: reduction },
            statut: 1,
            usage: { max: element.utilisation, now: 0 },
            billet: tb,
            nom_code: element.nomCode,
            debut: Timestamp.fromDate(new Date(debutField)),
            fin: Timestamp.fromDate(new Date(finField)),
            createDate: Timestamp.fromDate(new Date(d)),
          });
        }
      } else if (debutField) {
        for (let index = 0; index < codePromos.length; index++) {
          const element = codePromos[index];
          console.log('elemnt2 ', element);
          await addDoc(collection(db, `evenement/${eventID}/codePromo`), {
            // creationDate: name,
            code: element.codeSaisir,
            reduc: { type: reductionType, value: reduction },
            statut: 1,
            usage: { max: element.utilisation, now: 0 },
            billet: tb,
            nom_code: element.nomCode,
            debut: Timestamp.fromDate(new Date(debutField)),
            createDate: Timestamp.fromDate(new Date(d)),
          });
        }
      } else if (finField) {
        for (let index = 0; index < codePromos.length; index++) {
          const element = codePromos[index];
          console.log('elemnt3 ', element);
          await addDoc(collection(db, `evenement/${eventID}/codePromo`), {
            // creationDate: name,
            code: element.codeSaisir,
            reduc: { type: reductionType, value: parseInt(reduction) },
            statut: 1,
            usage: { max: element.utilisation, now: 0 },
            billet: tb,
            nom_code: element.nomCode,
            debut: Timestamp.fromDate(Date.now()),
            fin: Timestamp.fromDate(new Date(finField)),
            createDate: Timestamp.fromDate(new Date(d)),
          });
        }
      } else {
        for (let index = 0; index < codePromos.length; index++) {
          const element = codePromos[index];
          console.log('elemnt4 ', element);
          await addDoc(collection(db, `evenement/${eventID}/codePromo`), {
            // creationDate: name,
            code: element.codeSaisir,
            reduc: { type: reductionType, value: parseInt(reduction) },
            statut: 1,
            usage: { max: parseInt(element.utilisation), now: 0 },
            billet: tb,
            nom_code: element.nomCode,
            createDate: Timestamp.fromDate(new Date(d)),
          });
        }
      }
      setMsg({ content: 'Le code a été créé avec succès', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      console.log('erro');
      console.log(error.message);
      setMsg({ content: 'une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    navigate(-1, { replace: true });
  };

  // chargement
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // gestion du radio boutton permettant de selectionner le type de reduction
  const handleRadioChange = (event) => {
    setReductionType(event.target.value);
    console.log(reductionType);
  };

  // gestion du checkBox
  const handleCheckChange = (e) => {
    const tmp = checks;
    console.log('salut');
    console.log(e);
    tmp.splice(e, 1, !checks[e]);
    setCheck(tmp);
    console.log(checks);
  };

  // gestion de la saisir de la reduction
  const handleReduction = (e) => {
    setReductionError(false);
    if (reduction < 0) {
      setReductionError(true);
    }
    if (reductionType === 'percent' && reduction > 100) {
      setReduction(100);
    } else {
      setReduction(e);
    }
  };

  // generate code
  const [openGenerate, setOpenGenerate] = React.useState(false);
  const handleOpenGenerate = () => setOpenGenerate(true);
  const handleCloseGenerate = () => setOpenGenerate(false);

  // saisir code
  const [openCode, setOpenCode] = React.useState(false);
  const handleOpenCode = () => setOpenCode(true);
  const handleCloseCode = () => setOpenCode(false);

  const [advancedConfig, setAdvancedConfig] = React.useState(false);

  return (
    <Page title="Code de promotion">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ajouter un code de promotion
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Card>
            {/* saisir ou generation de de code */}
            <Box sx={{ p: 3 }} dir="ltr">
              <Grid alignItems="center" container spacing={3} justifyContent="space-between">
                <Grid item xs={10} sm={6} md={6}>
                  <Typography variant="h6">
                    Code(s) de promotion
                    <br />
                    <Typography variant="body2">
                      Saisissez vos propres codes, copiez-collez les, ou générez des codes aléatoires.
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ textAlign: { xs: 'start', sm: 'end' } }}>
                  <CustomButton
                    type='primaire'
                    buttonText='Générer des coodes'
                    onClick={handleOpenGenerate}
                  />

                  {/* Modal generation de code */}
                  <Modal
                    open={openGenerate}
                    onClose={handleCloseGenerate}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography variant="h6">Generer des code de promotion aleatoires</Typography>
                      <Box sx={{ marginTop: 5, backgroundColor: 'gainsboro', p: 2 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Typography>Quantite à generer (50 max)</Typography>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            {quantiteGenerer > 50 ? (
                              <TextField
                                label="Entrer la quantité à générer"
                                type="number"
                                value={quantiteGenerer}
                                onChange={(e) => setQuantiteGenerer(e.target.value)}
                                fullWidth
                                required
                                helperText="entrer une quantite inferieur ou egale a 50"
                              />
                            ) : (
                              <TextField
                                label="Entrer la quantité à générer"
                                type="number"
                                value={quantiteGenerer}
                                onChange={(e) => setQuantiteGenerer(e.target.value)}
                                fullWidth
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid container spacing={2} justifyContent="start" paddingTop={5}>
                        <Grid item>
                          <Button
                            variant="outlined"
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              color: 'black',
                              backgroundColor: 'white',
                              borderRadius: '7px',
                              borderColor: 'black',
                              '&:hover': {
                                backgroundColor: 'black',
                                color: 'white',
                                borderColor: 'black',
                              },
                            }}
                            onClick={handleCloseGenerate}
                          >
                            Annuler
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              Color: 'white',
                              backgroundColor: '#5E17EB',
                              borderRadius: '7px',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: '#5E17EB',
                                borderColor: '#5E17EB',
                              },
                            }}
                            onClick={generateCode}
                          >
                            Generer
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Modal>
                </Grid>
              </Grid>

              <Box sx={{ p: 2 }} dir="ltr">
                <Grid paddingY={1} alignItems="center" container spacing={3}>
                  {codePromosError && (
                    <Alert severity="error">
                      Veuillez genérer automatiquemment des codes promo avec bouton{' '}
                      <strong>Générer automatiquemment</strong> ou saisir manuellemment vos codes avec bouton{' '}
                      <strong>Saisissez vos propres codes</strong>
                    </Alert>
                  )}
                  {codePromos.length !== 0 && (
                    <Grid item xs={12} sm={10} md={10}>
                      <Scrollbar>
                        <TableContainer sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                          <Table sx={{ minWidth: 250 }} aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">{''}</TableCell>
                                <TableCell align="center">Non du code</TableCell>
                                <TableCell align="center">Code à saisir (obligatoire)</TableCell>
                                <TableCell align="center">Nombre d'utilisation (0 = illimité)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {codePromos.map((promo, index) => (
                                <TableRow key={promo.nomCode}>
                                  <TableCell align="center">{index + 1}</TableCell>
                                  <TableCell align="center" component="th" scope="row">
                                    {promo.nomCode}
                                  </TableCell>
                                  <TableCell align="center">{promo.codeSaisir}</TableCell>
                                  <TableCell align="center">{promo.utilisation}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell align="center">{codePromos.length + 1}</TableCell>
                                <TableCell align="center" component="th" scope="row">
                                  {''}
                                </TableCell>
                                <TableCell align="center">{''}</TableCell>
                                <TableCell align="center">{''}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Scrollbar>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={12}>
                    <Button
                      variant="text"
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'white',
                        backgroundColor: 'black',
                        borderRadius: '7px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: 'black',
                          borderColor: 'black',
                        },
                      }}
                      onClick={handleOpenCode}
                    >
                      Saisissez vos propres codes
                    </Button>
                    {/* modal saisir de code */}
                    <Modal
                      open={openCode}
                      onClose={handleCloseCode}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      sx={{ borderRadius: '7px' }}
                    >
                      <Box sx={style}>
                        <Typography variant="h6">Saisissez votre propre code de promotion</Typography>
                        <Box sx={{ marginTop: 5, backgroundColor: 'gainsboro', p: 2 }} dir="ltr">
                          <Grid paddingY={1} alignItems="center" container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography>Nom du code</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={8}>
                              <TextField
                                label="Entrer le nom du code"
                                type="text"
                                value={nomDuCode}
                                onChange={(e) => setNom(e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                          <Grid paddingY={1} alignItems="center" container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography>code à saissir</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={8}>
                              <TextField
                                label="Entrer le code à saisir"
                                type="text"
                                value={codeASaisir}
                                onChange={(e) => setASaisir(e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                          <Grid paddingY={1} alignItems="center" container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography>nombre d'utilisation</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={8}>
                              <TextField
                                label="Entrer le nombre d'utilisation"
                                type="text"
                                value={nbUtilisation}
                                onChange={(e) => setNb(e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container spacing={2} justifyContent="start" paddingTop={5}>
                          <Grid item>
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: 'initial',
                                border: '2px solid transparent',
                                color: 'black',
                                backgroundColor: 'white',
                                borderRadius: '7px',
                                borderColor: 'black',
                                '&:hover': {
                                  backgroundColor: 'black',
                                  color: 'white',
                                  borderColor: 'black',
                                },
                              }}
                              onClick={handleCloseCode}
                            >
                              Annuler
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: 'initial',
                                border: '2px solid transparent',
                                Color: 'white',
                                backgroundColor: '#5E17EB',
                                borderRadius: '7px',
                                '&:hover': {
                                  backgroundColor: 'white',
                                  color: '#5E17EB',
                                  borderColor: '#5E17EB',
                                },
                              }}
                              onClick={saisiCode}
                            >
                              Enregister
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Modal>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* Type de reduction */}
            <Box sx={{ p: 3 }} dir="ltr">
              <Grid alignItems="center" container spacing={3}>
                <Grid item xs={10} sm={4} md={4}>
                  <Typography variant="h6">Type de réduction </Typography>
                </Grid>
              </Grid>

              <Box sx={{ backgroundColor: '#f2f2f2', p: 2, borderRadius: '7px' }} dir="ltr">
                <Alert severity="info">
                  ce code permettra d'avoir une reduction de{' '}
                  <strong>
                    {reductionType === 'fixe'
                      ? `${reduction} CFA`
                      : reductionType === 'percent'
                        ? `${reduction} %`
                        : ''}{' '}
                  </strong>{' '}
                  sur les billets
                </Alert>
                <FormControl fullWidth>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    value={reductionType}
                    onChange={handleRadioChange}
                  >
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={10} sm={4} md={4}>
                        <Typography>Réduction fixe en CFA</Typography>
                      </Grid>
                      <Grid item xs={2} sm={8} md={8}>
                        <FormControlLabel value="fixe" control={<Radio />} />
                      </Grid>
                    </Grid>
                    {/* formulaire reduction fixe */}
                    {reductionType === 'fixe' && (
                      <Box sx={{ p: 3, bgcolor: 'gainsboro', borderRadius: '7px' }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Montant de la réduction</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={4}>
                            <TextField
                              label="montant de la reduction"
                              required
                              name="nom"
                              type="number"
                              value={reduction}
                              onChange={(e) => handleReduction(e.target.value)}
                              error={reductionError}
                              helperText={reduction < 0 || reductionError ? 'veuillez saisir un valeur valide' : ''}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={10} sm={4} md={4}>
                        <Typography>Reduction en pourcentage</Typography>
                      </Grid>
                      <Grid item xs={2} sm={8} md={8}>
                        <FormControlLabel value="percent" control={<Radio />} />
                      </Grid>
                    </Grid>
                    {/* formulaire pourcentage */}
                    {reductionType === 'percent' && (
                      <Box sx={{ p: 3, bgcolor: 'gainsboro', borderRadius: '7px' }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Montant de la réduction</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={4}>
                            <TextField
                              label="montant de la reduction"
                              required
                              name="nom"
                              type="number"
                              value={reduction}
                              onChange={(e) => handleReduction(e.target.value)}
                              error={reductionError}
                              helperText={reduction < 0 || reductionError ? 'veuillez saisir un valeur valide' : ''}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} dir="ltr">
              <Button
                variant="text"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: '#5E17EB',
                  backgroundColor: 'transparent',
                  borderRadius: '7px',
                  borderColor: 'transparent',
                  '&:hover': {
                    color: '#5E17EB',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                  },
                }}
                onClick={() => setAdvancedConfig(!advancedConfig)}
              >
                Configuration avancée
              </Button>
              <Iconify icon="icon-park-solid:down-one" sx={{ color: '#5E17EB' }} />
            </Box>
            {advancedConfig && (
              <>
                {/* choix du Ticket */}
                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={10} sm={4} md={4}>
                      <Typography variant="h6">Tickets</Typography>
                    </Grid>
                  </Grid>

                  <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                    {limitedT && <Alert severity="warning">ce code de reduction sera limité au ticket suivant</Alert>}
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={10} sm={4} md={4}>
                        <Typography> Limiter à certains tickets</Typography>
                      </Grid>
                      <Grid item xs={2} sm={8} md={8}>
                        <FormControlLabel control={<Switch checked={limitedT} onChange={handleLimited} />} />
                      </Grid>
                    </Grid>
                    {limitedT && (
                      <Box sx={{ p: 3, bgcolor: 'gainsboro', borderRadius: '7px' }} dir="ltr">
                        {limitedTError && <Alert severity="error">Veuillez selectionnez au moins un billet</Alert>}
                        <FormGroup>
                          {tickets.map((ticket, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                checks[index] ? (
                                  <Checkbox checked onClick={() => handleCheckChange(index)} />
                                ) : (
                                  <Checkbox onClick={() => handleCheckChange(index)} />
                                )
                              }
                              label={ticket.data().ticket_name}
                            />
                          ))}
                        </FormGroup>
                      </Box>
                    )}
                  </Box>
                </Box>

                {/* periode de validite */}
                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={10} sm={4} md={4}>
                      <Typography variant="h6">Période de validité </Typography>
                    </Grid>
                  </Grid>

                  <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                    {period && <Alert severity="warning">ce code de reduction aura une periode validite</Alert>}
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={10} sm={4} md={4}>
                        <Typography>
                          Définir une période <br />
                          <Typography variant="caption">Par defaut : pour toute l'année</Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sm={8} md={8}>
                        <FormControlLabel control={<Switch checked={period} onChange={handlePeriod} />} />
                      </Grid>
                    </Grid>
                    {period && (
                      <Box sx={{ p: 3, bgcolor: 'gainsboro', borderRadius: '7px' }} dir="ltr">
                        {periodError && <Alert severity="error">Definissez une periode de validité</Alert>}
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Date debut</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={4}>
                            <TextField
                              label="Entrer la date de debut"
                              id="datetime-local"
                              type="datetime-local"
                              value={debutField}
                              onChange={(e) => {
                                setDebut(e.target.value);
                              }}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Date de fin</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={4}>
                            <TextField
                              label="Entrer la date de fin"
                              id="datetime-local"
                              type="datetime-local"
                              value={finField}
                              onChange={(e) => {
                                setFin(e.target.value);
                              }}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}

            <Box sx={{ p: 3 }} dir="ltr">
              <Grid container spacing={1}>
                <Grid item xs={6} sm={2} md={2}>
                  <Button
                    fullWidth
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '7px',
                      borderColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'black',
                      },
                    }}
                    variant="outlined"
                    onClick={() => window.history.back()}
                  >
                    Annuler
                  </Button>
                </Grid>
                <Grid item xs={6} sm={2} md={2}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      Color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    type="submit"
                  >
                    Enregister
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </form>
        <Snackbar
          open={openSnack}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          message={
            <Box>
              {msg.content}{' '}
              <Iconify
                sx={{ color: msg.color }}
                icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
              />
            </Box>
          }
        />
      </Container>
    </Page>
  );
}

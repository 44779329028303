/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import { faker } from '@faker-js/faker';
// @mui
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Alert,
} from '@mui/material';

// components

import { collection, doc, Firestore, getDoc, getDocs, query, Timestamp, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import Confetti, { ReactConfetti } from 'react-confetti';
import { db, storage } from '../firebase.config';

import Page from '../components/Page';
import Iconify from '../components/Iconify';
import CustomButton from '../components/CustomButton';
import CheckCard from '../components/CheckCard';

// sections

// ----------------------------------------------------------------------

export default function Publish() {
  // rich text
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const affiche = () => {
    console.log('salut');
    console.log(editorState.getCurrentContent());
  };
  const theme = useTheme();
  const { eventID } = useParams();
  const [events, setEvents] = React.useState([]);
  const [description, setDescription] = React.useState([]);
  const [desc, setDesc] = React.useState('');
  const [tickets, setTickets] = React.useState([]);
  const [operateurs, setOperateurs] = React.useState([]);
  const [visibilite, setVisibilite] = React.useState(0);
  const [publiée, setPublish] = React.useState(false);
  const navigate = useNavigate();

  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);

  const setVisible = () => {
    setVisibilite(0);
  };

  const setPrivate = () => {
    setVisibilite(1);
  };

  React.useEffect(() => {
    (async () => {
      try {
        setEvents([]);
        setTickets([]);
        setLoading(true);

        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);

        if (docSnap.exists()) {
          createEventList(docSnap.data());
          // recupération des tickets
          const q = query(collection(db, `evenement/${docSnap.id}/tickets`));
          const snapshot = await getDocs(q);
          snapshot.forEach((d) => {
            console.log(d.data());
            setTickets((tickets) => [...tickets, d.data()]);
          });

          // recupération des operateurs

          // recupération des seances
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [refreh]);

  // upload functions

  const [selectedImage, setSelectedImage] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  // --------------------

  // loading
  const [loading, setLoading] = React.useState(false);

  // convert timestamp

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };

  // fonction de mise à jour

  const handleToggle = () => {
    setOpen(!open);
  };

  const handlePublication = async () => {
    try {
      setLoading(true);
      if (tickets.length !== 0) {
        const refEvent = doc(db, `evenement`, eventID);
        const docSnap = await getDoc(refEvent);
        if (docSnap.exists()) {
          await updateDoc(refEvent, {
            status: parseInt(1),
            visibility: parseInt(visibilite),
          });
          setPublish(!publiée);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <Page title="Publication">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      {events.map((event) =>
        !publiée ? (
          <Container key={event.nomEvent} sx={{ marginTop: { xs: 3 } }}>
            <CheckCard />
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Publication de votre événement
                <br />
                <Typography variant="body2">
                  Vous êtes à un clic de publier votre événement !Prenez le temps de vérifier les éléments suivants,
                  <br /> vous pourrez quasiment tous les modifier par la suite.
                </Typography>
              </Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
              <CustomButton
                type='secondaire'
                buttonText='Retour'
                startIcon={<Iconify icon='ion:arrow-back-outline' sx={{ width: 20, height: 20 }} />}
                onClick={() => {
                  navigate(`/${eventID}/dashboard/app`, { replace: true });
                }}
              />
              <Stack spacing={1} direction='row'>
                <CustomButton
                  type='primaire'
                  backgroundColor="black"
                  color="#fff"
                  buttonText='Planifier'
                  startIcon={<Iconify icon='ic:baseline-schedule' sx={{ width: 20, height: 20 }} />}
                  onClick={() => {
                    navigate(`/${eventID}/dashboard/app`, { replace: true });
                  }}
                />
                <CustomButton
                  type='primaire'
                  backgroundColor="black"
                  color="#fff"
                  buttonText='Publier'
                  onClick={handlePublication}
                />
              </Stack>

            </Stack>

            <Grid direction={{ xs: 'column-reverse', sm: 'row' }} container padding={2} paddingY={5} spacing={5}>
              <Grid item xs={12} sm={6}>
                <Box sx={{ height: '100%', padding: 2, borderRadius: '10px', bgcolor: '#f2f2f2' }}>
                  <Typography variant="h5">{event.nomEvent}</Typography>

                  <Typography variant="body2">
                    {event.type}
                  </Typography>

                  <Stack spacing={2}>
                    <Typography variant='body1'>

                      {format(new Date(handleTimestamp(event.dateDebut)), 'EEE dd MMM yyyy HH:mm', { locale: fr })} -{' '}
                      {format(new Date(handleTimestamp(event.dateFin)), 'EEE dd MMM yyyy hh:mm', { locale: fr })}{' '}

                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <Iconify
                        icon="material-symbols:location-on-outline"
                        sx={{ width: 20, height: 20, alignItems: 'center' }}
                      />
                      <Typography variant='body1'>
                        {event.lieu_event}, {event.city}, {event.country}{' '}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '7px',
                      borderColor: 'black',
                      '&:hover': {
                        backgroundColor: 'black',
                        color: 'white',
                        borderColor: 'black',
                      },
                    }}
                    startIcon={<Iconify icon="material-symbols:arrow-back-ios" />}
                    onClick={() => navigate(`/${eventID}/dashboard/app`, { replace: true })}
                  >
                    Aperçu
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardMedia component="img" height={300} image={`${event.idVisuel}`} alt="green iguana" />
                </Card>
              </Grid>
            </Grid>
            <Box sx={{ height: '100%', padding: 2, borderRadius: '10px', bgcolor: '#f2f2f2', marginX: 2 }}>
              <Typography variant="h5">Billets</Typography>
              <Grid paddingTop={2} container >
                <Grid item xs={6} sm={4} sx={{ padding: 3, borderRadius: '10px', bgcolor: 'gainsboro' }}>
                  <Typography> billet en vente</Typography>
                </Grid>
              </Grid>
              <Stack spacing={1} marginY={2} sx={{ padding: 2, borderRadius: '10px', bgcolor: 'gainsboro' }}>
                <Typography variant='body2'>
                  Un mail sera envoyé à vos partenaires de vente
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ height: '100%', padding: 2, borderRadius: '10px', bgcolor: '#f2f2f2', margin: 2 }}>
              <Typography variant="h5">Visibilité</Typography>
              <Typography variant="body2">Qui peut voir mon evenement</Typography>
              <Stack direction='row' spacing={1} marginY={2}>
                <Box
                  onClick={setVisible}
                  component="button"
                  padding={1}
                  color={visibilite === 0 ? '#7E1F86' : null}
                  sx={visibilite === 0 ? {
                    cursor: 'pointer',
                    borderRadius: '5px',
                    fontSize: 10,
                    border: '0.5px solid #B991DE',
                  } : {
                    cursor: 'pointer',
                    borderRadius: '5px',
                    fontSize: 10,
                    border: '0.5px solid gainsboro',
                  }}
                >
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Iconify icon='ic:outline-public' sx={{ height: 20, width: 20 }} />
                    <Typography variant='body2'><strong>Public</strong></Typography>
                  </Stack>

                </Box>
                <Box
                  onClick={setPrivate}
                  component="button"
                  padding={1}
                  color={visibilite === 1 ? '#7E1F86' : null}
                  sx={visibilite === 1 ? {
                    cursor: 'pointer',
                    borderRadius: '5px',
                    fontSize: 10,
                    border: '0.5px solid #B991DE',
                  } : {
                    cursor: 'pointer',
                    borderRadius: '5px',
                    fontSize: 10,
                    border: '0.5px solid gainsboro',
                  }}
                >
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Iconify icon='mdi:lock' sx={{ height: 20, width: 20 }} />
                    <Typography variant='body2'><strong>Privé</strong></Typography>
                  </Stack>
                </Box>
              </Stack>
              <Typography variant='body2'>{visibilite === 0 ? 'Tout le monde peut voir votre évènement. Les évènements publics sont visibles sur Choptonticket (site web & appli)' : 'Seules les personnes ayant le lien vers votre évènement pourront le voir.'}</Typography>
            </Box>


            {/* ----------------------------------------------------- */}

            <Box sx={{ p: 3 }} dir="ltr">
            <Stack spacing={1} direction='row' justifyContent='end'>
                <CustomButton
                  type='primaire'
                  backgroundColor="black"
                  color="#fff"
                  buttonText='Planifier'
                  startIcon={<Iconify icon='ic:baseline-schedule' sx={{ width: 20, height: 20 }} />}
                  onClick={() => {
                    navigate(`/${eventID}/dashboard/app`, { replace: true });
                  }}
                />
                <CustomButton
                  type='primaire'
                  backgroundColor="black"
                  color="#fff"
                  buttonText='Publier'
                  onClick={handlePublication}
                />
              </Stack>
            </Box>
          </Container>
        ) : (
          <Container key={event.nomEvent} sx={{ marginTop: { xs: 3 } }}>

            <Grid
              container
              padding={2}
              paddingY={5}
              spacing={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <Card sx={{ padding: 2 }}>
                  <Box padding={5} display="flex" alignItems="center" justifyContent="center">
                    <Iconify icon="el:ok-sign" sx={{ width: 100, height: 100, alignItems: 'center', color: 'green' }} />
                  </Box>
                  <Typography fontSize={20}>
                    Félicitation votre événement a été publié sur tous les canaux (app Mobile et site web).{' '}
                  </Typography>
                  <Typography fontSize={20}>
                    Vous pour le modifier a n'importe qu'elle moment depuis votre tableau de bord.
                  </Typography>
                </Card>
              </Grid>
            </Grid>

            {/* ----------------------------------------------------- */}

            <Box sx={{ p: 1 }} dir="ltr">
              <Grid container spacing={3} display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} md={6}>
                  <CustomButton
                    fullWidth
                    backgroundColor="#5E17EB"
                    color="#fff"
                    type='primaire'
                    buttonText={<Typography>Retourner au tableau de bord</Typography>}
                    onClick={() => {
                      navigate(`/${eventID}/dashboard/app`, { replace: true });
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ p: 1 }} dir="ltr">
              <Grid container spacing={3} display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} md={6}>
                  <CustomButton
                    fullWidth
                    backgroundColor="black"
                    color="#fff"
                    type='primaire'
                    buttonText={<Typography>Voir votre evenement</Typography>}
                    onClick={() => {
                      navigate(`/${eventID}/dashboard/app`, { replace: true });
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Container>
        )
      )}
    </Page>
  );
}

/* import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box, Tabs, Tab, Grid } from '@mui/material';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections

import BasicTabs from '../components/home/BasicTabs';
import HomeNavbar from '../layouts/home/HomeNavbar';
import AcceuilNavbar from '../layouts/home/AcceuilNavbar';




// ---------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: `calc(100%*0.7)`,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
    alignItems: 'center',
    position: 'absolute',
}));

const HeroStyle = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function Acceuil() {
    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');
    const [open, setOpen] = useState(false);



    return (
        <Page title="Back office">

            <RootStyle>
                <AcceuilNavbar onOpenSidebar={() => setOpen(true)} />
                <HeroStyle>
                    {
                        !smUp && (
                            <>
                            <Box sx={{ backgroundColor: 'violet' }}>
                                <img width='100%' src='https://ayanawebzine.com/wp-content/uploads/2016/12/events-heavenly-header.jpg' alt='' />
                            </Box>

                            <ContentStyle>
                                text
                            </ContentStyle></>

                        )
                    }
                    {
                        mdUp && (
                            <>
                                <Box sx={{ backgroundColor: 'violet' }}>
                                    <img width='100%' src='https://ayanawebzine.com/wp-content/uploads/2016/12/events-heavenly-header.jpg' alt='' />
                                </Box>

                                <ContentStyle>
                                    text
                                </ContentStyle></>

                        )
                    }

                </HeroStyle>

            </RootStyle>
        </Page>
    );
} */

import React from 'react';
import './acceuilStyle.css';
import 'antd/dist/antd.css';

import { Layout } from 'antd';
import AppHeader from '../components/home/header';
// import AppFooter from '../components/home/footer';
import AppHome from '../layouts/home';


const { Header, Content, Footer } = Layout;

function Acceuil() {
  return (
    <Layout className="mainLayout">
      <Header>
        <AppHeader/>
      </Header>
      <Content>
        <AppHome/>
      </Content>     
    </Layout>
  );
}

export default Acceuil;
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Link,
  Container,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
  Grid,
  Checkbox,
  Select,
  MenuItem,
} from '@mui/material';
// hooks
// components
// sections
import { useForm, useFrom } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { addDoc, doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { parseInt } from 'lodash';
import { UserAuth } from '../context/AuthContext';
import Iconify from '../components/Iconify';
import { RHFTextField, FormProvider, RHFCheckbox } from '../components/hook-form';
import AuthSocial from '../sections/auth/AuthSocial';
import { RegisterForm } from '../sections/auth/register';
import Logo from '../components/Logo';
import Page from '../components/Page';
import useResponsive from '../hooks/useResponsive';
import { db } from '../firebase.config';
import RHFSelect from '../components/hook-form/RHFSelect';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  background: 'linear-gradient(217deg, #5516CA, rgba(255,0,0,0) 100.71%), linear-gradient(127deg, #e66465, rgba(0,255,0,0) 60.71%), linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%)',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterOrg() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  // *******************************************************************

  const { user, signUp, signUpSecondStep } = UserAuth();
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [id, setId] = useState();
  const [certifie, setCert] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const RegisterOrgSchema = Yup.object().shape({
    statut: Yup.number().required('Le statut juridique est obligatoire'),
    nom: Yup.string().required("Le nom de l'organisation est obliagtoire"),
    ville: Yup.string().required('La ville est obligatoire'),
    pays: Yup.string().required('Le pays est obligatoire '),
    tel: Yup.number().optional(),
    confirmed: Yup.boolean().required('chan'),
  });

  const defaultValues = {
    nom: '',
    ville: '',
    pays: '',
    confirmed: true,
    tel:0
  };

  const methods = useForm({
    resolver: yupResolver(RegisterOrgSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (e) => {
    console.log(e.statut);
    console.log(e.nom);
    console.log(e.ville);
    console.log(e.pays);
    const statRef = doc(db, `organisations`, `${id}`);
   try {
      await updateDoc(statRef, {
        type: parseInt(e.statut),
        nomOrg: e.nom,
        updateDate: serverTimestamp(),
        certified: true,
      });

      // informations sur le contact
      const refCoord = doc(db, `organisations/${id}/coordonnee`, `${id}`);

      await setDoc(refCoord, {
        city: e.ville,
        country: e.pays,
        tel: e.tel,
      });
      navigate('/bo/new_event', { replace: true }); 
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
    // navigate('/bo', { replace: true });
  };

  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log('idog', docSnap.data().idOrg);
        if (docSnap.exists()) {
          const q = doc(db, 'organisations', docSnap.data().idOrg);
          const querySnapshot = await getDoc(q);
          if (querySnapshot.exists()) {
            setId(querySnapshot.id);
            console.log('org:', querySnapshot.id);
          }
          // setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user.uid]);

  // *****************************************************************************

  return (
    <Page title="Inscription">
      <RootStyle>
        <HeaderStyle>
          <Logo />
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Stack justifyContent='center' alignItems='center' paddingX={10} sx={{ }}>
              <Typography paddingBottom={5} variant='h3' sx={{color: 'white'}}>Sell your event better, faster, stronger.</Typography>
              <Typography textAlign='center' sx={{color: 'whitesmoke'}} fontSize={20}>
                Nous facilitons la vente de billets et le développement de vos communautés, afin que vous puissiez vous
                concentrer sur la création d’évènements exceptionnels.
              </Typography>
            </Stack>
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Informations de l'organisation
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Veillez renseigner les champs ci-dessous</Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <RHFSelect name="statut" placeholder="salut">
                  <MenuItem>Statut Juridique</MenuItem>
                  <MenuItem value={0}>Particulier</MenuItem>
                  <MenuItem value={1}>Entreprise</MenuItem>
                  <MenuItem value={2}>Association</MenuItem>
                </RHFSelect>
                <RHFTextField name="nom" label="Nom de l'organisation" /> 
                <RHFTextField name="ville" label="Ville" />
                <RHFTextField name="pays" label="Pays" />
                <RHFTextField name="tel" label="Téléphone" placeholder="0758632569" />
                <RHFCheckbox name="confirmed" label="je certifie sur l'honneur l'exactitude des informations ci-dessus." />

                <Typography>{console.log()}</Typography>

                <LoadingButton
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: '#5E17EB',
                    },
                  }}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Continuez
                </LoadingButton>
              </Stack>
            </FormProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
// material
import {
  Stack,
  Checkbox,
  Typography,
  Box,
  LinearProgress,
  Backdrop,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextareaAutosize,
  TextField,
  Grid,
  Button,
  alpha,
  Menu,
  styled,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
  Paper,
} from '@mui/material';
// components
import { SketchPicker } from 'react-color';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import { db } from '../../../firebase.config';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 7,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export default function OwnSite() {
  const { eventID } = useParams();
  // loading
  const [loading, setLoading] = React.useState(false);

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const [backgroundColor, setBackgroundColor] = useState('ffffff');
  const [textColor, setTextColor] = useState('000000');
  const [primaryColor, setPrimaryColor] = useState('000000');
  const [secondaryColor, setSecondaryColor] = useState('000000');
  const [customIndex, setCustom] = useState(0);
  const [embedParams, setEmbedParams] = useState('');
  const [colors, setColors] = useState({ bg: "", primaire: "#5E17EB", secondaire: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
    if (event.target.id === 'bt-fdColor') {
      setCustom(1)
    } else if (event.target.id === 'bt-primaire') {
      setCustom(2)
    } else if (event.target.id === 'bt-secondaire') {
      setCustom(3)
    }
  };
  const handleCloseMenu = (e) => {
    setAnchorEl(null);
  };

  function replaceBetweenChars(str, startChar, endChar, replacement) {
    const regex = new RegExp(`${startChar}([^${endChar}]*)${endChar}`, 'g');
    return str.replace(regex, `${startChar}${replacement}${endChar}`);
  }

  const handleBackgroundColorChange = (color) => {
    setBackgroundColor(color.hex.slice(1));
    console.error(color);
    // setColors({bg: color.hex.slice(1), primaire: colors.primaire, secondaire: colors.secondaire})
    if (embedParams.includes('bgColor')) {
      setEmbedParams(replaceBetweenChars(`${embedParams}&`, 'bgColor=', '&', color.hex.slice(1)).slice(0, -1));
    } else if (embedParams === '') {
      setEmbedParams(`bgColor=${color.hex.slice(1)}`);
    } else {
      setEmbedParams(`${embedParams}&bgColor=${color.hex.slice(1)}`);
    }
  };

  const handleTextColorChange = (color) => {
    setTextColor(color.hex);
  };
  const handlePrimaryColorChange = (color) => {
    setPrimaryColor(color.hex.slice(1));
    // setColors({bg: colors.bg, primaire: color.hex.slice(1), secondaire: colors.secondaire})
    if (embedParams.includes('pColor')) {
      setEmbedParams(replaceBetweenChars(`${embedParams}&`, 'pColor=', '&', color.hex.slice(1)).slice(0, -1));
    } else if (embedParams === '') {
      setEmbedParams(`pColor=${color.hex.slice(1)}`);
    } else {
      setEmbedParams(`${embedParams}&pColor=${color.hex.slice(1)}`);
    }
  };
  const handleSecondaryColorChange = (color) => {
    setSecondaryColor(color.hex.slice(1));
    if (embedParams.includes('sColor')) {
      setEmbedParams(replaceBetweenChars(`${embedParams}&`, 'sColor=', '&', color.hex.slice(1)).slice(0, -1));
    } else if (embedParams === '') {
      setEmbedParams(`sColor=${color.hex.slice(1)}`);
    } else {
      setEmbedParams(`${embedParams}&sColor=${color.hex.slice(1)}`);
    }
  };

  const [url, setUrl] = useState('');
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setUrl(`http://localhost:3001/fr/event_embed/${eventID}/${embedParams}`);
        console.log(`http://localhost:3001/fr/event_embed/${eventID}/${embedParams}`);
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh, embedParams]);

  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { gilad, jason, antoine } = state;

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          //
          if (docSnap.data().custom !== null) {
            console.log(docSnap.data().custom)
            setBackgroundColor(docSnap.data().custom.bgColor)
            setPrimaryColor(docSnap.data().custom.pColor)
            setSecondaryColor(docSnap.data().custom.sColor)
            if (docSnap.data().custom.bgColor !== null) {
              setBackgroundColor(docSnap.data().custom.bgColor)
            } else {
              setBackgroundColor('ffff')
            }
            if (docSnap.data().custom.pColor !== null) {
              setPrimaryColor(docSnap.data().custom.pColor)
            } else {
              setPrimaryColor(docSnap.data().custom.pColor)
            }
            if (docSnap.data().custom.sColor !== null) {
              setSecondaryColor(docSnap.data().custom.sColor)
            } else {
              setSecondaryColor(docSnap.data().custom.sColor)
            }
            if (docSnap.data().custom.txtColor !== null) {
              setSecondaryColor(docSnap.data().custom.txtColor)
            } else {
              setSecondaryColor("00000")
            }
          }
        }
      } catch (error) {
        console.log('error');
      }
      setLoading(false)
    })();
  }, [eventID]);

  // save population
  const saveCustom = async () => {
    setLoading(true)
    try {
      const statRef = doc(db, `evenement`, `${eventID}`);
      await updateDoc(statRef, {
        custom: { bgColor: backgroundColor, pColor: primaryColor, sColor: secondaryColor },
      });
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  };
  const isMobile = useMediaQuery('(max-width:600px)');
  const handleCopy = () => {
    navigator.clipboard.writeText(`choptonticket.com/fr/event/${eventID}`)
    // setMsg({ content: 'Lien copié dans le papier presse', color: 'green' });
    // setOpenSnack(true);
  };

  return (
    <Page title="Widget de vente" >
      <Box sx={{ height: 'calc(100vh - 100px)'}}>
        {loading && <LinearProgress />}
        <Grid container columnSpacing={1} sx={{ height: '100%' }}>
          {/* Partie Personnalisation */}
          <Grid item xs={12} md={6} sx={{height: '100%', overflowY: 'auto'}}>

            <Stack direction='row' justifyContent='space-between'>
              <Typography variant="h6">Personnalisation du Widget</Typography>
              <Button
                onClick={saveCustom}
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  Color: 'white',
                  backgroundColor: '#5E17EB',
                  borderRadius: '7px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',
                    borderColor: '#5E17EB',
                  },
                }}
                variant="contained"
              >
                enregistrer
              </Button>
            </Stack>

            <Stack sx={{
              bgcolor: '#f2f2f2',
              borderRadius: '7px',
              border: '1px solid gainsboro',
            }} direction="row" spacing={2} marginTop={5} padding={2} alignItems="center" justifyContent='space-between'>
              <Stack direction="column" spacing={2} justifyContent='center'>
                <Typography textAlign="Start" variant="body2">
                  <strong>Couleur de fond</strong>
                </Typography>
                <Typography textAlign="Start" variant="body2">
                  <strong>Couleur primaire</strong>
                </Typography>
                <Typography textAlign="Start" variant="body2">
                  <strong>Couleur secondaire</strong>
                </Typography>

              </Stack>
              <Stack spacing={1} justifyContent='center'>
                <Box
                  id='bt-fdColor'
                  onClick={handleClick}
                  sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '7px',
                    border: '2px solid gainsboro',
                    cursor: 'pointer',
                    bgcolor: `#${backgroundColor}`,
                  }}
                />
                <Box
                  id='bt-primaire'
                  sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '7px',
                    border: '2px solid gainsboro',
                    cursor: 'pointer',
                    bgcolor: `#${primaryColor}`,
                  }}
                  onClick={handleClick}
                />
                <Box
                  id='bt-secondaire'
                  sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '7px',

                    border: '2px solid gainsboro',
                    cursor: 'pointer',
                    bgcolor: `#${secondaryColor}`,
                  }}
                  onClick={handleClick}
                />
              </Stack>

            </Stack>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
            >
              <SketchPicker
                color={
                  customIndex === 1 ? backgroundColor : customIndex === 2 ? primaryColor : secondaryColor
                }
                onChange={
                  customIndex === 1 ? handleBackgroundColorChange : customIndex === 2 ? handlePrimaryColorChange : handleSecondaryColorChange
                }
              />
            </StyledMenu>

            {/* Texte du bouton */}
            <TextField
              label="Texte du bouton"
              fullWidth
              // value={buttonText}
              // onChange={(e) => setButtonText(e.target.value)}
              sx={{ my: 2 }}
            />

            {/* Forme des boutons */}
            <FormControl fullWidth sx={{ my: 2 }}>
              <InputLabel>Forme du bouton</InputLabel>
              <Select
              // value={buttonRadius}
              // onChange={(e) => setButtonRadius(e.target.value)}
              >
                <MenuItem value="4px">Arrondi</MenuItem>
                <MenuItem value="0px">Carré</MenuItem>
              </Select>
            </FormControl>

            {/* Code du Widget */}
            <Box sx={{padding:2, bgcolor:'#f2f2f2', borderRadius:'7px'}}>
              <Box>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>Code du Widget :</Typography>

                  <Stack onClick={handleCopy} alignItems='center' direction='row' sx={{ cursor: 'pointer', borderRadius: '7px', bgcolor: 'gainsboro', paddingX: 1, paddingY0: 0.5 }} spacing={0.5}>
                    <Iconify icon='bi:copy' />
                    <Typography variant='caption'>Copier</Typography>
                  </Stack>

                </Stack>
              </Box>
              <Box sx={{ bgcolor: 'gainsboro', borderRadius: '7px' }} dir="ltr">
                <Typography>
                  <TextField
                    disabled
                    sx={{ borderRadius: '7px' }}
                    minRows={2}
                    multiline
                    fullWidth
                    value={`<iframe width="100%" height="100%" src="${url}" title="cool" frameBorder={0} />`}
                  />
                </Typography>
              </Box>
              <Typography variant='caption'>
                Copiez et collez ce code à l’endroit où vous souhaitez que le widget apparaisse sur votre page Web
                </Typography>
            </Box>


          </Grid>

          {/* Partie Aperçu */}
          {!isMobile && (
            <Grid item xs={12} md={6} sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <iframe width="100%" height="100%"  src={url} title="wiget" frameBorder={0} />
            </Grid>
          )}
        </Grid>
      </Box>
      {/*
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      <Grid container justifyContent="space-between" spacing={1} >
        <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
          <Box
            sx={{
              borderRadius: '7px',
              padding: 3,
            }}
          >
            <Stack direction='row' justifyContent='space-between'>
              <Typography variant="h4">Widget</Typography>
              <Button
              onClick={saveCustom}
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  Color: 'white',
                  backgroundColor: '#5E17EB',
                  borderRadius: '7px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: 'white',
                    color: '#5E17EB',
                    borderColor: '#5E17EB',
                  },
                }}
                variant="contained"
              >
                enregistrer
              </Button>
            </Stack>

            <Stack sx={{
              bgcolor:'#f2f2f2',
              borderRadius: '7px',
              border: '1px solid gainsboro',
            }} direction="row" spacing={2} marginTop={5} padding={2} alignItems="center" justifyContent='space-between'>
              <Stack direction="column" spacing={2} justifyContent='center'>
                <Typography textAlign="Start" variant="body2">
                  <strong>Couleur de fond</strong>
                </Typography>
                <Typography textAlign="Start" variant="body2">
                  <strong>Couleur primaire</strong>
                </Typography>
                <Typography textAlign="Start" variant="body2">
                  <strong>Couleur secondaire</strong>
                </Typography>
                
              </Stack>
              <Stack spacing={1} justifyContent='center'>
                <Box
                  id='bt-fdColor'
                  onClick={handleClick}
                  sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '7px',
                    border: '2px solid gainsboro',
                    cursor: 'pointer',
                    bgcolor: `#${backgroundColor}`,
                  }}
                />
                <Box
                  id='bt-primaire'
                  sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '7px',
                    border: '2px solid gainsboro',
                    cursor: 'pointer',
                    bgcolor: `#${primaryColor}`,
                  }}
                  onClick={handleClick}
                />
                <Box
                  id='bt-secondaire'
                  sx={{
                    height: '30px',
                    width: '30px',
                    borderRadius: '7px',

                    border: '2px solid gainsboro',
                    cursor: 'pointer',
                    bgcolor: `#${secondaryColor}`,
                  }}
                  onClick={handleClick}
                />
              </Stack>

            </Stack>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
            >
              <SketchPicker
                color={
                  customIndex === 1 ? backgroundColor : customIndex === 2 ? primaryColor : secondaryColor
                }
                onChange={
                  customIndex === 1 ? handleBackgroundColorChange : customIndex === 2 ? handlePrimaryColorChange : handleSecondaryColorChange
                }
              />
            </StyledMenu>

          </Box>
          <Box>
            <Typography paddingBottom={2} variant="body2">
              Copier/coller cet code d'intégration sur votre site:
            </Typography>

            <Box sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
              <Typography>
                <TextField
                  disabled
                  sx={{ borderRadius: '7px' }}
                  minRows={2}
                  multiline
                  fullWidth
                  value={`<iframe width="100%" height="100%" src="${url}" title="cool" frameBorder={0} />`}
                />
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ height: 'calc(100vh - 170px)' }}>
          <Box
            sx={{
              height: '100%',
              borderRadius: '7px',
              border: '2px solid transparent',
              cursor: 'pointer',
              bgcolor: '#f2f2f2',
            }}
          >
            <iframe width="100%" height="100%" src={url} title="wiget" frameBorder={0} />
          </Box>
        </Grid>
      </Grid> */}
    </Page>
  );
}

import * as React from 'react';
import {useEffect} from "react";

import grapesjs from "grapesjs";
import 'grapesjs/dist/css/grapes.min.css'
import websitePlugin from 'grapesjs-preset-webpage';
// import basicBlockPlugin from 'grapesjs-blocks-basic'
// import formPlugin from 'grapesjs-plugin-forms'


function WebBuilder() {

    useEffect(() => {
       grapesjs.init({
         container: '#gjs',
         width: '100%',
         plugins: [websitePlugin],
       })
    },[])

    return (
        <div id="gjs"/>
    );
}

export default WebBuilder;
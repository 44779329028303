/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unneeded-ternary */
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Card,
  Link,
  Container,
  Typography,
  Stack,
  InputAdornment,
  IconButton,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  Box,
  TextField,
} from '@mui/material';
// hooks
// components
// sections
import { useForm, useFrom } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { addDoc, doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';

import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { parseInt } from 'lodash';
import { UserAuth } from '../context/AuthContext';
import Iconify from '../components/Iconify';
import { RHFTextField, FormProvider, RHFCheckbox } from '../components/hook-form';
import AuthSocial from '../sections/auth/AuthSocial';
import { RegisterForm } from '../sections/auth/register';
import Logo from '../components/Logo';
import Page from '../components/Page';
import useResponsive from '../hooks/useResponsive';
import { db } from '../firebase.config';
import RHFSelect from '../components/hook-form/RHFSelect';
import logo1 from '../assets/logo/logo2.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '80vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(5, 0),
}));

// ----------------------------------------------------------------------

export default function ChangePass() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  // *******************************************************************

  const { user, signUp, signUpSecondStep } = UserAuth();

  const navigate = useNavigate();
  const [id, setId] = useState();
  const [mdpA, setMdpA] = useState('');
  const [mdpN, setMdpN] = useState('');
  const [mdpC, setMdpc] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const RegisterOrgSchema = Yup.object().shape({
    email: Yup.string().required('Ce champs est obligatoire'),
    prenom: Yup.string().required('Ce champs est obligatoire'),
    nom: Yup.string().required('Ce champs est obligatoire '),
    tel: Yup.number().optional(),
  });

  const [defaultValues, setDefault] = useState({
    email: 'ze',
    prenom: '',
    nom: '',
  });
  const [methods, setMethods] = useState(
    useForm({
      resolver: yupResolver(RegisterOrgSchema),
      defaultValues,
    })
  );

  const onSubmit = async (e) => {
    setLoading(true);
    try {

        console.log(user)
        
    } catch (error) {
        console.log(error)
    }

    setLoading(false);
  };

  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    (async () => {
      try {
        // setLoading(true);
        
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log('idog', docSnap.data().idOrg);
        if (docSnap.exists()) {
          console.log('start');
          setUserInfo(docSnap.data());

          console.log('start');
          // setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user.uid]);

  const [showPasswordA, setShowPasswordA] = useState(false);
  const [showPasswordN, setShowPasswordN] = useState(false);
  const [showPasswordC, setShowPasswordC] = useState(false);

  // *****************************************************************************

  return (
    <Page title="Mon compte">
      <RootStyle>
        <Container>
          <Box sx={{ paddingTop: 5 }}>
            
            <Link onClick={() => window.history.back()} underline="hover">
            <Stack direction='row' display='flex' alignItems='center'>
            <Iconify icon="material-symbols:arrow-back-ios" />
              retour
              </Stack>
            </Link>
            
            
          </Box>

          <ContentStyle>
            <HeaderStyle>
              <Box sx={{ width: 80, height: 80 }}>
                <img src={logo1} alt="Logo" />
              </Box>
            </HeaderStyle>
            <Typography textAlign="center" paddingBottom={5} paddingTop={1} variant="h4" gutterBottom>
              Modification du mot de passe
            </Typography>

            {userInfo && (
              <Stack spacing={3}>
                <TextField
                  label="Mot de passe actuel"
                  value={mdpA}
                  onChange={(e) => setMdpA(e.target.value)}
                  type={showPasswordA ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPasswordA(!showPasswordA)} edge="end">
                          <Iconify icon={showPasswordA ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Nouveau mot de passe"
                  value={mdpN}
                  onChange={(e) => setMdpN(e.target.value)}
                  error={error && mdpN === '' ? true : false}
                  helperText={error && mdpN === '' ? 'Ce champs est obligatoire' : null}
                  type={showPasswordN ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPasswordN(!showPasswordN)} edge="end">
                          <Iconify icon={showPasswordN ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Confirmation du mot de passe"
                  value={mdpC}
                  onChange={(e) => setMdpc(e.target.value)}
                  error={error && mdpC === '' ? true : false}
                  helperText={error && mdpC === '' ? 'Ce champs est obligatoire' : null}
                  type={showPasswordC ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPasswordC(!showPasswordC)} edge="end">
                          <Iconify icon={showPasswordC ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadingButton
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: '#5E17EB',
                    },
                  }}
                  onClick={() => onSubmit()}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={loading}
                >
                  Enregister
                </LoadingButton>
              </Stack>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}

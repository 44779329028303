import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import { Avatar, Box, Card, Divider, IconButton, Stack, Typography } from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';

import { deepOrange } from '@mui/material/colors';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ContactDrawer({ user, nbEvents, nbTickets, email, nbDepense }) {
  React.useEffect(() => {
    (async () => {
      try {
        console.log(user.email);
        console.log(email);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [user]);

  return (
    <Box>
      <Stack padding={3} spacing={3}>
        <Box sx={{ width: '100%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid transparent',
              bgcolor: '#f2f2f2',
              borderColor: '#f2f2f2',}}>
          <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
            <Avatar sx={{ bgcolor: 'black', width: 70, height: 70 }} src={user ? user?.photoUrl : ''} />
            <Typography variant="h4">{user ? `${user?.nom} ${user?.prenom}` : '-'}</Typography>
            <Typography variant='body2'>{user.email !== undefined ? `${user?.email}` : `${email}`}</Typography>
          </Stack>
        </Box>
        <Stack direction="row" spacing={3} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
          <Box sx={{ width: '50%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid transparent',
              bgcolor: '#f2f2f2',
              borderColor: '#f2f2f2', }}>
            <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
              <Typography><strong>Total évènement</strong></Typography>
              <Typography variant="body1">{nbEvents !== undefined ? nbEvents : 0}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              width: '50%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid transparent',
              bgcolor: '#f2f2f2',
              borderColor: '#f2f2f2',
            }}
          >
            <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
              <Typography> <strong>Total tickets</strong></Typography>
              <Typography variant="body1">{nbTickets !== undefined ? nbTickets : 0}</Typography>
            </Stack>
          </Box>
        </Stack>
        <Box sx={{ width: '50%',
              borderRadius: '7px',
              padding: 3,
              border: '2px solid transparent',
              bgcolor: '#f2f2f2',
              borderColor: '#f2f2f2', }}>
            <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
              <Typography><strong>Total Dépensé</strong></Typography>
              <Typography variant="body1">{nbDepense !== undefined ? nbDepense : 0}</Typography>
            </Stack>
          </Box>
      </Stack>
    </Box>
  );
}

import * as Yup from 'yup';
import { faker } from '@faker-js/faker';
// @mui
import React, { useEffect } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Divider,
  FormControlLabel,
  Switch,
  CardMedia,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Backdrop,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// components
import { LoadingButton, StaticDateTimePicker } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { parseInt } from 'lodash';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';
import { db } from '../../firebase.config';

import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import { FormProvider } from '../../components/hook-form';
import { Variables } from '../../context/VariableContext';
import CustomButton from '../../components/CustomButton';

// Toogle Group Button
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0.5,
    width: 60,
    height: 30,
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
      '&:hover': {
        backgroundColor: '#5E17EB',

      },
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
//-----------------------------------------------------------------------


export default function AddTicket() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { eventID } = useParams();
  const { generalReload, setGeneralReload } = Variables()

  // champs

  // tarif
  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  // montant
  const [montant, setMontant] = React.useState(0);
  const [commission, setCommission] = React.useState(0);
  const [revenu, setRevenu] = React.useState(0);
  const handleMontant = (event) => {
    setMontant(event.target.value);
    if (montant !== 0) {
      if (event.target.value >= 10000) {
        console.log('event sup =', event.target.value);
        console.log('montant =', montant, 'cfa');
        const tmp = event.target.value * 5;

        const tmp1 = tmp / 100;

        setCommission(tmp1);
        setRevenu(event.target.value - tmp1);
      } else {
        console.log('event inf =', event.target.value);
        console.log('montant =', montant, 'cfa');
        const tmp = event.target.value * 7;

        const tmp1 = tmp / 100;

        setCommission(tmp1);
        setRevenu(event.target.value - tmp1);
      }
    } else {
      setRevenu(0);
      setCommission(0);
    }
  };

  // quantité
  const [limitedQt, setLinmitedQt] = React.useState(true);

  const handleLimitedQt = () => {
    setLinmitedQt((prev) => !prev);
  };

  // programmation
  // date / time
  const [dateDebut, setDateDebut] = React.useState(null);
  const [timeDebut, setTimeDebut] = React.useState('');

  const handleDateDebutChange = (newDate) => {
    setDateDebut(newDate);
  };

  const handleTimeDebutChange = (newTime) => {
    console.log('parent')
    setTimeDebut(newTime);
  };
  const [dateFin, setDateFin] = React.useState(null);
  const [timeFin, setTimeFin] = React.useState('');

  const handleDateFinChange = (newDate) => {
    setDateFin(newDate);
  };

  const handleTimeFinChange = (newTime) => {
    setTimeFin(newTime);
  };

  // convert timestamp

  const handleTimestamp = (e) => {
    console.log('handle time stamp')
    const date = e.toDate();
    return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
  };

  const handleTimestampCustom = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.')[0].split('T')[1].split(':');
    return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
  };

  const [salePeriod, setSalePeriod] = React.useState(0);
  const [autoVente, setAutoVente] = React.useState(false);

  // programmation
  const [quantitePerClient, setQuantitePerClient] = React.useState();

  const handleAutoVente = (e) => {
    setAutoVente((prev) => !prev);
  };
  const [debutAutoVente, setDebutAutoVente] = React.useState('');
  const [finAutoVente, setFinAutoVente] = React.useState('');

  const programSell = () => { };

  // config des champs
  const [libelle, setLibelle] = React.useState('');
  const [libelleError, setLibelleError] = React.useState(false);
  const [prix, setPrix] = React.useState(0);
  const [prixError, setPrixError] = React.useState(false);
  const [quantite, setQuantite] = React.useState(0);
  const [quantiteError, setQuantiteError] = React.useState(false);
  const [advancedConfig, setAdvancedConfig] = React.useState(false);
  const [desc, setDesc] = React.useState('');
  const RegisterSchema = Yup.object().shape({
    nom: Yup.string().required('Nom est obligatoire'),
    // lastName: Yup.string().required('Prénom est obliagtoire'),
    // email: Yup.string().email('Email doit est être un email valide').required('Email est obligatoire'),
    // password: Yup.string().required('Mot de passe est obligatoire'),
  });

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const defaultValues = {
    nom: 'ticket',
    // lastName: '',
    // email: '',
    // password: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // billet
  const onSubmit = async (e) => {
    setLibelleError(false);
    setPrixError(false);
    setQuantiteError(false);
    let tmp = false;

    try {
      handleToggle();
      if (libelle === '') {
        setLibelleError(true);
        tmp = true;
      }
      if (checked && montant === 0) {
        setPrixError(true);
        tmp = true;
      }
      if (limitedQt && quantite === 0) {
        setQuantiteError(true);
        tmp = true;
      }
      if (autoVente) {
        //
      }

      if (!tmp) {

        const docRef = await addDoc(collection(db, `evenement/${eventID}/tickets`), {
          ticket_name: libelle,
          quota: limitedQt ? parseInt(quantite) : parseInt(0),
          sell: 0,
          status: 1,
          price: !checked ? parseInt(0) : parseInt(montant),
          description: desc,
          createDate: Timestamp.fromDate(new Date()),
        });
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${docRef.id}`);
        if (autoVente) {
          const tmpDateDebut = new Date(dateDebut);
          // Utiliser setHours pour remplacer l'heure et les minutes
          tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
          const tmpDateFin = new Date(dateFin);
          // Utiliser setHours pour remplacer l'heure et les minutes
          tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);

          const auto = await addDoc(collection(db, `automationTickets/scheduling/automations`), {
            event: eventID,
            ticket: docRef.id,
            type: 0,
            debut: tmpDateDebut ? Timestamp.fromDate(tmpDateDebut) : Timestamp.fromDate(new Date()),
            fin: tmpDateFin ? Timestamp.fromDate(tmpDateFin) : null,
          });
          await updateDoc(doc(db, `automationTickets`, `scheduling`), {
            update: true,
          });

          await updateDoc(statRef, {
            status: 2,
            automation: auto.id,
          });
        }

        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        const tmpM = !checked ? parseInt(0) : parseInt(montant);
        if (docSnap.exists()) {
          if (!docSnap.data().minPrice) {
            const eventRef = doc(db, `evenement/${eventID}`);
            await updateDoc(eventRef, {
              minPrice: parseInt(tmpM)
            });
          }
          else if (tmpM < docSnap.data().minPrice) {
            const eventRef = doc(db, `evenement/${eventID}`);
            await updateDoc(eventRef, {
              minPrice: parseInt(tmpM)
            });
          }
        }

        // await addDoc(collection(db, 'event'))
        // console.log(docRef);
        setGeneralReload(!generalReload)
        setMsg({ content: 'Le billet à été créé avec success', color: 'green' });
        setOpenSnack(true);
      }
    } catch (error) {
      console.log('erro');

      console.log(error.message);
      setMsg({ content: 'une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    handleClose();
    if (!tmp) {
      navigate(-1, { replace: true });
    }
  };

  // chargement
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Page title="Dashboard">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Creer un nouveau ticket
          </Typography>

          <CustomButton
            type='back'
            buttonText='Retour'
            startIcon={<Iconify icon='ion:arrow-back-outline' sx={{ width: 20, height: 20 }} />}
            onClick={() => window.history.back()}
          />
        </Stack>
        <Card>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ p: 3 }} dir="ltr">
              <Grid alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography> Libele ticket</Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    label="Entrer le libelle du ticket"
                    value={libelle}
                    required
                    onChange={(e) => setLibelle(e.target.value)}
                    name="nom"
                    error={libelleError}
                    helperText={libelleError ? 'Cette information est requise' : ''}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ p: 3 }} dir="ltr">
              <Grid alignItems="center" container spacing={3}>
                <Grid item xs={6} sm={4} md={4}>
                  <Typography>Ticket payant ?</Typography>
                </Grid>
                {/* <Grid item xs={2} sm={4} md={4}>
                  <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} />
                </Grid> */ }
                <Grid item xs={6} sm={8} md={8}>
                  <Paper
                    elevation={0}
                    sx={{
                      display: 'flex',
                      width: 120,
                      marginY: 1,
                      border: (theme) => `1px solid #f2f2f2`,
                      borderRadius: '7px',
                      flexWrap: 'wrap',
                      bgcolor: '#f2f2f2',
                    }}
                  >
                    <StyledToggleButtonGroup
                      value={checked}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                      sx={{ color: 'red' }}
                    >
                      <ToggleButton value={false}>Non</ToggleButton>
                      <ToggleButton value>Oui</ToggleButton>
                    </StyledToggleButtonGroup>
                  </Paper>
                </Grid>
              </Grid>
              {checked ? (
                <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Prix de vente du ticket</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        label="Entrer le prix du ticket"
                        name="montant"
                        type="number"
                        fullWidth
                        required
                        error={prixError}
                        helperText={prixError ? 'Veuillez entrer une valeur valide' : 'Montant en CFA'}
                        onChange={handleMontant}
                        value={montant}
                      />
                    </Grid>
                  </Grid>
                  {montant !== 0 && (
                    <>
                      <Grid paddingY={1} paddingX={2} alignItems="center" container>
                        <Grid item xs={12} sm={10} md={10}>
                          <Stack spacing={2}>
                            <Stack direction="row" justifyContent="space-between">
                              <Typography variant="caption">Commission</Typography>
                              <Typography variant="caption">
                                <strong>{commission} CFA</strong>
                              </Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between">
                              <Typography variant="caption">Votre revenue</Typography>
                              <Typography variant="caption">
                                <strong>{revenu} CFA</strong>
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              ) : (
                <Alert severity="warning">Ce billet sera vendu gratuitement</Alert>
              )}
            </Box>

            <Box sx={{ p: 3 }} dir="ltr">
              <Grid alignItems="center" container spacing={3}>
                <Grid item xs={6} sm={4} md={4}>
                  <Typography> Limiter la quantité</Typography>
                </Grid>
                <Grid item xs={6} sm={8} md={8}>
                  <Paper
                    elevation={0}
                    sx={{
                      display: 'flex',
                      width: 120,
                      marginY: 1,
                      border: (theme) => `1px solid #f2f2f2`,
                      borderRadius: '7px',
                      flexWrap: 'wrap',
                      bgcolor: '#f2f2f2',
                    }}
                  >
                    <StyledToggleButtonGroup
                      value={limitedQt}
                      exclusive
                      onChange={handleLimitedQt}
                      aria-label="Platform"
                      sx={{ color: 'red' }}
                    >
                      <ToggleButton value={false}>Non</ToggleButton>
                      <ToggleButton value>Oui</ToggleButton>
                    </StyledToggleButtonGroup>
                  </Paper>
                </Grid>
              </Grid>
              {limitedQt ? (
                <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Quantité Max</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        label="Nombre de billet à mettre en vendre"
                        value={quantite}
                        required
                        onChange={(e) => setQuantite(e.target.value)}
                        name="quantite"
                        type="number"
                        error={quantiteError}
                        fullWidth
                        helperText={
                          quantiteError ? 'Veuillez entrer une valeur valide' : 'le nombre de quantité mise en vente'
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Alert severity="warning">
                  Cet billet sera vendu sans limite de place. Assuré vous d'avoir assez d'espace pour accieuillir tout
                  le monde
                </Alert>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} dir="ltr">
              <Button
                variant="text"
                sx={{
                  textTransform: 'initial',
                  border: '2px solid transparent',
                  color: '#5E17EB',
                  backgroundColor: 'transparent',
                  borderRadius: '7px',
                  borderColor: 'transparent',
                  '&:hover': {
                    color: '#5E17EB',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                  },
                }}
                onClick={() => setAdvancedConfig(!advancedConfig)}
              >
                Configuration avancée
              </Button>
              <Iconify icon={advancedConfig ? "icon-park-solid:up-one" : "icon-park-solid:down-one"} sx={{ color: '#5E17EB' }} />
            </Box>
            {advancedConfig && (
              <>
                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Description</Typography>
                      <Typography variant="caption">
                        {' '}
                        La description apparaitra sur la page de vente et sur le Ticket PDF
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        fullWidth
                        name="desc"
                        placeholder="Donnez plus d'information sur le ticket, tels les conditions d'accès, le bonus liés au ticket"
                        rows={5}
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography> Quantité Max par client</Typography>
                      <Typography variant="caption">
                        {' '}
                        Limitez la quantité de tickets que peut acheter un client
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        type="number"
                        value={quantitePerClient}
                        onChange={(e) => setQuantitePerClient(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={10} sm={4} md={4}>
                      <Typography> Automatiser la mise en vente</Typography>
                      <Typography variant="caption"> Plannifier la mise en vente de votre ticket</Typography>
                    </Grid>
                    <Grid item xs={6} sm={8} md={8}>
                      <Paper
                        elevation={0}
                        sx={{
                          display: 'flex',
                          width: 120,
                          marginY: 1,
                          border: (theme) => `1px solid #f2f2f2`,
                          borderRadius: '7px',
                          flexWrap: 'wrap',
                          bgcolor: '#f2f2f2',
                        }}
                      >
                        <StyledToggleButtonGroup
                          value={autoVente}
                          exclusive
                          onChange={(e) => handleAutoVente(e.target.value)}
                          aria-label="Platform"
                          sx={{ color: 'red' }}
                        >
                          <ToggleButton value={false}>Non</ToggleButton>
                          <ToggleButton value>Oui</ToggleButton>
                        </StyledToggleButtonGroup>
                      </Paper>
                    </Grid>
                  </Grid>

                  {autoVente ? (
                    <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                      <Alert severity="warning">
                        Cet billet sera mise en vente du {dayjs(debutAutoVente).format('ddd DD MMM YYYY HH:mm')} au{' '}
                        {finAutoVente ? dayjs(finAutoVente).format('ddd DD MMM YYYY HH:mm') : '-'}
                      </Alert>
                      <Box sx={{ p: 2 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Debut</Typography>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                              <DateTimePicker
                                hideTabs
                                disablePast
                                minutesStep={15}
                                value={debutAutoVente}
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                onChange={(newValue) => {
                                  setDebutAutoVente(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ p: 2 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Fin</Typography>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                              <DateTimePicker
                                hideTabs
                                disablePast
                                minutesStep={15}
                                value={finAutoVente}
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                onChange={(newValue) => {
                                  setFinAutoVente(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  ) : null}

                  {autoVente ? (
                    <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                      <Alert severity="warning">
                        Cet billet sera mise en vente {dateDebut && timeDebut && !dateFin && !timeFin ? 'a partir de' : 'du'}  {dateDebut && timeDebut ? format((new Date(dateDebut).setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0)), 'eee dd MMM yyyy HH:mm', { locale: fr }) : '-'} 
                        {dateFin && timeFin ? ` au ${ format((new Date(dateFin).setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0)), 'eee dd MMM yyyy HH:mm', { locale: fr })}` : ''}
                      </Alert>
                      <Stack spacing={1} padding={3} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                        <Box>
                          <Typography>Début</Typography>
                        </Box>
                        <Stack spacing={1}>
                          <CustomDateTimePicker date={dateDebut} time={timeDebut} onDateChange={handleDateDebutChange} onTimeChange={handleTimeDebutChange} />
                        </Stack>
                        <Box paddingTop={2}>
                          <Typography>Fin</Typography>
                        </Box>
                        <Stack spacing={1}>
                          <CustomDateTimePicker date={dateFin} time={timeFin} minDate={dateDebut || null} onDateChange={handleDateFinChange} onTimeChange={handleTimeFinChange} />
                        </Stack>

                      </Stack>

                    </Box>
                  ) : null}
                </Box>
              </>
            )}

            <Box sx={{ p: 3 }} dir="ltr">
              <Stack direction='row' justifyContent='end' spacing={2}>
                <CustomButton
                  type='secondaire'
                  buttonText='Annuler'
                  onClick={() => window.history.back()}
                />
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#6F31ED',
                      color: 'white',
                      borderColor: '#6F31ED',
                    },
                  }}
                  type="submit"
                >
                  Enregister
                </Button>
              </Stack>
            </Box>
          </FormProvider>
        </Card>
        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          message={
            <Box>
              {msg.content}{' '}
              <Iconify
                sx={{ color: msg.color }}
                icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
              />
            </Box>
          }
        />
      </Container>
    </Page>
  );
}

// component
import { useParams } from 'react-router-dom';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;



const navConfigOrg = [
  {
    title: 'Information',
    path: '/organisation/app',
    icon: getIcon('octicon:organization-24'),
  },
  {
    title: 'CTT-Doors',
    path: '/organisation/door',
    icon: getIcon('vaadin:qrcode'),
  },
  {
    title: 'Facturation',
    path: '',
    icon: getIcon(''),
  },
  {
    title: 'Coordonnées reversement',
    path: '/organisation/coordonnee_reversement',
    icon: getIcon('mdi:account-payment'),
  },
  {
    title: 'Comptabillité',
    path: '/organisation/comptabilite',
    icon: getIcon('material-symbols:account-balance'),
  },
  
  {
     title: 'Partenaire',
    path: '',
    icon: getIcon('iconoir:shop-alt'),
  },
  {
    title: 'Partenaire de vente',
    path: '/organisation/sells_patners',
    icon: getIcon('octicon:organization-24'),
  },
  {
    title: 'Parteneaire CTT',
    path: '/organisation/ctt_patners',
    icon: getIcon('octicon:organization-24'),
  },
  {
    title: 'Guichet ( Bientôt)',
    path: '',
    icon: getIcon(''),
  },
  
  
];

export default navConfigOrg;

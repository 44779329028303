import React from 'react';

const CustomTicketSection = ({ title, description, price }) => {
  return (
    <div className="custom-ticket-section">
      <h2>{title}</h2>
      <p>{description}</p>
      <p>Price: ${price}</p>
    </div>
  );
};

export default CustomTicketSection;
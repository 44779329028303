/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable no-loop-func */
/* eslint-disable radix */
/* eslint-disable no-await-in-loop */
// @mui
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Grid,
  Container,
  Typography,
  Card,
  Box,
  Stack,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  InputLabel,
  ListSubheader,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
// components
// import { uuid } from 'uuidv4';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';

import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { db } from '../../firebase.config';
import Page from '../../components/Page';
import Iconify from "../../components/Iconify";

// sections

// ----------------------------------------------------------------------
const Alert = React.forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const randomstring = require('randomstring');

export default function AddParticipant() {
  const { eventID } = useParams();
  // loading
  const [loading, setLoading] = React.useState(false);

  const [event, setEvent] = React.useState([]);

  // tarif
  const [checked, setChecked] = React.useState(false);
  // information
  const [numero, setTel] = React.useState('');
  const [name, setName] = React.useState('');
  const [mail, setMail] = React.useState('');

  // montant
  const [quantite, setQuantite] = React.useState(0);
  const [prixUni, setPrix] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  // statut commande --------------------
  const [statCommande, setStatCommande] = React.useState('');

  // ticket--------------------
  const [ticket, setTicket] = React.useState(-1);
  const [tickets, setTickets] = React.useState([]);
  // options enregistrement--------------------
  const [option, setOption] = React.useState('');

  // recuperation de la séance
  const [groupDate, setGroup] = React.useState([]);
  const [itemGroup, setItemGroup] = React.useState([]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  // fonction de restauration
  const restauration = () => {
    setQuantite(0);
    setStatCommande('');
    setOption('');
    setTel('');
    setName('');
    setMail('');
    setTicket(-1);
  };

  const handleMontant = (event) => {
    setQuantite(event.target.value);
    const tmp = event.target.value * prixUni;
    setTotal(tmp);
  };

  // quantité

  const handleStatCommande = (e) => {
    setStatCommande(e.target.value);
  };

  // error
  const [error, setError] = React.useState(false); // permet d'afficher les message d'erreur

  // choix du ticket
  const handleTicket = (e) => {
    if (
      tickets[e.target.value].data().quota === tickets[e.target.value].data().sell &&
      tickets[e.target.value].data().quota !== 0
    ) {
      setMsg('Le ticket choisi est epuisé. Merci de choisir un autre ticket');
      setOpen(true);
      setTicket(-1);
      setPrix(0);
    } else {
      setTicket(e.target.value);
      // console
      setPrix(tickets[e.target.value].data().price);
    }
  };
  const [seance, setSeance] = React.useState('');
  // choix de la seance si seance active
  const handleSeance = async (e) => {
    setSeance(e.target.value)
    const value = e.target.value.split(".")
    console.log(itemGroup[parseInt(value[0])][parseInt(value[1])].data().billets)
    const tmpB = []
    itemGroup[parseInt(value[0])][parseInt(value[1])].data().billets.forEach(element => {
      if (element.status === 1) {
        tmpB.push(element)
      }
    });
    setTickets([])
    tmpB.forEach(async (d) => {
      const docRef0 = doc(db, `evenement/${eventID}/tickets`, d.id);
      const docSnap = await getDoc(docRef0);
      if (docSnap.exists()) {
        setTickets((tickets) => [...tickets, docSnap]);
      }
    });
  };

  const handleOption = (e) => {
    setOption(e.target.value);
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[0];
  };
  const handleTimestamp1 = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[1];
  };

  // recuperation des tickets
  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        // event
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());

          // verification du mode seance
          if (docSnap.data().seanceActive === 1) {
            // recuperation des dates
            const q = query(collection(db, `evenement/${eventID}/seances`), orderBy('date', 'asc'));
            const snapshot = await getDocs(q);
            const tmpd = [];
            const groups = [];
            const group = [];
            snapshot.forEach((d) => {
              // setDates((dates) => [...dates, d]);
              const tmpDate = format(new Date(handleTimestamp(d.data().date)), 'MMM yyyy', { locale: fr });
              tmpd.push(d.data());
              const found = groups.find((element) => element === tmpDate);
              if (!found) {
                console.log('not found ');
                groups.push(tmpDate);
              }
            });
            console.log(groups.length);

            for (let index = 0; index < groups.length; index++) {
              const tgroup = [];
              snapshot.forEach((d) => {
                const tmpDate = format(new Date(handleTimestamp(d.data().date)), 'MMM yyyy', { locale: fr });
                if (groups[index] === tmpDate) {
                  tgroup.push(d);
                }
              });
              group.push(tgroup);
            }
            setGroup(groups);
            setItemGroup(group);
          } else {
            const q = query(collection(db, `evenement/${eventID}/tickets`));
            const snapshot = await getDocs(q);
            snapshot.forEach((d) => {
              setTickets((tickets) => [...tickets, d]);
            });
          }
        }

        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, []);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // gestion de l'enregistrement d'un participant
  const handleEnregistrement = async (e) => {
    let idStat;
    let exist;
    let idTicket;
    let idOrder;
    let tmpError = false;
    const d = Date.now();

    setError(false);
    if (quantite === 0) {
      setError(true);
      tmpError = true;
    } else if (statCommande === '') {
      setError(true);
      tmpError = true;
    } else if (option === '') {
      setError(true);
      tmpError = true;
    }
    // await delay(1000);
    try {
      if (!tmpError) {
        setLoading(true);
        idOrder =
          randomstring.generate({ length: 6, charset: 'numeric' }) +
          randomstring.generate({ length: 2, charset: 'alphabetic' });
        const querySnapshot = await getDocs(collection(db, 'stats'));
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          idStat = doc.id;
        });

        // mis a jour des stats------------------------------------------
        const statRef = doc(db, 'stats', `${idStat}`);
        await delay(100);
        await updateDoc(statRef, {
          orders: increment(1),
          tickets: increment(parseInt(quantite)),
        });

        // verification de l'existance de l'idOrder------------------------------------------
        console.log('ajout de la commande');
        const comRef = doc(db, `evenement/${eventID}/commandes`, `C${idOrder}`);
        const comSnap = await getDoc(comRef);
        exist = comSnap.exists();
        while (exist) {
          console.log('le document existe deja');
          // regeneration du idOrder
          idOrder =
            randomstring.generate({ length: 6, charset: 'numeric' }) +
            randomstring.generate({ length: 2, charset: 'alphabetic' });
          // reverification de l'existance de l'idOrder--------------------------
          const comRef = doc(db, `evenement/${eventID}/commandes`, `C${idOrder}`);
          const comSnap = await getDoc(comRef);
          exist = comSnap.exists();
        }
        // ajout de la commande------------------------------------------
        await delay(100);
        await setDoc(doc(db, `evenement/${eventID}/commandes`, `C${idOrder}`), {
          totalBillet: quantite,
          status: statCommande,
          nom: name,
          tel: numero,
          email: mail,
          totalPrice: total,
          billets: [tickets[ticket].id],
          origine: 0,
          date: serverTimestamp(),
          createDate: Timestamp.fromDate(new Date(d)),
        });

        // regneration de l'idTicket-------------------------------------------
        idTicket =
          randomstring.generate({ length: 12, charset: 'numeric' }) +
          randomstring.generate({ length: 2, charset: 'alphabetic' });
        // verification de l'exsitance du billet--------------------------------
        const bilRef = doc(db, `evenement/${eventID}/billets`, `T${idTicket}0`);
        const bilSnap = await getDoc(bilRef);
        exist = bilSnap.exists();
        while (exist) {
          console.log('le billet existe deja');
          // regeneration d'un id billet---------------------------------------------
          idTicket =
            randomstring.generate({ length: 12, charset: 'numeric' }) +
            randomstring.generate({ length: 2, charset: 'alphabetic' });

          const comRef = doc(db, `evenement/${eventID}/commandes`, `T${idTicket}0`);
          const comSnap = await getDoc(comRef);
          exist = comSnap.exists();
        }

        for (let i = 0; i < quantite; i += 1) {
          // enregistrement des billet----------------------------------------------------------
          await delay(50);
          await setDoc(doc(db, `evenement/${eventID}/billets`, `T${idTicket}${i}`), {
            numero: `T${idTicket}${i}`,
            refCommande: `C${idOrder}`,
            status: 1,
            scanner: 0,
            nom: tickets[ticket].data().ticket_name,
            price: tickets[ticket].data().price,
            reduction: 0,
            createDate: Timestamp.fromDate(new Date(d)),
          });

          // mis a jour des stats des tickets---------------------------------------------------------
          await delay(100);
          const statRef = doc(db, `evenement/${eventID}/tickets`, `${tickets[ticket].id}`);
          await updateDoc(statRef, {
            sell: increment(1),
          });
        }

        // mise a jour des statistiques evenement---------------------------------------------------
        const date = format(new Date(), 'yyMMdd');
        const statisticRef = doc(db, `evenement/${eventID}/stats`, `${date}`);
        const statisticSnap = await getDoc(statisticRef);
        exist = statisticSnap.exists();
        const tmp = tickets[ticket].data().ticket_name;
        await delay(100);
        if (exist) {
          await updateDoc(statisticRef, {
            [tmp]: increment(parseInt(quantite)),
          });
        } else {
          await setDoc(statisticRef, {
            [tmp]: parseInt(quantite),
          });
        }

        // update event participant-----------------------------------------------------------------
        const partRef = doc(db, 'evenement', `${eventID}`);
        await delay(100);
        await updateDoc(partRef, {
          participant: increment(parseInt(quantite)),
        });

        // update event origine-----------------------------------------------------------------
        const provRef = doc(db, `evenement/${eventID}/provenance`, `${eventID}`);
        const provSnap = await getDoc(provRef);
        exist = provSnap.exists();
        if (exist) {
          await updateDoc(provRef, {
            ajout: increment(parseInt(quantite)),
          });
        } else {
          await setDoc(provRef, {
            ajout: parseInt(quantite),
            application: parseInt(0),
            guichet: parseInt(0),
            site: parseInt(0),
          });
        }

        // update event recette
        const recRef = doc(db, `evenement/${eventID}/recette`, `${eventID}`);
        const recSnap = await getDoc(recRef);
        exist = recSnap.exists();
        if (exist) {
          await updateDoc(recRef, {
            recette: increment(parseInt(total)),
          });
        } else {
          await setDoc(recRef, {
            recette: parseInt(total),
          });
        }

        setMsg('participant enregistré avec succes');
        setOpen(true);
        restauration();
      }
    } catch (error) {
      setMsg("Un probleme est survenu lors de l'ajout des tickets");
      setOpen(true);
      setLoading(false);
      console.log(error);
    }

    setLoading(false);
  };


  // gestion de l'enregistrement d'un participant lorsque la seance est active
  const handleEnregistrement1 = async (e) => {
    let idStat;
    let exist;
    let idTicket;
    let idOrder;
    let tmpError = false;
    console.log('seance : ', seance)
    const tmpSeance = seance.split('.')
    // eslint-disable-next-line prefer-const
    let itemG = itemGroup[parseInt(tmpSeance[0])][parseInt(tmpSeance[1])];
    const idSeance = itemG.id
    const tmpBillets = itemG.data().billets;
    const trouve = tmpBillets.findIndex(({ id }) => id === tickets[ticket].id)
    console.log('trouver : ', trouve)
    tmpBillets.splice(trouve, 1, { 'id': tmpBillets[trouve].id, 'quota': tmpBillets[trouve].quota, 'sell': parseInt(tmpBillets[trouve].sell) + parseInt(quantite) })
    console.log(tmpBillets)

    setError(false);
    if (quantite === 0) {
      setError(true);
      tmpError = true;
    } else if (statCommande === '') {
      setError(true);
      tmpError = true;
    } else if (option === '') {
      setError(true);
      tmpError = true;
    }
    // await delay(1000);
    try {
      if (!tmpError) {
        setLoading(true);
        idOrder =
          randomstring.generate({ length: 6, charset: 'numeric' }) +
          randomstring.generate({ length: 2, charset: 'alphabetic' });
        const querySnapshot = await getDocs(collection(db, 'stats'));
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          idStat = doc.id;
        });

        // mis a jour des stats generale ------------------------------------------
        const statRef = doc(db, 'stats', `${idStat}`);
        await delay(100);
        await updateDoc(statRef, {
          orders: increment(1),
          tickets: increment(parseInt(quantite)),
        });

        // verification de l'existance de l'idOrder------------------------------------------
        console.log('ajout de la commande');
        const comRef = doc(db, `evenement/${eventID}/commandes`, `C${idOrder}`);
        const comSnap = await getDoc(comRef);
        exist = comSnap.exists();
        while (exist) {
          console.log('le document existe deja');
          // regeneration du idOrder
          idOrder =
            randomstring.generate({ length: 6, charset: 'numeric' }) +
            randomstring.generate({ length: 2, charset: 'alphabetic' });
          // reverification de l'existance de l'idOrder--------------------------
          const comRef = doc(db, `evenement/${eventID}/commandes`, `C${idOrder}`);
          const comSnap = await getDoc(comRef);
          exist = comSnap.exists();
        }
        // ajout de la commande------------------------------------------
        const d = Date.now();
        await delay(100);
        await setDoc(doc(db, `evenement/${eventID}/commandes`, `C${idOrder}`), {
          totalBillet: quantite,
          status: statCommande,
          nom: name,
          tel: numero,
          email: mail,
          totalPrice: total,
          billets: [tickets[ticket].id],
          origine: 0,
          date: serverTimestamp(),
          createDate: Timestamp.fromDate(new Date(d)),
        });

        // ajout de la commande niveau de la seance ------------------------------------------
        await setDoc(doc(db, `evenement/${eventID}/seances/${idSeance}/commandes`, `C${idOrder}`), {
          totalBillet: quantite,
          status: statCommande,
          nom: name,
          tel: numero,
          email: mail,
          totalPrice: total,
          billets: [tickets[ticket].id],
          origine: 0,
          date: serverTimestamp(),
          createDate: Timestamp.fromDate(new Date(d)),
        });

        // regneration de l'idTicket-------------------------------------------
        idTicket =
          randomstring.generate({ length: 12, charset: 'numeric' }) +
          randomstring.generate({ length: 2, charset: 'alphabetic' });
        // verification de l'exsitance du billet--------------------------------
        const bilRef = doc(db, `evenement/${eventID}/billets`, `T${idTicket}0`);
        const bilSnap = await getDoc(bilRef);
        exist = bilSnap.exists();
        while (exist) {
          console.log('le billet existe deja');
          // regeneration d'un id billet---------------------------------------------
          idTicket =
            randomstring.generate({ length: 12, charset: 'numeric' }) +
            randomstring.generate({ length: 2, charset: 'alphabetic' });

          const comRef = doc(db, `evenement/${eventID}/commandes`, `T${idTicket}0`);
          const comSnap = await getDoc(comRef);
          exist = comSnap.exists();
        }

        for (let i = 0; i < quantite; i += 1) {
          // enregistrement des billet avec ajout de la seance----------------------------------------------------------
          const d1 = Date.now();
          await delay(50);
          await setDoc(doc(db, `evenement/${eventID}/billets`, `T${idTicket}${i}`), {
            numero: `T${idTicket}${i}`,
            refCommande: `C${idOrder}`,
            status: 1,
            scanner: 0,
            seance: idSeance,
            nom: tickets[ticket].data().ticket_name,
            price: tickets[ticket].data().price,
            reduction: 0,
            createDate: Timestamp.fromDate(new Date(d1)),
          });

          // enregistrement des billet avec ajout de la seance niviea de la seance----------------------------------------------------------
          await delay(50);
          await setDoc(doc(db, `evenement/${eventID}/seances/${idSeance}/billets`, `T${idTicket}${i}`), {
            numero: `T${idTicket}${i}`,
            refCommande: `C${idOrder}`,
            status: 1,
            scanner: 0,
            seance: idSeance,
            nom: tickets[ticket].data().ticket_name,
            price: tickets[ticket].data().price,
            reduction: 0,
            createDate: Timestamp.fromDate(new Date(d1)),
          });


        }

        // mis a jour des stats des tickets---------------------------------------------------------
        await delay(100);
        const ticketRef = doc(db, `evenement/${eventID}/tickets`, `${tickets[ticket].id}`);
        await updateDoc(ticketRef, {
          sell: increment(parseInt(quantite)),
        });

        // mise a jour des sell de billet niveau seance
        const seanceRef = doc(db, `evenement/${eventID}/seances`, `${idSeance}`);
        await updateDoc(seanceRef, {
          participants: increment(parseInt(quantite)),
          billets: tmpBillets,
          recette: increment(parseInt(total)),
        })

        // mise a jour des statistiques evenement---------------------------------------------------
        const date = format(new Date(), 'yyMMdd');
        const statisticRef = doc(db, `evenement/${eventID}/stats`, `${date}`);
        const statisticSnap = await getDoc(statisticRef);
        exist = statisticSnap.exists();
        const tmp = tickets[ticket].data().ticket_name;
        await delay(100);
        if (exist) {
          await updateDoc(statisticRef, {
            [tmp]: increment(parseInt(quantite)),
          });
        } else {
          await setDoc(statisticRef, {
            [tmp]: parseInt(quantite),
          });
        }

        // mise a jour des statistiques seance ---------------------------------------------------
        const statisticRef1 = doc(db, `evenement/${eventID}/seances/${idSeance}/stats`, `${date}`);
        const statisticSnap1 = await getDoc(statisticRef1);
        exist = statisticSnap1.exists();
        await delay(100);
        if (exist) {
          await updateDoc(statisticRef1, {
            [tmp]: increment(parseInt(quantite)),
          });
        } else {
          await setDoc(statisticRef1, {
            [tmp]: parseInt(quantite),
          });
        }

        // update event participant-----------------------------------------------------------------
        const partRef = doc(db, 'evenement', `${eventID}`);
        await delay(100);
        await updateDoc(partRef, {
          participant: increment(parseInt(quantite)),
        });

        // update event origine-----------------------------------------------------------------
        const provRef = doc(db, `evenement/${eventID}/provenance`, `${eventID}`);
        const provSnap = await getDoc(provRef);
        exist = provSnap.exists();
        if (exist) {
          await updateDoc(provRef, {
            ajout: increment(parseInt(quantite)),
          });
        } else {
          await setDoc(provRef, {
            ajout: parseInt(quantite),
            application: parseInt(0),
            guichet: parseInt(0),
            site: parseInt(0),
          });
        }

        // update event recette
        const recRef = doc(db, `evenement/${eventID}/recette`, `${eventID}`);
        const recSnap = await getDoc(recRef);
        exist = recSnap.exists();
        if (exist) {
          await updateDoc(recRef, {
            recette: increment(parseInt(total)),
          });
        } else {
          await setDoc(recRef, {
            recette: parseInt(total),
          });
        }

        setMsg('participant enregistré avec succes');
        setOpen(true);
        restauration();
      }
    } catch (error) {
      setMsg("Un probleme est survenu lors de l'ajout des tickets");
      setOpen(true);
      setLoading(false);
      console.log(error);
    }

    setLoading(false);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');

  const renderSelectGroup = (index) => {
    const items = itemGroup[index].map((p, j) => {
      return (
        <MenuItem key={p.id} value={`${index}.${j}`}>
          <Typography variant="caption">
            {format(new Date(handleTimestamp(p.data().date)), 'EEEE dd MMM yyyy', { locale: fr })} - {handleTimestamp1(p.data().date)}
          </Typography>
        </MenuItem>
      );
    });
    return [<ListSubheader>{groupDate[index]}</ListSubheader>, items];
  };

  return (
    <Page title="Ajout Participant">
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ajouter des participants <br />
            <Typography variant="body2">Ajouter manuellement des participants en saisissant une commande</Typography>
          </Typography>
        </Stack>
        {/** choix de la seance si le mode seance est activé */}
        {event.seanceActive === 1 && (
          <Card>
            <Box sx={{ p: 3 }} dir="ltr">
              <Grid alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Choix de la séance</Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Filtrer par séance</InputLabel>
                    <Select
                      label="Filtrer par séance"
                      fullWidth
                      value={seance || ''}
                      onChange={handleSeance}
                      defaultValue=""
                    >
                      {groupDate?.map((p, index) => renderSelectGroup(index))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Card>
        )}

        {tickets.length !== 0 && (
          <>
            {/* choix du ticket */}
            <Card>
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container>
                  <Grid item xs={10} sm={6} md={6}>
                    <Typography variant="h5">Information Commande </Typography>{' '}
                  </Grid>
                </Grid></Box>
              <Box sx={{ ml: 3, mr: 3, mb: 3, p: 3, bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                <Box sx={{ pb: 3 }} dir="ltr">
                  <Grid alignItems="center" container spacing={3} >
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>
                        Ticket
                        <br />
                        <Typography variant="body2">choix du ticket à générer</Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="statusReservation"
                          value={ticket}
                          onChange={handleTicket}
                        >
                          {tickets.map((ticket, index) => (
                            <MenuItem value={index} key={index}>
                              {ticket.data().ticket_name}
                              {ticket.data().quota === ticket.data().sell && ticket.data().quota !== 0 && (
                                <Box
                                  component="button"
                                  padding={0.5}
                                  sx={{
                                    borderRadius: '5px',
                                    fontSize: 11,
                                    backgroundColor: '#FBE79D',
                                    border: '1px dashed #FBE79D',
                                  }}
                                >
                                  épuisé
                                </Box>
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>

                {/* commande */}
                {ticket !== -1 && (
                  <>
                    {' '}
                    <Box dir="ltr" >
                      <Grid alignItems="center" container spacing={3} >
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>
                            Commande
                            <br />
                            <Typography variant="body2">Nombre de tickets à générer</Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={2} md={2}>
                          {quantite === 0 && error ? (
                            <TextField
                              error
                              id="quantiteField"
                              type="number"
                              fullWidth
                              helperText="Enter la quantité de tickets à générer"
                              onChange={handleMontant}
                              value={quantite}
                            />
                          ) : (
                            <TextField
                              id="quantiteField"
                              type="number"
                              fullWidth
                              helperText="Quantité"
                              onChange={handleMontant}
                              value={quantite}
                            />
                          )}
                        </Grid>
                        <Grid item xs={6} sm={2} md={2}>
                          <TextField
                            disabled
                            id="priceField"
                            type="number"
                            fullWidth
                            helperText="Prix unitaire TTC"
                            value={prixUni}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                          <TextField
                            disabled
                            id="totalField"
                            type="number"
                            fullWidth
                            helperText="Total TTC"
                            value={total}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Statut de la commande</Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            {statCommande === '' && error ? (
                              <Select
                                labelId="demo-simple-select-label"
                                id="statusReservation"
                                value={statCommande}
                                onChange={handleStatCommande}
                                error
                              >
                                <MenuItem value={1}>Déja payé</MenuItem>
                                <MenuItem value={0}>Reserver</MenuItem>
                              </Select>
                            ) : (
                              <Select
                                labelId="demo-simple-select-label"
                                id="statusReservation"
                                value={statCommande}
                                onChange={handleStatCommande}
                              >
                                <MenuItem value={1}>Déja payé</MenuItem>
                                <MenuItem value={0}>Reserver</MenuItem>
                              </Select>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>

                  </>)}
              </Box>
            </Card>
            {ticket !== -1 && (
              <>
                <Card sx={{ my: 2 }}>
                  {/* information acheteur */}
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Grid alignItems="center" container spacing={3}>
                      <Grid item xs={10} sm={6} md={6}>
                        <Typography variant="h5">Information Acheteur </Typography>{' '}
                        <Typography variant="body2"> (optionnel )</Typography>
                      </Grid>
                    </Grid>

                    <Box dir="ltr" sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Nom et Prénoms</Typography>
                        </Grid>

                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            placeholder="Nom et prenoms"
                            id="nameField"
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Email</Typography>
                        </Grid>

                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            id="emailField"
                            type="email"
                            onChange={(e) => setMail(e.target.value)}
                            value={mail}
                            fullWidth
                            placeholder="xyz@example.com"
                          />
                        </Grid>
                      </Grid>
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Téléphone</Typography>
                        </Grid>

                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            id="telField"
                            type="tel"
                            onChange={(e) => setTel(e.target.value)}
                            value={numero}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Card>
                {/* option d'enregistrement */}
                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Options d'enregistrement</Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl fullWidth>
                        {error && option === '' ? (
                          <Select
                            labelId="demo-simple-select-label"
                            id="statusReservation"
                            value={option}
                            onChange={handleOption}
                            error
                          >
                            <MenuItem value={0}>Enregistrer</MenuItem>
                            <MenuItem value={1}>Enregistrer et télécharger</MenuItem>
                            <MenuItem value={2}>Enregister et envoyer par mail</MenuItem>
                          </Select>
                        ) : (
                          <Select
                            labelId="demo-simple-select-label"
                            id="statusReservation"
                            value={option}
                            onChange={handleOption}
                          >
                            <MenuItem value={0}>Enregistrer</MenuItem>
                            <MenuItem value={1}>Enregistrer et télécharger</MenuItem>
                            <MenuItem value={2}>Enregister et envoyer par mail</MenuItem>
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                {error && (
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Typography color="red">Veuillez remplir les tous les champs requis</Typography>
                  </Box>
                )}
                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid container spacing={1}>
                    <Grid item xs={6} sm={2} md={2}>
                      <Button
                        fullWidth
                        variant="outlined"
                        sx={{
                          textTransform: 'initial',
                          border: '2px solid transparent',
                          color: 'black',
                          backgroundColor: 'white',
                          borderRadius: '7px',
                          borderColor: 'black',
                          '&:hover': {
                            backgroundColor: 'black',
                            color: 'white',
                            borderColor: 'black',
                          },
                        }}
                        onClick={() => restauration()}
                      >
                        Restaurer
                      </Button>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                      {
                        event.seanceActive === 1 ?
                          <LoadingButton
                            fullWidth
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              color: 'white',
                              backgroundColor: '#5E17EB',
                              borderRadius: '7px',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: '#5E17EB',
                                borderColor: '#5E17EB',
                              },
                            }}
                            onClick={handleEnregistrement1}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                          >
                            Enregistrer
                          </LoadingButton>
                          :
                          <LoadingButton
                            fullWidth
                            sx={{
                              textTransform: 'initial',
                              border: '2px solid transparent',
                              color: 'white',
                              backgroundColor: '#5E17EB',
                              borderRadius: '7px',
                              '&:hover': {
                                backgroundColor: 'white',
                                color: '#5E17EB',
                                borderColor: '#5E17EB',
                              },
                            }}
                            onClick={handleEnregistrement}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                          >
                            Enregistrer
                          </LoadingButton>
                      }
                    </Grid>
                  </Grid>
                </Box>{' '}
              </>
            )}
          </>
        )}


        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={<Box>{msg} <Iconify icon='system-uicons:cross-circle' /></Box>}
        />
      </Container>
    </Page>
  );
}

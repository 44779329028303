/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import * as Yup from 'yup';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Switch,
  FormControlLabel,
  Divider,
  Backdrop,
  CircularProgress,
  IconButton,
  Tooltip,
  Skeleton,
  Snackbar,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from '@mui/material';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
  orderBy,
  Timestamp,
} from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { db } from '../../firebase.config';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import { FormProvider } from '../../components/hook-form';
// mock
import USERLIST from '../../_mock/user';
import CheckCard from '../../components/CheckCard';
import { Variables } from '../../context/VariableContext';
import CustomButton from '../../components/CustomButton';
import CustomDateTimePicker from '../../components/CustomDateTimePicker';

// ----------------------------------------------------------------------

// Toogle Group Button
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0.5,
    width: 60,
    height: 30,
    border: 0,
    // background: 'black',
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      border: 0,
      background: '#5E17EB',
      color: 'white',
      '&:hover': {
        backgroundColor: '#5E17EB',

      },
    },
    '&:not(:first-of-type)': {
      // background: 'red',
      borderRadius: '7px',
    },
    '&:first-of-type': {
      // background: 'black',
      borderRadius: '7px',
    },
  },
}));
//-----------------------------------------------------------------------

// for modal
const style = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 350,
  width: 500,
  bgcolor: 'background.paper',
  p: 4,
};
const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  p: 3,
};

export default function Billets() {
  const { eventID } = useParams();
  const { generalReload, setGeneralReload } = Variables()
  const [page, setPage] = useState(0);

  const [selected, setSelected] = useState([]);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setModif(false);
    setRefresh((prev) => !prev);
  };

  // take tickets from firebase
  const [event, setEvent] = useState();
  const [tickets, setTicket] = React.useState([]);

  const [modif, setModif] = React.useState(false); // ouvrir section de modification

  const [curIndex, setIndex] = React.useState(); // l'index du billet a modifier

  // config des champs
  const [libelle, setLibelle] = React.useState(''); // le nom du ticket
  const [libelleError, setLibelleError] = React.useState(false);
  const [prix, setPrix] = React.useState(0);
  const [quantite, setQuantite] = React.useState(0); // fixer le quota
  const [desc, setDesc] = React.useState(''); // ajouter une description au billet
  const [limitedQt, setLinmitedQt] = React.useState(false); // activer le quota
  const [montant, setMontant] = React.useState(0); // rensiegner le prix du ticket
  const [prixError, setPrixError] = React.useState(false);
  const [quantiteError, setQuantiteError] = React.useState(false);
  const [commission, setCommission] = React.useState(0); // commission
  const [revenu, setRevenu] = React.useState(0); // revenu
  const [checked, setChecked] = React.useState(false);
  const RegisterSchema = Yup.object().shape({
    nom: Yup.string().required('Nom est obligatoire'),
    // lastName: Yup.string().required('Prénom est obliagtoire'),
    // email: Yup.string().email('Email doit est être un email valide').required('Email est obligatoire'),
    // password: Yup.string().required('Mot de passe est obligatoire'),
  });

  const defaultValues = {
    nom: 'ticket',
    // lastName: '',
    // email: '',
    // password: '',
  };

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // convert timestamp

  const handleTimestamp = (e) => {
    console.log('handle time stamp')
    const date = e.toDate();
    return format((new Date(date.toJSON())), 'yyyy-MM-dd', { locale: fr }).toString();
  };

  const handleTimestampCustom = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.')[0].split('T')[1].split(':');
    return format((new Date(date.toJSON())), 'HH:mm', { locale: fr }).toString();
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleChange = () => {
    if (checked) {
      setMontant(0);
    }
    setChecked((prev) => !prev);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const onSubmit = async (e) => {
    // fonction de mise a jour des billet
    try {
      handleToggle();
      let tmp = false;
      setPrixError(false);
      setQuantiteError(false);
      const tmpDateDebut = new Date(dateDebut);
      // Utiliser setHours pour remplacer l'heure et les minutes
      tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
      const tmpDateFin = new Date(dateFin);
      // Utiliser setHours pour remplacer l'heure et les minutes
      tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);
      if (checked && montant === 0) {
        setPrixError(true);
        tmp = true;
      }
      if ((limitedQt && quantite === 0) || quantite < tickets[curIndex].data().sell) {
        setQuantiteError(true);
        tmp = true;
      }
      if (!tmp) {
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${tickets[curIndex].id}`);
        await updateDoc(statRef, {
          quota: limitedQt ? parseInt(quantite) : parseInt(0),
          price: !checked ? parseInt(0) : parseInt(montant),
          description: desc,
        });

        if (montant !== tickets[curIndex].data().price && tickets[curIndex].data().sell > 0) {
          const logRef = collection(db, `evenement/${eventID}/tickets/${tickets[curIndex].id}/logs`);
          await addDoc(logRef, {
            type: 'modification du prix du billet',
            old: tickets[curIndex].data().price,
            new: parseInt(montant),
          });
        }
        // plannification du billet
        if (autoVente) {
          if (tickets[curIndex].data().automation) {
            const automateId = tickets[curIndex].data().automation
            const tmpDateDebut = new Date(dateDebut);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
            const tmpDateFin = new Date(dateFin);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);
            const auto = await updateDoc(doc(db, `automationTickets/scheduling/automations`, automateId), {
              type: 0,
              debut: tmpDateDebut ? Timestamp.fromDate(tmpDateDebut) : Timestamp.fromDate(new Date()),
              fin: tmpDateFin ? Timestamp.fromDate(tmpDateFin) : null,
            });
            await updateDoc(doc(db, `automationTickets`, `scheduling`), {
              update: true,
            });

            await updateDoc(statRef, {
              status: 2,
            });
          } else {
            const tmpDateDebut = new Date(dateDebut);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateDebut.setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0);
            const tmpDateFin = new Date(dateFin);
            // Utiliser setHours pour remplacer l'heure et les minutes
            tmpDateFin.setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0);

            const auto = await addDoc(collection(db, `automationTickets/scheduling/automations`), {
              event: eventID,
              ticket: tickets[curIndex].id,
              type: 0,
              debut: tmpDateDebut ? Timestamp.fromDate(tmpDateDebut) : Timestamp.fromDate(new Date()),
              fin: tmpDateFin ? Timestamp.fromDate(tmpDateFin) : null,
            });
            await updateDoc(doc(db, `automationTickets`, `scheduling`), {
              update: true,
            });

            await updateDoc(statRef, {
              status: 2,
              automation: auto.id,
            });

          }

        }
        console.log(event.minPrice)
        // define the min price
        let lowestPrice = tickets[0];
        for (let i = 1; i < tickets.length; i++) {
          // Vérifier si le prix du ticket actuel est inférieur au prix le plus bas trouvé jusqu'à présent
          if (tickets[i] < lowestPrice) {
            // Si oui, mettre à jour le prix le plus bas
            lowestPrice = tickets[i];
          }
        }
        if (montant !== tickets[curIndex].data().price && parseInt(montant) < lowestPrice) {
          const eventRef = doc(db, `evenement/${eventID}`);
          await updateDoc(eventRef, {
            minPrice: parseInt(montant)
          });
        }
        setMsg({ content: 'Le billet a été modifié avec succès ', color: 'green' });
        setOpenSnack(true);
        handleClose();
      }
    } catch (error) {
      console.log(error.message);
      setMsg({ content: 'Une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    setOpen(false);
    // navigate(-1, { replace: true });
  };

  const handleMontant = (event) => {
    setMontant(event.target.value);
    if (event.target.value !== 0) {
      console.log('montant =', event.target.value);
      const tmp = event.target.value * 5;

      const tmp1 = tmp / 100;

      setCommission(tmp1);

      setRevenu(event.target.value - tmp1);
    }
  };

  const handleLimitedQt = () => {
    if (limitedQt) {
      setQuantite(0);
    }
    setLinmitedQt((prev) => !prev);
  };

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      setTicket([]);
      try {
        const docRef0 = doc(db, 'evenement', eventID);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEvent(docSnap.data());
        }

        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          setTicket((tickets) => [...tickets, d]);
          console.log(d.data())
        });
        /* const q1 = query(
          collection(db, `automationTickets/scheduling/automations`),
          where('fin', '!=', null),
          orderBy('fin', 'asc'),
          limit(1)
        );
        const snapshot1 = await getDocs(q1);
        snapshot1.forEach((d) => {
          console.log(d.data());
        }); */
        // const last1 = await admin.firestore().collection('automationTickets').doc(docu.id).collection('automations').orderBy('debut', 'asc').limit(1).get();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [refreh]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // page de modification
  const [openModif, setOpenModif] = React.useState(false);
  const handleCloseModif = () => setOpenModif(false);
  const [temp, setTemp] = React.useState([]);

  // modification d'un billet
  const handleModif = async (index) => {
    setLoading(true);
    if (modif) {
      setModif(false);
    } else {
      setIndex(index);
      setLibelle(tickets[index].data().ticket_name);
      setDesc(tickets[index].data().description);
      setMontant(parseInt(tickets[index].data().price));
      if (parseInt(tickets[index].data().price) !== 0) {
        setChecked(true);
      } else if (parseInt(tickets[index].data().price) === 0) {
        setChecked(false);
      }
      setQuantite(parseInt(tickets[index].data().quota));
      if (parseInt(tickets[index].data().quota) !== 0) {
        setLinmitedQt(true);
      } else if (parseInt(tickets[index].data().quota) === 0) {
        setLinmitedQt(false);
      }
      if (parseInt(tickets[index].data().price) !== 0) {
        console.log('montant =', parseInt(tickets[index].data().price));
        const tmp = parseInt(tickets[index].data().price) * 5;

        const tmp1 = tmp / 100;

        setCommission(tmp1);

        setRevenu(parseInt(tickets[index].data().price) - tmp1);
      }
      // check automation
      if (tickets[index].data().automation) {
        setAdvancedConfig(true)
        setAutoVente(true)
        console.log(tickets[index].data().automation)
        try {
          const automationDoc = await getDoc(doc(db, `automationTickets/scheduling/automations`, tickets[index].data().automation));
          if (automationDoc.exists()) {
            console.log(automationDoc.data())
            setDateDebut(handleTimestamp(automationDoc.data().debut))
            setTimeDebut(handleTimestampCustom(automationDoc.data().debut))
            setDateFin(handleTimestamp(automationDoc.data().fin))
            setTimeFin(handleTimestampCustom(automationDoc.data().fin))
          }


        } catch (error) {
          console.log(error)
          setMsg({ content: 'Une erreur est survenue', color: 'red' });
          setOpenSnack(true);
        }

      }
      console.log(curIndex);
      setModif(true);
    }
    setLoading(false);
  };
  const back = () => {
    setLoading(true);

    setIndex('');
    setLibelle('');
    setDesc('');
    setMontant();
    // check automation
    setAdvancedConfig(false)
    setAutoVente(false)


    setLoading(false);
    setModif(false);
  };
  // supression d'un billet
  // Modal deleted
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);
  const handleSupp = async (index) => {
    handleToggle();
    console.log('supp');
    const ticket = tickets[index];
    if (ticket.data().sell === 0) {
      try {
        await deleteDoc(doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`));
        setMsg({ content: 'Le billet a été supprimé avec succès ', color: 'green' });
        setGeneralReload(!generalReload)
        setOpenSnack(true);
      } catch (error) {
        console.log(error.message);
        setMsg({ content: 'Une erreur est survenue', color: 'red' });
        setOpenSnack(true);
        handleClose();
      }
    }
    handleClose();
    handleCloseDeleteModal();
  };

  // desactiver le billet
  const handleVisible = async (index) => {
    const ticket = tickets[index];
    if (ticket.data().status === 0) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`);

        await updateDoc(statRef, {
          status: 1,
        });
        setMsg({ content: 'Le Billet a été activé avec succès ', color: 'green' });
        setOpenSnack(true);
      } catch (error) {
        setMsg({ content: 'Une erreur est survenue', color: 'red' });
        setOpenSnack(true);
        handleClose();
        console.log(error.message);
      }
    } else if (ticket.data().status === 1) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/tickets`, `${ticket.id}`);
        await updateDoc(statRef, {
          status: 0,
        });
        setMsg({ content: 'Le Billet a été desactivé avec succès ', color: 'green' });
        setOpenSnack(true);
      } catch (error) {
        setMsg({ content: 'Une erreur est survenue', color: 'red' });
        setOpenSnack(true);
        handleClose();
        console.log(error.message);
      }
    }
    handleClose();
  };
  // page de d'activation/desactivation
  const handleActivation = (index) => {
    const temp = tickets[index];
  };

  const [advancedConfig, setAdvancedConfig] = React.useState(false);
  // programmation
  const [salePeriod, setSalePeriod] = React.useState(0);
  const [autoVente, setAutoVente] = React.useState(false);

  // programmation
  const [quantitePerClient, setQuantitePerClient] = React.useState();

  const handleAutoVente = (e) => {
    setSalePeriod(e);
    setAutoVente((prev) => !prev);
  };
  const [debutAutoVente, setDebutAutoVente] = React.useState('');
  const [finAutoVente, setFinAutoVente] = React.useState('');

  /** new programmation billet */
  // programmation
  // date / time
  const [dateDebut, setDateDebut] = React.useState(null);
  const [timeDebut, setTimeDebut] = React.useState('');

  const handleDateDebutChange = (newDate) => {
    setDateDebut(newDate);
  };

  const handleTimeDebutChange = (newTime) => {
    console.log('parent')
    setTimeDebut(newTime);
  };
  const [dateFin, setDateFin] = React.useState(null);
  const [timeFin, setTimeFin] = React.useState('');

  const handleDateFinChange = (newDate) => {
    setDateFin(newDate);
  };

  const handleTimeFinChange = (newTime) => {
    setTimeFin(newTime);
  };

  return (
    <Page title="Mes Billets">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}
      <Container sx={{ marginTop: { xs: 3 } }}>
        <Modal
          open={openModif}
          onClose={handleCloseModif}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Quota Maximal
            </Typography>
            <TextField
              value={temp.quota}
              size="small"
              fullWidth
              id="outlined-number"
              placeholder="Entrer le quota"
              type="number"
            />
            <Typography variant="body2" sx={{ mt: 2 }}>
              le quota est la capaciter maximale de personne que vous souhaitez acceuillir.
            </Typography>
            <Button onClick={handleOpen} size="small">
              Enregistrer
            </Button>
          </Box>
        </Modal>
        {!modif ? (
          <>
            {/* en tete de la page */}
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Billet & Mise en vente <br />
                <Typography variant="body2">Creez et modifiez vos billets</Typography>
              </Typography>
              <CustomButton type='primaire' buttonText='Ajouter' startIcon={<Iconify icon="eva:plus-fill" sx={{ width: 20, height: 20 }} />} component={RouterLink}
                to="nouveau_billet" />
            </Stack>
            {/* Card */}
            <Grid paddingBottom={5} container spacing={1}>
              <Grid item xs={6} sm={3} md={3}>
                <Card >
                  <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                      Billet en vente
                    </Typography>
                    <Typography variant="body2">{loading ? <Skeleton /> : tickets.length}</Typography>
                  </CardContent>
                  <CardActions>
                    <CustomButton type='tertiaire' buttonText='Ajouter' component={RouterLink}
                      to="nouveau_billet" />
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            {/* Tableau des billets */}
            <Card>
              <Scrollbar>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 500 }} aria-label="caption table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Mes billets</TableCell>
                        <TableCell align="right">Prix (CFA)</TableCell>
                        {event && event.seanceActive === 0 ? (
                          <TableCell align="right">Vendu / Quota</TableCell>
                        ) : (
                          <TableCell align="right">Vendu</TableCell>
                        )}
                        <TableCell align="right">Statut</TableCell>
                        <TableCell align="right">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    {loading ? (
                      <caption>
                        <Skeleton variant="rounded" width="100%" />
                      </caption>
                    ) : tickets.length === 0 && !loading ? (
                      <caption>
                        <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                          <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                          <Typography variant="caption">Accun billet</Typography>
                        </Stack>
                      </caption>
                    ) : (
                      <TableBody>
                        {tickets.map((ticket, index) => (
                          <TableRow key={index} hover sx={{ cursor: 'pointer' }}>
                            <TableCell component="th" scope="row" onClick={() => handleModif(index)}>
                              {ticket.data().ticket_name}
                            </TableCell>

                            <TableCell align="right" onClick={() => handleModif(index)}>
                              {ticket.data().price !== 0 ? (
                                ticket.data().price
                              ) : (
                                <Typography variant="caption">Gratuit</Typography>
                              )}
                            </TableCell>
                            {event.seanceActive === 0 ? (
                              <TableCell align="right" onClick={() => handleModif(index)}>
                                {ticket.data().quota
                                  ? `${ticket.data().sell} / ${ticket.data().quota}`
                                  : `${ticket.data().sell} / Illimité`}
                              </TableCell>
                            ) : (
                              <TableCell align="right" onClick={() => handleModif(index)}>
                                {ticket.data().sell !== 0 ? ticket.data().sell : <Typography>-</Typography>}
                              </TableCell>
                            )}

                            <TableCell align="right" onClick={() => handleModif(index)}>
                              {ticket.data().status === 0 ? (
                                <Box
                                  component="button"
                                  padding={0.5}
                                  sx={{
                                    borderRadius: '5px',
                                    fontSize: 11,
                                    backgroundColor: '#FB8888',
                                    border: '1px dashed #FB8888',
                                  }}
                                >
                                  desactivé
                                </Box>
                              ) : ticket.data().status === 1 ? (
                                ticket.data().quota === 0 ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#C9E4D4',
                                      border: '1px dashed #C9E4D4',
                                    }}
                                  >
                                    actif
                                  </Box>
                                ) : ticket.data().sell === ticket.data().quota ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#FBE79D',
                                      border: '1px dashed #FBE79D',
                                    }}
                                  >
                                    épuisé
                                  </Box>
                                ) : (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#C9E4D4',
                                      border: '1px dashed #C9E4D4',
                                    }}
                                  >
                                    actif
                                  </Box>
                                )
                              ) : ticket.data().status === 2 ? (
                                ticket.data().quota === 0 ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#C9E4D4',
                                      border: '1px dashed #C9E4D4',
                                    }}
                                  >
                                    vente programé
                                  </Box>
                                ) : ticket.data().sell === ticket.data().quota ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#FBE79D',
                                      border: '1px dashed #FBE79D',
                                    }}
                                  >
                                    épuisé
                                  </Box>
                                ) : (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#C9E4D4',
                                      border: '1px dashed #C9E4D4',
                                    }}
                                  >
                                    vente programmé
                                  </Box>
                                )
                              ) : ticket.data().status === 3 ? (
                                ticket.data().quota === 0 ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#C9E4D4',
                                      border: '1px dashed #C9E4D4',
                                    }}
                                  >
                                    en reduction
                                  </Box>
                                ) : ticket.data().sell === ticket.data().quota ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#FBE79D',
                                      border: '1px dashed #FBE79D',
                                    }}
                                  >
                                    épuisé
                                  </Box>
                                ) : (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#C9E4D4',
                                      border: '1px dashed #C9E4D4',
                                    }}
                                  >
                                    en reduction
                                  </Box>
                                )
                              ) : ticket.data().status === 1 ? (
                                ticket.data().quota === 0 ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#C9E4D4',
                                      border: '1px dashed #C9E4D4',
                                    }}
                                  >
                                    prevente
                                  </Box>
                                ) : ticket.data().sell === ticket.data().quota ? (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#FBE79D',
                                      border: '1px dashed #FBE79D',
                                    }}
                                  >
                                    épuisé
                                  </Box>
                                ) : (
                                  <Box
                                    component="button"
                                    padding={0.5}
                                    sx={{
                                      borderRadius: '5px',
                                      fontSize: 11,
                                      backgroundColor: '#C9E4D4',
                                      border: '1px dashed #C9E4D4',
                                    }}
                                  >
                                    prevente
                                  </Box>
                                )
                              ) : null}
                            </TableCell>
                            <TableCell align="right">
                              {ticket.data().status === 1 ? (
                                <Tooltip title="Desactiver le ticket">
                                  <IconButton key={index} onClick={(e) => handleVisible(index)}>
                                    <Iconify icon="gridicons:visible" />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Activer le ticket">
                                  <IconButton key={index} onClick={(e) => handleVisible(index)}>
                                    <Iconify icon="gridicons:not-visible" />
                                  </IconButton>
                                </Tooltip>
                              )}
                              <Tooltip title="Modifer le ticket">
                                <IconButton onClick={(e) => handleModif(index)}>
                                  <Iconify icon="material-symbols:settings" />
                                </IconButton>
                              </Tooltip>
                              {ticket.data().sell === 0 ? (
                                <>
                                  <IconButton onClick={handleOpenDeleteModal}>
                                    <Iconify sx={{ color: '#FB8888' }} icon="material-symbols:delete-outline" />
                                  </IconButton>
                                  <Modal
                                    open={openDeleteModal}
                                    onClose={handleCloseDeleteModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    sx={{ borderRadius: '7px' }}
                                  >
                                    <Box sx={styleModal}>
                                      <Typography variant="h6">Supprimer du billet</Typography>
                                      <Box sx={{ marginTop: 5, p: 2 }} dir="ltr">
                                        <Alert severity="error">
                                          Cette action entraînera la suppression définitive du billet.
                                        </Alert>
                                      </Box>
                                      <Grid container spacing={2} justifyContent="end" paddingTop={5}>
                                        <Grid item>
                                          <Button
                                            variant="outlined"
                                            sx={{
                                              textTransform: 'initial',
                                              border: '2px solid transparent',
                                              color: 'black',
                                              backgroundColor: 'white',
                                              borderRadius: '7px',
                                              borderColor: 'black',
                                              boxShadow: 'none',
                                              '&:hover': {
                                                backgroundColor: 'black',
                                                color: 'white',
                                                borderColor: 'black',
                                              },
                                            }}
                                            onClick={handleCloseDeleteModal}
                                          >
                                            Annuler
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              textTransform: 'initial',
                                              border: '2px solid transparent',
                                              Color: 'white',
                                              backgroundColor: 'red',
                                              borderRadius: '7px',
                                              boxShadow: 'none',
                                              '&:hover': {
                                                backgroundColor: 'white',
                                                color: 'red',
                                                borderColor: 'red',
                                              },
                                            }}
                                            onClick={() => handleSupp(index)}
                                          >
                                            Supprimer
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Modal>
                                </>
                              ) : (
                                <Tooltip title="Vous ne pouvez pas supprimer le ticket car vous avez deja réalisé une vente">
                                  <IconButton disabled>
                                    <Iconify icon="material-symbols:delete-outline" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Card>
          </>
        ) : (
          <>
            <Typography variant="h4">{tickets[curIndex].data().ticket_name}</Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <CustomButton
              type='back'
              buttonText='Retour'
              startIcon={<Iconify icon='ion:arrow-back-outline' sx={{ width: 20, height: 20 }} />}
              onClick={back}
            />

            <Card sx={{ marginY: 3 }}>
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography> Libele ticket</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        label="Entrer le libelle du ticket"
                        value={libelle}
                        disabled={tickets[curIndex].data().sell === 0 ? false : true}
                        required
                        onChange={(e) => setLibelle(e.target.value)}
                        name="nom"
                        error={libelleError}
                        helperText={libelleError ? 'Cette information est requise' : ''}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={6} sm={4} md={4}>
                      <Typography>Ticket payant ?</Typography>
                    </Grid>
                    {/* <Grid item xs={2} sm={4} md={4}>
                  <FormControlLabel control={<Switch checked={checked} onChange={handleChange} />} />
                </Grid> */ }
                    <Grid item xs={6} sm={8} md={8}>
                      <Paper
                        elevation={0}
                        sx={{
                          display: 'flex',
                          width: 120,
                          marginY: 1,
                          border: (theme) => `1px solid #f2f2f2`,
                          borderRadius: '7px',
                          flexWrap: 'wrap',
                          bgcolor: '#f2f2f2',
                        }}
                      >
                        <StyledToggleButtonGroup
                          value={checked}
                          exclusive
                          onChange={handleChange}
                          aria-label="Platform"
                          sx={{ color: 'red' }}
                        >
                          <ToggleButton value={false}>Non</ToggleButton>
                          <ToggleButton value>Oui</ToggleButton>
                        </StyledToggleButtonGroup>
                      </Paper>
                    </Grid>
                  </Grid>
                  {checked ? (
                    <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Prix de vente du ticket</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            label="Entrer le prix du ticket"
                            name="montant"
                            type="number"
                            fullWidth
                            required
                            error={prixError}
                            helperText={prixError ? 'Veuillez entrer une valeur valide' : 'Montant en CFA'}
                            onChange={handleMontant}
                            value={montant}
                          />
                        </Grid>
                      </Grid>
                      {montant !== 0 && (
                        <>
                          <Grid paddingY={1} paddingX={2} alignItems="center" container>
                            <Grid item xs={12} sm={10} md={10}>
                              <Stack spacing={2}>
                                <Stack direction="row" justifyContent="space-between">
                                  <Typography variant="caption">Commission</Typography>
                                  <Typography variant="caption">
                                    <strong>{commission} CFA</strong>
                                  </Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                  <Typography variant="caption">Votre revenue</Typography>
                                  <Typography variant="caption">
                                    <strong>{revenu} CFA</strong>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Box>
                  ) : (
                    <Alert severity="warning">Ce billet sera vendu gratuitement</Alert>
                  )}
                </Box>

                <Box sx={{ p: 3 }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={10} sm={4} md={4}>
                      <Typography> Limiter la quantité</Typography>
                    </Grid>
                    <Grid item xs={6} sm={8} md={8}>
                      <Paper
                        elevation={0}
                        sx={{
                          display: 'flex',
                          width: 120,
                          marginY: 1,
                          border: (theme) => `1px solid #f2f2f2`,
                          borderRadius: '7px',
                          flexWrap: 'wrap',
                          bgcolor: '#f2f2f2',
                        }}
                      >
                        <StyledToggleButtonGroup
                          value={limitedQt}
                          exclusive
                          onChange={handleLimitedQt}
                          aria-label="Platform"
                          sx={{ color: 'red' }}
                        >
                          <ToggleButton value={false}>Non</ToggleButton>
                          <ToggleButton value>Oui</ToggleButton>
                        </StyledToggleButtonGroup>
                      </Paper>
                    </Grid>
                  </Grid>
                  {limitedQt ? (
                    <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Quantité Max</Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            label="Nombre de billet à mettre en vendre"
                            value={quantite}
                            required
                            onChange={(e) => setQuantite(e.target.value)}
                            name="quantite"
                            type="number"
                            error={quantiteError}
                            fullWidth
                            helperText={
                              quantiteError && quantite > tickets[curIndex].data().sell
                                ? 'Veuillez entrer une valeur valide'
                                : quantiteError && quantite < tickets[curIndex].data().sell
                                  ? 'le nombre de quantité mise en vente inferieur au nombre de billet vendu'
                                  : 'le nombre de quantité mise en vente'
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Alert severity="warning">
                      Cet billet sera vendu sans limite de place. Assuré vous d'avoir assez d'espace pour accieuillir
                      tout le monde
                    </Alert>
                  )}
                </Box>

                <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }} dir="ltr">
                  <Button
                    variant="text"
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: '#5E17EB',
                      backgroundColor: 'transparent',
                      borderRadius: '7px',
                      borderColor: 'transparent',
                      '&:hover': {
                        color: '#5E17EB',
                        backgroundColor: 'transparent',
                        borderColor: 'transparent',
                      },
                    }}
                    onClick={() => setAdvancedConfig(!advancedConfig)}
                  >
                    Configuration avancée
                  </Button>
                  <Iconify icon={advancedConfig ? "icon-park-solid:up-one" : "icon-park-solid:down-one"} sx={{ color: '#5E17EB' }} />
                </Box>
                {advancedConfig && (
                  <>
                    <Box sx={{ p: 3 }} dir="ltr">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Description</Typography>
                          <Typography variant="caption">
                            {' '}
                            La description apparaitra sur la page de vente et sur le Ticket PDF
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            fullWidth
                            name="desc"
                            placeholder="Donnez plus d'information sur le ticket, tels les conditions d'accès, le bonus liés au ticket"
                            rows={5}
                            ch
                            value={desc}
                            onChange={(e) => setDesc(e.target.value)}
                            multiline
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ p: 3 }} dir="ltr">
                      <Grid alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography> Quantité Max par client</Typography>
                          <Typography variant="caption">
                            {' '}
                            Limitez la quantité de tickets que peut acheter un client
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            type="number"
                            value={quantitePerClient}
                            onChange={(e) => setQuantitePerClient(e.target.value)}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ p: 3 }} dir="ltr">
                      <Grid alignItems="center" container spacing={3}>
                        <Grid item xs={10} sm={4} md={4}>
                          <Typography> Automatiser la mise en vente</Typography>
                          <Typography variant="caption"> Plannifier la mise en vente de votre ticket</Typography>
                        </Grid>
                        <Grid item xs={6} sm={8} md={8}>
                          <Paper
                            elevation={0}
                            sx={{
                              display: 'flex',
                              width: 120,
                              marginY: 1,
                              border: (theme) => `1px solid #f2f2f2`,
                              borderRadius: '7px',
                              flexWrap: 'wrap',
                              bgcolor: '#f2f2f2',
                            }}
                          >
                            <StyledToggleButtonGroup
                              value={autoVente}
                              exclusive
                              onChange={(e) => handleAutoVente(e.target.value)}
                              aria-label="Platform"
                              sx={{ color: 'red' }}
                            >
                              <ToggleButton value={false}>Non</ToggleButton>
                              <ToggleButton value>Oui</ToggleButton>
                            </StyledToggleButtonGroup>
                          </Paper>
                        </Grid>
                      </Grid>
                      {autoVente ? (
                        <Box sx={{ p: 3, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                          <Alert severity="warning">
                            Cet billet sera mise en vente {dateDebut && timeDebut && !dateFin && !timeFin ? 'a partir de' : 'du'}  {dateDebut && timeDebut ? format((new Date(dateDebut).setHours(timeDebut.split(':')[0], timeDebut.split(':')[1], 0)), 'eee dd MMM yyyy HH:mm', { locale: fr }) : '-'}
                            {dateFin && timeFin ? ` au ${format((new Date(dateFin).setHours(timeFin.split(':')[0], timeFin.split(':')[1], 0)), 'eee dd MMM yyyy HH:mm', { locale: fr })}` : ''}
                          </Alert>
                          <Stack spacing={1} padding={3} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>
                            <Box>
                              <Typography>Début</Typography>
                            </Box>
                            <Stack spacing={1}>
                              <CustomDateTimePicker date={dateDebut} time={timeDebut} onDateChange={handleDateDebutChange} onTimeChange={handleTimeDebutChange} />
                            </Stack>
                            <Box paddingTop={2}>
                              <Typography>Fin</Typography>
                            </Box>
                            <Stack spacing={1}>
                              <CustomDateTimePicker date={dateFin} time={timeFin} minDate={dateDebut || null} onDateChange={handleDateFinChange} onTimeChange={handleTimeFinChange} />
                            </Stack>

                          </Stack>

                        </Box>
                      ) : null}
                    </Box>
                  </>
                )}
                <Box sx={{ p: 3, display: 'flex', justifyContent: 'end' }} dir="ltr">

                  <Stack direction='row' spacing={1} justifyContent='space-between'>
                    <CustomButton
                      type='secondaire'
                      buttonText='Annuler'
                      onClick={back}
                    />
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'white',
                        backgroundColor: '#5E17EB',
                        borderRadius: '7px',
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: '#6F31ED',
                          color: 'white',
                          borderColor: '#6F31ED',
                        },
                      }}
                      type="submit"
                    >
                      Enregister
                    </Button>
                  </Stack>
                </Box>
              </FormProvider>
            </Card>
          </>
        )}
        <Snackbar
          open={openSnack}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
          message={
            <Stack spacing={1} direction={'row'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant='caption'>{msg.content}{' '}</Typography>
              <Iconify
                sx={{ color: msg.color }}
                icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
              />
            </Stack>
          }
        />
      </Container>
    </Page>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import websitePlugin from 'grapesjs-preset-webpage';

// import '../../styles.css'; // Fichier de styles personnalisés
import CustomTicketSection from './customComponent/customTicketSection'; // Composant personnalisé pour les billets
import HeroSection from './customComponent/HeroSection';

const SiteEditor = () => {
  const editorRef = useRef(null);

  useEffect(() => {
    const editor = grapesjs.init({
      container: '#gjs',
      height: '100vh',
      width: 'auto',
      storageManager: false,
      plugins: ['gjs-preset-webpage'],
      blockManager: {
        appendTo: '#blocks',
        blocks: [
          {
            id: 'container',
            label: '<i class="fas fa-box"></i> Container',
            content: `<div class="container" style="padding: 20px; border: 1px solid #ddd;">
              <!-- Your content here -->
            </div>`,
            category: 'Layout',
            attributes: { class: 'gjs-block-section' },
          },
          {
            id: 'column',
            label: '<i class="fas fa-columns"></i> Column',
            content: `<div class="row">
              <div class="col" style="padding: 10px; border: 1px solid #ddd;">Column 1</div>
              <div class="col" style="padding: 10px; border: 1px solid #ddd;">Column 2</div>
            </div>`,
            category: 'Layout',
            attributes: { class: 'gjs-block-section' },
          },
          {
            id: 'slider',
            label: '<i class="fas fa-images"></i> Slider',
            content: `<div class="slider" style="position: relative; width: 100%; height: 300px; overflow: hidden;">
              <div class="slides" style="display: flex; transition: transform 0.5s ease;">
                <img src="https://via.placeholder.com/800x300" style="width: 100%;" />
                <img src="https://via.placeholder.com/800x300" style="width: 100%;" />
              </div>
            </div>`,
            category: 'Components',
            attributes: { class: 'gjs-block-section' },
          },
          {
            id: 'card',
            label: '<i class="fas fa-credit-card"></i> Card',
            content: `<div class="card" style="border: 1px solid #ddd; border-radius: 8px; padding: 16px; max-width: 300px;">
              <img src="https://via.placeholder.com/300x200" style="width: 100%; border-radius: 8px;" />
              <div class="card-body">
                <h3 style="font-size: 1.5em;">Card Title</h3>
                <p>Card description goes here.</p>
              </div>
            </div>`,
            category: 'Components',
            attributes: { class: 'gjs-block-section' },
          },
          {
            id: 'code-editor',
            label: '<i class="fas fa-code"></i> Code Editor',
            content: `<textarea style="width: 100%; height: 200px;">// Your code here</textarea>`,
            category: 'Widgets',
            attributes: { class: 'gjs-block-section' },
          },
          {
            id: 'video',
            label: '<i class="fas fa-video"></i> Video',
            content: `<div class="video-container" style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden;">
              <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" frameborder="0" allowfullscreen></iframe>
            </div>`,
            category: 'Media',
            attributes: { class: 'gjs-block-section' },
          },
          {
            id: 'table',
            label: '<i class="fas fa-table"></i> Table',
            content: `<table style="width: 100%; border-collapse: collapse;">
              <thead>
                <tr>
                  <th style="border: 1px solid #ddd; padding: 8px;">Header 1</th>
                  <th style="border: 1px solid #ddd; padding: 8px;">Header 2</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="border: 1px solid #ddd; padding: 8px;">Row 1, Cell 1</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">Row 1, Cell 2</td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ddd; padding: 8px;">Row 2, Cell 1</td>
                  <td style="border: 1px solid #ddd; padding: 8px;">Row 2, Cell 2</td>
                </tr>
              </tbody>
            </table>`,
            category: 'Components',
            attributes: { class: 'gjs-block-section' },
          },
          {
            id: 'accordion',
            label: '<i class="fas fa-caret-down"></i> Accordion',
            content: `<div class="accordion" style="border: 1px solid #ddd; border-radius: 8px;">
              <h3 style="background: #eee; cursor: pointer; padding: 10px;">Accordion Item 1</h3>
              <div style="padding: 10px; display: none;">
                <p>Content for the first accordion item.</p>
              </div>
              <h3 style="background: #eee; cursor: pointer; padding: 10px;">Accordion Item 2</h3>
              <div style="padding: 10px; display: none;">
                <p>Content for the second accordion item.</p>
              </div>
            </div>`,
            category: 'Widgets',
            attributes: { class: 'gjs-block-section' },
          },
        ],
      },
      styleManager: {
        appendTo: '#styles-container',
        sectors: [
          {
            name: 'Dimensions',
            open: false,
            buildProps: ['width', 'min-height', 'padding', 'margin'],
            properties: [
              { name: 'Largeur', property: 'width', type: 'select', defaults: 'auto', options: [{ value: 'auto', name: 'Auto' }, { value: '50%', name: '50%' }, { value: '100%', name: '100%' }] },
              { name: 'Hauteur minimum', property: 'min-height', type: 'text' },
              { name: 'Padding', property: 'padding' },
              { name: 'Margin', property: 'margin' },
            ],
          },
          {
            name: 'Typographie',
            open: false,
            buildProps: ['font-family', 'font-size', 'color', 'line-height', 'letter-spacing'],
            properties: [
              { name: 'Police', property: 'font-family', type: 'select', options: [{ value: 'Arial', name: 'Arial' }, { value: 'Georgia', name: 'Georgia' }, { value: 'Times New Roman', name: 'Times New Roman' }] },
              { name: 'Taille de police', property: 'font-size', type: 'text' },
              { name: 'Couleur du texte', property: 'color', type: 'color' },
            ],
          },
        ],
      },
    });

    // Ajouter des traits spécifiques pour chaque type d'élément
    editor.DomComponents.addType('container', {
      model: {
        defaults: {
          name: 'Container',
          traits: [
            { name: 'Padding', type: 'text', label: 'Padding' },
            { name: 'Margin', type: 'text', label: 'Margin' },
          ],
        },
      },
    });

    editor.DomComponents.addType('column', {
      model: {
        defaults: {
          name: 'Column',
          traits: [
            { name: 'Padding', type: 'text', label: 'Padding' },
            { name: 'Background Color', type: 'color', label: 'Background Color' },
          ],
        },
      },
    });

    editor.DomComponents.addType('slider', {
      model: {
        defaults: {
          name: 'Slider',
          traits: [
            { name: 'Height', type: 'text', label: 'Height' },
            { name: 'Autoplay', type: 'checkbox', label: 'Autoplay' },
          ],
        },
      },
    });
    editorRef.current = editor;
    return () => {
      editor.destroy();
    };
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {/* Conteneur pour les blocs à gauche */}
      <div id="blocks" style={{ width: '250px', background: 'yellow', padding: '10px', height: '100%' }} />

      {/* Conteneur principal de l'éditeur */}
      <div id="gjs" style={{ flex: 1, height: '100%' }} />

      {/* Conteneur pour le gestionnaire de styles à droite */}
      <div id="styles-container" style={{ width: '250px', background: '#f5f5f5', padding: '10px', height: '100%' }} />
    </div>

  );
};

export default SiteEditor;
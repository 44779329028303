// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { AuthContextProvider } from './context/AuthContext';
import { VariableContextProvider } from './context/VariableContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <VariableContextProvider>
      <AuthContextProvider>
        <ThemeProvider>
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router />
        </ThemeProvider>
      </AuthContextProvider>
    </VariableContextProvider>
  );
}

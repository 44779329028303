// component
import { useParams } from 'react-router-dom';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;



const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Configuration',
    path: '',
    icon: getIcon(''),
  },
  {
    title: 'Donnée événement',
    path: '/dashboard/information',
    icon: getIcon('clarity:settings-solid'),
  },
  {
    title: 'Billet & Mise en vente',
    path: '/dashboard/billets',
    icon: getIcon('ep:ticket'),
  },
  /* {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon('eva:people-fill'),
  }, */
  {
    title: 'Seances',
    path: '/dashboard/datelist',
    icon: getIcon('fontisto:date'),
  },
  {
    title: 'Communication',
    path: '',
    icon: getIcon(''),
  },
  {
    title: 'Code de promotions',
    path: '/dashboard/code_promo',
    icon: getIcon('teenyicons:discount-solid'),
  },
  
  {
    title: 'Autre canal de vente',
    path: '',
    icon: getIcon(''),
  },
  
  {
    title: 'Widget',
    path: '/dashboard/site_personnel',
    icon: getIcon('gg:website'),
  },
  {
    title: 'Participants',
    path: '',
    icon: getIcon(''),
  },
  {
    title: 'Gérer les participants',
    path: '/dashboard/participants',
    icon: getIcon('bi:people-fill'),
  },
  {
    title: 'Ajouter des participants',
    path: '/dashboard/ajout_participant',
    icon: getIcon('eva:person-add-fill'),
  },
  
  {
    title: 'Contrôle d\'acces',
    path: '',
    icon: getIcon(''),
  },
  /* {
    title: 'Liste de contrôle',
    path: '/404',
    icon: getIcon('fluent:text-bullet-list-square-person-20-filled'),
  }, */
  {
    title: 'Opérateurs',
    path: '/dashboard/verification',
    icon: getIcon('bi:qr-code-scan'),
  },
  {
    title: 'Statistiques',
    path: '',
    icon: getIcon(''),
  },
  {
    title: 'Participation',
    path: '/dashboard/statistique',
    icon: getIcon('icomoon-free:stats-dots'),
  },
];

export default navConfig;

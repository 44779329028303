/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  LinearProgress,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  FormGroup,
  Snackbar,
  Skeleton,
  Alert,
} from '@mui/material';
// components
import { addDoc, collection, deleteDoc, doc, getDocs, query, Timestamp, updateDoc } from 'firebase/firestore';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { db } from '../../firebase.config';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
// mock
import USERLIST from '../../_mock/user';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  bgcolor: 'white',
  border: '1px solid white',
  borderRadius: '7px',
  boxShadow: 0,
  p: 3,
};

export default function Discount() {
  const [page, setPage] = useState(0);
  const { eventID } = useParams();
  // loading
  const [loading, setLoading] = React.useState(false);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  // modification de code
  const [nomDuCode, setNom] = React.useState(''); // valeur de la reduction
  const [codeASaisir, setASaisir] = React.useState(''); // valeur de la reduction
  const [nbUtilisation, setNb] = React.useState(0); // valeur de la reduction
  const [reduction, setReduction] = React.useState(0); // valeur de la reduction
  const [debutField, setDebut] = React.useState(); // valeur de la reduction
  const [finField, setFin] = React.useState(); // valeur de la reduction
  const [limitedT, setLimit] = React.useState(false); //
  const [period, setPeriod] = React.useState(false); // activer un periode de validité
  const [reductionType, setReductionType] = React.useState('fixe'); // reduction type
  // recuperation de billets
  const [tickets, ticketList] = React.useState([]); // contient la liste des tickets
  const [checks, setCheck] = React.useState([]);

  // for change value of globla quota
  const [modif, setModif] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [refreh, setRefresh] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setModif(false);
    setRefresh((prev) => !prev);
  };

  //  snack bar
  const [openSnack, setOpenSnack] = React.useState(false);
  const [msg, setMsg] = React.useState({ content: '', color: '' });
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  // setup des codes promo
  const [discounts, setDiscount] = useState([]);
  const [curent, setCurent] = useState();
  React.useEffect(() => {
    (async () => {
      setDiscount([]);
      try {
        setLoading(true);
        const q = query(collection(db, `evenement/${eventID}/codePromo`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          setDiscount((discounts) => [...discounts, d]);
          console.log(d.id, ' => ', d.data());
        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
    })();
  }, [refreh]);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleVisible = async (index) => {
    const discount = discounts[index];
    if (discount.data().statut === 0) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`);

        await updateDoc(statRef, {
          statut: 1,
        });
        setMsg({ content: 'Code promotionel activé avec success', color: 'green' });
        setOpenSnack(true);
      } catch (error) {
        handleClose();
        console.log(error.message);
        setMsg({ content: 'une erreur est survenue', color: 'red' });
        setOpenSnack(true);
      }
    } else if (discount.data().statut === 1) {
      try {
        handleToggle();
        const statRef = doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`);
        await updateDoc(statRef, {
          statut: 0,
        });
        setMsg({ content: 'Code promotionel desactivé avec success', color: 'green' });
        setOpenSnack(true);
      } catch (error) {
        handleClose();
        console.log(error.message);
        setMsg({ content: 'une erreur est survenue', color: 'red' });
        setOpenSnack(true);
      }
    }
    handleClose();
  };
  const handleModif = async (index) => {
    setLoading(true);
    setCurent(index);
    try {
      // Remplissage des informations
      setNom(discounts[index].data().nom_code);
      setASaisir(discounts[index].data().code);
      setNb(discounts[index].data().usage.max);
      setReductionType(discounts[index].data().reduc.type);
      setReduction(discounts[index].data().reduc.value);
      if (discounts[index].data().billet.length !== 0) {
        setLimit(true);
        const q = query(collection(db, `evenement/${eventID}/tickets`));
        const snapshot = await getDocs(q);
        snapshot.forEach((d) => {
          ticketList((tickets) => [...tickets, d]);
          console.log(d.id);
          console.log(discounts[index].data().billet);
          const exist = discounts[index].data().billet.findIndex(({ b }) => `${b}` === `${d.id}`);
          console.log(exist);
          if (exist !== -1) {
            setCheck((checks) => [...checks, true]);
          } else {
            setCheck((checks) => [...checks, false]);
          }
        });
      } else {
        setLimit(false);
      }
      // recuperation de billet
    } catch (error) {
      console.log(error);
    }
    setModif(true);
    setCurent(index);
    setLoading(false);
  };
  const backModif = () => {
    setLoading(true);
    try {
      // Remplissage des informations
      setNom();
      setASaisir();
      setNb();
      setReductionType();
      setReduction();
      ticketList([]);
      setCheck([]);
      // recuperation de billet
    } catch (error) {
      console.log('error');
    }
    setModif(false);
    setCurent();
    setLoading(false);
  };
  // supression d'un billet
  const handleSupp = async (index) => {
    handleToggle();
    console.log('supp');
    const discount = discounts[index];

    try {
      await deleteDoc(doc(db, `evenement/${eventID}/codePromo`, `${discount.id}`));
      setMsg({ content: 'Code promotionel supprimé avec success', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      console.log(error.message);
      setMsg({ content: 'Une erreur est survenue', color: 'red' });
      setOpenSnack(true);
      handleClose();
    }

    handleClose();
    handleCloseDeleteModal();
  };

  const handleLimited = () => {
    setLimit((prev) => !prev);
  };
  const handlePeriod = () => {
    setPeriod((prev) => !prev);
  };

  // creer nos codes de promotion
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log('reduction ', reduction);
    const d = Date.now();
    try {
      // handleToggle();
      const tb = []; // tableau des billets selectionnes
      for (let index = 0; index < checks.length; index++) {
        const element = checks[index];
        if (element) {
          tb.push(tickets[index].id);
        }
      }
      console.log('checks : ', tb);

      if (debutField && finField) {
        // verification de la periode

        const element = discounts[curent];
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, `${discounts[curent].id}`), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: reduction },
          statut: 1,
          usage: { max: nbUtilisation, now: discounts[curent].data().usage.now },
          billet: tb,
          nom_code: nomDuCode,
          debut: Timestamp.fromDate(new Date(debutField)),
          fin: Timestamp.fromDate(new Date(finField)),
          createDate: Timestamp.fromDate(new Date(d)),
        });
      } else if (debutField) {
        const element = discounts[curent];
        console.log('elemnt2 ', element);
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, `${discounts[curent].id}`), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: reduction },
          statut: 1,
          usage: { max: nbUtilisation, now: discounts[curent].data().usage.now },
          billet: tb,
          nom_code: nomDuCode,
          debut: Timestamp.fromDate(new Date(debutField)),
          createDate: Timestamp.fromDate(new Date(d)),
        });
      } else if (finField) {
        const element = discounts[curent];
        console.log('elemnt3 ', element);
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, `${discounts[curent].id}`), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: reduction },
          statut: 1,
          usage: { max: nbUtilisation, now: discounts[curent].data().usage.now },
          billet: tb,
          nom_code: nomDuCode,
          debut: Timestamp.fromDate(Date.now()),
          fin: Timestamp.fromDate(new Date(finField)),
          createDate: Timestamp.fromDate(new Date(d)),
        });
      } else {
        const element = discounts[curent];
        console.log('elemnt4 ', element);
        await updateDoc(doc(db, `evenement/${eventID}/codePromo`, `${discounts[curent].id}`), {
          // creationDate: name,
          code: codeASaisir,
          reduc: { type: reductionType, value: reduction },
          statut: 1,
          usage: { max: nbUtilisation, now: discounts[curent].data().usage.now },
          billet: tb,
          nom_code: nomDuCode,
          createDate: Timestamp.fromDate(new Date(d)),
        });
      }
      setMsg({ content: 'Code promotionel modifié avec success', color: 'green' });
      setOpenSnack(true);
    } catch (error) {
      console.log('erro');
      console.log(error.message);
      setMsg({ content: 'Une erreur est survenue', color: 'red' });
      setOpenSnack(true);
    }
    setLoading(false);
    // navigate(-1, { replace: true });
  };

  // gestion du radio boutton permettant de selectionner le type de reduction
  const handleRadioChange = (event) => {
    setReductionType(event.target.value);
  };

  // gestion du checkBox
  const handleCheckChange = (e) => {
    const tmp = checks;
    tmp.splice(e, 1, !checks[e]);
    setCheck(tmp);
    console.log(checks);
  };

  const handleTimestamp = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    const t = tmp[0].split('T');
    return t[0];
  };

  // Modal deleted
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const handleOpenDeleteModal = () => setOpenDeleteModal(true);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  return (
    <Page title="Code Promotion">
      <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && <LinearProgress />}

      {!modif ? (
        <Container sx={{ marginTop: { xs: 3 } }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'center' }}
            justifyContent="space-between"
            mb={5}
            spacing={2}
          >
            <Typography variant="h4" gutterBottom>
              Code de promotion <br />
              <Typography variant="body2">
              Creez et modifiez vos codes de promo
              </Typography>
            </Typography>
            <Button
              sx={{
                textTransform: 'initial',
                border: '2px solid transparent',
                Color: 'white',
                backgroundColor: '#5E17EB',
                borderRadius: '7px',
                boxShadow:'none',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#5E17EB',
                  borderColor: '#5E17EB',
                },
              }}
              variant="contained"
              component={RouterLink}
              to="ajout"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Ajouter un code
            </Button>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 500 }} aria-label="caption table">
                  <caption>
                    {' '}
                    {discounts.length === 0
                      ? "Vous pouvez créer des codes de promotion pour votre événement. Utilisez-les pour offrir une remise sur le prix de vos billets ou dans le cadre de vos opérations marketing ! Vous n'avez pas encore créé de code de promotion. Créez-en un !"
                      : null}{' '}
                  </caption>
                  
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Non du code</TableCell>
                      <TableCell align="center">Code à saisir</TableCell>
                      <TableCell align="center">Réduction</TableCell>
                      <TableCell align="center">Date de création</TableCell>
                      <TableCell align="center">Utilisation</TableCell>
                      <TableCell align="center">Actions</TableCell>
                      <TableCell align="center">Statut</TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <caption>
                      <Skeleton variant="rounded" width="100%" />
                    </caption>
                  ) : discounts.length === 0 && !loading ? (
                    <caption>
                      <Stack spacing={1} sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                        <Iconify icon="icomoon-free:drawer2" sx={{ width: 30, height: 30 }} />
                        <Typography variant="caption">Aucune donnée</Typography>
                      </Stack>
                    </caption>
                  ) : (
                    <TableBody>
                      {discounts.map((row, index) => (
                        <TableRow key={row.data().name}>
                          <TableCell align="center" component="th" scope="row">
                            {row.data().nom_code}
                          </TableCell>
                          <TableCell align="center">{row.data().code}</TableCell>
                          <TableCell align="center">
                            {row.data().reduc.value}
                            {row.data().reduc.type === 'percent' ? '%' : 'CFA'}
                          </TableCell>
                          <TableCell align="center">
                            {format(new Date(handleTimestamp(row.data().createDate)), 'dd/MM/yyyy', { locale: fr })}
                          </TableCell>
                          <TableCell align="center">
                            {row.data().usage.now}/{row.data().usage.max}
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Modifer le code">
                              <IconButton onClick={(e) => handleModif(index)}>
                                <Iconify icon="material-symbols:settings" />
                              </IconButton>
                            </Tooltip>

                            <>
                              <IconButton onClick={handleOpenDeleteModal}>
                                <Iconify sx={{ color: '#FB8888' }} icon="material-symbols:delete-outline" />
                              </IconButton>
                              <Modal
                                open={openDeleteModal}
                                onClose={handleCloseDeleteModal}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{ borderRadius: '7px' }}
                              >
                                <Box sx={styleModal}>
                                  <Typography variant="h6">Supprimer cet code de promotion</Typography>
                                  <Box sx={{ marginTop: 5, p: 2 }} dir="ltr">
                                    <Alert severity="error">
                                      Cette action entraînera la suppression définitive le code de promotion.
                                    </Alert>
                                  </Box>
                                  <Grid container spacing={2} justifyContent="end" paddingTop={5}>
                                    <Grid item>
                                      <Button
                                        variant="outlined"
                                        sx={{
                                          textTransform: 'initial',
                                          border: '2px solid transparent',
                                          color: 'black',
                                          backgroundColor: 'white',
                                          borderRadius: '7px',
                                          borderColor: 'black',
                                          '&:hover': {
                                            backgroundColor: 'black',
                                            color: 'white',
                                            borderColor: 'black',
                                          },
                                        }}
                                        onClick={handleCloseDeleteModal}
                                      >
                                        Annuler
                                      </Button>
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        sx={{
                                          textTransform: 'initial',
                                          border: '2px solid transparent',
                                          Color: 'white',
                                          backgroundColor: 'red',
                                          borderRadius: '7px',
                                          '&:hover': {
                                            backgroundColor: 'white',
                                            color: 'red',
                                            borderColor: 'red',
                                          },
                                        }}
                                        onClick={() => handleSupp(index)}
                                      >
                                        Supprimer
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Modal>
                            </>
                          </TableCell>
                          <TableCell align="center">
                            {row.data().statut === 1 ? (
                              <Tooltip title="Desactiver le code">
                                <IconButton key={index} onClick={(e) => handleVisible(index)}>
                                  <Iconify sx={{ color: 'green' }} icon="el:ok-sign" />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Activer le code">
                                <IconButton key={index} onClick={(e) => handleVisible(index)}>
                                  <Iconify sx={{ color: '#FB8888' }} icon="mdi:remove-circle" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      ) : (
        <Container sx={{ marginTop: { xs: 3 } }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Modifier un code de promotion
            </Typography>
          </Stack>
          <form onSubmit={handleSubmit}>
            <Card>
              {/* saisir ou generation de de code */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3} justifyContent="space-between">
                  <Grid item xs={10} sm={6} md={6}>
                    <Typography variant="h6">Informations sur le code</Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>Nom du code</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                      <TextField
                        label="Entrer le nom du code"
                        type="text"
                        value={nomDuCode}
                        onChange={(e) => setNom(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>code à saissir</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                      <TextField
                        label="Entrer le code à saisir"
                        type="text"
                        value={codeASaisir}
                        onChange={(e) => setASaisir(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid paddingY={1} alignItems="center" container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography>nombre d'utilisation</Typography>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6}>
                      <TextField
                        label="Entrer le nombre d'utilisation"
                        type="text"
                        value={nbUtilisation}
                        onChange={(e) => setNb(e.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* Type de reduction */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Typography variant="h6">Type de réduction </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <FormControl fullWidth>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={reductionType}
                      onChange={handleRadioChange}
                    >
                      <Grid alignItems="center" container spacing={3}>
                        <Grid item xs={10} sm={4} md={4}>
                          <Typography>Réduction fixe en CFA</Typography>
                        </Grid>
                        <Grid item xs={2} sm={8} md={8}>
                          <FormControlLabel value="fixe" control={<Radio />} />
                        </Grid>
                      </Grid>
                      {/* formulaire reduction fixe */}
                      {reductionType === 'fixe' && (
                        <Box sx={{ backgroundColor: 'gainsboro', p: 3, borderRadius: '7px' }} dir="ltr">
                          <Grid paddingY={1} alignItems="center" container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography>Montant de la réduction</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={6}>
                              <TextField
                                label="montant de la reduction"
                                required
                                name="nom"
                                type="number"
                                value={reduction}
                                onChange={(e) => setReduction(e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                      <Grid alignItems="center" container spacing={3}>
                        <Grid item xs={10} sm={4} md={4}>
                          <Typography>Reduction en pourcentage</Typography>
                        </Grid>
                        <Grid item xs={2} sm={8} md={8}>
                          <FormControlLabel value="percent" control={<Radio />} />
                        </Grid>
                      </Grid>
                      {/* formulaire pourcentage */}
                      {reductionType === 'percent' && (
                        <Box sx={{ backgroundColor: 'gainsboro', p: 3, borderRadius: '7px' }} dir="ltr">
                          <Grid paddingY={1} alignItems="center" container spacing={3}>
                            <Grid item xs={12} sm={4} md={4}>
                              <Typography>Montant de la réduction</Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} md={6}>
                              <TextField
                                label="montant de la reduction"
                                required
                                name="nom"
                                type="number"
                                value={reduction}
                                onChange={(e) => setReduction(e.target.value)}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>

              {/* choix du Ticket */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Typography variant="h6">Tickets</Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={10} sm={4} md={4}>
                      <Typography> Limiter à certains tickets</Typography>
                    </Grid>
                    <Grid item xs={2} sm={8} md={8}>
                      <FormControlLabel control={<Switch checked={limitedT} onChange={handleLimited} />} />
                    </Grid>
                  </Grid>
                  {limitedT && (
                    <Box sx={{ p: 2 }} dir="ltr">
                      <FormGroup>
                        {tickets.map((ticket, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              checks[index] ? (
                                <Checkbox checked onClick={() => handleCheckChange(index)} />
                              ) : (
                                <Checkbox onClick={() => handleCheckChange(index)} />
                              )
                            }
                            label={ticket.data().ticket_name}
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* periode de validite */}
              <Box sx={{ p: 3 }} dir="ltr">
                <Grid alignItems="center" container spacing={3}>
                  <Grid item xs={10} sm={4} md={4}>
                    <Typography variant="h6">Période de validité </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ p: 2, bgcolor: '#f2f2f2', borderRadius: '7px' }} dir="ltr">
                  <Grid alignItems="center" container spacing={3}>
                    <Grid item xs={10} sm={4} md={4}>
                      <Typography>
                        Définir une période <br />
                        <Typography variant="caption">Par defaut : pour toute l'année</Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={8} md={8}>
                      <FormControlLabel control={<Switch checked={period} onChange={handlePeriod} />} />
                    </Grid>
                  </Grid>
                  {period && (
                    <Box sx={{ backgroundColor: 'gainsboro', p: 3, borderRadius: '7px' }} dir="ltr">
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Date debut</Typography>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            label="Entrer la date de debut"
                            id="datetime-local"
                            type="datetime-local"
                            value={debutField}
                            onChange={(e) => {
                              setDebut(e.target.value);
                            }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid paddingY={1} alignItems="center" container spacing={3}>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography>Date de fin</Typography>
                        </Grid>

                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            label="Entrer la date de fin"
                            id="datetime-local"
                            type="datetime-local"
                            value={finField}
                            onChange={(e) => {
                              setFin(e.target.value);
                            }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box sx={{ p: 3 }} dir="ltr">
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={2} md={2}>
                    <Button
                      fullWidth
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        color: 'black',
                        backgroundColor: 'white',
                        borderRadius: '7px',
                        borderColor: 'black',
                        '&:hover': {
                          backgroundColor: 'black',
                          color: 'white',
                          borderColor: 'black',
                        },
                      }}
                      variant="outlined"
                      onClick={() => backModif()}
                    >
                      Annuler
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={2} md={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        textTransform: 'initial',
                        border: '2px solid transparent',
                        Color: 'white',
                        backgroundColor: '#5E17EB',
                        borderRadius: '7px',
                        '&:hover': {
                          backgroundColor: 'white',
                          color: '#5E17EB',
                          borderColor: '#5E17EB',
                        },
                      }}
                      type="submit"
                    >
                      Enregister
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </form>
        </Container>
      )}
      <Snackbar
        open={openSnack}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        message={
          <Box>
            {msg.content}{' '}
            <Iconify
              sx={{ color: msg.color }}
              icon={`${msg.red ? 'system-uicons:cross-circle' : 'mdi:success-circle-outline'}`}
            />
          </Box>
        }
      />
    </Page>
  );
}

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useRef, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Paper,
  TableHead,
  Grid,
  CardActions,
  CardContent,
  Box,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  useTheme,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Menu,
  Link,
} from '@mui/material';
// components
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import EmailEditor from 'react-email-editor';
import Page from '../../../../components/Page';
import Label from '../../../../components/Label';
import Scrollbar from '../../../../components/Scrollbar';
import Iconify from '../../../../components/Iconify';

// mock
import USERLIST from '../../../../_mock/user';

import { db } from '../../../../firebase.config';
import CheckCard from '../../../../components/CheckCard';
import { UserAuth } from '../../../../context/AuthContext';
import CustomButton from '../../../../components/CustomButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// for modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CreateCampaign() {
  const { eventID } = useParams();
  const { user } = UserAuth();
  const theme = useTheme();


  // for change value of globla quota
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [refreh, setRefresh] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    // setModif(false);
    setRefresh((prev) => !prev);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  // take operateurs from firebase
  const [contacts, setContact] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setContact([]);
      try {
        setLoading(true);
        setTemplates([])
        /* const docRef0 = doc(db, 'templates');

        const docSnap = await getDocs(db, 'templates'); */
        /* if (docSnap.exists()) {
          
        } */
        // setIdOrg(docSnap.data().idOrg)

        const q = query(collection(db, `templates`));
        const snapshot = await getDocs(q);
        snapshot.forEach(async (d) => {
          setTemplates((templates) => [...templates, d]);
        });
        setLoading(false);
      } catch (error) {
        console.log('error');
      }
      setLoading(false);
    })();
  }, [refreh]);

  // loading
  const [loading, setLoading] = React.useState(false);

  // templates
  const [builder, setBuilder] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false);

  const getPublicTemplate = async () => {
    setLoading(true);
    setTemplates([])
    const templatesData = [];
    /* const docRef0 = doc(db, 'templates');

    const docSnap = await getDocs(db, 'templates'); */
    /* if (docSnap.exists()) {
      
    } */
    // setIdOrg(docSnap.data().idOrg)
    if (templateType === 1) {
      const q = query(collection(db, `publicTemplates`));
      const snapshot = await getDocs(q);
      snapshot.forEach(async (d) => {
        console.log(d.data())
        setTemplates((templates) => [...templates, d]);
      });
    } else if (templateType === 2) {
      const q = query(collection(db, `publicTemplates`));
      const snapshot = await getDocs(q);
      snapshot.forEach(async (d) => {
        console.log(d.data())
        setTemplates((templates) => [...templates, d]);
      });
    }


    setLoading(false);
  };
  const [templateType, setTemplateType] = useState();

  React.useEffect(() => {
    (async () => {
      setTemplates([]);
      try {
        await getPublicTemplate()
      } catch (error) {
        console.log('error');
      }
      setLoading(false);
    })();
  }, [templateType]);

  const [curentTemp, setCurentTemp] = React.useState();
  const [templates, setTemplates] = React.useState([]);
  const pickTemplate = (index) => {
    setCurentTemp(index)
    setBuilder(true)
  };

  // email builder
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml(data => {
      const { design, html } = data;
      // console.log('exported html:', html);
      // Sauvegarder dans Firestore la version HTML et la structure design
    });
    console.log('salut')
    console.log(emailEditorRef.current?.editor)
  };

  const onLoad = () => {
    /* const editor = emailEditorRef.current.editor;
    
    // Charger les paramètres personnalisés dans l'éditeur
    editor.loadDesign({
      body: {
        rows: [],
      },
    });
 
    // Ajouter le slider pour arrondir les bords des images
    const imageSettings = editor.getToolboxConfig('image');
 
    if (imageSettings) {
      imageSettings.properties.push({
        label: 'Arrondir les bords',
        name: 'borderRadius',
        type: 'slider',
        default: 0,
        min: 0,
        max: 50,
      });
 
      editor.setToolboxConfig('image', imageSettings);
    }
  */
    if (curentTemp) {
      emailEditorRef.current.editor.loadDesign(templates[curentTemp].data().ddesign);
    }
  };

  return (
    <Page title="Campagne">
      {
        !builder ?
          <Box>
            <Backdrop sx={{ color: '#ffff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Container sx={{ marginTop: { xs: 3 } }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" mb={5}>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '7px',
                    borderColor: 'black',
                    '&:hover': {
                      backgroundColor: 'black',
                      color: 'white',
                      borderColor: 'black',
                    },
                  }}
                  onClick={() => window.history.back()}
                >
                  retour
                </Button>
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    fullWidth
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                  >
                    Tester
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    sx={{
                      textTransform: 'initial',
                      border: '2px solid transparent',
                      color: 'white',
                      backgroundColor: '#5E17EB',
                      borderRadius: '7px',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: 'white',
                        color: '#5E17EB',
                        borderColor: '#5E17EB',
                      },
                    }}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained"
                  >
                    Envoyer
                  </LoadingButton>
                </Stack>
              </Stack>
              <Typography variant="h6" gutterBottom>
                Créer une nouvelle campagne e-mail
              </Typography>

              <Card sx={{ padding: 3, marginY: 3 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="fluent:mail-28-regular" />
                  <Typography variant="h6">Audience Cible</Typography>
                </Stack>
                <Typography variant="caption">A qui envoyez-vous cette campagne</Typography>
              </Card>
              <Card sx={{ padding: 3, marginY: 3 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="fluent:mail-28-regular" />
                  <Typography variant="h6">Sujet</Typography>
                </Stack>
                <Typography variant="caption">Quel est le sujet de cette campagne</Typography>
                <Box paddingTop={1} sx={{ bgcolor: '#f2f2f2', borderRadius: '7px' }}>

                  <Grid container spacing={1} padding={2} >
                    <Grid item xs={12} sm={4}>
                      <Typography variant='body1'>Sujet</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField fullWidth />
                    </Grid>
                  </Grid>

                </Box>
              </Card>
              <Card sx={{ padding: 3, marginY: 3 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="fluent:mail-28-regular" />
                  <Typography variant="h6">Conception</Typography>
                </Stack>
                <Typography variant="caption">Choisissez un template pour commencer ou partez de zero</Typography>


                {/** contenu du template */}
                {showTemplates ? (
                  <Box width="100%" padding={2} sx={{bgcolor:'#f2f2f2', borderRadius:'7px'}}>
                    <Stack direction='row'>
                      <CustomButton type='back' buttonText='retour' onClick={() => { setShowTemplates(false) }} />
                        <Box sx={{flex:1, display:'flex', alignItems:'center', justifyContent:'center'}}>
                          <Typography variant='caption'><strong>Choisir votre template</strong></Typography>
                        </Box>
                      
                    </Stack>

                    <Grid container spacing={1} padding={2}>
                      {templates.map((template, index) => (
                        <Grid key={index} item xs={6} sm={3} >
                          <Card sx={{ padding: 2 }} onClick={() => { pickTemplate(index) }}>
                            <Box sx={{ flex: 1, overflow: 'auto' }}>
                              <iframe
                                title={index}
                                srcDoc={template.data().html}
                                style={{ width: '100%', height: '100%', border: 'none' }}
                              />
                            </Box>
                            <Typography>{template.data().titre}</Typography>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ) :
                  <Box padding={2} sx={{ bgcolor: '#f2f2f2', borderRadius:'7px' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={4} sm={3} onClick={() => { }}>
                        <Stack justifyContent='center' sx={{ cursor: 'pointer', width: '100%', height: '100%', padding: 2, border: '1px solid gainsboro', borderRadius: '7px' }} onClick={(e) => { setBuilder(true) }}>
                          <Typography variant='body2'><strong>Nouveau template</strong></Typography>
                          <Typography variant='body2'>Créer un nouveau template</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} sm={3} onClick={() => { }}>
                        <Stack justifyContent='center' sx={{ cursor: 'pointer', width: '100%', height: '100%', padding: 2, border: '1px solid gainsboro', borderRadius: '7px' }} onClick={() => { setShowTemplates(true); setTemplateType(1) }}>
                          <Typography variant='body2'><strong>Templates de campagnes</strong></Typography>
                          <Typography variant='body2'>Tous vos template utilisé lors de vos campagnes</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={4} sm={3} onClick={() => { }}>
                        <Stack justifyContent='center' sx={{ cursor: 'pointer', width: '100%', height: '100%', padding: 2, border: '1px solid gainsboro', borderRadius: '7px' }} onClick={() => { setShowTemplates(true); setTemplateType(2); }}>
                          <Typography variant='body2'><strong>Templates publics</strong></Typography>
                          <Typography variant='body2'>Provided by Choptonticket</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                }
              </Card>

            </Container>
          </Box>
          :
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Stack direction='row' justifyContent='space-between' sx={{ flex: '0 0 auto', padding: 1, backgroundColor: '#f5f5f5' }}>
              <CustomButton type='back' buttonText='retour' onClick={() => { setBuilder(false) }} />
              <Typography variant='body2'><strong>Design</strong></Typography>
              <Stack direction='row' spacing={1}>
                <CustomButton type='secondaire' buttonText='Envoyer un tester' />
                <CustomButton type='primaire' buttonText='Enregistrer' />
              </Stack>
            </Stack>
            <Box sx={{ flex: 1, }}>
              <EmailEditor
                ref={emailEditorRef}
                options={{
                  appearance: {
                    theme: 'dark',
                    panels: {
                      tools: {
                        dock: 'left',
                      },
                    },
                  }
                }}
                onLoad={onLoad} style={{ height: '89.5vh' }} />
            </Box>

          </Box>
      }

    </Page>
  );
}

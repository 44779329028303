import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation, useNavigate } from 'react-router-dom';
import CampaignCrm from './component/campaignCrm';
import ContactsCrm from './component/contactsCrm';
import PopulationCrm from './component/populationCrm';
import Iconify from '../../components/Iconify';
import CanalVente from '../communication/canal_de_vente/CanalDeVente';

export default function CrmTab() {
  const [value, setValue] = React.useState('1');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === '1') {
      if (!pathname.includes('campaigns')) {
        navigate(`/marketing/campaigns`);
      }

    }
    else if (newValue === '2') {
      if (!pathname.includes('contacts')) {
        navigate(`/marketing/contacts`);
      }
    }
    else if (newValue === '3') {
      if (!pathname.includes('populations')) {
        navigate(`/marketing/populations`);
      }
    }
    else if (newValue === '4') {
      if (!pathname.includes('socialmedia')) {
        navigate(`/marketing/socialmedia`);
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const tmp = pathname.split('/')
        console.log(tmp[tmp.length - 1])
        if (tmp[tmp.length - 1] === 'campaigns') {
          navigate(`/marketing/campaigns`, { replace: true });
          setValue('1')
        } else if (tmp[tmp.length - 1] === 'contacts') {
          navigate(`/marketing/contacts`, { replace: true });
          setValue('2')
        } else if (tmp[tmp.length - 1] === 'populations') {
          navigate(`/marketing/populations`, { replace: true });
          setValue('3')
        }
        else if (tmp[tmp.length - 1] === 'socialmedia') {
          navigate(`/marketing/socialmedia`, { replace: true });
          setValue('4')
        }
      } catch (error) {
        console.log('error');
      }

    })();
  }, [pathname]);


  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{
          paddingX: 5, borderBottom: 1, borderColor: 'divider', position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: 'background.paper'
        }}>
          <TabList sx={{ indicatorColor: '#5E17EB' }} onChange={handleChange} aria-label="lab API tabs example">
            <Tab icon={<Iconify icon='simple-icons:campaignmonitor' />} iconPosition="start" label="Campagnes" value="1" />
            <Tab icon={<Iconify icon='fluent-mdl2:contact' />} iconPosition="start" label="Contacts" value="2" />
            <Tab icon={<Iconify icon='healthicons:i-groups-perspective-crowd-outline' />} iconPosition="start" label="Populations" value="3" />
            <Tab icon={<Iconify icon='tabler:social' />} iconPosition="start" label="Reseau sociaux" value="4" />
          </TabList>
        </Box>
        <Box sx={{ height: 'calc(100vh - 167px)', overflowY: 'auto' }}>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="1">
            <CampaignCrm />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="2">
            <ContactsCrm />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="3">
            <PopulationCrm />
          </TabPanel>
          <TabPanel sx={{ paddingX: { xs: 0, sm: 3 } }} value="4">
            <CanalVente />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
}
/* eslint-disable radix */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  Timestamp,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import { db } from '../../firebase.config';

import Iconify from '../Iconify';
import { UserAuth } from '../../context/AuthContext';
import Page from '../Page';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const params = useParams;

export default function TabOrganisation() {
  const { user } = UserAuth();
  const [value, setValue] = React.useState(0);
  const [organisation, setOrg] = React.useState();
  const [pastEvents, setPastEvents] = React.useState([]);
  const [brouillonEvent, setbrouillonEvent] = React.useState([]);
  const [notReadyEvents, setNotReadyEvents] = React.useState([]);

  const helptext = ' Ce champs est requis. Veuillez le saisir!';

  // information de description
  const [statusJur, setStatus] = React.useState();
  const [nomOrganisation, setNomOrg] = React.useState();
  const [id, setId] = React.useState();
  const [numLic, setNum] = React.useState();

  // representant legal
  const [nom, setNom] = React.useState();
  const [prenoms, setPrenoms] = React.useState();
  const [fonction, setFonction] = React.useState();

  // coordonne
  const [num, setContact] = React.useState();
  const [ville, setVille] = React.useState();
  const [pays, setPays] = React.useState();
  const [adresse, setAdresse] = React.useState();
  const [email, setEmail] = React.useState();

  const [certifie, setCert] = React.useState(false);

  // loading
  const [loading, setLoading] = React.useState(false);
  // loading
  const [brouillon, setBrouillon] = React.useState(false);

  const createPastEventList = (doc) => {
    setPastEvents((events) => [...events, doc]); // ajouter a la fin
  };

  const createBrouillonEventList = (doc) => {
    setbrouillonEvent((events) => [...events, doc]); // ajouter a la fin
  };

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          console.log(docSnap.data().idOrg);
          // const q = query(collection(db, 'organisations'), where('orgId', '==', docSnap.data().idOrg));
          const q = doc(db, 'organisations', docSnap.data().idOrg);
          const querySnapshot = await getDoc(q);
          setOrg(querySnapshot);
          if (querySnapshot.exists()) {
            setNomOrg(querySnapshot.data().nomOrg);
            setId(querySnapshot.id);
            setContact(querySnapshot.data().contact);
            setNum(querySnapshot.data().licence);
            setStatus(querySnapshot.data().type);
            
              setCert(querySnapshot.data().certified);
            
            

            const q1 = doc(db, `organisations/${querySnapshot.id}/representant`, querySnapshot.id);
            const querySnapshot1 = await getDoc(q1);
            if (querySnapshot1.exists()) {
              setNom(querySnapshot1.data().nom_repre);
              setPrenoms(querySnapshot1.data().prenom_repre);
              setFonction(querySnapshot1.data().fonction_repre);
            }
          }
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [user.uid]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // fonction de mise a jour
  const update = async () => {
    setLoading(true);
    /* if(!nomOrganisation || !nom){
      setLoading(false)
      return
    } */
    const statRef = doc(db, `organisations`, `${id}`);
    try {
      await updateDoc(statRef, {
        contact: num,
        type: parseInt(statusJur),
        nomOrg: nomOrganisation,
        licence: numLic,
        updateDate: serverTimestamp(),
        certified: certifie,
      });

      const refDesc = doc(db, `organisations/${id}/representant`, `${id}`);
      const docSnap = await getDoc(refDesc);
      if (docSnap.exists()) {
        await updateDoc(refDesc, {
          nom_repre: nom,
          prenom_repre: prenoms,
          fonction_repre: fonction,
        });
      } else {
        await setDoc(refDesc, {
          nom_repre: nom,
          prenom_repre: prenoms,
          fonction_repre: fonction,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" paddingY={2}>
        <Typography variant="h4" gutterBottom>
          Mon organisation
        </Typography>
      </Stack>
      <Box>
        {/* commande */}

        <>
          {/* Description organisation */}
          <Box sx={{ p: 1 }} dir="ltr">
            <Grid alignItems="center" container spacing={3}>
              <Grid item xs={10} sm={4} md={4}>
                <Typography variant="h5">Description</Typography>
              </Grid>
            </Grid>

            <Box sx={{ p: 1 }} dir="ltr">
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Stauts jurique de l'organisation</Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                  <FormControl fullWidth>
                    <Select
                      value={parseInt(statusJur)}
                      onChange={(e) => setStatus(e.target.value)}
                      labelId="demo-simple-select-label"
                      id="statusJuridique"
                    >
                      <MenuItem value={0}>Particulier</MenuItem>
                      <MenuItem value={1}>Entreprise</MenuItem>
                      <MenuItem value={2}>Association</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Nom de l'organisation</Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                  {nomOrganisation ? (
                    <TextField
                      requis
                      onChange={(e) => setNomOrg(e.target.value)}
                      value={nomOrganisation}
                      placeholder="Entrer le nom de votre organisation"
                      id="nameField"
                      type="text"
                      fullWidth
                    />
                  ) : (
                    <TextField
                      error
                      helperText={helptext}
                      requis
                      onChange={(e) => setNomOrg(e.target.value)}
                      value={nomOrganisation}
                      placeholder="Entrer le nom de votre organisation"
                      id="nameField"
                      type="text"
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Numero de licence</Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                  <TextField
                    value={numLic}
                    onChange={(e) => setNum(e.target.value)}
                    placeholder="Entrer le numero de licence"
                    id="licenceField"
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Representant legal */}
          <Box sx={{ p: 1 }} dir="ltr">
            <Grid alignItems="center" container spacing={3}>
              <Grid item xs={10} sm={4} md={4}>
                <Typography variant="h5">Représentant légal</Typography>
              </Grid>
            </Grid>

            <Box sx={{ p: 1 }} dir="ltr">
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Nom</Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                  {nom ? (
                    <TextField
                      value={nom}
                      onChange={(e) => setNom(e.target.value)}
                      placeholder="Entrer votre nom"
                      id="nameField"
                      type="text"
                      fullWidth
                    />
                  ) : (
                    <TextField
                      value={nom}
                      onChange={(e) => setNom(e.target.value)}
                      error
                      helperText={helptext}
                      placeholder="Entrer votre nom"
                      id="nameField"
                      type="text"
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Prenom(s)</Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                  {prenoms ? (
                    <TextField
                      value={prenoms}
                      onChange={(e) => setPrenoms(e.target.value)}
                      placeholder="Entrer votre prenom"
                      id="prenomField"
                      type="text"
                      fullWidth
                    />
                  ) : (
                    <TextField
                      error
                      helperText={helptext}
                      value={prenoms}
                      onChange={(e) => setPrenoms(e.target.value)}
                      placeholder="Entrer votre prenom"
                      id="prenomField"
                      type="text"
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Fonction</Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                    <TextField
                      value={fonction}
                      onChange={(e) => setFonction(e.target.value)}
                      placeholder="Entrer votre fonction"
                      id="fonctionField"
                      type="text"
                      fullWidth
                    />
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* Coordonnées */}
          <Box sx={{ p: 1 }} dir="ltr">
            <Grid alignItems="center" container spacing={3}>
              <Grid item xs={10} sm={4} md={4}>
                <Typography variant="h5">Coordonnées</Typography>
              </Grid>
            </Grid>

            <Box sx={{ p: 1 }} dir="ltr">
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Adresse </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={6}>
                  {adresse ? (
                    <TextField label="Entrer votre adresse" id="nameField" type="text" fullWidth />
                  ) : (
                    <TextField
                      error
                      helperText={helptext}
                      label="Entrer votre adresse"
                      id="nameField"
                      type="text"
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>

              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Ville</Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                  {ville ? (
                    <TextField requis placeholder="Entrer votre ville" id="villeField" type="text" fullWidth />
                  ) : (
                    <TextField
                      requis
                      error
                      helperText="Ce champs est requis. Veuillez les saisir !"
                      placeholder="Entrer votre ville"
                      id="villeField"
                      type="text"
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Pays</Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={4}>
                  <FormControl fullWidth>
                    <Select id="paysField">
                      <MenuItem value={"Côte d'Ivoire"}>Côte d'ivoire</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Téléphone</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  {num ? (
                    <TextField
                      required
                      placeholder="Entrer un contact"
                      value={num}
                      onChange={(e) => setContact(e.target.value)}
                      id="telField"
                      type="tel"
                      fullWidth
                    />
                  ) : (
                    <TextField
                      error
                      required
                      placeholder="Entrer un contact"
                      value={num}
                      onChange={(e) => setContact(e.target.value)}
                      id="telField"
                      type="tel"
                      fullWidth
                      helperText="Ce champs est important. Veuillez le remplir"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Site web</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                  <TextField id="telField" placeholder="Entrer le lien de votre site internet" type="text" fullWidth />
                </Grid>
              </Grid>
              <Grid paddingY={1} alignItems="center" container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography>Email</Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <TextField id="telField" type="email" fullWidth />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ p: 1 }} dir="ltr">
            <Grid paddingY={1}  container spacing={3}>
              <Grid item xs={10} sm={6} md={6}>
                <Typography>je certifie sur l'honneur l'exactitude des informations ci-dessus.</Typography>
              </Grid>
              <Grid item xs={2} sm={6} md={6} alignItems='start'>
                <Checkbox onClick={() => setCert(!certifie)} checked={certifie} />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 3 }} dir="ltr">
            <Grid container spacing={1}>
              <Grid item xs={6} sm={2} md={2}>
                <LoadingButton
                  onClick={() => update()}
                  fullWidth
                  color="secondary"
                  loading={loading}
                  loadingPosition="end"
                  variant="contained"
                >
                  Enregistrer
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>{' '}
        </>
      </Box>
    </Container>
  );
}

import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { collection, getDocs, query, where } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../../firebase.config';
import { UserAuth } from '../../../context/AuthContext';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [resetPass, setResetPass] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [password, setPassword] = useState('');
  const { user, logIn, resetpass } = UserAuth();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = !resetPass ? Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  }) : Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });
  

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (e) => {
    console.log(e.email);

    try {
      await logIn(e.email, e.password);
      setError(false);
      navigate('/bo');
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };
  const onEmailSend = async (e) => {
    try {
      // await logIn(e.email, e.password)
      // setLoading(true);
        // check
        console.log('result')
        const addMessage =  httpsCallable(functions, "emailRestOrg");
        const result = await addMessage({ email: e.email });
        console.log(result)
        setEmailSent(true);
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  return !resetPass ? (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email" />

        <RHFTextField
          name="password"
          label="Mot de passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover" onClick={() => setResetPass(true)}>
          Mot de passe oublié?
        </Link>
      </Stack>
      {error && (
        <Alert sx={{ marginY: 2 }} severity="error">
          Email / Mot de passe erroné
        </Alert>
      )}

      <LoadingButton
        sx={{
          textTransform: 'initial',
          border: '2px solid transparent',
          color: 'white',
          backgroundColor: '#5E17EB',
          borderRadius: '7px',
          '&:hover': {
            backgroundColor: 'white',
            color: '#5E17EB',
            borderColor: '#5E17EB',
          },
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Connexion
      </LoadingButton>
    </FormProvider>
  ) : (
    <FormProvider methods={methods} onSubmit={handleSubmit(onEmailSend)}>
      <Stack spacing={3} paddingY={2}>
        <RHFTextField name="email" label="Email" />
      </Stack>

      {emailSent && (
        <Alert sx={{ marginY: 2 }} severity="success">
          Lien envoyé avec succès
        </Alert>
      )}

      <LoadingButton
        sx={{
          textTransform: 'initial',
          border: '2px solid transparent',
          color: 'white',
          backgroundColor: '#5E17EB',
          borderRadius: '7px',
          '&:hover': {
            backgroundColor: 'white',
            color: '#5E17EB',
            borderColor: '#5E17EB',
          },
        }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Envoyer le lien
      </LoadingButton>
      <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{ my: 3 }}>
        <Link variant="subtitle2" underline="hover" onClick={() => setResetPass(false)}>
          Revenir en arrière
        </Link>
      </Stack>
    </FormProvider>
  );
}

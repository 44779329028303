/* eslint-disable import/order */
import React, { useState } from 'react';

import { Anchor, Drawer } from 'antd';

import { Grid, Button, Box, Typography } from '@mui/material';
import Iconify from '../Iconify';
import { ReactComponent as Jotaro } from '../../assets/logo/choptonticket-3.svg';
import logo from '../../assets/logo/logo2.png';
import { NavLink } from 'react-router-dom';

const { Link } = Anchor;

function AppHeader() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="container-fluid">
      <div className="header">
        <div className="logo">
          <Box sx={{width: 60, height: 60 }}>
            <a href="/">
                <img src={logo} alt="Logo" />
            </a>
          </Box>
        </div>

        <div className="mobileHidden">
          <Anchor targetOffset="65">
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <Link href="#works" ><Typography color='white'>Comment ca marche</Typography> </Link>
              </Grid>
              <Grid item>
                <Link href="#feature" > <Typography color='white'>Services</Typography> </Link>
              </Grid>
              <Grid item>
                <Link href="#pricing" ><Typography color='white'>Tarifs</Typography> </Link>
              </Grid>
              <Grid item>
                <Link href="#faq"> <Typography color='white'>FAQ</Typography> </Link>
              </Grid>
              
              

              <Grid item>
                <Button
                  size="large"
                  fullWidth
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#5E17EB',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#5E17EB',
                      borderColor: '#5E17EB',
                    },
                  }}
                  variant="contained"
                  href="/bo"
                >
                  Se connecter
                </Button>
                
              </Grid>
              <Grid item>
                <Button
                  size="large"
                  sx={{
                    textTransform: 'initial',
                    border: '2px solid transparent',
                    color: 'white',
                    backgroundColor: '#141414',
                    borderRadius: '7px',
                    '&:hover': {
                      backgroundColor: 'white',
                      color: '#141414',
                      borderColor: '#141414',
                    },
                  }}
                  variant="contained"
                  href="/bo/new_event"
                >
                  Creer un evenement
                </Button>
              </Grid>
            </Grid>
          </Anchor>
        </div>
        <div className="mobileVisible">
          <Button size="medium"
                  sx={{textTransform: 'initial', borderColor: '#5E17EB', backgroundColor: '#5E17EB', borderRadius: '5px', color: 'white' }}
                  variant="outlined"
                  href="/bo">
            Se connecter
          </Button>
          <Button type="link" onClick={showDrawer}>
            <Iconify icon="bx:menu" sx={{width:30, height:30, color:'white'}} />
          </Button>

          <Drawer placement="right" width="80%" closable={false} onClose={onClose} open={visible}>
            <Anchor style={{}} targetOffset="65">
              <Link sx={{}} href="#hero" title="Home" />
              <Link href="#about" title="About" />
              <Link href="#feature" title="Features" />
              <Link href="#works" title="How it works" />
              <Link href="#faq" title="FAQ" />
              <Link href="#pricing" title="Pricing" />

              <Grid paddingLeft={2} alignItems="center" container spacing={1}>
                <Grid item>
                  <Button size="medium"
                  sx={{textTransform: 'initial', borderColor: '#5E17EB', backgroundColor: '#5E17EB', borderRadius: '5px', color: 'white' }}
                  variant="outlined"
                  href="/bo">
                    Se connecter
                  </Button>
                </Grid>
                <Grid item>
                  <Button size="medium"
                  sx={{textTransform: 'initial', color: 'white', backgroundColor: 'black', borderRadius: '5px' }}
                  variant="contained"
                  href="/bo/new_event">
                    Créer un évenement
                  </Button>
                </Grid>
              </Grid>
            </Anchor>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default AppHeader;

/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';

import { Container } from '@mui/system';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  addDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import { db } from '../../firebase.config';

import Iconify from '../Iconify';
import { UserAuth } from '../../context/AuthContext';
import Reversement from './facturation/Reversement';
import Comptabilite from './facturation/Comptabilite';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingX: 1, paddingY: 5, minHeight: 400 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabFacturation() {
  const { user } = UserAuth();
  const [value, setValue] = React.useState(0);

  const [opMobile, setOpMobile] = React.useState();
  const [moyenVir, setMoyenVir] = React.useState([]);
  // loading
  const [loading, setLoading] = React.useState(false);
  
  const createMoyenVir = (doc) => {
    setMoyenVir((events) => [...events, doc]); // ajouter a la fin
  };

  // Field bancaire
  const [nom, setNom] = React.useState();
  const [numBank, setBank] = React.useState();
  const [codeSwift, setSwift] = React.useState();
  const [id, setId] = React.useState();

  // field Mobile

  const [num, setNum] = React.useState();


  const update = async (e) => {
    setLoading(true);
    console.log(e);

    try {
      const refDesc = doc(db, `organisations/${id}/infoPaie`, `${moyenVir[activeModif].id}`);

      if (e === 0) {
        await updateDoc(refDesc, {
          name: nom,
          iban: numBank,
          swift: codeSwift,
          updateDate: serverTimestamp(),
        });
      } else if (e === 1) {
        await updateDoc(refDesc, {
          name: nom,
          op: opMobile,
          mobile: num,
          updateDate: serverTimestamp(),
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addVir = async () => {
    setLoading(true);

    try {
      const orgRef = doc(db, `organisations`, `${id}`);
      const docSnap = await getDoc(orgRef);
      if (docSnap.exists()) {
        await updateDoc(orgRef, {
          infoPaie: docSnap.data().infoPaie + 1,
        });

        if (selectedValue === 'b') {
          await addDoc(collection(db, `organisations/${id}/infoPaie`), {
            name: nom,
            iban: numBank,
            swift: codeSwift,
            type: 0,
            createDate: serverTimestamp(),
          });
        } else if (selectedValue === 'a') {
          await addDoc(collection(db, `organisations/${id}/infoPaie`), {
            name: nom,
            op: opMobile,
            mobile: num,
            type: 1,
            createDate: serverTimestamp(),
          });
        }
      }
      setLoading(false);
    } catch (error) {
      console.log('error');
      console.log(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        setMoyenVir([]);
        setLoading(true);
        const docRef0 = doc(db, 'organisateurs', user.uid);
        const docSnap = await getDoc(docRef0);
        console.log(docSnap.data().idOrg);
        if (docSnap.exists()) {
          console.log(docSnap.data().idOrg);
          setId(docSnap.data().idOrg);
          const q = collection(db, `organisations/${docSnap.data().idOrg}/infoPaie`);
          const querySnapshot = await getDocs(q);
          console.log('collection ', querySnapshot);
          querySnapshot.forEach((doc) => {
            console.log('collection ', doc.data());
            createMoyenVir(doc);
          });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [user.uid]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [selectedValue, setSelectedValue] = React.useState('b');

  const handleChangePaie = (event) => {
    console.log('value', event.target.value);
    setSelectedValue(event.target.value);
  };
  const [activeModif, setActiveModif] = React.useState();
  const [screen, setScreen] = React.useState('0');
  const handleModif = (index) => {
    console.log(index);
    setActiveModif(index);
    setNom(moyenVir[index].data().name);
    if (moyenVir[index].data().type === 0) {
      setSwift(moyenVir[index].data().swift);
      setBank(moyenVir[index].data().iban);
    }
    if (moyenVir[index].data().type === 1) {
      setOpMobile(moyenVir[index].data().op);
      setNum(moyenVir[index].data().mobile);
    }
    setScreen('2');
  };

  const changeScreen = (e) =>{
    setNom()
    setOpMobile()
    setNum()
    setBank()
    setSwift()
    setScreen(e)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          scrollButtons="auto"
        >
          <Tab label={`Cordonnées de reversement `} {...a11yProps(0)} />
          <Tab label={`Historique de reversement`} {...a11yProps(1)} />
          <Tab label={`Comptabilité`} {...a11yProps(2)} />
        </Tabs>
      </Box>
      {loading && <LinearProgress />}
      {/** moyen de reversement */}
      <TabPanel value={value} index={0}>
        <Box>
          {screen === '0' && (
            <Box textAlign="end">
              <Button variant="text" onClick={() => changeScreen('1')} sx={{ textTransform: 'initial' }}>
                {' '}
                ajouter un moyen
              </Button>
            </Box>
          )}
          {screen === '1' || screen === '2' ? (
            <Box textAlign="start">
              <Button variant="text" onClick={() => changeScreen('0')} sx={{ textTransform: 'initial' }}>
                retour
              </Button>
            </Box>
          ) : null}

          {/* Ajout de moyen de virement */}
          {screen === '1' ? (
            <Container>
              <Card sx={{padding: 2, margin: 2}}>
                Attention ! Ces informations nous sont indispensables pour effectuer vos virements !
              </Card>
              <Stack direction="row" alignItems="center" justifyContent="space-between" paddingY={2}>
                <Typography variant="h6" gutterBottom>
                  Transfère bancaire
                </Typography>
                <Radio
                  checked={selectedValue === 'b'}
                  onChange={handleChangePaie}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'B' }}
                />
              </Stack>
              {selectedValue === 'b' && (
                <Box>
                  <>
                    {/* Coordonnées bancaires */}
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Box sx={{ p: 1 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Nom du titulaire</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              placeholder="Entrer le nom"
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              id="nameField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Code IBAN (N° de compte beneficiaire)</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={numBank}
                              onChange={(e) => setBank(e.target.value)}
                              placeholder="Entrer le numero de licence"
                              id="licenceField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Code BIC / SWIFT</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={codeSwift}
                              onChange={(e) => setSwift(e.target.value)}
                              placeholder="Entrer le numero de licence"
                              id="licenceField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </>
                </Box>
              )}

              <Divider />
              <Stack direction="row" alignItems="center" justifyContent="space-between" paddingY={2}>
                <Typography variant="h6" gutterBottom>
                  Transfère mobile
                </Typography>
                <Radio
                  checked={selectedValue === 'a'}
                  onChange={handleChangePaie}
                  value="a"
                  name="radio-button"
                  inputProps={{ 'aria-label': 'A' }}
                />
              </Stack>

              <Box>
                <>
                  {/* Coordonnées mobile */}
                  {selectedValue === 'a' && (
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Box sx={{ p: 1 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Nom du titulaire</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              placeholder="Entrer le nom"
                              id="nameField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Opérateur mobile</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <FormControl fullWidth>
                              <Select
                                value={parseInt(opMobile)}
                                onChange={(e) => setOpMobile(e.target.value)}
                                labelId="demo-simple-select-label"
                                id="opMobile"
                                placeholder="Entrer le nom"
                              >
                                <MenuItem value={0}>Orange</MenuItem>
                                <MenuItem value={1}>MTN</MenuItem>
                                <MenuItem value={2}>Moov</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Numéro mobile</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={num}
                              onChange={(e) => setNum(e.target.value)}
                              placeholder="+225 09999999"
                              id="licenceField"
                              type="number"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  )}
                  <Box sx={{ p: 3 }} dir="ltr">
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={2} md={2}>
                        <LoadingButton
                          onClick={() => addVir()}
                          fullWidth
                          color="secondary"
                          loading={loading}
                          loadingPosition="end"
                          variant="contained"
                        >
                          Ajouter
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>{' '}
                </>
              </Box>
            </Container>
          ) : /* Affiche de moyen de virement */
          screen === '0' ? (
            <Container>
              <Grid container spacing={5}>
                {moyenVir.map((doc, index) => (
                  <Grid item xs={12} sm={4}>
                    <Card key={index} onClick={() => handleModif(index)} sx={{ padding: 3, cursor: 'pointer' }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {doc.data().type === 1 ? (
                            <Typography>
                              {doc.data().op === 1
                                ? 'MTN'
                                : doc.data().op === 0
                                ? 'Orange'
                                : doc.data().op === 2
                                ? 'Moov'
                                : null}
                            </Typography>
                          ) : doc.data().type === 0 ? (
                            <Typography>CB</Typography>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          {doc.data().type === 1 ? (
                            <Typography>{doc.data().mobile}</Typography>
                          ) : doc.data().type === 0 ? (
                            <Typography>{doc.data().iban}</Typography>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
          ) : /* Modification de moyen de virement */
          screen === '2' ? (
            <Container>
              <Box>
                <>
                  {/* Coordonnées bancaires */}
                  {moyenVir[activeModif].data().type === 0 ? (
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Box sx={{ p: 1 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Nom du titulaire</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              placeholder="Entrer le nom"
                              id="nameField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Code IBAN (N° de compte beneficiaire)</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={numBank}
                              onChange={(e) => setBank(e.target.value)}
                              placeholder="Nom et prenoms du titulaire du compte"
                              id="licenceField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Code BIC / SWIFT</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={codeSwift}
                              onChange={(e) => setSwift(e.target.value)}
                              placeholder="Code BIC / SWIFT"
                              id="licenceField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ p: 3 }} dir="ltr">
                        <Grid container spacing={1}>
                          <Grid item xs={6} sm={2} md={2}>
                            <LoadingButton
                              onClick={() => update(0)}
                              fullWidth
                              color="secondary"
                              loading={loading}
                              loadingPosition="end"
                              variant="contained"
                            >
                              Enregistrer
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Box>{' '}
                    </Box>
                  ) : (
                    <Box sx={{ p: 1 }} dir="ltr">
                      <Box sx={{ p: 1 }} dir="ltr">
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Nom du titulaire</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              placeholder="Nom et prenoms du titulaire du compte"
                              id="nameField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Opérateur mobile</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <FormControl fullWidth>
                              <Select
                                value={parseInt(opMobile)}
                                onChange={(e) => setOpMobile(e.target.value)}
                                labelId="demo-simple-select-label"
                                id="opMobile"
                                placeholder="Entrer le nom"
                              >
                                <MenuItem value={0}>Orange</MenuItem>
                                <MenuItem value={1}>MTN</MenuItem>
                                <MenuItem value={2}>Moov</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid paddingY={1} alignItems="center" container spacing={3}>
                          <Grid item xs={12} sm={4} md={4}>
                            <Typography>Mobile</Typography>
                          </Grid>

                          <Grid item xs={12} sm={8} md={6}>
                            <TextField
                              value={num}
                              onChange={(e) => setNum(e.target.value)}
                              placeholder="Numero mobile"
                              id="licenceField"
                              type="text"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ p: 3 }} dir="ltr">
                        <Grid container spacing={1}>
                          <Grid item xs={6} sm={2} md={2}>
                            <LoadingButton
                              onClick={() => update(1)}
                              fullWidth
                              color="secondary"
                              loading={loading}
                              loadingPosition="end"
                              variant="contained"
                            >
                              Mettre a jour
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Box>{' '}
                    </Box>
                  )}
                </>
              </Box>
            </Container>
          ) : null}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Reversement/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Comptabilite/>
      </TabPanel>
    </Box>
  );
}
